<template>

  <div id="mea" v-touch:start="disableSwipe" v-touch:end="enableSwipe">
    <!-- <b-carousel
      id="carousel-fade"
      indicators
      controls
      :interval="8000"
    > -->
    <b-carousel
      id="carousel-fade"
      indicators
      controls
      :interval="0"
    >

    <!-- <b-carousel-slide>
      <template #img>
      <div class="pre_wrap_slide">   
        <div class="visuel d-flex">
            <video width="320" height="240" autoplay loop muted>
              <source src="../../assets/images/featured/russian_collab.mp4" type="video/mp4">
            Your browser does not support the video tag.
            </video>
        </div>
        <div class="intro">
          <div class="headline">FEATURED: Art Therapy.</div>
          <div class="desc "><strong>Mad Russian Collaboration</strong></div>
          <div class="mention mt-2 "><a href="https://mainnet.sign-art.app/user/3PQ7hRjNApQD685Dd3sToSXFqnhP7R1V5yM/artwork/BT6uCccA6rnY6GXTikNL3jKsoyRKmAyTFsHY3ySfjmkh">Open for offers until 28/02/2022.</a></div> 
          <div class="cta">
            <a href="https://mainnet.sign-art.app/user/3PQ7hRjNApQD685Dd3sToSXFqnhP7R1V5yM/artwork/BT6uCccA6rnY6GXTikNL3jKsoyRKmAyTFsHY3ySfjmkh" tag="button" class="cta_btn">See the collaboration <img src="../../assets/images/arrow-link.svg"/></a>
          </div>
        </div>
      </div>
      
      <div class="wrap_slide">
      </div>
      </template>
    </b-carousel-slide>

    <b-carousel-slide>
      <template #img>
      <div class="pre_wrap_slide">   
        <div class="visuel d-flex">
            <video width="320" height="240" autoplay loop muted>
              <source src="../../assets/images/featured/camilla.mp4" type="video/mp4">
            Your browser does not support the video tag.
            </video>
        </div>
        <div class="intro">
          <div class="headline">FEATURED: Camilla Gulì</div>
          <div class="desc ">New collection from <strong>Merch.bio</strong></div>
          <div class="mention mt-2 "><a href="https://mainnet.sign-art.app/user/3PPhGayWfpENW9QwZj5Sk3L8cd2EzQYuYtc">Support Merch.bio fight against Ocean Pollution.</a></div> 
          <div class="cta">
            <a href="https://mainnet.sign-art.app/user/3PPhGayWfpENW9QwZj5Sk3L8cd2EzQYuYtc" tag="button" class="cta_btn">Visit her profile <img src="../../assets/images/arrow-link.svg"/></a>
          </div>
        </div>
      </div>
      
      <div class="wrap_slide">
      </div>
      </template>
    </b-carousel-slide>

     -->
     <b-carousel-slide>
      <template #img>
      <div class="pre_wrap_slide">   
        <div class="visuel d-flex">
          <video width="320" height="240" autoplay loop muted>
              <source src="../../assets/images/featured/brassart.mp4" type="video/mp4">
            Your browser does not support the video tag.
            </video>
        </div>
        <div class="intro">
          <div class="headline">Brassart &amp; SIGN Art Battle</div>
          <div class="desc ">All Brassart's Art Students invited for the big battle!</div>
          <div class="mention mt-2 "><a href="https://mainnet.sign-art.app/page/1?search=brassart&status=all">Discover all their drops!</a></div> 
          <div class="cta">
            <a href="https://battle.sign-art.app/" target="_blank" tag="button" class="cta_btn">Vote for your favorite artwork! <img src="../../assets/images/arrow-link.svg"/></a>
          </div>
        </div>
      </div>
      
      <div class="wrap_slide">
      </div>
      </template>
    </b-carousel-slide>

    <b-carousel-slide>
      <template #img>
      <div class="pre_wrap_slide">   
        <div class="visuel d-flex">
            <video width="320" height="240" autoplay loop muted>
              <source src="../../assets/images/featured/wallee.mp4" type="video/mp4">
            Your browser does not support the video tag.
            </video>
        </div>
        <div class="intro">
          <div class="headline">NEW 3D ROOM EXPERIENCE</div>
          <div class="desc ">Available for all <strong>Approved Artists</strong></div>
          <div class="mention mt-2 "><a href="https://room.sign-art.app/3PGCBDtL6s1gFezvoTDX23bcUY5UXJXyH1J" target="_blank">Visit your favorite artist's portfolio in a 3D environment.</a></div> 
          <div class="cta">
            <a href="https://room.sign-art.app/3P9KqYuACcbHBLVxtG4GceE93KUjUyo5nqP" tag="button" class="cta_btn" target="_blank">Enzo <img src="../../assets/images/arrow-link.svg"/></a>
            <a href="https://room.sign-art.app/3PCqKWRJRPpUPBaWtFKWcSj1mZ24pwcBNw1" tag="button" class="cta_btn" target="_blank">Bernadarte  <img src="../../assets/images/arrow-link.svg"/></a>
            <a href="https://room.sign-art.app/3PR3qePu5rSE7h9moBa19dfkAKFaMjtG3MD" tag="button" class="cta_btn" target="_blank">FreensidArt  <img src="../../assets/images/arrow-link.svg"/></a>
          </div>
        </div>
      </div>
      
      <div class="wrap_slide">
      </div>
      </template>
    </b-carousel-slide>

     <b-carousel-slide>
      <template #img>
      <div class="pre_wrap_slide">   
        <div class="visuel d-flex">
          <a href="https://mainnet.sign-art.app/user/3PHxCuCpq5sS4ZnBMEwKRbNzNa9P6qDSZxH/collection/159/page/1">
            <img src="../../assets/images/featured/joey.jpg" class="img-fluid d-block">
          </a>
        </div>
        <div class="intro">
          <div class="headline">COLLECTION Women of A.I.</div>
          <div class="desc ">By artist Joey Gates</div>
          <div class="mention mt-2 "><a href="https://mainnet.sign-art.app/user/3PHxCuCpq5sS4ZnBMEwKRbNzNa9P6qDSZxH/collection/159/page/1">Renders of A.I. women artistically enhanced.</a></div> 
          <div class="cta">
            <a href="https://mainnet.sign-art.app/user/3PHxCuCpq5sS4ZnBMEwKRbNzNa9P6qDSZxH/collection/159/page/1" tag="button" class="cta_btn">The collection <img src="../../assets/images/arrow-link.svg"/></a>
            <a href="https://room.sign-art.app/3PHxCuCpq5sS4ZnBMEwKRbNzNa9P6qDSZxH/collection/159/page/1" tag="button" class="cta_btn" target="_blank">In the 3D Room  <img src="../../assets/images/arrow-link.svg"/></a>
          </div>
        </div>
      </div>
      
      <div class="wrap_slide">
      </div>
      </template>
    </b-carousel-slide>

    

    </b-carousel>
  </div>

</template>

<script>
import globalMixin from '@/mixins/global.js';
export default {
  mixins: [globalMixin],
  data: function(){
    return {}
  },
  mounted(){
    /* Element.prototype.shuffleChildren = function() {
      for (var i = this.children.length; i >= 0; i--) {
        this.appendChild(this.children[Math.random() * i | 0]);
      }
    };
    document.querySelector('#mea .carousel-inner').shuffleChildren(); */
  }
}
</script>
<style lang="scss">
  /* .carousel-caption {
      position: relative!important;
      right: 0!important;
      bottom: 0!important;
      left: 0!important;
      z-index: 0!important;
      padding-top: 0!important;
      padding-bottom: 0!important;
      color: #fff;      
  } */
  .carousel-item{
    position: relative;
    .pre_wrap_slide{
      width: 80%;
      margin: auto;
      display: flex;
      padding: 20px 30px;
      z-index: 2;
      @media(max-width:1200px){
         width: 90%;
      }
    }
    .wrap_slide{
      top: 0;
      width: 68%;
      height: 100%;
      position: absolute;
      z-index: -1;
      border: solid 2px #000;
      padding: 15px 30px;
      right: 12%;
      @media(max-width:1200px){
         width: 75%;
         right: 9%;
      }
    }
  }

  .carousel-control-prev,
  .carousel-control-next{
    width: 8%!important;
    opacity: 1!important;
    transition: opacity .4s ease!important;
    
    &:hover{
      opacity: 0.6!important;
    }
  }
  span.carousel-control-next-icon, span.carousel-control-prev-icon{
    background-image: none!important;
    width: 60px!important;
    height: 60px!important;
    border-radius: 50%;
    background-color: white;
  }
  span.carousel-control-next-icon:after,
  span.carousel-control-prev-icon:after {
      display: block;
      width: 60px;
      height: 60px;
      content: "";
      font-weight: 900;
      font-size: 30px;
      font-family: "Font Awesome 5 Free";
      color: #380c4f;
  }
  span.carousel-control-prev-icon:after {
    background: none;
    background-image: url("../../assets/images/arrow-left-slider.svg");
    background-repeat: no-repeat;
  }
  span.carousel-control-next-icon:after {
    background: none;
    background-image: url("../../assets/images/arrow-right-slider.svg");
    background-repeat: no-repeat;
  }

  .carousel-indicators{
    margin-top: 15px!important;
    position: relative!important;
    li{
      border-top: 0px solid transparent!important;
      border-bottom: 0px solid transparent!important;
      background-color: #380c4f!important;
      width: 12px!important;
      height: 4px!important;
      margin-right: 5px!important;
      margin-left: 5px!important;
      border-radius: 6px !important;
      transition: all .4s ease !important;
      &.active{
        width: 32px !important;
      }
    }
  }

  @media(max-width: 1200px){
    #mea{
      padding-left: 0;
      .visuel img{
        max-height: 250px;
      }
    }
    span.carousel-control-next-icon, span.carousel-control-prev-icon{
      width: 40px!important;
      height: 40px!important;
    }
    span.carousel-control-next-icon:after,
    span.carousel-control-prev-icon:after {
      width: 40px;
      display: flex;
      align-items: center;
      height: 40px;
      background-size: contain;
    }
  }
  @media(max-width: 992px){
    .carousel-control-prev, .carousel-control-next{
      display: none!important;
    }
    .carousel-item .pre_wrap_slide{
      width: 100%!important;
    }
    .carousel-item .pre_wrap_slide{
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 10px;
    }
    .carousel-item .wrap_slide {
      width: 96%;
      right: 2%;
      top: 10%;
      height: 90%;
    }
    #mea{
      .intro{
        margin-left: 0!important;
        width: 100%;
        text-align: left;
        .headline{
          margin-top: 20px!important;
        }
        .mention{
          margin-bottom: 0!important;
        }
        .cta{
          margin-top: 0!important;
        }
      }
    }
  }
  @media(max-width:768px){
    #mea{
      padding: 10px 0 2px 0!important;
    }
  }
   @media(max-width:560px){
    #mea{
      padding: 0px 0 2px 0!important;
    }
  }
</style>
<style lang="scss" scoped>
#mea{
    margin-bottom: 15px;
    padding: 20px 0 2px 0;
    .visuel{
      video{
        width: 100%;
        max-width: 460px;
        min-height: 300px;
        object-fit: cover;
      }
      img{
        max-height: 300px;
      } 
    }
    .intro{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: #380c4f;
      margin-left: 35px;
      .headline{
        font-size: 70px;
        line-height: 70px;
        width: 100%;
        font-family: IBM Plex Mono;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 130%;
        color: #1E1A1A;
        word-spacing: -3px;

      }
      .desc{
        font-size: 25px;
        width: 100%;
        line-height: 32px;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 130%;
        color: #000000;
        a{
          color: #380c4f;
        }
        @media(max-width: 1690px){
          font-size: 20px;
        }
        @media(max-width: 1400px){
          font-size: 16px;
        }
      }
      .mention{
        font-size: 18px;
        width: 100%;
        margin-top: 0;
        a{
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.05em;
          color: #9A0E88;
          span{
            color: #000;
          }

        }
        @media(max-width: 1690px){
          font-size: 16px;
        }
      }
    }
    .cta{
      
      &:before{
        content: "";
        width: 150px;
        height: 2px;
        background-color: #000;
        display: block;
        margin-top: 40px;
        margin-bottom: 15px;
        @media(max-width:1200px){
          margin-top: 20px;
          margin-bottom: 10px;
        }
      }
      .cta_btn{
        background-color: white;
        padding: 0;
        transition: all .4s ease;
        margin-right: 15px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.05em;
        color: #9A0E88;
        display: inline-block;
        border-radius: 0;
        border-bottom: 2px solid white;
        transition: all .4s ease;
        &:last-child{
          margin-right:0px;
        }
        &:hover{
          border-bottom: 2px solid #9A0E88;
        }
      }
    }
  }
  @media (max-width: 1200px) and (min-width: 992px){
    #mea{
      padding-left: 0px;
    }
  }
  @media(max-width: 992px){
    #mea{
      text-align: center;
      margin-bottom: 0;
      .cta{
            margin-top: 20px;
      }
      .intro{
        .headline{
          margin-top: 50px;
          &.small, .small{
            font-size: 40px;
            line-height: 40px;
          }
          .small{
            font-size: 30px;
            line-height: 30px;
          }
        }
      }
      .mention{
        &.small{
          margin-top: 0!important;
        }
        margin-bottom: 25px;
      }
    }
  }
</style>