import Vue from 'vue'
import Vuex from 'vuex'
import { Signer } from "@waves/signer";
import { ProviderWeb } from "@waves.exchange/provider-web";
import { ProviderCloud } from '@waves.exchange/provider-cloud';
import { ProviderLedger } from '@waves/provider-ledger';
import { ProviderKeeper } from '@waves/provider-keeper';
import { ProviderKeeperMobile } from '@keeper-wallet/provider-keeper-mobile';
import { ProviderMetamask } from '@waves/provider-metamask';
import { ethTxId2waves } from '@waves/node-api-js';
/* import { wavesAddress2eth, wavesAsset2Eth } from '@waves/node-api-js'; */
/* import { ethTxId2waves } from '@waves/node-api-js'; */
import Web3 from 'web3';
import encodeUrl from 'encodeurl';
import VueCookies from "vue-cookies";
import ipfs from "../plugins/ipfs.js";
import { nodeInteraction, libs, validators } from "@waves/waves-transactions";

Vue.use(Vuex)
Vue.use(VueCookies);
Vue.$cookies.config('90d')

let configObj = {
  // retrieve decimal in detail page and the like and define it here for future
  network: process.env.VUE_APP_network,
  wavesExplorer: process.env.VUE_APP_wavesEplorer,
  assetID: process.env.VUE_APP_signAssetID,
  wavesAssetID: process.env.VUE_APP_wavesAssetID,
  ethAssetID: process.env.VUE_APP_ethAssetID,
  usdnAssetID: process.env.VUE_APP_usdnAssetID,
  usdtAssetID: process.env.VUE_APP_usdtAssetID,
  usdtPPAssetID: process.env.VUE_APP_usdtPPAssetID,
  dapp_addr_users: process.env.VUE_APP_dapp_addr_users,
  dapp_pubkey_users: process.env.VUE_APP_dapp_pubkey_users,
  dapp_addr_collectors: process.env.VUE_APP_dapp_addr_collectors,
  dapp_addr_artworks: process.env.VUE_APP_dapp_addr_artworks,
  dapp_pubkey_artworks: process.env.VUE_APP_dapp_pubkey_artworks,
  dapp_addr_secondary: process.env.VUE_APP_dapp_addr_secondary,
  dapp_addr_burn: process.env.VUE_APP_dapp_addr_burn,
  dapp_addr_whitelist: process.env.VUE_APP_dapp_addr_whitelist,
  sign_dapp_addr: process.env.VUE_APP_sign_dapp_addr,
  sign_dapp_pubKey: process.env.VUE_APP_sign_dapp_pubKey,
  signOracle: process.env.VUE_APP_signOracle,
  ipfsGateway: process.env.VUE_APP_ipfsGateway,
  nodeURL: process.env.VUE_APP_nodeURL,
  apiURL: process.env.VUE_APP_apiURL,
  providerUrl: process.env.VUE_APP_providerUrl,
  signwebappUrl: process.env.VUE_APP_signwebappUrl,
  signArtApiURL: process.env.VUE_APP_signArtApiURL, // "http://localhost:3200"
  wavesExchangeURL: process.env.VUE_APP_wavesExchange,
  matcherURL: process.env.VUE_APP_matcherURL,
  useIMGIX: process.env.VUE_APP_useIMGIX,
  wavesExchangeAPI: process.env.VUE_APP_wavesExchange_API,
  sign_art_gateway_url: process.env.VUE_APP_sign_art_gateway_url,
};

export const store = new Vuex.Store({
  state: {
    windowWidth: window.innerWidth,
    isMobile: false,
    config: configObj,
    enableSwipe: true,
    imgFit: false,
    nodeInteraction: nodeInteraction,
    cryptolibs: libs.crypto,
    signingChoice: {
      error: null,
      showChoice: false,
      keeper: false,
      signer: false
    },
    signer: {
      signer: null,
      provider: null,
      isMetamask: false,
      metamaskLogged: false
    },
    certificationPrice: null,
    sponsoredFee: null,
    sponsoredFeeUSDN: null,
    sponsoredFeeUSDT: null,
    sponsoredFeeUSDTPP: null,
    sponsoredFeeETH: null,
    url: {
      twitter: "https://twitter.com/signArtApp",
      telegram: "https://t.me/SIGNArtApp",
      discord: "https://t.co/MPQPKDM3ws",
      email: "contact@signature-chain.com",
      website: "https://www.signature-chain.com/",
    },
    defaultLicence: {
      cid: "bafybeigisfqtyo2qdfsceh5fpcp7eeymrpeglp6edao2bmyhijbayvausy/licence.pdf",
      hash: "3df79d34abbca99308e79cb94461c1893582604d68329a41fd4bec1885e6adb4"
    },
    ipfs: ipfs,
    adminAddress: "3P4JutBKW6pADm91FxMfGL3EYxKJtWYsWfX",
    user: {
      userAddress: "",
      publicKey: "",
      thumbnail: "",
      checkingInfos: false,
      status: null,
      isLogged: false,
      firstLogin: false,
      isSuspended: false,
      isWhiteListed: false,
      isRegistered: false,
      isRegisteredAsCollector: false,
      signBalance: null,
      usdnBalance: null,
      usdtBalance: null,
      usdtPPBalance: null,
      wavesBalance: null,
      userReady: false,
      sessionData: null,
      sessionDataGateway: null
    },
    priceInUSDN: {
      signPriceInUsd: 0,
      wavesPriceInUsd: 0,
      xtnPriceInUsd: 0
    },
    homeFilterUser: "REGISTERED", // REGISTERED to all both or VERIFIED to verified only
    homeFilterArtworks: "VERIFIED", // "" for all or REGISTERED or VERIFIED
    isFilteringArtHome: false,
    artistsList: [],
    artistsListVerified: [],
    artistsListRegistered: [],
    artistListLoaded: false,
    alert: false,
    alertMessage: "",
    activeMarket: "home-paginate",
    homeScrollState: {
      scrollToId: 0,
      artworksLoaded:0,
      allArtworks: null,
      cursors: [],
      cursor: ""
    },
    marketplaceScrollState: {
      scrollToId: 0,
    },
    collectionsScrollState: {
      scrollToId: 0,
    },
    artistScrollState: {
      scrollToId: 0,
      artworksLoaded:0,
      allArtworks: null
    },
    collectionItemScrollState: {
      scrollToId: 0
    },
    acceptedFiles: {
      display: {
        accept: ["image/png", "image/gif", "image/jpg", "image/jpeg", "video/mp4"],
        extVid: ["mp4"],
        extImg: ["jpg", "jpeg", "gif", "png"]
      },
      crawler: {
        accept: ["image/png", "image/jpg", "image/jpeg"],
        extImg: ["jpg", "jpeg", "png"]
      },
      collection_cover: {
        accept: ["image/png", "image/jpg", "image/jpeg", "image/gif"],
        extImg: ["jpg", "jpeg", "gif", "png"]
      },
      exported: {
        accept: ["image/png", "image/gif", "image/jpg", "image/jpeg", "video/mp4"],
        extVid: ["mp4"],
        extImg: ["jpg", "jpeg", "gif", "png"]
      },
      licence: {
        accept: [".doc", ".docx",".txt",".pdf"]
      }
    },
    searchStr: '',
    filtering:{
      home:{
        status: "all", // all or verified or register
        currency: "all",
        onsale: "all",
        fromRange: null,
        toRange: null,
        sort: 'dateDESC',
        crosschainOnly: 'no'
      },
      artist_init:{
        currency: "all",
        onsale: "all",
        fromRange: null,
        toRange: null,
        sort: 'dateDESC'
      },
      artist:{
        currency: "all",
        onsale: "all",
        fromRange: null,
        toRange: null,
        sort: 'dateDESC'
      },
      marketplace:{
        statusArtist: "verified",
        status: "open",
        currency: "all",
        onsale: "all",
        fromRange: null,
        toRange: null,
        sort: 'dateDESC',
        crosschainOnly: 'no'
      },
      collections:{
        status: "verified",
        sort: 'dateDESC',
        crosschainOnly: 'no'
      },
    },
    showBuyPopin: false
  },
  getters: {
    getWhiteListStatus: (state) => {
      return state.user.isWhiteListed
    },
    getUserObj: (state) => {
      return state.user;
    },
    getConfigObj: (state) => {
      return state.config;
    },
    getArtistsList: (state) => (keyword) => {
      let getListVerified = Object.values(state.artistsListVerified).filter((artist) => {
        if(artist.user_name){
          return artist.user_name.toLowerCase().includes(keyword.toLowerCase());
        }
      });

      let getListNonVerified = Object.values(state.artistsListRegistered).filter((artist) => {
        if(artist.user_name){
          return artist.user_name.toLowerCase().includes(keyword.toLowerCase());
        }
      });

      getListVerified = Object.values(getListVerified).sort(function(a,b){
        return new Date(parseInt(b.user_date)) - new Date(parseInt(a.user_date));
      });
      getListNonVerified = Object.values(getListNonVerified).sort(function(a,b){
        return new Date(parseInt(b.user_date)) - new Date(parseInt(a.user_date));
      });
      
      if (state.homeFilterUser != "") {
        return getListVerified
      }else{
        return [...getListVerified, ...getListNonVerified]
      }
    },
    getArtistByAddress: (state) => (addr) => {
      return Object.values(state.artistsList).filter((artist) => {
        return artist.user_addr == addr;
      });
    },
    getCookie: () => (name) => {
      return VueCookies.get(name)
    }
  },
  actions: {
    signerLogin: async function(context, choice) {
      this.state.signingChoice.error = null
      let data

      /* if(choice == "keeper"){
        let {account} = await window.WavesKeeper.publicState()
        if(account.network != this.state.config.network){
          this.state.signingChoice.error = "Wrong network, switch Waves Keeper to "+this.state.config.network.toUpperCase()
          return
        }
        try{
          const authData = { data: "Connect to SIGN Art" };
          data = await window.WavesKeeper.auth(authData)
        }catch(error){
          data = { error: error, result: false }
        }
      }else if(choice == "signer" || choice == "signer-email" || choice == "ledger" || choice == "signer-keeper"){ */
        try{
          
          await this.commit("initSigner", choice)
          data = await this.state.signer.signer.login()
          console.log("data");
          console.log(data)
        }catch(error){
          console.log(error)
          let betterError = error.toString().includes("not equals keeper connect") ? "Please switch keeper to "+ this.state.config.network : error
          data = { error: betterError, result: false }
        }
      //}
      if(!data.error){

        this.state.signingChoice.showChoice = false
        this.state.signingChoice.signer = choice == "signer" || choice == "signer-email" || choice == "ledger" || choice == "signer-keeper" || choice == "metamask" ? true : false
        //this.state.signingChoice.keeper = choice == "keeper" ? true : false
        this.state.user.userAddress = data.address
        this.state.user.publicKey = data.publicKey
        this.state.user.isLogged = true

        if(choice == "metamask"){
          this.state.signer.isMetamask = true
        }else{
          this.state.signer.isMetamask = false
        }

        VueCookies.set("loginChoice", {choice: choice, address: data.address, publicKey: data.publicKey})

        if (VueCookies.get("firstLogin") == true || VueCookies.get("firstLogin") == null) {
          this.state.user.firstLogin = true
        }
        this.state.signingChoice.error = null
        return {result: "ok"}
      }else{
        this.state.signingChoice.error = data.error
        return {result: data.error}
      }
      
    },
    loginMetamask: async function(){
      if(!this.state.signer.metamaskLogged && this.state.signer.isMetamask){
        let web3 = new Web3(window.ethereum || "ws://localhost:8545");
        let _this = this
        web3.eth.getAccounts(function(err, accounts){
          if (err != null) {
            console.error("An error occurred: "+err);
          }else if (accounts.length == 0) {
            console.log("User is not logged in to MetaMask");
          }else{
            console.log("User is logged in to MetaMask");
            _this.state.signer.metamaskLogged = true
          }
        });
        
        try{
          await this.state.signer.signer.login()
        }catch(err){
          console.log(err)
        }
      }
    },
    signerInvoke: async function(context, data) {
      let res
      let _this = this;

      // metamask require login first
      await this.dispatch('loginMetamask')
        try{
          
          if(data.payment && data.payment[0] && data.payment[0].amount){
            data.payment[0].amount = Math.round(data.payment[0].amount)
          }
          //console.log(JSON.stringify(data, undefined, 4));
          res = await this.state.signer.signer.invoke(data).broadcast()
        }catch(error){
          console.log("try ko")
          console.log(error)
          res = { error: error }
        }

      if(!res.error){
        console.log("res")
        console.log(res)
        let idToCheck = res.id ? res.id : res[0].id
        await this.state.nodeInteraction.waitForTx(
          idToCheck, { apiBase: _this.state.config.nodeURL }
        );
        let finalState = await this.state.nodeInteraction.stateChanges(
          idToCheck,  _this.state.config.nodeURL
        );
        let user = res && res.sender ? res.sender : null
        console.log("res")
        console.log(res)
        res = { data: finalState, user:  user, id: idToCheck};
      }

      return res
    },
    signerTransfer: async function(context, data) {
      let _this = this;
      let res
      await this.dispatch('loginMetamask')
      /* if(this.state.signingChoice.keeper){ //KEEPER
        //let decimalFee = data.data.feeAssetId == this.state.config.usdnAssetID ? 6 : 8
        let decimal = data.data.assetId == this.state.config.usdnAssetID ? 6 : 8
        let amount = data.isNFT ? data.data.amount : data.data.amount / Math.pow(10, decimal)
        let keeperData = {
          type: 4,
          data: {
              amount: { tokens: amount, assetId: data.data.assetId },
             // fee: { tokens: data.data.fee / Math.pow(10, decimalFee), assetId: data.data.feeAssetId},
              recipient: data.data.recipient,
              attachment: data.data.attachment
          }
        }
        try{
          res = await window.WavesKeeper.signAndPublishTransaction(keeperData)
        }catch(error){
          res = {error: error}
        }
      }else if(this.state.signingChoice.signer){ // SIGNER */
        
        /* if(this.state.signer.isMetamask){
          data.data.recipient = wavesAddress2eth(data.data.recipient)
        }

         if(this.state.signer.isMetamask){
          data.data.assetId = wavesAsset2Eth(data.data.assetId)
        } */
        try{
          console.log("data")
          console.log(data)
          res = await  this.state.signer.signer.transfer(data.data).broadcast()
          console.log("res")
          console.log(res)
        }catch(err){
          
          res = {error: err}
        }
      //}

      if(!res.error){
        console.log("start nodeInteraction")
        let idToCheck = res.id ? res.id : res[0].id

        idToCheck = validators.isBase58(idToCheck) ? idToCheck : ethTxId2waves(idToCheck)
        await this.state.nodeInteraction.waitForTx(
          idToCheck,
          { apiBase: _this.state.config.nodeURL }
        );
        res = { data: data.data };
        console.log("nodeInteraction done")
      }
      return res
    },
    signMessage: async function(context, data) { // notification/session
      console.log("data to sign")
      let res
      await this.dispatch('loginMetamask')
        try{
          res = await this.state.signer.signer.signMessage(data)
        }catch(err){
          res = {error: err}
        }
      //}
      return res
    },
    signMessageGateways: async function(context, data) { // Gateway wx

      let res
        try{
          res = await this.state.signer.signer.signMessage(data)
        }catch(err){
          console.log("res err")
          console.log(res)
          res = {error: err}
        }
      //}
      return res
    },
    getArtists: async function(context, status) {
      let _this = this;
           
      return await fetch(this.state.config.signArtApiURL + "/users/status/" + status).then((res) => res.json()).then((data) => {
        if(data.length){
         _this.commit("setArtistsList", {data: data, status: status});
        }
        return;
      }).catch((err) => console.log(err));
    },
    getPriceInUsd: async function(){

      let _this = this
      let signPriceInBusd, wavesPriceInBusd, xtnPriceInBusd

      wavesPriceInBusd = await fetch("https://wavescap.com/api/asset/WAVES.json")
      wavesPriceInBusd = await wavesPriceInBusd.json()
      

      xtnPriceInBusd = await fetch("https://wavescap.com/api/asset/XTN.json")
      xtnPriceInBusd = await xtnPriceInBusd.json()

      signPriceInBusd = await fetch("https://wavescap.com/api/asset/SIGN.json")
      signPriceInBusd = await signPriceInBusd.json()

      _this.commit("setPriceInUsd", {signPriceInUsd: signPriceInBusd.data.lastPrice_usdt, wavesPriceInUsd: wavesPriceInBusd.data.lastPrice_busd, xtnPriceInUsd: xtnPriceInBusd.data.lastPrice_busd});
   
      return

    },
    getCertificationPrice: async function(){
      let state = this.state;
      let _this = this
      let regex = encodeUrl("certification_fee_"+state.config.assetID)
      return await fetch(this.state.config.nodeURL + "/addresses/data/" + state.config.signOracle + "?matches="+regex).then((res) => res.json()).then((data) => {
        _this.commit("setCertificationPrice", data[0].value);
      })
      .catch((err) => console.log(err));
    },
    getSignBalance: function() {
      let state = this.state;
      let _this = this;
      return fetch(this.state.config.nodeURL + "/assets/balance/" + state.user.userAddress + "/" + state.config.assetID).then((res) => res.json()).then((data) => {
        _this.commit("setSignBalance", data);
        return;
      }).catch((err) => console.log(err));
    },
    getUsdnBalance: function() {
      let state = this.state;
      let _this = this;
      return fetch(this.state.config.nodeURL + "/assets/balance/" + state.user.userAddress + "/" + state.config.usdnAssetID).then((res) => res.json()).then((data) => {
        _this.commit("setUsdnBalance", data);
        return;
      }).catch((err) => console.log(err));
    },
    getUsdtBalance: function() {
      let state = this.state;
      let _this = this;
      return fetch(this.state.config.nodeURL + "/assets/balance/" + state.user.userAddress + "/" + state.config.usdtAssetID).then((res) => res.json()).then((data) => {
        _this.commit("setUsdtBalance", data);
        return;
      }).catch((err) => console.log(err));
    },
    getUsdtPPBalance: function() {
      let state = this.state;
      let _this = this;
      return fetch(this.state.config.nodeURL + "/assets/balance/" + state.user.userAddress + "/" + state.config.usdtPPAssetID).then((res) => res.json()).then((data) => {
        _this.commit("setUsdtPPBalance", data);
        return;
      }).catch((err) => console.log(err));
    },
    getWavesBalance: function() {
      let state = this.state;
      let _this = this;
      return fetch(this.state.config.nodeURL + "/addresses/balance/details/" + state.user.userAddress).then((res) => res.json()).then((data) => {
        _this.commit("setWavesBalance", data);
        return;
      }).catch((err) => console.log(err));
    },
    getSponsoredFee: function(){
      let state = this.state;
      let _this = this;
      return fetch(this.state.config.nodeURL + "/assets/details/" + state.config.assetID).then((res) => res.json()).then((data) => {
        _this.commit("setSponsoredFee", data);
        return;
      }).catch((err) => console.log(err));
    },
    getSponsoredFeeUSDN: function(){
      let state = this.state;
      let _this = this;
      return fetch(this.state.config.nodeURL + "/assets/details/" + state.config.usdnAssetID).then((res) => res.json()).then((data) => {
        _this.commit("setSponsoredFeeUSDN", data);
        return;
      }).catch((err) => console.log(err));
    },
    getSponsoredFeeUSDT: function(){
      let state = this.state;
      let _this = this;
      return fetch(this.state.config.nodeURL + "/assets/details/" + state.config.usdtAssetID).then((res) => res.json()).then((data) => {
        _this.commit("setSponsoredFeeUSDT", data);
        return;
      }).catch((err) => console.log(err));
    },
    getSponsoredFeeUSDTPP: function(){
      let state = this.state;
      let _this = this;
      return fetch(this.state.config.nodeURL + "/assets/details/" + state.config.usdtPPAssetID).then((res) => res.json()).then((data) => {
        _this.commit("setSponsoredFeeUSDT", data);
        return;
      }).catch((err) => console.log(err));
    },
    getSponsoredFeeETH: function(){
      let state = this.state;
      let _this = this;
      return fetch(this.state.config.nodeURL + "/assets/details/" + state.config.ethAssetID).then((res) => res.json()).then((data) => {
        _this.commit("setSponsoredFeeETH", data);
        return;
      }).catch((err) => console.log(err));
    },
    checkSignHashExist: function(context, hash) {
      let state = this.state;
      let regex = encodeUrl("^"+hash+"(.*)")
      return fetch(this.state.config.nodeURL + "/addresses/data/" + state.config.sign_dapp_addr + "?matches="+regex).then((res) => res.json()).then((data) => {
        return data
      }).catch((err) => console.log(err));
    },
    checkDappMode: async function(){
      let state = this.state;
      let regex = encodeUrl("conf_dapp_is_running")
      return await fetch(this.state.config.nodeURL + "/addresses/data/" + state.config.dapp_addr_users + "?matches="+regex).then((res) => res.json()).then((data) => {
        let dappIsRunning = data[0] ? data[0].value : true
        return dappIsRunning
      }).catch((err) => console.log(err));
    },
    checkStoredLogin: async function(){
      let loginChoiceStored = VueCookies.get("loginChoice")
      if(loginChoiceStored){
        await this.commit("initSigner", loginChoiceStored.choice)
        await this.commit("setLoginChoice", loginChoiceStored)
        await this.commit("getUserInfos")
      }

    },
    updateStatus: async function(context, status){
        await this.commit("setArtistStatus", status)
    },
    setCookie: async function(context, {name, data}){
        await this.commit("setCookieByName", {name, data});
    }
  },
  mutations: {
    setCookieByName: function(context, {name, data}){
      VueCookies.set(name, data);
    },
    setArtistStatus: function(context, status){
      this.state.user.status = status
    },
    setLoginChoice: function(context, data){
      this.state.signingChoice.showChoice = false
      this.state.signingChoice.signer = data.choice == "signer" || data.choice == "signer-email" || data.choice == "ledger" || data.choice == "signer-keeper"  || data.choice == "metamask" ? true : false
      //this.state.signingChoice.keeper = data.choice == "keeper" ? true : false
      this.state.user.userAddress = data.address
      this.state.user.publicKey = data.publicKey
      this.state.user.isLogged = true
      
      if(data.choice == "metamask"){
        this.state.signer.isMetamask = true
      }else{
        this.state.signer.isMetamask = false
      }
    },  
    setWindowWidth: function(state) {
      state.windowWidth = window.innerWidth;
    },
    initSigner: function(state, loginType) {

      let node = state.config.nodeURL

      // Remove when keeper work with our own node, for now for some reason only official waves node works, waves is looking into it
      /* if(loginType=="signer-keeper"){
        if(state.config.network == "mainnet"){
          node = "https://nodes.wavesnodes.com"
        }else{
          node = "https://nodes-testnet.wavesnodes.com"
        }
      } */
      // end remove

      state.signer.signer = new Signer({
        NODE_URL: node,
      });

      if(loginType == "signer"){
        state.signer.provider = new ProviderWeb(
          state.config.providerUrl
        ); 
        state.signer.signer.setProvider(state.signer.provider);
      }
      if(loginType == "signer-email"){
        state.signer.signer.setProvider(new ProviderCloud());
      }
      if(loginType == "signer-keeper"){
        state.signer.signer.setProvider(new ProviderKeeper());
      }
      if(loginType == "signer-keeper-mobile"){
        state.signer.signer.setProvider(new ProviderKeeperMobile());
      }
      if(loginType == "ledger"){
        let code = state.config.network == "mainnet" ? 87 : 84
        state.signer.signer.setProvider(new ProviderLedger({
          wavesLedgerConfig: { networkCode: code }
        }));
      }

      if(loginType == "metamask"){
        state.signer.isMetamask = true
        console.log("state.signer.isMetamask")
        let code = state.config.network == "mainnet" ? 87 : 84
        state.signer.signer.setProvider(new ProviderMetamask({
          wavesConfig: {
            nodeUrl: state.config.nodeURL,
            chainId: code
          }
        }));
      }else{
        state.signer.isMetamask = false
      }
      
    },
    getUserInfos: function(state) {

      if (state.user.userAddress) {
        state.user.checkingInfos = true;
        state.nodeInteraction.accountData({ address: state.config.dapp_addr_users, match: "^user(.*)" + state.user.userAddress }, this.state.config.nodeURL)
          .then((res) => {
            let status = res["user_status_" + state.user.userAddress];
            let thumb = res["user_thumb_" + state.user.userAddress];
            if (status) {
              if (status.value != "SUSPENDED") {
                state.user.isSuspended = false;
                if (status.value == "VERIFIED" || status.value == "REGISTERED" || status.value == "CHANGE_REQUIRED") {
                  state.user.isRegistered = true;
                }
              } else {
                state.user.isSuspended = true;
              }
              state.user.status = status.value
            }
            if (thumb) {
              state.user.thumbnail = thumb.value;
            }
            state.user.checkingInfos = false;
            //state.user.userReady = true
          })
          .catch((err) => {
            console.log(err);
            state.user.checkingInfos = false;
            //state.user.userReady = true
          });

        state.nodeInteraction.accountDataByKey("user_status_"+state.user.userAddress, state.config.dapp_addr_users, this.state.config.nodeURL)
          .then((res) => {
              if (res) {
                if(res.value == "VERIFIED" || res.value == "ALLOWED" || res.value == "REGISTERED" || res.value == "CHANGE_REQUIRED"){
                  state.user.isWhiteListed = true;
                  state.user.status = res.value
                }
                //state.user.userReady = true
              }
          })
          .catch((err) => {
            console.log(err)
            //state.user.userReady = true
          });
      }
    },
    /* setArtistsList: function(state, res) {

      let newData = [], orderedData
      if(res.data.length){
        
        res.data.forEach(function(data){
            let _key = data.key;
            let getAddr = _key.split("_")[1]
            let getStatus = _key.split("_")[0]
            newData.push({
              "user_addr": getAddr,
              "user_status": getStatus,
              "user_name": state.cryptolibs.bytesToString(state.cryptolibs.base64Decode(JSON.parse(data.value).user_name)),
              "user_thumb": state.cryptolibs.bytesToString(state.cryptolibs.base64Decode(JSON.parse(data.value).user_thumb)),
              "user_date" : JSON.parse(data.value).user_date
            })
            orderedData = Object.values(newData).sort(function(a, b) {
              return new Date(parseInt(b.user_date)) - new Date(parseInt(a.user_date));
            });
        })
        if(res.status == "VERIFIED"){
          state.artistsListVerified = orderedData;
        }else{
          state.artistsListRegistered = orderedData;
        }

        state.artistsList = state.artistsListVerified.concat(state.artistsListRegistered)
        state.artistListLoaded = true;
      } 
    }, */
    setArtistsList: function(state, res) {

      let newData = [], orderedData
      if(res.data.length){
        
        res.data.forEach(function(data){
            newData.push({
              "user_addr": data.address,
              "user_status": data.status,
              "user_name": data.name,
              "user_thumb": data.thumb,
              "user_date" : data.date
            })
            orderedData = Object.values(newData).sort(function(a, b) {
              return new Date(parseInt(b.date)) - new Date(parseInt(a.date));
            });
        })
        if(res.status == "VERIFIED"){
          state.artistsListVerified = orderedData;
        }else{
          state.artistsListRegistered = orderedData;
        }

        state.artistsList = state.artistsListVerified.concat(state.artistsListRegistered)
        state.artistListLoaded = true;
      } 
    },
    setCertificationPrice: function(state, res){
      state.certificationPrice = res / Math.pow(10,8)
    },
    setPriceInUsd: function(state, res){
      state.priceInUSDN = res
    },
    setSignBalance: function(state, res){
      state.user.signBalance = res.balance / Math.pow(10,8)
    },
    setUsdnBalance: function(state, res){
      state.user.usdnBalance = res.balance / Math.pow(10,6)
    },
    setUsdtBalance: function(state, res){
      state.user.usdtBalance = res.balance / Math.pow(10,6)
    },
    setUsdtPPBalance: function(state, res){
      state.user.usdtPPBalance = res.balance / Math.pow(10,6)
    },
    setWavesBalance: function(state, res){
      state.user.wavesBalance = res.available / Math.pow(10,8)
    },
    setSponsoredFee: function(state, res){
      state.sponsoredFee = res.minSponsoredAssetFee / Math.pow(10,8)
    },
    setSponsoredFeeUSDN: function(state, res){
      state.sponsoredFeeUSDN = res.minSponsoredAssetFee / Math.pow(10,6)
    },
    setSponsoredFeeUSDT: function(state, res){
      state.sponsoredFeeUSDT = res.minSponsoredAssetFee / Math.pow(10,6)
    },
    setSponsoredFeeUSDTPP: function(state, res){
      state.sponsoredFeeUSDTPP = res.minSponsoredAssetFee / Math.pow(10,6)
    },
    setSponsoredFeeETH: function(state, res){
      state.sponsoredFeeETH = res.minSponsoredAssetFee / Math.pow(10,8)
    }
  },
});