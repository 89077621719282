<template>
  <div>
      <div id="activity_drops" class="container-fluid">
          <!-- <h2 class="mb-5 mt-5 text-center">Recent activity</h2> -->
          <div class="row">
            <div class="col-lg-12 d-flex justify-content-start mb-3 nav">
              <div class="new artwork w-100" v-for="(item, key) in htmlArtworkData" :key="key">
                <drops-item  :item="item" />
              </div>
            </div>
            <div class="col-12">
              <div id="loading" class="mt-3 mb-3 text-center" v-if="isLoading">Loading historical activity...</div>
              <button class="btn btn-primary d-block mt-3 mb-3 mx-auto" id="loadmore" @click="getArtworksTxs()" v-else>Load more</button>
            </div>
          </div>
      </div>
  </div> 
</template>

<script>
import globalMixin from '@/mixins/global.js';
import activityMixin from '@/mixins/activity.js';
import ActivityDropItem from '@/components/content/activity/items/drop.vue'
export default {
  mixins: [globalMixin, activityMixin],
  data: function(){
    return {
        htmlArtworkData: [],
        after: null,
        isLoading: true,
        amountToGet: 20
    }
  },
  components:{
    'drops-item': ActivityDropItem
  },
  mounted(){
    this.getArtworksTxs()
  },
  methods: {
    getArtworksTxs: async function(){
      let thumb, imgix, artist, artwork, sender
      let _this = this

    this.isLoading = true
    let url
    if(this.after){
      url = `${this.$store.state.config.nodeURL}/transactions/address/${this.$store.state.config.dapp_addr_artworks}/limit/${this.amountToGet}?after=${this.after}`
    }else{
      url = `${this.$store.state.config.nodeURL}/transactions/address/${this.$store.state.config.dapp_addr_artworks}/limit/${this.amountToGet}`
    }
    let dataRes = await fetch(url)
    let resJSON = await dataRes.json()
    for( var tx of resJSON[0]){
      if(tx.type == 16 || (tx.type == 18 && tx.payload.type=="invocation")){
        if(tx.type==18){
          tx = {...tx,...tx.payload}
        }
        switch(tx.call.function){
          case "addArtwork":
            thumb = tx.call.args.length >= 12 && tx.call.args[11].value != "" ? tx.call.args[11].value : tx.call.args[6].value
            imgix  = await this.getImgixUrl(thumb)
            sender = tx.sender == this.$store.state.config.dapp_addr_artworks ? this.addressFromPublicKey(tx.call.args[13].value) : tx.sender
            artist = await fetch(this.$store.state.config.signArtApiURL+"/users/address/"+sender, )
            artist = await artist.json()
            _this.htmlArtworkData.push({
              txid: tx.id,
              function: "addArtwork",
              date: tx.timestamp,
              label: "Art added",
              price: null,
              image_link: `/user/${artist[0].address}/artwork/${tx.id}`,
              image_path: imgix.res[0].url,
              artist_link: `/user/${artist[0].address}`,
              artist_name: artist[0].name,
              description: this.trimLongText(tx.call.args[2].value)
            })

          break;

          case "updateArtwork":
            thumb = tx.call.args.length >= 11 && tx.call.args[10].value != "" ? tx.call.args[10].value : tx.call.args[5].value
            imgix  = await this.getImgixUrl(thumb)
            sender = tx.sender == this.$store.state.config.dapp_addr_artworks ? this.addressFromPublicKey(tx.call.args[12].value) : tx.sender
            artist = await fetch(this.$store.state.config.signArtApiURL+"/users/address/"+sender)
            artist = await artist.json()
            _this.htmlArtworkData.push({
              txid: tx.id,
              function: "updateArtwork",
              date: tx.timestamp,
              label: "Art updated",
              price: null,
              image_link: `/user/${artist[0].address}/artwork/${tx.call.args[0].value}`,
              image_path: imgix.res[0].url,
              artist_link: `/user/${artist[0].address}`,
              artist_name: artist[0].name,
              description: this.trimLongText(tx.call.args[1].value)
            })
            

          break;

          case "sellArtwork":
            artwork = await fetch(this.$store.state.config.signArtApiURL+"/artworks/txid/"+tx.call.args[0].value)
            artwork = await artwork.json()
            sender = tx.sender == this.$store.state.config.dapp_addr_artworks ? this.addressFromPublicKey(tx.call.args[5].value) : tx.sender
            if(artwork[0]){
              if(!this.isVideoActivity(artwork[0].display_cid)){
                thumb = artwork[0].display_cid 
              }else{
                let getCrawler = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_artworks}?matches=art_crawler_cid_${tx.call.args[0].value}_${sender}`)
                getCrawler = await getCrawler.json()
                thumb = getCrawler[0].value
              } 
              imgix  = await this.getImgixUrl(thumb)
              _this.htmlArtworkData.push({
                txid: tx.id,
                function: "sellArtwork",
                date: tx.timestamp,
                label: "Art for sale",
                price: this.humanPrice(tx.call.args[1].value, tx.call.args[3].value),
                image_link: `/user/${artwork[0].userinfo.address}/artwork/${tx.call.args[0].value}`,
                image_path: imgix.res[0].url,
                artist_link: `/user/${artwork[0].userinfo.address}`,
                artist_name: artwork[0].userinfo.name,
                description: this.trimLongText(artwork[0].name)
              })
            

            }
          break;

          case "buyArtwork":
            artwork = await fetch(this.$store.state.config.signArtApiURL+"/artworks/txid/"+tx.call.args[0].value)
            artwork = await artwork.json()
            if(artwork[0]){
              if(!this.isVideoActivity(artwork[0].display_cid)){
                thumb = artwork[0].display_cid 
              }else{
                let getCrawler = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_artworks}?matches=art_crawler_cid_${tx.call.args[0].value}_${artwork[0].userinfo.address}`)
                getCrawler = await getCrawler.json()
                thumb = getCrawler[0].value
              } 
              imgix  = await this.getImgixUrl(thumb)
              _this.htmlArtworkData.push({
                txid: tx.id,
                function: "buyArtwork",
                date: tx.timestamp,
                label: "Art sold",
                price: this.humanPrice(tx.payment[0].amount, tx.payment[0].assetId), //this.numberWithSpaces(tx.payment[0].amount)+" "+this.getTickerByAssetId(tx.payment[0].assetId),
                image_link: `/user/${artwork[0].userinfo.address}/artwork/${tx.call.args[0].value}`,
                image_path: imgix.res[0].url,
                artist_link: `/user/${artwork[0].userinfo.address}`,
                artist_name: artwork[0].userinfo.name,
                description: this.trimLongText(artwork[0].name),
                collector: {
                  name: tx.sender == artwork[0].userinfo.address ? artwork[0].userinfo.name : this.trimLongAddress(tx.sender),
                  url: `/user/${tx.sender}`
                }
              })


            }
          break;

          case "setBid":
            artwork = await fetch(this.$store.state.config.signArtApiURL+"/artworks/txid/"+tx.call.args[0].value)
            artwork = await artwork.json()
            if(artwork[0]){
              if(!this.isVideoActivity(artwork[0].display_cid)){
                thumb = artwork[0].display_cid 
              }else{
                let getCrawler = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_artworks}?matches=art_crawler_cid_${tx.call.args[0].value}_${artwork[0].userinfo.address}`)
                getCrawler = await getCrawler.json()
                thumb = getCrawler[0].value 
              }
              imgix  = await this.getImgixUrl(thumb)
              _this.htmlArtworkData.push({
                txid: tx.id,
                function: "setBid",
                date: tx.timestamp,
                label: "New offer",
                price: this.humanPrice(tx.payment[0].amount, tx.payment[0].assetId), //this.numberWithSpaces(tx.payment[0].amount)+" "+this.getTickerByAssetId(tx.payment[0].assetId),
                image_link: `/user/${artwork[0].userinfo.address}/artwork/${tx.call.args[0].value}`,
                image_path: imgix.res[0].url,
                artist_link: `/user/${artwork[0].userinfo.address}`,
                artist_name: artwork[0].userinfo.name,
                description: this.trimLongText(artwork[0].name),
                collector: {
                  name: tx.sender == artwork[0].userinfo.address ? artwork[0].userinfo.name : this.trimLongAddress(tx.sender),
                  url: `/user/${tx.sender}`
                }
              })
              
            }
          break; 

          case "acceptBid":
            artwork = await fetch(this.$store.state.config.signArtApiURL+"/artworks/txid/"+tx.call.args[0].value)
            artwork = await artwork.json()
            if(artwork[0]){
              if(!this.isVideoActivity(artwork[0].display_cid)){
                thumb = artwork[0].display_cid 
              }else{
                let getCrawler = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_artworks}?matches=art_crawler_cid_${tx.call.args[0].value}_${tx.sender}`)
                getCrawler = await getCrawler.json()
                thumb = getCrawler[0].value 
              } 
              imgix  = await this.getImgixUrl(thumb)
              var bidData = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_artworks}?matches=bid_${tx.call.args[0].value}_${tx.call.args[1].value}_${tx.call.args[3].value}_${tx.call.args[2].value}_(.*)`)
              bidData = await bidData.json()
              bidData = bidData[0].value.split("_")
              _this.htmlArtworkData.push({
                txid: tx.id,
                function: "setBid",
                date: tx.timestamp,
                label: "Offer accepted",
                price: this.humanPrice(bidData[1], bidData[0]),
                image_link: `/user/${artwork[0].userinfo.address}/artwork/${tx.call.args[0].value}`,
                image_path: imgix.res[0].url,
                artist_link: `/user/${artwork[0].userinfo.address}`,
                artist_name: artwork[0].userinfo.name,
                description: this.trimLongText(artwork[0].name),
                collector: {
                  name: tx.call.args[2].value == artwork[0].userinfo.address ? artwork[0].userinfo.name : this.trimLongAddress(tx.call.args[2].value),
                  url: `/user/${tx.call.args[2].value}`
                }
              })
              
            }
          break;

          case "flagArtwork":
            artwork = await fetch(this.$store.state.config.signArtApiURL+"/artworks/txid/"+tx.call.args[0].value)
            artwork = await artwork.json()
            if(artwork[0]){
              if(!this.isVideoActivity(artwork[0].display_cid)){
                thumb = artwork[0].display_cid 
              }else{
                let getCrawler = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_artworks}?matches=art_crawler_cid_${tx.call.args[0].value}_${tx.call.args[1].value}`)
                getCrawler = await getCrawler.json()
                thumb = getCrawler[0].value 
              } 
              imgix  = await this.getImgixUrl(thumb)

              _this.htmlArtworkData.push({
                txid: tx.id,
                function: "flagArtwork",
                date: tx.timestamp,
                label: "Admin update",
                price: null,
                image_link: `/user/${artwork[0].userinfo.address}/artwork/${tx.call.args[0].value}`,
                image_path: imgix.res[0].url,
                artist_link: `/user/${artwork[0].userinfo.address}`,
                artist_name: artwork[0].userinfo.name,
                description: this.trimLongText(artwork[0].name),
                collector: null,
                flag: {
                  label: tx.call.args[2].value,
                  note: tx.call.args[3] != undefined ? tx.call.args[3].value : ""
                }
              })
              
            }
          break;

          case "deleteArtwork":
            artwork = await fetch(`${this.$store.state.config.nodeURL}/transactions/info/${tx.call.args[0].value}`)
            artwork = await artwork.json()
            artist = await fetch(this.$store.state.config.signArtApiURL+"/users/address/"+tx.call.args[1].value)
            artist = await artist.json()
            if(artwork.type==18){
              artwork = {...artwork, ...artwork.payload}
            }
            if(!this.isVideoActivity(artwork.call.args[6].value)){
                thumb = artwork.call.args[6].value 
              }else{
                let getCrawler = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_artworks}?matches=art_crawler_cid_${tx.call.args[1].value}_${tx.call.args[0].value}`)
                getCrawler = await getCrawler.json()
                thumb = getCrawler[0].value 
              } 
              imgix  = await this.getImgixUrl(thumb)

              _this.htmlArtworkData.push({
                txid: tx.id,
                function: "deleteArtwork",
                date: tx.timestamp,
                label: "Delete artwork",
                price: null,
                image_link: ``,
                image_path: imgix.res[0].url,
                artist_link: `/user/${artist[0].address}`,
                artist_name: artist[0].name,
                description: this.trimLongText(artwork.call.args[2].value),
                collector: null
              })
          break;

          default:
            
        }
      }
      this.after = tx.id
    }
    
    this.isLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>

</style>