<template>
  <div class="col-md-4 col-lg-4 col-xl-2 p-0" id="sidebar">
    <div id="closeMobile" @click="closeMenu"><i class="fa fa-times" aria-hidden="true"></i></div>
    <div id="top">
      <router-link :to="'/page/1'+dropsQueries" @mousedown.native="resetHomeScroll">
        <img src="../../assets/images/logo.svg" class="w-100 img-fluid d-block" id="mainLogo" width="185" @click="closeMenu"/>
      </router-link>
    </div>
    <h2><span class="artists_title">ARTISTS</span> <!-- <span class="artists_num">({{ numOfArtist }})</span> --></h2>
    <div id="wrap_search">
      <i class="fas fa-times" @click="clearSearch" v-show="searchArtist!=''"></i>
      <input type="text" id="search" v-model="searchArtist" placeholder="Search artist" autocomplete="off" class="img-fluid mt-3 w-100 mx-auto mb-3 mt-0" :class="{'searching':searchArtist!=''}"/>
      <div class="wrapCheckBox mb-2" @click="showAllArtist" :class="{'on': !verifiedOnly}">
        <div class="checkBox"><i class="fas fa-check"></i></div> <span>{{ verifiedOnly ? 'Approved Artists only' : 'All Artists' }} </span>
      </div>
    </div>
    
    <transition name="fade">
      <div class="loadNonVerified px-4" :class="{'show':loadNonVerified}"><div class="text-wrap" >Loading data...</div></div>
    </transition>
    <div id="artistsListWrap">
     
      <ul id="artistsListWrapUl">
        <div class="wrap_artists" v-show="artistsLoaded" v-lazy-container="{ selector: 'img' }">
          <li class="user_link" v-for="(artist, index) in getArtistsList" :key="artist.user_addr" :data-id="index" :data-name="artist.user_name" :data-addr="artist.user_addr" >
            <router-link :to="'/user/'+artist.user_addr" @mousedown.native="resetArtistFilter(); resetArtistScroll();" exact>
              <div class="wrap_thumb" :class="{'verified':artist.user_status=='VERIFIED'}" >
                <img :data-src="artist.user_thumb != '' ? getIpfsLink(artist.user_thumb) : emptyThumb" :data-loading="spinerSidebarSrc" class="user_thumb" @click="closeMenu" />
              </div>
              <div class="nom" @click="closeMenu">{{artist.user_name | shortName}}</div>
            </router-link>
          </li>   
        </div>
      </ul>
      
    </div>
    <app-signup></app-signup>
    <div class="bas">
      <app-stats></app-stats>
      <ul>
          <li class="pl-0"><a :href="$store.state.url.telegram" target="_blank" v-b-tooltip.hover.top title="Telegram"><i class="fab fa-telegram"></i></a></li>
          <li><a :href="$store.state.url.twitter" target="_blank" v-b-tooltip.hover.top title="Twitter"><i class="fab fa-twitter"></i></a></li>
          <li><a :href="$store.state.url.discord" target="_blank" v-b-tooltip.hover.top title="Discord"><i class="fab fa-discord"></i></a></li>
          <li><a :href="'mailto:'+$store.state.url.email" target="_blank" v-b-tooltip.hover.top title="Email"><img src="../../assets/images/envelop.svg" /></a></li>            
          <li><a :href="$store.state.url.website" target="_blank" v-b-tooltip.hover.top title="Website"><i class="fas fa-globe-americas"></i></a></li>
          <li  class="pr-0" v-b-tooltip.hover.top title="Newsletter" @click="newsLetter"><i class="fas fa-newspaper"></i></li>
      </ul>
    </div>
  </div>
</template>

<script>

import Scrollbar from "smooth-scrollbar";
import globalMixin from '@/mixins/global.js';
import SignUp from '@/components/elements/Signup.vue'
import Stats from '@/components/elements/Stats.vue'

export default {
  name: 'Sidebar',
  mixins: [globalMixin],
  props: {
    menuOpen: Boolean
  },
  data: function(){
    return {
      searchArtist: '',
      artistsLoaded: false,
      numOfArtist: 0,
      verifiedOnly: true,
      loadNonVerified: false
    }
  },
  components: {
    'app-signup': SignUp,
    'app-stats': Stats
  },
  filters: {
    shortName: function(name){
      return name.length >= 28 ? name.substring(0, 28) + "..." : name
    }
  },
  methods: {
    clearSearch: function(){
      this.searchArtist = ""
    },
    newsLetter: async function(){
        this.$root.$emit('openNewsletter')
    },
    showAllArtist: async function(){
      
      this.verifiedOnly = !this.verifiedOnly
      /* if(this.$route.name == "home" || this.$route.name == "home-paginate"){
        this.$store.state.isFilteringArtHome = true
      } */
      if(this.verifiedOnly){
        this.$store.state.homeFilterUser = "VERIFIED"
        this.$store.state.homeFilterArtworks = "VERIFIED"
      }else{  
        this.loadNonVerified =true
        await this.$store.dispatch("getArtists", "REGISTERED")
        this.$store.state.homeFilterUser = ""
        this.$store.state.homeFilterArtworks = ""
        this.loadNonVerified = false
      }
    },
    closeMenu(){
      this.$parent.menuOpen = false
    }
  },
  watch: {
    '$store.state.artistsList'(){
      if(this.$store.state.artistsList != {}){
        this.artistsLoaded = true;
      }
    }
  },
  computed: {
    getArtistsList: function(){
      let _this = this
      let artistList = this.$store.getters.getArtistsList(this.searchArtist)
      _this.numOfArtist = artistList.length
      return artistList
    },
    dropsQueries() {
        return `?search=${this.$store.state.searchStr}&status=${this.$route.query.status || this.$store.state.filtering.home.status}`
    },
  },
  mounted(){
    const ulUsersElm = document.getElementById("artistsListWrapUl");
    if (ulUsersElm){
      Scrollbar.init(ulUsersElm);
    }
  }
}
</script>

<style lang="scss" scoped>
  #wrap_search{
    padding: 0 25px;
    position: relative;
    .fa-times{
      cursor: pointer;
      color: white;
      position: absolute;
      left: 45px;
      top: 30px;
    }
    input{
      background: transparent;
      border: solid 1px white;
      border-radius: 50px;
      padding: 10px 15px 10px 45px;
      color: white;
      background: url("../../assets/images/search.svg");
      background-repeat: no-repeat;
      background-position: 15px center;
      &.searching{
        background: transparent;
      }
      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #DFDFDF!important;
        opacity: 0.5; /* Firefox */
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.02em;
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #DFDFDF !important;
        opacity: 0.5;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.02em;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: #DFDFDF !important;
        opacity: 0.5;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.02em;
      }
    }

    .wrapCheckBox{
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 0 15px;
      span{
        font-weight: 400;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 147%;
        pointer-events: none;
      }
      .checkBox{
        border: solid 1px white;
        border-radius: 2px;
        padding: 0 4px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        .fa-check{
          color: #BA53F0;
          font-size: 10px;
          line-height: 12px;
        }
      }

      &.on{
        .fa-check{
          color: transparent;
        }
      }
    }
  }
  .loadNonVerified{
      text-align: center;
      margin-bottom: 0px;
      font-size: 13px;
      color: #e0acfb;
      margin-top: 0px;
      position: absolute;
      pointer-events: none;
      opacity: 0;
      &.show{
        
        transition: opacity .4s ease;
        opacity:1;
        position: relative;
      }
  }
  .fa-newspaper{
    cursor: pointer;
    font-size: 18px;
  }
  .scroll-content{
    width: 100%;
  }
  .tooltip{
        max-width: 150px;
  }
  h2{
    line-height: 20px;
    padding: 0 25px;
    
    .artists_title{
      font-weight: 400;
      font-size: 24px;
      width: 100%;
      display: block;
      border-bottom: 1px solid #662483;
      padding-bottom: 8px;
      letter-spacing: 0.05em;
    }
    .artists_num{
      font-size: 16px;
      margin-top: -2px;
    }
    i{
      margin-left: 8px;
    }
  }
  .wrap_artists {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 25px;
    .wrap_thumb{
      position: relative;
      width: 40px;
      margin-right: 10px;
      img[lazy=loading] {
        width: 25px!important;
        height: 25px!important;
      }
      &.verified{
        &:before {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f058";
          position: absolute;
          top: 0;
          right: -2px;
          font-size: 12px;
          color: #7F00FF;
          background-color:white;
          border-radius: 50%;
          top: 0;
          line-height: 10px;
          width: 10px;
          top: 3px;
        }
      }
    }
  }

  @media(max-width: 768px){
    .tooltip{
      display: none!important;
    }
  }

  @media(max-width: 580px){
    #main{
      #sidebar{ 
        #top {
          padding-top: 15px!important;
          padding: 15px;
        }
        h2{
          padding: 0 15px;
          padding-top: 10px;
        }
        #wrap_search{
            padding: 0 15px;
            .fa-times{
              left: 35px;
              top: 26px;
            }
            input{
              padding: 5px 15px 5px 45px;
            }
        }
        .wrap_artists{
          padding: 0 25px;
        }
        .bas{
          padding: 0px 15px 15px 15px;
          ul{
            li{
              padding: 5px 5px 0 5px;
            }
          }
        }
      }
    }
  }
</style>