<template>
  <ul class="tabs-links d-flex mr-lg-1">
      <router-link :to="'/user/'+artistInfos.user_addr" @click.native="resetArtistScroll()" exact tag="li" :class="{active: $route.name == 'user-infos' || $route.name == 'user-infos-front'}">
        Created <span v-if="created_amount>0">{{ created_amount }}</span>
      </router-link>
      <router-link :to="'/user/'+artistInfos.user_addr+'/collections/page/1'" @click.native="resetArtistScroll()" exact tag="li" :class="{active: $route.name == 'user-collections' || $route.name == 'user-collection-detail'}">
        Collections <span v-if="collections_amount>0">{{ collections_amount }}</span>
      </router-link>
      <router-link :to="'/user/'+artistInfos.user_addr+'/owned'" @click.native="resetArtistScroll()" exact tag="li" :class="{active: $route.name == 'user-owned'}">
        Owned <span v-if="owned_amount>0">{{ owned_amount }}</span>
      </router-link>
  </ul>
</template>
<script>
import tabsCount from '@/mixins/tabsCount.js';

export default {
  mixins: [tabsCount],
  props: ['artistInfos'],
  data: function(){
    return {
      created_amount: 0,
      collections_amount: 0,
      owned_amount: 0
    }
  },
  async created(){
    this.created_amount = await this.getCreatedAmount()
    this.collections_amount = await this.getCollectionsAmount()
    this.owned_amount = await this.getOwnedAmount()
  },
}
</script>
<style lang="scss">
  .tabs-links li span{
    font-size: 13px;
    font-weight: 400;
    color: rgb(103, 103, 103);
  }
</style>