<template>
  <div>
    <div id="registerFirst">
      <div v-if="!$store.state.user.checkingInfos" class="wrap text-center">
        <strong>Please register as an artist first to use this functionality:</strong>
        <div class="mt-4">
          <a href="https://sign-art.app/register" target="_blank" class="white_link"><strong>REGISTRATION FORM</strong></a>
        </div>
        <div class="mt-4">Contact-us</div>
        <ul class="d-flex mt-1">
          <li><a :href="$store.state.url.telegram" target="_blank" v-b-tooltip.hover.top title="Telegram"><i class="fab fa-telegram"></i></a></li>
          <li><a :href="$store.state.url.twitter" target="_blank" v-b-tooltip.hover.top title="Twitter"><i class="fab fa-twitter"></i></a></li>
          <li><a :href="$store.state.url.discord" target="_blank" v-b-tooltip.hover.top title="Discord"><i class="fab fa-discord"></i></a></li>
          <li><a :href="'mailto:'+$store.state.url.email" target="_blank" v-b-tooltip.hover.top title="Email"><i class="far fa-envelope"></i></a></li>            
          <li><a :href="$store.state.url.website" target="_blank" v-b-tooltip.hover.top title="Website"><i class="fas fa-globe-americas"></i></a></li>
        </ul>
      </div>
      <div v-else class="wrap text-center">
        Checking user data for {{$store.state.user.userAddress}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function(){
    return {}
  }
}
</script>
<style lang="scss" scoped>
  h4{
    font-weight: 700;
  }
  .white_link{
    color: white!important;
    &:hover{
      text-decoration: underline;
    }
  }
</style>