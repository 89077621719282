<template>
  <div>
    <div v-if="$store.state.user.isWhiteListed || $store.state.user.isSuspended">
      <h2 class="text-center mt-3 mb-3">Manage your artworks</h2>
      <ul id="artworksTabs">
        <li>
          <router-link :to="'/user/'+$store.state.user.userAddress+'/account/artworks/list'" tag="a"  class="nav-link">
            Your Artworks
          </router-link>
        </li>
        <li>
          <router-link :to="'/user/'+$store.state.user.userAddress+'/account/artworks/collections/page/1'" tag="a"  class="nav-link" :class="{active: $route.name == 'user-artworks-collections' || $route.name == 'user-artworks-collections-detail'}">
            Your Collections
          </router-link>
        </li>
      </ul>
      <router-view></router-view>
    </div>
    <register v-else></register>
  </div> 
</template>

<script>
import globalMixin from '@/mixins/global.js';
import orderedArtworks from "@/mixins/orderedArtworks.js"
import Register from '@/components/elements/Register.vue'

export default {
  mixins: [globalMixin, orderedArtworks],
  data: function(){
    return {
      loadmore: false,
      allArtworks: undefined,
      artworks: undefined,
      artworksIndex: 0,
      elementsInit: 15,
      elementsInitMobile: 10,
      elementsPerScroll: 10,
      elementsPerScrollMobile: 6 
    }
  },
  components:{
    "register": Register
  },
  mounted(){},
  methods: {
  }
}
</script>
<style lang="scss" scopped>
  ul#artworksTabs{
    display: flex!important;
    justify-content: center;
    border-bottom: 1px solid #b5b5b5;
    margin-bottom: 15px;
    li{
      margin-right: 15px;
      &:last-child{
        margin-right: 0;
      }
      margin-bottom: -1px;
      .nav-link{
        border: solid 1px #b5b5b5;
        border-radius: 5px 5px 0 0;
        color: #898989;
        
        &.router-link-active, &.active{
          border-bottom: solid 1px white;
        }
      }
    }
  }
</style>