<template>
  <div>
    <div >
      <h2 class="text-center mt-3 mb-0 pb-0">Update your account info</h2>
      <div class="mb-4 text-center mt-3">
        <p class="mb-1" v-if="note == ''"><strong><span>{{ $store.state.user.isWhiteListed && statusNotAllowed ? "Artist" : "Collector" }}</span> account</strong> with status: <span>{{ status != '' ? status : 'UNREGISTERED' }}</span></p>
        <p v-else id="suspended">{{ status }} - {{note}}</p>
      </div>
      <div v-if="$store.state.user.isWhiteListed && !statusNotAllowed" class="migrate col-xl-10 mx-auto text-center mb-4">
        You have been accepted as an artist, please click "UPDATE INFORMATIONS" to access artist features.
      </div>
      <div id="response" :class="{error: haveError}" v-show="responseMessage!=''" class="col-xl-10 mx-auto text-center mt-3">
        <div class="wrap">{{responseMessage}}</div>
      </div>
      <form>
        <div class="form-group">
          <div class="container">
            <div class="row">
              <div class="col-12 col-lg-8 offset-lg-2">
                <img :src="previewImage" class="uploading-image d-block mx-auto" :class="{'fade': imageCompressionAlert!='' && imageCompressionAlert!='Image too big'}"/>
                <div class="imageCompressionAlert mention" v-if="imageCompressionAlert!=''">{{imageCompressionAlert}}</div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-12 col-lg-8 offset-lg-2">
                <div class="form-group">
                  <label for="file">Choose a picture for your profile <span class="mention">(80Kb maximum or it will be resized)</span></label>
                  <input type="file" id="thumbnail" @change="uploadImage($event)" class="form-control mb-2" accept="image/*"/>
                </div>
                <div class="form-group">
                  <label for="userName">Choose a public name <span class="mandatory">*</span> ({{ userName.length }} / 45 characters)</label>
                  <input type="text" id="userName" v-model="userName" class="form-control mb-2" placeholder="Displayed name" maxlength="45"/>
                </div>
                <div class="form-group">
                  <label for="userIntro">Enter your description <span class="mandatory">*</span> ({{ userIntro.length }} / 600 characters)</label>
                  <textarea id="userIntro" v-model="userIntro" class="form-control mb-2" placeholder="Description"></textarea>
                </div>
                <div class="row">
                  <div class="form-group col-lg-6 mb-2">
                    <label for="facebook">Facebook link</label>
                    <input type="text" id="facebook" v-model="social.facebook" class="form-control mb-2" placeholder="ex: https://www.facebook.com/julienVersan/"/>
                  </div>
                  <div class="form-group col-lg-6 mb-2">
                    <label for="twitter">Twitter link</label>
                    <input type="text" id="twitter" v-model="social.twitter" class="form-control mb-2" placeholder="ex: https://twitter.com/wavesplatform"/>
                  </div>
                  <div class="form-group col-lg-6 mb-2">
                    <label for="behance">Behance link</label>
                    <input type="text" id="behance" v-model="social.behance" class="form-control mb-2" placeholder="ex: https://www.behance.net/bengarbraith"/>
                  </div>
                  <div class="form-group col-lg-6 mb-2">
                    <label for="deviantart">Deviantart link</label>
                    <input type="text" id="deviantart" v-model="social.deviantart" class="form-control mb-2" placeholder="ex: https://www.deviantart.com/yessyse"/>
                  </div>
                  <div class="form-group col-lg-6 mb-2">
                    <label for="instagram">Instagram link</label>
                    <input type="text" id="instagram" v-model="social.instagram" class="form-control mb-2" placeholder="ex: https://www.instagram.com/joeygatesart"/>
                  </div>
                  <div class="form-group col-lg-6 mb-2">
                    <label for="website">Website link</label>
                    <input type="text" id="website" v-model="social.website" class="form-control mb-2" placeholder="ex: https://www.signature-chain.com/"/>
                  </div>
                </div>
                <div class="row" v-if="$store.state.user.isWhiteListed && statusNotAllowed">
                  <div class="form-group col-lg-12 mb-2">
                    <label for="website">Resell royalties</label>
                    <div class="mention mb-3">Set global royalties percentage for reselling your NFTs, it will be used as default royalties every time you add a new artwork.</div>
                    <input type="number" id="royalties" v-model="royalties" class="form-control mb-2" placeholder="Enter the royalties amount"/>
                  </div>
                </div>
              </div>

              <div class="col-12 col-lg-8 offset-lg-2 text-center mt-4">
                <p class="mandatory">* Mandatory fields</p>
                <div class="fee_collector_account text-center mb-4" v-if="!$store.state.user.isWhiteListed && statusNotAllowed && !$store.state.user.isRegisteredAsCollector">
                  Creating a collector account requires a one time only fee of <strong>{{(($store.state.certificationPrice * Math.pow(10,8))*2).toFixed(0) / Math.pow(10, 8)}} SIGN </strong>
                </div>
                <button class="btn btn-primary mb-2 bigger" @click.prevent="updateInfos">UPDATE INFORMATION</button>
              </div>
            </div>
          </div>
        </div>
      </form>
      
      <div class="container" v-if="$store.state.user.isWhiteListed && statusNotAllowed">
        <div id="setERC20" class="row">
          <div class="col-lg-8 offset-lg-2 mb-4">
            <hr/>
            <p class="mb-0">
              Enter your Ethereum address to allow collectors to transfer your NFT's to Ethereum through our 
              <a :href="$store.state.config.sign_art_gateway_url" target="_blank">SIGN Art Gateway</a> - <span class="mention mt-1">(Royalties from other blockchains, if supported, will be paid to this address. <a href="https://signaturechain.medium.com/sign-art-nfts-bridge-to-ethereum-network-2ca2a0436e2d" target="_blank">Read more</a>)</span>
            </p>

          </div>
          <div class="col-lg-8 offset-lg-2">
            <div class="row">
                <div class="form-group col-lg-8 mb-2">
                  <label for="erc20">Ethereum address</label>
                  <input type="text" id="erc20" v-model="userErc20" class="form-control mb-2" placeholder="ex: 0x79FC24Fe4cE264C7Ca67769514e3b578d71e0287"/>
                </div>
                <div class="form-group col-lg-4 mb-2 d-flex align-items-end justify-content-center">
                  <button class="btn btn-primary mb-2 bigger" @click.prevent="updateErc20">UPDATE ADDRESS</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <register v-else></register> -->
  </div> 
</template>

<script>
import globalMixin from '@/mixins/global.js';
/* import Register from '@/components/elements/Register.vue' */
import Jimp from 'jimp';
import VueScrollTo from "vue-scrollto";
import encodeUrl from 'encodeurl';

var options = {
  container: "#content",
  easing: "ease-in",
};

export default {
  mixins: [globalMixin],
  data: function(){
    return {
      previewImage:null,
      imageCompressionAlert: "",
      ipfsThumbCid: this.$store.state.user.thumbnail,
      addr: this.$store.state.user.userAddress,
      dappRead: this.$store.state.user.isWhiteListed && this.$store.state.user.status != "ALLOWED" && this.$store.state.user.status != "" ? this.$store.state.config.dapp_addr_users : this.$store.state.config.dapp_addr_collectors,
      dappWrite: this.$store.state.user.isWhiteListed ? this.$store.state.config.dapp_addr_users : this.$store.state.config.dapp_addr_collectors,
      userName: '',
      userIntro: '',
      status: '',
      note: '',
      userErc20: '',
      royalties: 10,
      social: {
        facebook:'',
        twitter: '',
        behance: '',
        deviantart: '',
        instagram: '',
        website: ''
      },
      haveError: false,
      responseMessage:'',
      statusNotAllowed: this.$store.state.user.status != 'ALLOWED',
      isWhiteListedTxs: {
        isWhitelisted: false,
        amount: 0
      },
    }
  },
/*   components:{
    "register": Register
  }, */
  methods: {
    loadImage: async function(path) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = path
        img.onload = () => {
          resolve(img)
        }
        img.onerror = e => {
          reject(e)
        }
      })
    },
    compressImage: async function (image){
      let tempImg, compression = 100, tempImgB64 = []
          while(tempImgB64.length > 80000 || tempImgB64.length == 0){
            try{
              tempImg = await Jimp.read(image)
              await tempImg.cover(300, 300).quality(compression);
              tempImgB64 = await tempImg.getBase64Async(Jimp.MIME_JPEG)
            }catch(err){
              console.log(err)
            }
            compression -= 10

          }
          return tempImgB64
    },
    uploadImage: async function(e){
      if(!this.isProcessingImage){
        this.isProcessingImage = true
        if(e.target.files[0].size > 2000000){this.imageCompressionAlert = 'Image too big'; this.isProcessingImage = false; return}
        this.imageCompressionAlert = ''
        const image = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = async e => {
          await this.loadImage(reader.result)
          if(image.size < 85000){
            this.previewImage = e.target.result;
          }else{
            this.imageCompressionAlert = "Image over 80 KB, processing compression, please wait..."
            this.previewImage = await this.compressImage(reader.result)
            this.imageCompressionAlert = ""
          }
        }
        this.isProcessingImage = false
      }
    },
    progress: function(data){
      let completedPercentage = parseInt( ( data.loaded / data.total ) * 100 );
      if(document.getElementById("msg-layer").querySelector(".progress-txt")){
        if(!data.addingToIpfs){
          document.getElementById("msg-layer").querySelector(".progress-txt").innerHTML = completedPercentage+"%"
          document.getElementById("msg-layer").querySelector(".progress-bar").style.width = completedPercentage+"%"
        }else{
          document.getElementById("msg-layer").querySelector(".progress-txt").innerHTML = "Adding file to IPFS, please wait..."
          document.getElementById("msg-layer").querySelector(".progress-bar").style.width = "100%"
        }
      }
    },
    updateErc20: async function(){
      this.haveError = false
      this.responseMessage = 'Checking data...'
      if(this.userErc20 == ''){
        this.haveError = true
        this.responseMessage = 'Erc20 address cannot be empty'
        return
      }
      const dataTx = {
        dApp: this.$store.state.config.dapp_addr_users,
        /* feeAssetId: this.$store.state.config.assetID,  */
            call: {
                function: "setERC20User",
                 args:[
                    { type:"string", value: this.userErc20 }
                ]
            }
      }

      if(this.isWhiteListedTxs.isWhiteListed){
        dataTx.call.args.push({ type:"string", value: this.$store.state.user.publicKey})
        dataTx.senderPublicKey = this.$store.state.config.dapp_pubkey_users
        dataTx.fee = 500000
        dataTx.feeAssetId = null
      }else{
        dataTx.call.args.push({ type:"string", value: "" })
      }

      this.$store.state.alert=true
      this.$store.state.alertMessage='Sending your data to the Waves Blockchain...'
      this.$store.dispatch("signerInvoke", dataTx).then(res => {
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          if(res.error){
            // siplay error
            console.log(res.error)
            this.responseMessage = res.error.message
            this.haveError = true
          }else{
            // display confirmation message of success
            this.responseMessage = 'Informations updated!'
            this.haveError = false
          }
      })
    },
    
    urlToFile: async function(url, filename) {
      const res = await fetch(url);
      const buf = await res.arrayBuffer();
      return new File([buf], filename);
    },
    updateInfos: async function(){
      let _this = this
      VueScrollTo.scrollTo('#content', 600, options)
      this.haveError = false
      this.responseMessage = 'Checking data...'
      
      let fileInput = document.getElementById("thumbnail")
      let file = this.previewImage
      let thumbnail
      
      if(this.userName == ''){
        this.haveError = true
        this.responseMessage = 'Name cannot be empty'
        return
      }

      if(this.userIntro == ''){
        this.haveError = true
        this.responseMessage = 'Description cannot be empty'
        return
      }

      if(this.social.facebook != '' && this.social.facebook.indexOf("facebook.com") == -1){
        this.haveError = true
        this.responseMessage = 'Wrong link for facebook field'
        return
      }
      if(this.social.twitter != '' && this.social.twitter.indexOf("twitter.com") == -1){
         this.haveError = true
        this.responseMessage = 'Wrong link for twitter field'
        return
      }
      if(this.social.instagram != '' && this.social.instagram.indexOf("instagram.com") == -1){
         this.haveError = true
        this.responseMessage = 'Wrong link for instagram field'
        return
      }
      if(this.social.deviantart != '' && this.social.deviantart.indexOf("deviantart.com") == -1){
         this.haveError = true
        this.responseMessage = 'Wrong link for deviantart field'
        return
      }
      if(this.social.behance != '' && this.social.behance.indexOf("behance.net") == -1){
         this.haveError = true
        this.responseMessage = 'Wrong link for behance field'
        return
      }
      this.$store.state.alert=true
      this.$store.state.alertMessage=this.responseMessage

      if(fileInput.files[0]){
        if(file.length > 85000){
          this.responseMessage = "Image is too big, 80Kb maximum"
          this.haveError = true
          this.$store.state.alert=false
          this.$store.state.alertMessage=""
          return
        }
        this.$store.state.alertMessage='Sending your files to IPFS, Please wait...'      
        let ext = this.getFileExtension(fileInput.files[0].name)
        let new_thumbnail = await this.urlToFile(file,'thumbnail.'+ext)
        let addThumbToIpfs = await this.$store.state.ipfs.upload(new_thumbnail, this.progress)
        thumbnail = addThumbToIpfs.cid.string+"/"+new_thumbnail.name
      }else{
        thumbnail = this.ipfsThumbCid
      }

      let functionToCall = 'registerUser'
      if(this.$store.state.user.isWhiteListed && this.$store.state.user.status != 'ALLOWED'){
        if(this.$store.state.user.isRegistered){
          functionToCall = 'updateUser'
        }
      }else{
        if(this.$store.state.user.isRegisteredAsCollector){
          functionToCall = 'updateUser'
        }
      }
      
      /* let invokeFee = this.$store.state.sponsoredFee * 5 * 10 ** 8; */
      const dataTx = {
        dApp: this.dappWrite,
        /* feeAssetId: this.$store.state.config.assetID,  */
            call: {
                function: functionToCall,
                 args:[
                    { type:"string", value: this.userName },
                    { type:"string", value: this.userIntro },
                    { type:"string", value: thumbnail },
                    { type: "string", value: JSON.stringify(this.social)}
                ]
            }
      }

      if(this.dappWrite == this.$store.state.config.dapp_addr_collectors && !this.$store.state.user.isRegisteredAsCollector){
       dataTx.payment = [{ assetId: this.$store.state.config.assetID, amount: ((this.$store.state.certificationPrice * Math.pow(10,8))*2).toFixed(0) }] 
      }
      if(this.dappWrite == this.$store.state.config.dapp_addr_users){
        dataTx.call.args.push({ type:"integer", value: this.royalties * Math.pow(10, 2)})
      }

      if(this.isWhiteListedTxs.isWhiteListed){
        dataTx.call.args.push({ type:"string", value: _this.$store.state.user.publicKey})
        dataTx.senderPublicKey = this.$store.state.config.dapp_pubkey_users
        dataTx.fee = 500000
        dataTx.feeAssetId = null

      }else{
        dataTx.call.args.push({ type:"string", value: "" })
      }

      console.log("this.isWhiteListedTxs.isWhiteListed", this.isWhiteListedTxs.isWhiteListed)
      console.log("dataTx before call", dataTx)
      this.$store.state.alertMessage='Sending your data to the Waves Blockchain...'
      this.$store.dispatch("signerInvoke", dataTx).then(res => { 
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          console.log("res", res)
          if(res.error){
            // siplay error
            console.log(res.error)
            
            this.responseMessage = res.error.message
            this.haveError = true
          }else{
            // display confirmation message of success
            this.responseMessage = 'Informations updated!'
            this.haveError = false
            this.$store.state.user.thumbnail = thumbnail
            if(this.$store.state.user.isWhiteListed){
              this.$store.state.user.isRegistered = true
              if(this.$store.state.user.status == "ALLOWED"){
                this.$store.state.user.status == 'REGISTERED'
                this.$store.dispatch("updateStatus")
              }
            }else{
              this.$store.state.user.isRegisteredAsCollector = true
            }
            _this.init()
          }
      })
      

    },
    init: async function(){
    this.dappRead = this.$store.state.user.isWhiteListed && this.$store.state.user.status != "ALLOWED" && this.$store.state.user.status != "" ? this.$store.state.config.dapp_addr_users : this.$store.state.config.dapp_addr_collectors
    this.dappWrite = this.$store.state.user.isWhiteListed ? this.$store.state.config.dapp_addr_users : this.$store.state.config.dapp_addr_collectors
    document.querySelector(".wrap").classList.add("show")
    const _this = this
    let regex = encodeUrl("^user(.*)" + _this.addr)
    fetch(this.$store.state.config.nodeURL+"/addresses/data/"+this.dappRead+"?matches="+regex).then(res => res.json()).then(data => {
          let newData = Object.values(data).reduce((acc, curr) => {
            const _key = curr.key;
            const getAddr = _key.substr(_key.lastIndexOf("_") + 1, _key.length);
            const getKeyWithoutAddr = _key.substr(0, _key.lastIndexOf("_"));
            if (!acc[getAddr]) {
              acc[getAddr] = [];
              acc[getAddr]["user_addr"] = getAddr;
            }
            acc[getAddr]["" + getKeyWithoutAddr] = curr.value;
            return acc;
          }, {});

          let orderedData = Object.values(newData).sort(function(a, b) {
            return new Date(b.user_date) - new Date(a.user_date);
          });

          if(orderedData[0]["user_name"]){
            if(orderedData[0]["user_status"] == "REGISTERED" || orderedData[0]["user_status"] == "VERIFIED" || orderedData[0]["user_status"] == "CHANGE_REQUIRED"){
              if(this.$store.state.user.isWhiteListed){
                this.$store.state.user.isRegistered=true
              }else{
                this.$store.state.user.isRegistered=false
                this.$store.state.user.isRegisteredAsCollector=true
              }
            }
            _this.userErc20 = orderedData[0].user_ERC20
            _this.userName = orderedData[0].user_name
            _this.userIntro = orderedData[0].user_desc
            _this.ipfsThumbCid = orderedData[0].user_thumb

            if(orderedData[0].user_royalties >= 0){
              _this.royalties = orderedData[0].user_royalties / Math.pow(10, 2)
            }

            
            _this.$store.state.user.thumbnail = orderedData[0].user_thumb
            _this.previewImage = _this.getIpfsLink(orderedData[0].user_thumb)
            try {
            let social = JSON.parse(orderedData[0].user_social)
              _this.social.facebook = social.facebook
              _this.social.twitter = social.twitter
              _this.social.behance = social.behance
              _this.social.deviantart = social.deviantart
              _this.social.instagram = social.instagram
              _this.social.website = social.website
            }catch(err){
              console.log("Invalid json")
            }
            _this.note = orderedData[0].user_note ? orderedData[0].user_note : ''

            switch(orderedData[0]["user_status"]){
              case "REGISTERED":
              _this.status = "REGISTERED"
              break

              case "CHANGE_REQUIRED":
              _this.status = "CHANGE REQUIRED"
              break

              case "VERIFIED":
              _this.status = "APPROVED"
              break

              case "SUSPENDED":
              _this.status = "SUSPENDED"
              break

              case "REMOVED":
              _this.status = "REMOVED"
              break

              case "ALLOWED":
              _this.status = "ALLOWED"
              break

              default:
            }
            
          }
        }).catch((err) => console.error(err));
    },
  },
  watch:{
    '$store.state.user.status': function(status){

      this.statusNotAllowed = status != 'ALLOWED'
      this.status = status
    }
  },
  async created(){
    this.isWhiteListedTxs = await this.whitelistedTxs(this.$store.state.user.userAddress)
  },
  mounted(){
    this.init()
  }
}
</script>
<style lang="scss" scoped>
  .imageCompressionAlert{
    text-align: center;
    margin-bottom: 25px;
    margin-top: 10px;
    color: #fff;
    background-color: #9a4ac5;
    padding: 5px;
    border-radius: 5px;
  }
  textarea.form-control {
    height: 200px !important;
  }
  .uploading-image{
    max-width: 100px;
    margin-bottom: 20px;
    &.fade{
      opacity: 0.5;
    }
  }
  .migrate{
    padding: 10px;
    background-color: #9a4ac5;
    color: white;
    border-radius: 5px;
  }
  .fee_collector_account{
    background-color: #9a4ac5;
    color: white;
    padding: 10px;
    border-radius: 5px;
  }
</style>