<template>
  <div class="wrap_crosschain"> 
    <div v-if="pepe_widget_loaded == false" class="text-center">Loading Gateway widget, please wait...</div>
    <div id="bridge">

    </div>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js';
import { BridgeWidget } from '@pepe-team/bridge-widget-embedder'
export default {
  mixins: [globalMixin],
  data: function(){
    return {
      pepe_widget_loaded: false
    }
  },
  mounted(){
    let _this = this
    const widget = new BridgeWidget();
    widget.run({
      iframeUrl: 'https://bridge.pepe.team/iframe',
      host: document.getElementById("bridge"),
      color_schema: {
        'bg-primary': '#57137b',
        'text-primary': '#000000',
        'border-primary': '#57137b',
        'border-secondary': '#57137b',
        'button-bg-primary': '#ca26c0',
        'button-border-primary': '#ca26c0',
        'button-border-secondary': '#57137b',
        'tab-btn-primary': '#ca26c0',
        'tab-btn-secondary': '#ffffff'
      }
    });
    
    widget.on("widgetLoaded", (res) => {
      console.log('Gateway widget loaded', res);
      document.getElementById("bridge").querySelector("iframe").style.width = "auto"
      document.getElementById("bridge").querySelector("iframe").style.minWidth = "420px"
      document.getElementById("bridge").querySelector("iframe").style.minHeight = "680px"
      document.getElementById("bridge").querySelector("iframe").style.margin = "auto"
      document.getElementById("bridge").style.opacity = 1 
      _this.pepe_widget_loaded=true
    });
  }
}
</script>
<style lang="scss" scoped>
  #bridge{
    opacity: 0;
  }
</style>