
<template>
    <ul id="wrap_faq" @mouseleave="showDefault = false">
      <!-- <li><a class="faq" v-b-tooltip.hover.left title="Get SIGN token" :href="$store.state.config.wavesExchangeURL+'/trading/spot/'+$store.state.config.assetID+'_WAVES'" target="_blank"><i class="fas fa-coins"></i></a></li> -->
      <!-- <li><a class="faq pink" v-b-tooltip.hover.left title="Swap SIGN token" @click.prevent="$store.state.showBuyPopin = !$store.state.showBuyPopin "><i class="fas fa-coins"></i></a></li> -->
      <li :class="{hideDefault:!showDefault}">
        <router-link :to="'/code-of-conduct'" class="faq" v-b-tooltip.hover.left title="Code Of Conduct" @mousedown.native="resetArtistScroll()" exact>
        <i class="far fa-handshake"></i>
        </router-link>
      </li>
      <li :class="{hideDefault:!showDefault}">
        <router-link :to="'/faq'" class="faq" v-b-tooltip.hover.left title="FAQ" @mousedown.native="resetArtistScroll()" exact>
          <i class="fas fa-info-circle"></i>
        </router-link>
      </li>
      <li :class="{hideDefault:!showDefault}">
        <router-link :to="'/terms-of-service'" class="faq" v-b-tooltip.hover.left title="Terms Of Service" @mousedown.native="resetArtistScroll()" exact>
          <i class="fas fa-file-alt"></i>
        </router-link>
      </li>
      <li :class="{hideDefault:!showDefault}">
        <router-link :to="'/privacy'" class="faq" v-b-tooltip.hover.left title="Privacy Policy" @mousedown.native="resetArtistScroll()" exact>
          <i class="fas fa-bullhorn"></i>
        </router-link>
      </li>
      <li :class="{hideDefault:!showDefault}">
        <a href="https://docs.sign-art.app" class="faq" v-b-tooltip.hover.left title="Documentation" target="_blank">
          <i class="fas fa-book"></i>
        </a>
      </li>
      <li class="faq question" @mouseenter="entered" @click="switchDisplay">?</li>
    </ul>
</template>

<script>
import globalMixin from '@/mixins/global.js';
export default {
  mixins: [globalMixin],
  data: function(){
    return {
      showDefault: false,
      isMobile: false
    }
  },
  methods: {
    switchDisplay:function(){
      this.showDefault = !this.showDefault
    },
    entered: function(){
      if(!this.isMobile){
        this.showDefault = true
      }
    }
  },
  watch:{
    '$store.state.isMobile'(value) {
      this.isMobile = value
    }
  },
  mounted(){
    this.isMobile = this.$store.state.isMobile
  }
}
</script>
<style lang="scss" scoped>
  li{
    transition: all .4s ease;
    opacity: 1;
    margin-bottom: 10px;
    &.hideDefault{
      opacity: 0;
      margin-bottom: -10px;
      height: 10px;
    }
  }
  .faqPage .card-text .red {
      background-color: white!important;
  }
  .faq {
    background-color: white;
    width: 40px;
    height: 40px;
    color: rebeccapurple;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    margin-bottom: 10px;
    z-index: 1;
    &.pink{
      background-color: #ca26c0;
      color: white;
    }
    &.question{
      z-index: 50;
      font-size: 30px;
      position: absolute;
    }
  }
  #wrap_faq {
    padding-bottom: 50px;
    position: absolute;
    z-index: 4500;
    bottom: 60px;
    font-size: 20px;
    bottom: 10px;
    right: 10px;
  }
  @media(max-width: 768px){
    .tooltip{
      display: none!important;
    }
  }
</style>