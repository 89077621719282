<template>
<div>
  <app-user-infos-artist 
    :isBanned="isBanned" 
    :artistInfos='artistInfos' 
    :infosLoaded='infosLoaded' 
    :userExist='userExist' 
    :hasProfile='hasProfile' 
    :userNote='userNote' 
    
    v-if="artistInfos 
    && artistInfos.user_status != 'ALLOWED' 
    && artistInfos.user_status != 'SUSPENDED'
    && artistInfos.user_status != 'REMOVED'
    "/>
  <app-user-infos-collector  
  :isBanned="isBanned" 
  :collectorInfos='collectorInfos' 
  :infosLoaded='infosLoaded' 
  :userExist='userExist' 
  :hasProfile='hasProfile' 
  :userNote='userNote' 
  
  v-else/>
</div>
</template>

<script>
import globalMixin from '@/mixins/global.js';
import UserInfosArtist from '@/components/elements/UserInfosArtist.vue'
import UserInfosCollector from '@/components/elements/UserInfosCollector.vue'

export default {
  mixins: [globalMixin],
  props: ['isBanned', 'artistInfos', 'collectorInfos', 'infosLoaded', 'userExist', 'hasProfile', 'userNote'],
  data: function(){
    return {}
  },
  components: {
    'app-user-infos-artist': UserInfosArtist,
    'app-user-infos-collector': UserInfosCollector
  }
}
</script>