<template>
  <div>
      <div id="activity_marketplace" class="container-fluid">
          <!-- <h2 class="mb-5 mt-5 text-center">Recent activity</h2> -->
          <div class="row">
            <div class="col-lg-12 d-flex justify-content-start mb-3 nav">
              <div class="new artwork w-100" v-for="(item, key) in htmlArtistsData" :key="key">
                <artists-item  :item="item" />
              </div>
            </div>
            <div class="col-12">
              <div id="loading" class="mt-3 mb-3 text-center" v-if="isLoading">Loading historical activity...</div>
              <button class="btn btn-primary d-block mt-3 mb-3 mx-auto" id="loadmore" @click="getArtistsTxs()" v-else>Load more</button>
            </div>
          </div>
      </div>
  </div> 
</template>

<script>

import globalMixin from '@/mixins/global.js';
import activityMixin from '@/mixins/activity.js';
import ActivityArtistsItem from '@/components/content/activity/items/drop.vue'
export default {
  mixins: [globalMixin, activityMixin],
  data: function(){
    return {
        htmlArtistsData: [],
        after: null,
        isLoading: true,
        amountToGet: 20
    }
  },
  components:{
    'artists-item': ActivityArtistsItem
  },
  mounted(){
    this.getArtistsTxs()
  },
  methods: {
    getArtistsTxs: async function(){
      let thumb, imgix, status, account, artist, sender
    this.isLoading = true
    let url
    if(this.after){
      url = `${this.$store.state.config.nodeURL}/transactions/address/${this.$store.state.config.dapp_addr_users}/limit/${this.amountToGet}?after=${this.after}`
    }else{
      url = `${this.$store.state.config.nodeURL}/transactions/address/${this.$store.state.config.dapp_addr_users}/limit/${this.amountToGet}`
    }
    let dataRes = await fetch(url)
    let resJSON = await dataRes.json()
    for( var tx of resJSON[0]){
      if(tx.type == 16 || (tx.type == 18 && tx.payload.type=="invocation")){
        if(tx.type==18){
          tx = {...tx,...tx.payload}
        }
          switch(tx.call.function){
            case "updateUser":
            case "registerUser":
              if(tx.call.args[2].value != ""){
                thumb = tx.call.args[2].value
                imgix  = await this.getImgixUrl(thumb)
                thumb = imgix.res[0].url
              }else{
                thumb = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
              }
              sender = tx.sender == this.$store.state.config.dapp_addr_users ? this.addressFromPublicKey(tx.call.args[5].value) : tx.sender
              this.htmlArtistsData.push({
                  txid: tx.id,
                  function: "registerUser",
                  date: tx.timestamp,
                  label: tx.call.function == "registerUser" ? "New artist" : "Artist updated",
                  price: null,
                  image_link: `/user/${sender}`,
                  image_path: imgix ? imgix.res[0].url : '',
                  artist_link: `/user/${sender}`,
                  artist_name: tx.call.args[0].value,
                  description: this.trimLongText(tx.call.args[1].value),
                })
            break;

            case "changeUserStatus":
              thumb = "https://signart.infura-ipfs.io/ipfs/bafybeifwvkie2kdrwvgu5lbppupvhredh5fvnjpvzztrfunbt3g4y3awtm/thumbnail.jpg"
              status = tx.call.args[1].value == "ALLOWED" ? "WHITELISTED" : tx.call.args[1].value
              account = this.trimLongAddress(tx.call.args[0].value)
              
              if(status=="VERIFIED"){
                status = "APPROVED"
                artist = await fetch(this.$store.state.config.signArtApiURL+"/users/address/"+tx.call.args[0].value)
                artist = await artist.json()
                if(artist.length){
                  if(artist[0].thumb != ""){
                    imgix  = await this.getImgixUrl(artist[0].thumb)
                    thumb = imgix.res[0].url
                  }
                  account = artist[0].name
                }
              }
              this.htmlArtistsData.push({
                  txid: tx.id,
                  function: "changeUserStatus",
                  date: tx.timestamp,
                  label: "Admin update",
                  price: null,
                  image_link: ``,
                  image_path: thumb,
                  artist_link: ``,
                  artist_name: ``,
                  description: ``,
                  collector: null,
                  status: {
                    account: {
                      name: account,
                      path: `/user/${tx.call.args[0].value}`
                    },
                    label: status,
                    note: tx.call.args[2] != undefined ? tx.call.args[2].value : ""
                  }
                })

              
            break;

            case "setERC20User":
              thumb = "https://signart.infura-ipfs.io/ipfs/bafybeifwvkie2kdrwvgu5lbppupvhredh5fvnjpvzztrfunbt3g4y3awtm/thumbnail.jpg"
              /* status = tx.call.args[1].value == "ALLOWED" ? "WHITELISTED" : tx.call.args[1].value*/
              account = ''
              sender = tx.sender == this.$store.state.config.dapp_addr_users ? this.addressFromPublicKey(tx.call.args[1].value) : tx.sender
              artist = await fetch(this.$store.state.config.signArtApiURL+"/users/address/"+sender)
              artist = await artist.json()
              if(artist.length){
                if(artist[0].thumb != ""){
                  imgix  = await this.getImgixUrl(artist[0].thumb)
                  thumb = imgix.res[0].url
                }
                account = artist[0].name
              }
              
              this.htmlArtistsData.push({
                  txid: tx.id,
                  function: "setERC20User",
                  date: tx.timestamp,
                  label: "Allowed crosschain",
                  price: null,
                  image_link: ``,
                  image_path: thumb,
                  artist_link: ``,
                  artist_name: ``,
                  description: ``,
                  collector: null,
                  status: {
                    account: {
                      name: account,
                      path: `/user/${sender}`
                    },
                    label: 'NFTs from this artists are now crosschain.',
                    note: ''
                  }
                })

              
            break;

            case "deleteUser":
              thumb = "https://bafybeifwvkie2kdrwvgu5lbppupvhredh5fvnjpvzztrfunbt3g4y3awtm.ipfs.infura-ipfs.io/thumbnail.jpg"
              status = "REMOVED"
              account = this.trimLongAddress(tx.call.args[0].value)
              this.htmlArtistsData.push({
                  txid: tx.id,
                  function: "changeUserStatus",
                  date: tx.timestamp,
                  label: "Admin update",
                  price: null,
                  image_link: ``,
                  image_path: thumb,
                  artist_link: ``,
                  artist_name: ``,
                  description: ``,
                  collector: null,
                  status: {
                    account: {
                      name: account,
                      path: `/user/${tx.call.args[0].value}`
                    },
                    label: status,
                    note: "ILLEGAL CONTENT"
                  }
                })
            break;

            default:

          }
      }
      this.after = tx.id
    }
    
    this.isLoading = false
    
    }
  }
}
</script>
<style lang="scss" scoped>

</style>