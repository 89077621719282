<template>
  <div class="wrap_swap">
    <p class="text-center mt-2 intro mb-2"><strong>Transfer SIGN, WAVES, ETH, XTN or USDT within the Waves network</strong></p>
    <p class="mention mb-4 text-center">Send only to a Waves address or your funds may be lost.<br/>For other networks, use the Gateways tabs.</p>
    <div class="form w-100">
      <div class="wrap_fields mb-3 w-100">

          <b-dropdown id="dropdown" :text="selectedTicker" class="mr-3">
            <b-dropdown-item @click="setOption('SIGN')">
              SIGN
            </b-dropdown-item>

            <b-dropdown-item @click="setOption('WAVES')">
              WAVES 
            </b-dropdown-item>

            <b-dropdown-item @click="setOption('ETH')">
              ETH
            </b-dropdown-item>

            <b-dropdown-item @click="setOption('XTN')">
              XTN
            </b-dropdown-item>
            <b-dropdown-item @click="setOption('USDT-PP')">
              USDT-PP
            </b-dropdown-item>
          </b-dropdown>

        <input type="text" id="recipient" v-model="recipient" placeholder="Recipient address" @change="isAddress(recipient) || recipient=='' ? valideRecipient = '' : valideRecipient ='This address is not valid'">
        <p v-if="valideRecipient!=''"><span class="mention d-block text-center mt-1">{{valideRecipient}}</span></p>
      </div>
      <div class="wrap_fields d-flex w-100 mb-3">
        <textarea v-model="attachment" class="w-100" placeholder="Enter your message of 140 char. max" maxlength="140"></textarea>
      </div>
      <div class="wrap_fields d-flex w-100">
        <input type="text" id="amountToSend" v-model="amountToSend" placeholder="Amount to swap">
        <button id="transfer" @click="chooseSigningMethod()">SEND</button>
      </div>
    </div>

  <div>
  
</div>
    <div class="current_bal text-center">Available balance: {{ numberWithSpaces(currentBal) }} {{ selectedTicker }} - <span class="useMax cursor-pointer" @click="useMax">Use all</span></div>
    <div class="mt-3" id="signer-result" v-show="messageInfo!=''">{{messageInfo}}</div>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js';

export default {
  mixins: [globalMixin],
  props: ["updateBalances", "signBal", "wavesBal", "usdtBal", "usdtPPBal", "usdnBal", "ethBal", "onResizeWrapper"],
  data: function(){
    return {
      user: null,
      userBalance: {},
      messageInfo: "",
      selectedTicker: "SIGN",
      currentBal: 0,
      amountToSend: 0,
      recipient: '',
      valideRecipient: null,
      attachment: ""
    }
  },
  mounted(){
    if(this.$store.state.user.isLogged){
       this.setCurrentBal()
    }
    this.onResizeWrapper()
  },
  watch: {
    signBal: function(){
      this.setCurrentBal()
    },
    ethBal: function(){
      this.setCurrentBal()
    },
    wavesBal: function(){
      this.setCurrentBal()
    },
    usdnBal: function(){
      this.setCurrentBal()
    },
    usdtBal: function(){
      this.setCurrentBal()
    },
    usdtPPBal: function(){
      this.setCurrentBal()
    }
  },  
  methods: {
    setOption: function(ticker){
      this.selectedTicker = ticker
      this.setCurrentBal()
    },
    useMax: function(){
      document.getElementById("amountToSend").value = this.currentBal
      this.amountToSend = this.currentBal
    },
    setCurrentBal: function(){
      let bal = 0
      if(this.selectedTicker == "SIGN"){
        bal = this.signBal
      }else if(this.selectedTicker == "WAVES"){
        bal = this.wavesBal
      }else if(this.selectedTicker == "ETH"){
        bal = this.ethBal
      }else if(this.selectedTicker == "XTN"){
        bal = this.usdnBal
      }else if(this.selectedTicker == "USDT"){
        bal = this.usdtBal
      }else if(this.selectedTicker == "USDT-PP"){
        bal = this.usdtPPBal
      }
      this.currentBal = bal
    },
    chooseSigningMethod: function(){
       if(this.$store.state.user.isLogged){
         this.transfer()
       }else{
         this.$store.state.signingChoice.showChoice = true
       }
    },
    getBase10Amount: function(amount){
      let convertedAmount = 0
      if(this.selectedTicker == "XTN" || this.selectedTicker == "USDT" || this.selectedTicker == "USDT-PP"){
        convertedAmount = amount * Math.pow(10, 6)
      }else{
        convertedAmount = amount * Math.pow(10, 8)
      }
      return convertedAmount
    },
    transfer: function(){
      let _this = this
  
      let validAmount = _this.getBase10Amount(this.amountToSend)

      _this.messageInfo = "Transfer in progress..."

      if(this.amountToSend == 0){
        _this.messageInfo = "Amount invalid"
        return
      }
      
      if(!this.isAddress(this.recipient)){
        _this.messageInfo = "Recipient invalid"
        return
      }

      /* let fee
      if(this.selectedTicker == 'SIGN'){
        fee = this.$store.state.sponsoredFee * 10 ** 8
      }
      if (this.selectedTicker == 'USDN'){
        fee = this.$store.state.sponsoredFeeUSDN * 10 ** 6
      }
      if(this.selectedTicker == 'WAVES'){
        fee = 100000
      }
      if(this.selectedTicker == 'ETH'){
        fee = this.$store.state.sponsoredFeeETH * 10 ** 8
        
      } */
      let assetId = _this.getAssetIdByTicker(_this.selectedTicker)

      // const attachment_base58 = _this.$store.state.cryptolibs.base58Encode(_this.$store.state.cryptolibs.stringToBytes(_this.attachment));
      let transferTx
      if(this.$store.state.signingChoice.keeper){
        transferTx = {amount: validAmount, recipient: _this.recipient, assetId: assetId, attachment: _this.attachment, feeAssetId: "WAVES", fee: 100000}
      }else{
        const attachment_base58 = _this.$store.state.cryptolibs.base58Encode(_this.$store.state.cryptolibs.stringToBytes(_this.attachment));
        transferTx = {amount: validAmount, recipient: _this.recipient, assetId: assetId, attachment: attachment_base58}
      }
        
        _this.$store.dispatch("signerTransfer", {data:transferTx, isNFT: false}).then(res => { 
          if(res.error){
            if(res.message){
              _this.messageInfo = res.message
            }else{
            _this.messageInfo = res.error
            }
            //_this.haveError=true
          }else{
            _this.messageInfo = "Asset Transfered successfully!"
            setTimeout(function(){ // Dirty but fuck... :)
              _this.updateBalances()
              _this.setCurrentBal()
              _this.messageInfo = ""
                setTimeout(function(){
                  _this.updateBalances()
                  _this.setCurrentBal()
                  _this.messageInfo = ""
                }, 3500);
            }, 3500);
            //_this.haveError=false
          }
        })
      
    }
  }

}
</script>

<style lang="scss" scoped>
textarea{
  border-radius: 20px;
  height: 120px;
  padding: 15px;
}
#dropdown{
  width: 25%;
  margin-right: 3%!important;
}
#recipient{
  width: 72%;
}

#transfer{
  width: 25%;
  margin-left: 3%!important;
}
#amountToSend{
  width: 72%;
}
#signer-result{
    width: 100%;
    background-color: rgb(36 17 62 / 80%);
    color: white;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    font-weight: 300;
    padding: 10px 20px;
    max-height: 200px;
    overflow-y: auto;
  }
.current_bal{
  margin-top: 10px;
  font-size: 14px;
  color: #aaa;
  .useMax{
    text-decoration: underline;
  }
}
  .intro{
    font-size: 18px;
    strong{
      font-weight: 700;
    }
  }

  .content{
    position: relative;
    min-height: 225px;
  }


  :focus-visible {
        outline: none;
  }

  input, button{
    
    border-radius: 100px;
    padding: 7px 10px;
    border-width: 1px;
  }
  input{
    width: 35%;
    padding-left: 20px;
  }
  
  button{
    width: 20%;
  }

  @media(max-width: 580px){
    #dropdown{
        width: 100%!important;
        
      }
    .dropdown-toggle{
      width: 100%!important;
      margin-right: 0!important;
    }
    #recipient{
      width: 100%;
      margin-top: 16px;
    }
    .wrap_dropdown{
      width: 90%;
      margin-bottom: 15px;

      #dropdown{
        width: 100%!important;
        
      }
      
    }
  }
</style>