export default {
  data: function() {
    return {}
  },
  methods: {
    goToAccount: function(){
      if(this.isHovering){
        this.isHovering = false;
      }
      let _this = this
      if(_this.$router.currentRoute.name == "account"){ return } 
      if(_this.$store.state.user.isWhiteListed){
        _this.$router.push("/user/" + _this.$store.state.user.userAddress + "/account/infos");
      }else{
        _this.$router.push("/user/" + _this.$store.state.user.userAddress + "/account/owned");
      }
    }
  }
}