<template>
  <div>
    <div id="home_page" class="wrap">
      <app-featured v-if="showFeatured && $route.params.pageindex == 1"></app-featured>
      
      <app-filters :showArtistStatus="true" :artistStatus="artistStatus"></app-filters>
      
      <div style="color:red;" v-show="false">{{ loading && !$store.state.artistListLoaded }}</div>

      <div v-if="loading && !$store.state.artistListLoaded" class="loading">
        <app-loading :label="'Artists'"></app-loading>
      </div>

        <div class="loading" v-else-if="loading">
          <app-loading :label="'Artworks'"></app-loading>
        </div>
        <div v-else class="min-vh-100" id="homeGridWrapper">
          <div v-if="!allArtworks || !allArtworks.length && !callingApi" class="noArtworks">
            <div v-if="$store.state.filtering.home.status=='verified'" class="noNFT" @click="switchArtistStatus">Nothing found.<br/><span>Try search among non approved artist status?</span></div>
            <div v-else class="wrapper">Nothing to display</div>
          </div>
          <div class="grid" ref="grid" v-else> 
            <div class="grid-sizer"></div>
            <div class="grid-item" v-for="(artwork, key) in allArtworks" :key="artwork.txid" :data-index="key" @mousedown="storeArtworkClickedIndex($event)">
                <app-artwork-item :artwork="artwork" :showArtist="true" :keyIndex="key"></app-artwork-item>
            </div>
          </div>

        <div class="wrap_pagination d-flex justify-content-center mt-2">
          <button class="btn btn-primary mb-2 bigger loadNFT loadprev mr-3"  v-if="this.$route.params.pageindex && this.$route.params.pageindex > 1" @click="scrollToId = false; prevNext('prev')">PREVIOUS</button>
          <button class="btn btn-primary mb-2 bigger loadNFT loadnext" @click="scrollToId = false; prevNext('next')" v-if="allArtworks.length == artPerPage">NEXT</button>
       </div>
      </div>
    </div>
  </div>
</template>

<script>

import Masonry from "masonry-layout";
import imagesLoaded from 'imagesloaded';
import globalMixin from '@/mixins/global.js';
import Loading from '@/components/elements/Loading.vue'

import Filters from '@/components/elements/Filters.vue'
import Featured from '@/components/elements/Featured.vue'
import ArtworkItemApi from '@/components/content/artwork/ArtworkItemApi.vue'
import orderedArtworks from "@/mixins/orderedArtworks.js"
import listingArtworks from "@/mixins/listingArtworks.js"

export default {
  mixins: [globalMixin, orderedArtworks, listingArtworks],
  data: function(){
    return {
      allArtworks: undefined,
      imgix: undefined,
      loading: true,
      /* loadnext: false,
      loadprev: false, */
      showPagination: false,
      scrollToId: true,
      artPerPage: 35,
      queryType: 2, // 1 = 2 per artists overall, 2 = 2 per artist then 3 per artist etc, 3 = 2 per artists then all remaining by date
      fullyLoaded: false,
      callingApi: false,
      cursor: '',
      currentPage: 1,
      filters: this.$store.state.filtering.home,
      showFeatured: true,
      artistStatus: null
    }
  },
  components: {
    'app-loading': Loading,
    'app-artwork-item': ArtworkItemApi,
    'app-filters': Filters,
    'app-featured': Featured
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    }
  },
  created(){
    this.init()
  },
  methods: {
    init: async function(){
      await this.$store.dispatch("getPriceInUsd")
      this.$parent.showMenuTop = false
      this.$nextTick(() => { // IF PERFORMANCE PROBLEM, see to run this once only
        if(window.msnry != undefined){
          this.$nextTick(() => {
            window.msnry.layout();
          }) 
        }
      })

    },
    switchArtistStatus: function(){
      this.artistStatus = null
      this.artistStatus = "all"
    },
    prevNext: function(direction){
      this.showPagination = false
      let query = { search: this.$store.state.searchStr }
      query.status = this.$store.state.filtering.home.status
      let index
      if(direction == "prev"){
        index = +Number(this.$route.params.pageindex)-1
      }else{
        index = +Number(this.$route.params.pageindex)+1
        
      }
      this.$router.push({ path: '/page/'+index, query: query }).catch(()=>{});
    },
    storeArtworkClickedIndex: function(e){
      this.$store.state.activeMarket = "home-paginate"
      this.$store.state.homeScrollState.scrollToId = e.target.id
    },
    hideSpiner: function(e){
      e.target.parentElement.querySelector(".spiner").classList.add("d-none")
    },
    getArtist: function(addr){
      return this.$store.getters.getArtistByAddress(addr)
    },
    updateMsnryLayout: function(){
      if(window.msnry){
        window.msnry.layout();
        if(document.getElementById(this.$store.state.homeScrollState.scrollToId) && this.$store.state.homeScrollState.scrollToId !=0 && this.scrollToId){
          document.getElementById(this.$store.state.homeScrollState.scrollToId).scrollIntoView()
          this.$store.state.homeScrollState.scrollToId = 0
          this.scrollToId = false
        }
        var imgLoad = imagesLoaded('.grid');
        imgLoad.on( 'progress', function(  ) {
          window.msnry.layout();
        });
      }
    },
    reset: function(){
      document.getElementById('main').scrollTop = 0
      if(this.scrollToId){
        if(document.getElementById(this.$store.state.homeScrollState.scrollToId) && this.$store.state.homeScrollState.scrollToId !=0){
          document.getElementById(this.$store.state.homeScrollState.scrollToId).scrollIntoView()
          this.$store.state.homeScrollState.scrollToId = 0
        }
      }
    }
  },
  watch: {
    $route(to){
      if(to.name == "home-paginate" || to.name == "home"){
        this.loadArtworksFromApi()
        this.reset()
      }
    },
    allArtworks: function(){
        this.$nextTick(() => { // IF PERFORMANCE PROBLEM, see to run this once only
          if(this.$refs.grid){
            window.msnry = new Masonry('.grid', {
              percentPosition: true,
              initLayout: false,
              itemSelector: ".grid-item",
              columnWidth: ".grid-sizer",
            });
            this.updateMsnryLayout()
            this.$nextTick(() => {
              this.updateMsnryLayout()
            }) 
          }
        })
      
    }
  },
  mounted(){
    this.reset()
  }
}
</script>

<style lang="scss" scoped>
  .noNFT{
    text-align: center;
    padding-bottom: 15px;
    cursor: pointer;
    padding-top: 0px;
    color: black;
    span{
      color: #ca26c0;
      text-decoration: underline;
    }
  }
  .wrap_pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 20px;
    pointer-events: none;
    .loadnext, .loadprev{
      pointer-events:all;
      margin: initial;
      margin-right: 30px;
      &.no-mr{ margin-right: 0px;}
    }
  }
  #homeGridWrapper{
    padding-bottom: 20px;
  }
  .noArtworks{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    .wrapper{
      color: #57137b;
    }
  }
  
</style>