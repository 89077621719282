let sha256 = require("js-sha256").sha256;
let uuidv1 = require("uuid");

window.method = sha256;
// HASHING
// Execute the hashing
function execute(e, targetProgress, callback) {
  let file, hash;
  // let fileIsTooBig = e.size > 10485760;
  let fileIsTooBig = false;
  let arrayBuffer = [];
  // A SORTIR DE LA LIB
  let progressBar = targetProgress.querySelector(".progression");
  progressBar.classList.remove("hide")

  file = e;

  let reader = new FileReader();

  if (window.method.update) {
    var batch = 1024 * 1024 * 2;
    var start = 0;
    var total = file.size;
    var current = window.method;
    reader.onload = function(event) {
      try {
        current = current.update(event.target.result);
        if (!fileIsTooBig) {
          arrayBuffer.push(reader.result);
        } else {
          arrayBuffer = [];
        }
        asyncUpdate();
      } catch (e) {
        console.log(e);
      }
    };
    var asyncUpdate = async function() {
      if (start < total) {
        var end = Math.min(start + batch, total);
        progressBar.style.width = ((start / total) * 100).toFixed(2) + "%";
        targetProgress.querySelector(".counter").innerHTML = ((start / total) * 100).toFixed(0) + "%";
        reader.readAsArrayBuffer(file.slice(start, end));
        start = end;
      } else {
        progressBar.style.width = "100%";
        targetProgress.querySelector(".counter").innerHTML = "100%";
        e.value = "";
        hash = current.hex();
        if (typeof callback == "function") {
          var blob;
          blob = new Blob(arrayBuffer);
          callback({
            hash: hash,
            index: uuidv1.v1(),
            title: file.name,
            buffer: blob,
          })
        }
      }
    };
    asyncUpdate();
  } else {
    reader.onload = function(event) {
      try {
        console.log(event)
      } catch (e) {
        console.log(e);
      }
    };
    reader.readAsArrayBuffer(file);
  }
}

module.exports = {
  execute,
};
