<template>
  <transition name="slide-left-fade" :duration="3000">
    <div id="signup" :class="{delay: delay}" v-show="open">
        <div class="closeNL" @click="closeForm"><i class="fas fa-times"></i></div>
        <label for="signup_email">Sign up to our newsletter:</label>
        <fieldset>
          <input id="signup_email" type="email" placeholder="Enter your email" class="form-control rounded-left" v-model="email" :disabled="disabled" />
          <button class="btn btn-primary rounded-right" @click.prevent="signUp" :disabled="disabled">SIGN UP</button>
        </fieldset>
        <div v-if="response!=''" class="mt-2">{{response}}</div>
    </div>
  </transition>
</template>
<script>
import globalMixin from '@/mixins/global.js';
import validator from 'validator';

export default {
  mixins: [globalMixin],
  props: ['user_social'],
  data: function(){
    return {
      email: '',
      open: false,
      disabled: false,
      isSigned: false,
      delay: true,
      response: ''
    }
  },
  created(){
    let isSignedUp = this.$cookies.get("hideSignup")
    if(isSignedUp){
      this.isSigned = true
    }
  },
  methods: {
    signUp: async function(){
      let _this = this
      this.response = 'Subscribing...'
      if(validator.isEmail(this.email)){
        // send to mailing
        let subscribe = await fetch(this.$store.state.config.signArtApiURL+'/newsletter', {
          method: 'POST', // or 'PUT'
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: "include",
          body: JSON.stringify({"email_address": this.email}),
        })
        let res = await subscribe.json()
        if(res.status == "pending"){
          this.response = res.status == 'pending' ? 'Thank you! Registration sent.' : res.status
          this.$cookies.set("hideSignup", true)
          this.email = ''
          setTimeout(function(){
            _this.closeForm()
            _this.response = ''
          }, 3000)
        }
        if(res.error){
          this.response = res.error == 'Member Exists' ? 'You are already registered' : res.error
        }
        // this.$cookies.set("signedup", false)
      }else{
        console.log("Wrong email")
      }
    },
    closeForm: function(){
      let _this = this
      this.disabled = true
      this.$cookies.set("hideSignup", true)
      setTimeout(function(){
        _this.open = false
      }, 100)
      
    },
    openForm: function(){
      this.disabled = false
      this.open = true
    }
  },
  mounted(){
    this.$root.$on('openNewsletter', () => {
        this.delay = false
        this.openForm()
    })
    if(!this.isSigned){
      this.openForm()
    }
  }
}
</script>
<style lang="scss">
  fieldset{
    display: flex;
    input[type="email"]{
      width: 65%;
      border-radius: 0;
    }
    button{
      width: 35%;
      font-size: 12px!important;
      background-color: #57137b!important;
      border-radius: 0!important;
    }
  }
  .closeNL{
    position: absolute;
    right: 15px;
    top: 5px;
    font-size: 20px;
    cursor: pointer;
  }

  #signup{
    position: absolute;
    z-index: 500;
    width: 100%;
    height: auto;
    bottom: 0px;
    font-size: 14px;
    padding: 20px 15px;
    color: white;
    background-color: #8e35bd;

    &.slide-left-fade-enter-active {
      transition: all .3s ease;
      opacity: 0;
      left: -40px;
    }
    &.slide-left-fade-leave-active {
      transition: all .3s ease;
    }

    &.slide-left-fade-enter-to{
      opacity: 1;
      left: 0px;
    }
    &.slide-left-fade-leave-to{
      opacity: 0;
    }
  }

  #signup.slide-left-fade-enter-active.delay{
    transition-delay: 2s;
  }

</style>