<template>           
  <transition name="fade">
    <div class="setSalePopin" v-show="$parent.setSalePopinOpen" @click="closeSetSaleForm($event)" :class="{'flexstart':haveError}">
      <i class="fa-solid fa-xmark close_popin_nft"></i>
      <div class="col-12 px-0 wrapper">
        <div class="mb-3 pointer-events-auto">
          
            <label :for="'sell_'+nftInfo.name" class="w-100 d-flex">
              <span>Sell <strong>"{{ nft.art_name || nft.primary.name }}"</strong> for:</span> <i class="fas fa-question-circle ml-auto" v-b-tooltip.hover.top :title="'Asset ID: ' + nftInfo.assetId + ' - (Ref: '+nftInfo.name+')'"></i>
            </label>
          
          <div class="d-flex flex-column">
            <input type="text" class="form-control mb-1" :id="'sell_'+nftInfo.name" placeholder="Enter the amount." v-model="sellAmount">
            <label @click.prevent="offerOnly = !offerOnly" class="mb-3"><input type="checkbox" v-model="offerOnly" /> <span>Accept offer only</span></label> 
            <select class="form-control" v-model="saleAssetId" >
                <option :value="$store.state.config.assetID" selected>SIGN</option>
                <option :value="$store.state.config.wavesAssetID">WAVES</option>
                <option :value="$store.state.config.usdnAssetID">XTN</option>
                <option :value="$store.state.config.usdtPPAssetID">USDT-PP</option>
            </select>
          </div>
        </div>

        <button class="btn btn-primary bigger mb-0 mt-4 mx-auto d-block pointer-events-auto" @click.prevent="chooseSigningMethod()">CONFIRM</button>
        <p v-show="sellResult!=''" class="text-center mt-1">{{ sellResult }}</p>
        <p v-show="haveError" class="text-center mt-1">{{ responseMessage }} </p>
      </div>
      <div class="clickZone pointer"></div>
    </div>
  </transition>
</template>
<script>
import globalMixin from '@/mixins/global.js';

export default {
  mixins: [globalMixin],
  props: ["nftInfo", "nft", "setSalePopinOpen", "isUpdate"],
  data: function(){
    return {
      
      sellAmount: "",
      saleAssetId: this.$store.state.config.assetID,
      sellResult: '',
      haveError: false,
      responseMessage: '',
      offerOnly: false

    }
  },
  created(){
    this.setCurrentPrice()
  },
  watch: {
    offerOnly: function(offerOnly){
      if(offerOnly){
        this.sellAmount = 0
      }else{
        this.sellAmount = ""
      }
    },
    sellAmount: function(sellAmount){
      if(sellAmount.toString() == "" || sellAmount != 0){
        this.offerOnly = false
      }
    }
  },
  methods: {
    setCurrentPrice: function(){
     if(this.nftInfo.secondaryInfo){
       let priceInfo = this.getPriceWithTicker(this.nftInfo.secondaryInfo.asset, this.nftInfo.secondaryInfo.price)
       this.sellAmount = priceInfo[1]
       this.saleAssetId = this.nftInfo.secondaryInfo.asset
     }
    },
    openSetSalePopin: function(){
      this.$parent.setSalePopinOpen = !this.$parent.setSalePopinOpen
      this.$parent.burnPopinOpen = false
      this.$parent.transferPopinOpen = false
    },
    closeSetSaleForm: function(e){
      if(e.target.closest('.clickZone') || e.target.closest('.close_popin_nft')){
        this.$parent.setSalePopinOpen = false;
      }
    },
    chooseSigningMethod: function(){
       if(this.$store.state.user.isLogged){
          this.setForSale()
       }else{
         this.$store.state.signingChoice.showChoice = true
       }
    },
    setForSale: function(){
      let _this = this
      let decimal = this.saleAssetId == this.$store.state.config.usdnAssetID || this.saleAssetId == this.$store.state.config.usdtAssetID || this.saleAssetId == this.$store.state.config.usdtPPAssetID  ? 6 : 8
      this.$store.state.alert=true
      this.$store.state.alertMessage='Processing the request, Please wait...'

      if(this.sellAmount.toString() == ""){
        this.$store.state.alert=false
        this.$store.state.alertMessage=''
        this.responseMessage = "Wrong amount"
        this.haveError = true
        return
      }

      let callData
      if(_this.isUpdate){
        callData = {
            function: "updateSalePrice",
              args:[
                { type:"string", value: _this.nftInfo.assetId },
                { type:"integer", value: (_this.sellAmount  * Math.pow(10, decimal)).toFixed(0)},
                { type:"string", value: _this.saleAssetId }
            ]
        }
      }else{
        callData = {
            function: "sellNFT",
              args:[
                { type:"integer", value: (_this.sellAmount  * Math.pow(10, decimal)).toFixed(0)},
                { type:"string", value: _this.saleAssetId }
            ]
        }
      }

      const dataTx = {
        dApp: this.$store.state.config.dapp_addr_secondary,
       /*  ...invokeFee, */
        call: callData
      }

      if(!_this.isUpdate){
        dataTx.payment = [{ assetId: _this.nftInfo.assetId, amount: 1 }]
      }
      this.$store.dispatch("signerInvoke", dataTx).then(res => { 
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          if(res.error){
            // siplay error
            this.responseMessage = res.error.message ? res.error.message : res.error
            this.haveError = true
          }else{
            // display confirmation message of success
            this.$store.state.alert=false
            this.$store.state.alertMessage=''
            this.haveError = false
            this.responseMessage=""
            this.$parent.$parent.actionRes = true
            this.$parent.$parent.actionMsg = 'NFT set for sale successfully! It will take around 1 min to appear in the marketplace listing.'
            _this.$parent.$emit('removeNFT', _this.nftInfo.assetId)
            
            if(_this.isUpdate){

              // TO DO IMPORTANT
              // MAYBE A SINGLE NFT DATA UPDATE INSTEAD OF FULL LIST REFRESH
              
              _this.$parent.$parent.resetNFT()
              _this.$parent.$parent.getAllNFT2ndMarket()
            }

            setTimeout(function(){
              
              _this.responseMessage = ''
              _this.$parent.$parent.actionRes = false
              _this.$parent.$parent.actionMsg = ''
              
              //_this.$parent.$parent.getAllNFT2ndMarket()
            }, 6000)
          }
      })       
    },
  }
}
</script>
<style lang="scss">

</style>