<template>
  <div class="wrap_swap">
    <div v-if="keeper_swap_loaded == false" class="text-center">{{ message }}</div>
    <div id="keeper-swap-widget"></div>
  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js';
export default {
  mixins: [globalMixin],
  data: function(){
    return {
      widget: null,
      keeper_swap_loaded: false,
      message: 'Loading swap widget, please wait...'
    }
  },
  mounted(){
    this.init_keeper()
  },
  watch: {
    '$store.state.user.isLogged'(logged){
      if(logged){
        this.widget = null
        this.init_keeper()
      }
    }
  },
  methods: {
    init_keeper: async function(){
      if(this.$store.state.user.isLogged){
        this.message = "Loading swap widget, please wait..."
        let _this = this
        _this.keeper_swap_loaded = false
        let keeperScript = document.createElement('script')
        keeperScript.setAttribute('src', 'https://swap-widget.keeper-wallet.app/lib/swap-widget.umd.js')
        document.head.appendChild(keeperScript)
        keeperScript.addEventListener("load", function(){
          _this.widget = window.KeeperSwapWidget.create(
          document.getElementById('keeper-swap-widget'), {
            useInternalSigner: false,
            referrer: 'signart'
            }
          );
          document.getElementById("keeper-swap-widget").querySelector("iframe").style.maxWidth = "100%"
          
          let address = _this.$store.state.user.userAddress
          let signer = _this.$store.state.signer.signer
          console.log({
            address,
            signer,
          })
          _this.widget.setWallet({
            address,
            signer,
          });
          _this.keeper_swap_loaded = true
          
        })
      }else{
        this.message = "Login first to access this feature."
      }
    }
  }
}
</script>