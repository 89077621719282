// import

export default {
  data: function() {
    return {
      messageInfo: "",
      disableLoginBtn: false,
      expiration: 3600000, // 1h
      errorSession: false
    };
  },
  methods: {
    getSessionToken: async function(){
      let getToken = await fetch(this.$store.state.config.signArtApiURL+"/collections/token/"+this.$store.state.user.userAddress)
      getToken = await getToken.json()
      return getToken
    },
    loginSession: async function(){
      console.log("login session")
      this.disableLoginBtn = true 
      let { token, signedMessage, timestamp, errorSession} = await this.checkActiveSession()
      if(!errorSession){
        this.messageInfo = "Retrieving your info..."
        this.$store.state.user.sessionData = {}
        this.$store.state.user.sessionData.token = token
        this.$store.state.user.sessionData.timestamp = timestamp
        this.$store.state.user.sessionData.waves_addr = this.$store.state.user.userAddress
        this.$store.state.user.sessionData.signedMessage = signedMessage
        this.messageInfo = ""
      }else{
        console.log("error session")
      }
      this.disableLoginBtn = false
      return {errorSession: errorSession}
    },
    checkActiveSession: async function(){
      this.errorSession = false
      let tokenData = {
        token: null,
        timestamp: null
      }
      let signedMessage

      if(this.$store.state.user.sessionData && this.$store.state.user.sessionData.token){
        if((Date.now() - this.$store.state.user.sessionData.timestamp) >= this.expiration){
          this.$store.state.user.sessionData = null
          this.disableLoginBtn = false
          this.messageInfo = ""
          return
        }else{
          tokenData.token = this.$store.state.user.sessionData.token
          tokenData.timestamp = this.$store.state.user.sessionData.timestamp
          signedMessage = this.$store.state.user.sessionData.signedMessage
        }
      }else{
        this.messageInfo = "Initializing session, please wait..."
        tokenData = await this.getSessionToken()
        this.messageInfo = "Wait for signin window..."
        signedMessage = await this.$store.dispatch("signMessage", tokenData.token)
        if(signedMessage.error){
          this.errorSession = true
          this.$store.state.user.sessionData = null
        }
        
      }

      return {token: tokenData.token, signedMessage: signedMessage, timestamp: tokenData.timestamp, errorSession: this.errorSession}
    },
  }
}