<template>
  <div class="col-md-8 col-lg-8 col-xl-12 px-1 art_detail_wrap wrap" id="content">
    
    <div v-if="artwork!=undefined && !nftIssuedList.length && isLoaded" id="suspended" class="text-center">
      Edition not found. <router-link :to="'/user/'+artistInfos.user_addr+'/artwork/'+txid" exact v-b-tooltip.hover.top title="See original drop" class="ml-2">See original drop</router-link>
    </div>
    <div id="user_page" v-else>
      <transition name="fade">
        <app-bid :artwork="artwork" :secondary="secondary" :showBid="showBid" :currentHighestBid="currentHighestBid" v-if="showBid"></app-bid>
      </transition>
      <transition name="fade">
        <app-bid-actions :bidData="bidData" :showbidData="showbidData" v-if="showbidData"></app-bid-actions>
      </transition>
      <transition name="fade">
        <div id="qrCode" ref="qrCode" v-show="showQrcode"><img src="" /></div>
      </transition>
      <!-- <app-user-infos :isBanned="isBanned" :artistInfos="artistInfos" :infosLoaded="infosLoaded" :userExist="userExist" :hasProfile="hasProfile" :userNote="userNote"></app-user-infos> -->
      <div class="container-fluid px-0" id="art_detail">
        <div v-if="artwork==undefined" class="loading">
          <app-loading :label="'data'"></app-loading>
        </div>
        <div class="row" v-else>
          <div class="col-lg-6" id="visuel" :class="{'d-flex':isGif(artwork) && !isVideo(artwork, 'export'), 'justify-content-center': isGif(artwork), 'align-items-center':isGif(artwork)}" :data-display="artwork.art_display_cid" :data-export="artwork.art_export_cid">
            <div id="extend" @click="imageFitScreen" v-if="!isVideo(artwork, 'export')" v-b-tooltip.hover.bottom title="See in High Definition"><i class="fa fa-expand" aria-hidden="true"></i></div>
            <img :src="spinerSrc" class="spiner" v-show="spinerShow" ref="spiner"/>
            <vue-plyr v-if="isVideo(artwork, 'export')" ref="plyr">
              <video
                crossorigin
                playsinline
                controls
                muted
                autoplay
                loop
                @loadedmetadata="hideSpiner();"
              >
                <source
                  :src="getIpfsLink(cidToUse(artwork, 'export'))"
                  type="video/mp4"
                />
              </video>
            </vue-plyr>
            <img 
              :src="getIpfsLink(cidToUse(artwork, 'export')) || emptySrc" 
              @load="imgLoaded"
              class="img-fluid w-100 d-block main mx-auto"  @error="replaceByDefaultImg" v-else />
          </div>
          <div class="col-lg-6 pr-lg-3 pr-xl-5 pt-3 pt-lg-0">
            <div id="wrapper_detail_infos">
              <h3 class="detailTitle">
                {{ artwork.art_name }} 
                <router-link :to="'/user/'+artistInfos.user_addr+'/account/artworks/list/'+txid" exact v-show="artistInfos.user_addr == $store.state.user.userAddress" class="edit" v-b-tooltip.hover.top title="Edit this artwork">Edit</router-link> 
                <router-link :to="'/user/'+artistInfos.user_addr" exact @click.native="resetArtistScroll();"><img src="../../assets/images/back_list_icon.svg" v-b-tooltip.hover.right title="Back to artist page" class="list_icon"/></router-link>
              </h3>
              
              <div class="added">
                <span><router-link :to="'/user/'+artistInfos.user_addr+'/artwork/'+txid" exact v-b-tooltip.hover.top title="See on primary market">Initial drop</router-link> added on {{ artwork.art_date | setDate }}</span> 
                <div class="wrap_share d-flex align-items-center position-relative ml-3">
                  <div class="copied" @click="copyShortLink"></div>
                  <span class="mr-3">Share:</span>
                  <div class="cursor-pointer mr-3" @click="showQrcode = true" v-b-tooltip.hover.top title="Show QR Code"><img src="../../assets/images/qr_code_icon.svg" class="pointer-event-none"/></div>
                  <div class="cursor-pointer mr-3" :data-link="currentURL" @click="copyShortLink" v-b-tooltip.hover.top title="Generate short link"><img src="../../assets/images/copy_icon.svg" class="pointer-event-none"/></div>
                  
                  <ShareNetwork
                    network="twitter"
                    :url="currentURL"
                    :title="artwork.art_name +' via @signArtApp - '"
                    :hashtags="getTagString()"
                    class="mr-3"
                  >
                    <i class="fab fa-twitter"
                    v-b-tooltip.hover.top 
                    title="on Twitter"></i>
                </ShareNetwork>
                <ShareNetwork
                    network="facebook"
                    :url="currentURL"
                    :title="artwork.art_name"
                    :quote="artwork.art_name"
                    :hashtags="getTagString()"
                    class="mr-3"
                  >
                  <i class="fab fa-facebook-f"
                  v-b-tooltip.hover.top 
                  title="on Facebook"
                  ></i>
                </ShareNetwork>
                <ShareNetwork
                    network="telegram"
                    :url="currentURL"
                    :title="artwork.art_name +' via @SIGNArtApp - '"
                    :hashtags="getTagString()"
                    class="mr-3"
                  >
                    <i class="fab fa-telegram-plane"
                    v-b-tooltip.hover.top 
                    title="on Telegram"
                    ></i>
                </ShareNetwork>
                </div>
              </div>
              <ul id="tags" v-show="taglist.length > 0">
                <li v-for="(tag, index) in taglist" :key="index" @click="clickTag(tag)">{{ tag }}</li>
              </ul>
              <h4 class="creation-detail">Details:</h4>
              <div class="description" v-html="$sanitize(artwork.art_desc)" v-linkified:options="{ className: 's-link' }">
              </div>
               <div class="spacer" v-show="artwork.art_flag"></div>
              <div id="flagged" v-show="artwork.art_flag">
                Status: {{ artwork.art_flag }}<br/>Reason: {{ artwork.art_note }}
              </div>
              <div class="spacer"></div>
              <ul id="extra">
                <li>
                  <ul class="d-flex justify-content-start mb-2">
                    <li class="mr-3"><span>Edition: </span> #{{ $route.params.issue }} of {{ artwork.art_maxmint }} {{ editionMessage() }}</li>
                    <li><span>Sold initially for:</span> {{ soldOnPrimaryFor("art_sold_"+$route.params.issue+"_of_"+artwork.art_maxmint) }}</li>
                  </ul>
                </li>
                <li v-show="secondary.saleInfo.price && secondary.saleInfo.price != 0 && secondary.onsale">
                  <ul class="wrap_price d-flex justify-content-start align-items-xl-start align-items-md-center" >
                    <li v-show="secondary.saleInfo.price && secondary.saleInfo.price != 0 && secondary.onsale" class="price"><span>Price:</span> {{ secondary.saleInfo.price / Math.pow(10, secondary.decimal) ? numberWithSpaces(secondary.saleInfo.price / Math.pow(10, secondary.decimal)) : 0 }} {{ getTickerByAssetId(secondary.saleInfo.asset)}} <app-UsdPrice :artwork="artwork" :secondary="secondary"/></li>
                  </ul>
                </li>
                <li v-show="!secondary.onsale"><span>Price:</span> NOT FOR SALE</li>
              </ul> 
              <div id="response" :class="{error: haveError}" v-show="responseMessage !=''" class="container w-100 mx-auto text-center mt-3">
                <div class="wrap">{{responseMessage}} <span v-if="buyerAddress != ''">- <router-link :to="'/user/'+buyerAddress+'/account/owned'" exact class="seeCollection">See your owned NFTs</router-link></span></div>
              </div>
              <ul class="links">
                <li class="buyBtn" v-show="secondary.onsale && secondary.price != 0 && secondary.saleInfo.price != 0 && artwork.art_flag != 'ILLEGAL' && artwork.art_flag != 'CHANGE_REQUIRED' && artistInfos.user_status != 'SUSPENDED'"><a href="#" @click.prevent="chooseSigningMethod('buy')" class="d-flex align-items-center"><img src="../../assets/images/dollar_icon.svg" class="mr-1"/> Buy this NFT</a></li>
                <li class="bidBtn" v-show="secondary.onsale && artwork.art_flag != 'ILLEGAL' && artwork.art_flag != 'CHANGE_REQUIRED' && artistInfos.user_status != 'SUSPENDED'"><a href="#" @click.prevent="chooseSigningMethod('bid')" class="d-flex align-items-center"><img src="../../assets/images/dollar_icon.svg"  class="mr-1"/> Make an offer</a></li>
                <li v-show="artwork.art_export_cid != ''"><a :href="getIpfsLink(artwork.art_export_cid)" target="_blank" class="d-flex align-items-center"><img src="../../assets/images/download_icon.svg" class="mr-2"/> Download</a></li>
                <li><a :href="$store.state.config.signwebappUrl + '/explorer/' + artwork.art_signid" target="_blank" class="d-flex align-items-center"><img src="../../assets/images/certificate_icon.svg" class="mr-2"/> Certificate</a></li>
                <li v-show="artwork.art_licence_cid != ''"><a :href="getIpfsLink(artwork.art_licence_cid)" target="_blank" class="d-flex align-items-center"><img src="../../assets/images/licence_icon.svg" class="mr-2"/> License</a></li>
                <li v-show="artwork.art_display_cid != ''"><a :href="'https://tineye.com/search/?url='+getIpfsLink(artwork.art_display_cid)" target="_blank" class="d-flex align-items-center" v-b-tooltip.hover.top title="Check this artwork on the Tineye image recognition tool."><img src="../../assets/images/tineye_icon.svg" class="mr-2"/> Tineye *</a></li>            
                <li v-show="artwork.art_display_cid != ''">
                  <a :href="isVideo(artwork, 'display') ? 'https://yandex.com/images/search?url='+getIpfsLink(artwork.art_crawler_cid)+'&rpt=imagelike' : 'https://yandex.com/images/search?url='+getIpfsLink(artwork.art_display_cid)+'&rpt=imagelike'" target="_blank" class="d-flex align-items-center" v-b-tooltip.hover.top title="Check this artwork on the Yandex image recognition tool."><i class="fab fa-yandex-international mr-2"></i> Yandex *</a>
                </li> 
                <li v-if="artwork.art_issued > 0 && artwork.art_maxmint > 1">
                  <router-link :to="'/marketplace/1?search='+txid+'&status='+(artistInfos.user_status == 'VERIFIED' ? 'verified' : 'all')" exact tag="a" class="d-flex align-items-center"><img src="../../assets/images/find_reseller_icon.svg" class="mr-2"/>More editions</router-link> 
                </li>
              </ul>
              <div class="mention cursor-pointer d-inline" v-b-tooltip.hover.top title="Tineye is an image recognition tool. It helps collectors to detect if the artwork uses any existing online content. Most unique creations should have no search results on Tineye unless it uses a copyright free image as a base. When in doubt, please ask the link to the original image license from the artist before buying.">
                * What is Tineye?
              </div>
              <div class="change_required" v-if="artwork.art_flag == 'CHANGE_REQUIRED'">
                  This artwork requires modification, it cannot be sold at the moment.
              </div>
              <div class="spacer"></div>
              <ul id="extra" class="mb-4">
                <li><span>Artwork type:</span> {{ artwork.art_type }}</li>
                <li>
                  <ul class="d-flex justify-content-start mt-2">
                    <li v-if="secondary.nftInfo" class="mr-lg-3 mr-0"><span>Reference: </span><a :href="getExplorerUrl()+'/assets/'+secondary.nftInfo.issuedNftId" target="_blank">{{ secondary.assetName }}</a></li>
                    <li class="mr-lg-3 mr-0"><span>Owner: </span> <router-link :to="'/user/'+secondary.currentOwner" exact>{{ isAddress(secondary.currentOwnerName) ? shortTxAddr(secondary.currentOwnerName) : secondary.currentOwnerName }}</router-link></li>
                    <li v-if="artwork.art_royalties" class="mr-lg-3 mr-0"><span>Artist royalty:</span> {{ setRoyaltiesBase10With2Decimals(artwork.art_royalties) }}% <span v-b-tooltip.hover.top :title="`When reselling the NFT on the secondary market, a commission of ${setRoyaltiesBase10With2Decimals(artwork.art_royalties)}% is applied for the artist as a royalty. A 3% fee is also applied for SIGN Art as commission; -${(setRoyaltiesBase10With2Decimals(artwork.art_royalties)) + 3}%`"><i class="fas fa-question-circle pl-1 pr-1"></i></span></li>
                    <li v-else class="mr-lg-3 mr-0"><span>Royalty:</span> 10% <span v-b-tooltip.hover.top :title="`When reselling the NFT on the secondary market, a commission of 10% is applied for the artist as a royalty. A 3% fee is also applied for SIGN Art as commission; -13%`"><i class="fas fa-question-circle pl-1 pr-1"></i></span></li>
                  </ul>
                </li>
              </ul>
              <app-user-infos-lite :isBanned="isBanned" :artistInfos="artistInfos" :infosLoaded="infosLoaded" :userExist="userExist" :hasProfile="hasProfile" :userNote="userNote" :collection="collection"></app-user-infos-lite>
            </div>
            
          </div>
        </div>
        
      </div>

      <div id="art_tx" v-touch:start="disableSwipe" v-touch:end="enableSwipe">

        <b-card no-body>
          <b-tabs card>

            <b-tab title="Offers" active>
              <b-card-text>
                <div id="currentowner">
                  <table class="table table-striped" v-if="offersList!=undefined">
                    <thead>
                      <tr>
                        <td>DATE</td>
                        <td>OFFER FROM</td>
                        <td>OFFERED PRICE</td>
                        <td>STATUS</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="offersList.length == 0 ">
                        <td colspan="4" v-if="bidsLoading">Checking offers...</td>
                        <td colspan="4" v-else>No offer(s) found.</td>
                      </tr>
                      <tr v-for="(offer, index) in offersList" :key="index" v-else>
                        <td>{{ offer.date | setDate }} <span class="offerTime">at {{ offer.date | setTime }}</span></td>
                        <td>
                          <router-link :to="'/user/'+offer.bidOwner" exact v-if="offer.bidOwner != undefined">{{ shortTxAddr(offer.bidOwner) }}</router-link>
                          <span v-else>Checking address...</span>
                        </td>
                        <td><strong>{{ numberWithSpaces(offer.price) }} {{ offer.assetId }}</strong> <!-- <span class="usdValue">({{numberWithSpaces(offer.priceInUsd.toFixed(2))+"$"}})</span> --><app-UsdPrice :offer="offer" :color="'grey'"/></td>
                        <td>
                          <div v-if="offer.status == 'OPEN' && offer.owner == $store.state.user.userAddress && artwork.art_maxmint != 0 && artwork.art_flag != 'ILLEGAL'">
                            <button class="acceptOffer mr-2" :data-artId="offer.artId" :data-saleOwner="offer.owner" :data-assetId="offer.assetId" :data-basePrice="offer.basePrice" :data-bidid="offer.bidid" :data-bidOwner="offer.bidOwner" @click.prevent="bidAction($event, 'accept', 'secondary', secondary.nftInfo.issuedNftId)">ACCEPT</button>
                            <button class="rejectOffer mr-2" :data-artId="offer.artId" :data-saleOwner="offer.owner" :data-assetId="offer.assetId" :data-basePrice="offer.basePrice" :data-bidid="offer.bidid" :data-bidOwner="offer.bidOwner" @click.prevent="bidAction($event, 'cancel', 'secondary', secondary.nftInfo.issuedNftId)">REJECT</button>
                          </div>
                          <div v-else-if="offer.status == 'OPEN' && offer.bidOwner == $store.state.user.userAddress">
                            <button class="cancelOffer" :data-artId="offer.artId" :data-saleOwner="offer.owner" :data-assetId="offer.assetId" :data-basePrice="offer.basePrice" :data-bidid="offer.bidid" :data-bidOwner="offer.bidOwner" @click.prevent="bidAction($event, 'cancel', 'secondary', secondary.nftInfo.issuedNftId)">CANCEL</button>
                          </div>
                           <div v-else v-html="setStatus(offer.status)" class="offerStatus"></div>
                        </td>
                      </tr> 
                    </tbody>
                  </table>
                </div>
              </b-card-text>
            </b-tab>

            <b-tab :title="this.$store.state.isMobile ? 'Transactions' : 'Transaction history'">
              <b-card-text>
                <div id="txhistory">
                  <table class="table table-striped" v-if="artwork!=undefined">
                    <thead>
                      <tr>
                        <td>DATE</td>
                        <td>ACTION</td>
                        <td>FROM</td>
                        <td>TO</td>
                        <td>PRICE</td>
                      </tr>
                    </thead>
                    <tbody v-if="tokenHistory && tokenHistory.length">
                      <tr v-for="(tx, index) in tokenHistory" :key="index">
                        <td>{{tx.timestamp | setDate}} <span class="offerTime">at {{ tx.timestamp | setTime }}</span></td>
                        <td><a :href="getExplorerUrl()+'/tx/'+tx.id" target="_blank">{{ setAction(tx.status) }}</a></td>
                        <td>
                          <router-link :to="'/user/'+tx.seller" exact v-if="tx.status == 'OPEN' || tx.status == 'CLOSED'">{{tx.seller}}</router-link>
                          <div v-if="tx.status == 'CANCELLED'">SIGN Art dApp</div>
                        </td>
                        <td>
                          <router-link :to="'/user/'+tx.buyer" exact v-if="tx.status == 'CLOSED'">{{tx.buyer}}</router-link>
                          <div v-if="tx.status == 'OPEN'">SIGN Art dApp</div>
                          <router-link :to="'/user/'+tx.seller" exact v-if="tx.status == 'CANCELLED'">{{tx.seller}}</router-link>
                        </td>
                        <td>{{ tx.price }} {{ tx.ticker }}</td>
                      </tr> 
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="5">No transaction history.</td>
                      </tr> 
                    </tbody>
                  </table>
                </div>
              </b-card-text>
            </b-tab>

          </b-tabs>
        </b-card>

      </div>
    </div>
  </div>
</template>

<script>
/* import UserInfos from '@/components/elements/UserInfos.vue' */
import UserInfosLite from '@/components/elements/UserInfosLite.vue'
import globalMixin from '@/mixins/global.js';
import userMixin from '@/mixins/userMixin.js';
import orderedArtworks from "@/mixins/orderedArtworks.js"
import { mapState } from 'vuex';
import Loading from '@/components/elements/Loading.vue'
import BidWindow from '@/components/content/bid/BidWindowSecondary.vue'
import bidActions from '@/components/content/bid/bidActions.vue' 
import UsdPrice from '@/components/elements/UsdPriceApi.vue' 
import offerMixin from '@/mixins/offerMixin.js';
import QRCode from 'qrcode'
import encodeUrl from 'encodeurl';
import collections from '@/mixins/collections.js';

export default {
  mixins: [globalMixin, userMixin, orderedArtworks, offerMixin, collections],
  data: function(){
    return {
      addr: this.$route.params.addr,
      txid: this.$route.params.txid,
      spinerShow: true,
      detailOpen: false,
      artwork: undefined,
      taglist: [],
      tokenHistory: undefined,
      haveError: false,
      responseMessage: '',
      buyerAddress: '',
      feeCost: this.$store.state.sponsoredFee * 5, 
      feeTicker: 'SIGN',
      nftIssuedList: [],
      decimal: 8,
      currentURL: "",
      isPlaying: false,
      isMuted: true,
      showBid: false,
      signMinPrice: null,
      showQrcode: false,

      issueNull: false,
      isLoaded: false,
      secondary: {
        currentOwner: "",
        currentOwnerName: "N/A",
        onsale: false,
        nftInfo: null,
        saleInfo: {
          timestamp: null,
          price: null,
          decimal: null,
          asset: null,
          nftid: null
        }

      },

      collection: null
      
    }
  },
  components: {
    /* 'app-user-infos': UserInfos, */
    'app-user-infos-lite': UserInfosLite,
    'app-loading': Loading,
    'app-bid': BidWindow,
    'app-bid-actions': bidActions,
    'app-UsdPrice': UsdPrice
  },
  created: async function(){

    if(this.$route.params.issue.length == 44){
      let regex = encodeUrl("^nft_"+this.$route.params.issue)
      let getEditionNumber = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+this.$store.state.config.dapp_addr_artworks+"?matches="+regex)
      let editionNumber = await getEditionNumber.json()
      editionNumber = editionNumber[0].value.split("_")[2]
      this.$router.push({name:'user-artwork-front-issue', params:{issue: editionNumber}})
      this.$route.params.issue = editionNumber
    }
    await this.init()
  },
  metaInfo() { 
    const name = this.artwork != undefined ? this.artwork.art_name : "";
    const description = this.artwork != undefined ? this.artwork.art_desc : "";
    const img = this.artwork != undefined ? this.getIpfsLink(this.artwork.art_display_cid) : ""
    const url = this.artwork != undefined ? this.currentURL : ""
    return {
      title: `SIGN Art - ${name}`,
      meta: [
          { name: 'description', content: description},
          { property: 'og:title', content: name},
          { property: 'og:site_name', content: 'SIGN ART'},
          { property: 'og:description', content: description},
          { property: 'og:type', content: 'Artwork'},
          { property: 'og:url', content: url},
          { property: 'og:image', content: img },
          { property: 'twitter:title', content: name},
          { property: 'twitter:image', content: img},
          { property: 'twitter:card', content: "summary_large_image"}
      ]
    }
  },
  methods: {
    init: async function(){
      await this.$store.dispatch("getPriceInUsd")
      await this.loadArtwork()
      this.getCurrentUrl()
      await this.getOffersListSecondary(this.secondary.nftInfo.issuedNftId, "")
      
      this.signMinPrice = await this.getSignMinSellPrice()

      let collectionItem = await this.getCollectionItem(this.$route.params.addr, this.$route.params.txid)
      if(collectionItem[0] && collectionItem[0].collection_id){
        let collection_detail = await this.getCollections(this.$route.params.addr, collectionItem[0].collection_id)
        this.collection = collection_detail[0]
      }
      return
    },
    clickTag: function(tag){
      this.$store.state.searchStr = tag
      this.$store.state.marketplaceScrollState.scrollToId = 0
      this.$router.push("/marketplace/1?search="+this.$store.state.searchStr+"&status="+this.$store.state.filtering.marketplace.statusArtist).catch(()=>{});
    },
    soldOnPrimaryFor: function(key){
      let data = this.artwork[key]
      if(!data) return
      let dataArray = data.split("_")
      return this.artwork.user_addr == dataArray[0] ? "Gifted" : this.getPriceWithTicker(dataArray[4], dataArray[3])[2]
    },
    editionMessage: function(){
      let msg =""
      switch(this.artwork.art_maxmint){
        case 0:
          msg = "(No edition set)"
        break;

        case 1:
          msg = "(Single edition)"
        break;

        default:
          msg = "(Multiple edition)"
      }
      return msg
    },
    setStatus: function(e){
      if(e == "CANCELED"){e = "CANCELLED"}
      return e == "CLOSED" ? '<i class="fas fa-check mr-2"></i> ' + e : e == "OPEN" ? '<i class="far fa-clock mr-2"></i> ' + e : '<i class="fas fa-times mr-2"></i> ' + e
    },
    hideSpiner: function(){
      this.$refs.spiner.classList.add("d-none")
    },
    getTagString: function(){
      let list = this.taglist.toString()
      list = list.split(",").join(",")
      return list.length ? list+",NFT,SignArt" : "NFT,SignArt"
    },
    imageFitScreen: function(){
      let url = this.artwork.art_export_cid != '' ? this.getIpfsLink(this.artwork.art_export_cid) : this.getIpfsLink(this.artwork.art_display_cid)
      this.$parent.$parent.loadImage(url)
      this.$store.state.imgFit = true
    },
    loadArtwork: async function(){
      const _this = this
        let regex = encodeUrl("^art(.*)"+ _this.$route.params.txid +"_"+ _this.$route.params.addr)
        try{
          let getData = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.dapp_addr_artworks+"?matches="+regex)
          let data = await getData.json()
          if(data.length > 1){
            let orderedData = this.getOrderedArtworks(data)
            _this.artwork = orderedData[0]
            await _this.getOwnersList()

            let splitTag = _this.artwork.art_tags.split(",")
            let cleanTags = splitTag.filter(function (el) {
              return el != "";
            });
            _this.taglist = cleanTags
            _this.decimal = _this.artwork.art_assetAccepted == _this.$store.state.config.usdnAssetID || _this.artwork.art_assetAccepted == _this.$store.state.config.usdtAssetID || _this.artwork.art_assetAccepted == _this.$store.state.config.usdtPPAssetID  ? 6 : 8
          }else{
            document.querySelector(".loading_wrap .text-center").innerHTML="This artwork doesn't exist on SIGN Art"
            document.querySelector(".loading_wrap img").style.display = "none"
          }
        } catch(err){
           console.error(err)
        }
    },
    setAction: function(status){
      let res
      switch(status){
        case "OPEN":
          res = "FOR SALE"
        break;

        case "CANCELLED":
          res = "CANCELLED"
        break;

        case "CLOSED":
          res = "SOLD"
        break;

        default:
      }
      return res
    },
    getHistoryOn2nd: async function(){
      let _this = this
      let txinfos = []
      let regex = encodeUrl("^sale2nd_info_(.*)_"+ _this.secondary.nftInfo.issuedNftId +"_(.*)_CLOSED")
      let data = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.dapp_addr_secondary+"?matches="+regex)
      data = await data.json()


      Object.values(data).forEach((curr) => {
          const _key = curr.key.split("_");

            const ownerAddr = _key[2];
            const saleStatus = _key[_key.length-1];
            let val = curr.value.split("_")
            
            if(saleStatus == "CLOSED"){
              // uncomment if have precise timestamp in db later to list other actions.
              /* let res_previous = {
                id: val[6],
                seller: ownerAddr,
                timestamp: val[0],
                price: price,
                ticker: ticker,
                status: "OPEN"
              } */
              let [ticker, price] = val.includes("CLOSED-OFFER") ? this.getPriceWithTicker(val[12], val[11]) : this.getPriceWithTicker(val[2], val[1]) 

              let res = {
                id: val[9],
                seller: ownerAddr,
                timestamp: val[8],
                price: price,
                ticker: ticker,
                status: saleStatus,
                buyer: val[10]
              } 
              //txinfos.push(res_previous);
              txinfos.push(res);
            }
          /* 
             if(saleStatus == "OPEN"){
               let res = {
                id: val[6],
                seller: ownerAddr,
                timestamp: val[0],
                price: price,
                ticker: ticker,
                status: saleStatus
              } 
              txinfos.push(res);
            }


            if(saleStatus == "CANCELLED"){
              let res_previous = {
                id: val[6],
                seller: ownerAddr,
                timestamp: val[0],
                price: price,
                ticker: ticker,
                status: "OPEN"
              }

              let res = {
                id: val[9],
                seller: ownerAddr,
                timestamp: val[8],
                price: price,
                ticker: ticker,
                status: saleStatus
              } 
              

              txinfos.push(res_previous);
              txinfos.push(res);
            } */
          
          
        }, {});

        // Since we can only use lastblock.timestamp and 2 entries could have same timestap, we add 1 millisecond to each timestamp before ordering by timestamp
        // because entries are returned in correct order for reason i don't get at all :)
        /* let increment = 0
        txinfos.map(function(a){
          increment += 100
          return a.timestamp = +a.timestamp + increment
        }); */
        
        // now can order by timestamp...
        let orderedHistory = Object.values(txinfos).sort(function(a,b){
          return b.timestamp - a.timestamp;
        });
        _this.tokenHistory = orderedHistory
    },
    getLastBlock: async function(){
      let lastBlock = await fetch(this.$store.state.config.nodeURL+"/blocks/last").then(res => res.json()).then(async data => { 
        return data.height
      })
      return lastBlock-1
    },
    getOwnersList: async function(){
      let _this = this
      let nftIssuedList = []
      let regex = encodeUrl("^art_sold_"+_this.$route.params.issue+"_of_(.*)_"+ _this.$route.params.txid +"_"+ _this.$route.params.addr)
      await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.dapp_addr_artworks+"?matches="+regex).then(res => res.json()).then(async data => {  
      data.map(function(e){
        let nftKeyValues = e.key.split("_")
        let nftValues = e.value.split("_")
        let nftValuesObj = {
          buyer: nftValues[0],
          buyDate: nftValues[1],
          buyId: nftValues[2],
          buyPrice: nftValues[3],
          buyAssetId: nftValues[4],
          issuedNftId: nftValues[5],
          issueNumber: nftKeyValues[2],
          maxIssuable: nftKeyValues[4],
          isBurn: false
        }
        nftIssuedList.push(nftValuesObj)
      })
        _this.nftIssuedList = nftIssuedList;
        _this.getEditionData()
      })
      _this.checkBurned()
    },
    checkBurned: async function(){
      let _this = this
        for(let nftid in _this.nftIssuedList){
          if(_this.nftIssuedList[nftid].issuedNftId != undefined){
            let checkAssetInfos = await fetch(this.$store.state.config.nodeURL+"/assets/details/"+_this.nftIssuedList[nftid].issuedNftId)
            checkAssetInfos = await checkAssetInfos.json()
            let isBurn = checkAssetInfos.quantity != 1
            if(isBurn){
              _this.nftIssuedList[nftid].isBurn = true
            }
          }
        }
    },
    imgLoaded: function(){
      this.spinerShow = false;
    },
    chooseSigningMethod: function(action){
       if(this.$store.state.user.isLogged){
         if(action == "buy"){
          this.buyArtwork()
         }
         if(action == "bid"){
           this.showBid = true
         }
       }else{
         this.$store.state.signingChoice.showChoice = true
       }
    },
    buyArtwork: function(){
      let _this = this;
      this.$store.state.alert=true
      this.$store.state.alertMessage='Processing the sale, Please wait...'
      /* let invokeFee
      if(this.feeTicker == 'SIGN'){
        invokeFee = {
          fee: this.feeCost * 10 ** 8,
          feeAssetId: this.feeAssetId
        }
      }
      if (this.feeTicker == 'USDN'){
        invokeFee = {
          fee: this.feeCost * 10 ** 6,
          feeAssetId: this.feeAssetId
        }
      }
      if(this.feeTicker == 'WAVES'){
        invokeFee = {
          fee: 500000
        }
      } */
      const dataTx = {
        dApp: this.$store.state.config.dapp_addr_secondary,
        /* ...invokeFee, */
            call: {
                function: "buyNFT",
                 args:[
                    { type:"string", value: _this.secondary.saleInfo.nftid }
                ]
            }
      }

      if(_this.secondary.saleInfo.asset != ""){
        dataTx.payment = [{ assetId: _this.secondary.saleInfo.asset, amount: _this.secondary.saleInfo.price }]
      }else{
        dataTx.payment = [{ assetId: null,amount: _this.secondary.saleInfo.price }]
      }
        
      this.$store.dispatch("signerInvoke", dataTx).then(res => { 
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          if(res.error){
            this.responseMessage = res.error.message ? res.error.message : res.error
            this.haveError = true
          }else{
            // display confirmation message of success
            this.$store.state.alert=false
            this.$store.state.alertMessage=''
            this.haveError = false
            this.responseMessage = 'NFT bought successfully!'
            this.buyerAddress =  this.$store.state.user.userAddress
            
            _this.loadArtwork()
            setTimeout(function(){
              this.responseMessage = ''
            }, 3000)
          }
      }) 
    },
    detectClickOutOfQRCodePopin: function(e){   
      if(this.showQrcode){
        if (!document.getElementById('qrCode').querySelector("img").contains(e.target)){
          this.showQrcode = false
        }
      }
    },
    getNameByAddress: async function(value) {
      if (!value) return "";
      let regex = encodeUrl("^user_name_"+this.secondary.currentOwner)
      let name = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+this.$store.state.config.dapp_addr_users+"?matches="+regex)
      name = await name.json()
      this.secondary.currentOwnerName = name.length ? name[0].value : value
    },
    getEditionData: async function(){
      let _this = this
      if(!_this.nftIssuedList.length){return}
      this.secondary.nftInfo = _this.nftIssuedList[0]

      // SET NFT NAME
      let nft_data = await fetch(this.$store.state.config.nodeURL+"/assets/details/"+_this.nftIssuedList[0].issuedNftId)
      nft_data = await nft_data.json()
      this.secondary.assetName = nft_data.name

      // GET CURRENT OWNER
      let regex = encodeUrl("current_owner_on_dapp_"+_this.nftIssuedList[0].issuedNftId)
      let currentOwner = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.dapp_addr_secondary+"?matches="+regex)
      currentOwner = await currentOwner.json()
      let lastBlock = await _this.getLastBlock()
      if(currentOwner.length == 0){
        if(this.nftIssuedList[0].issuedNftId != undefined){
            let info = await fetch(this.$store.state.config.nodeURL+"/assets/"+this.nftIssuedList[0].issuedNftId+"/distribution/"+lastBlock+"/limit/1")
            info = await info.json()
            let isolateAddr = Object.keys(info.items)[0]
            this.secondary.currentOwner = isolateAddr
            this.secondary.currentOwnerName = isolateAddr
            this.secondary.onsale = false
            await _this.getNameByAddress(isolateAddr)
          }
      }else{
        _this.secondary.currentOwner = currentOwner[0].value
        await _this.getNameByAddress(currentOwner[0].value)
        let regex = encodeUrl("^sale2nd_info_"+currentOwner[0].value+"_"+_this.nftIssuedList[0].issuedNftId+"_"+this.$route.params.txid+"_"+ this.$route.params.addr+"_OPEN")
        await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.dapp_addr_secondary+"?matches="+regex).then(res => res.json()).then(async data => {
          if(data[0]){
            let temp = data[0].value.split("_")
            _this.secondary.saleInfo.timestamp = temp[0]
            _this.secondary.saleInfo.price = temp[1]
            _this.secondary.saleInfo.asset = temp[2]
            _this.secondary.saleInfo.nftid = temp[3]
            _this.secondary.decimal = temp[2] == _this.$store.state.config.usdnAssetID || temp[2] == _this.$store.state.config.usdtAssetID || temp[2] == _this.$store.state.config.usdtPPAssetID  ? 6 : 8
             this.secondary.onsale = true
             
          }

        })
      }
      _this.getHistoryOn2nd()
      _this.isLoaded = true
    }
  },
  computed: 
    mapState(['sponsoredFee'])
  ,
  watch: {
    sponsoredFee: function(old_value, new_value){
      if(this.feeTicker == 'SIGN'){
        if(new_value){
          this.feeCost = new_value * 5
        }else{
          this.feeCost = old_value * 5
        }
      }
    },
    '$route'(to){
       if(to.params.issue.length == 44){
         this.$router.go(-1)
       }
       this.addr = to.params.addr
       this.txid = to.params.txid
       this.loadArtwork()
    },
    showQrcode: function(){
      if(this.showQrcode){
          QRCode.toDataURL("https://"+location.host+this.$route.fullPath)
          .then(url => {
            document.getElementById('qrCode').querySelector("img").src=url
          })
          .catch(err => {
            console.error(err)
          })
        document.getElementById('qrCode').addEventListener('mousedown', this.detectClickOutOfQRCodePopin);
      }else{
        if(document.getElementById('qrCode')){
          document.getElementById('qrCode').removeEventListener('mousedown', this.detectClickOutOfQRCodePopin);
        }
      }
    }
  },
  beforeDestroy(){
    if(document.getElementById('qrCode')){
      document.getElementById('qrCode').removeEventListener('mousedown', this.detectClickOutOfQRCodePopin);
    }
  },
  mounted(){
    if(this.$refs.plyr){
      this.$refs.plyr.player.on('ended', () => this.isPlaying = false)
    }
    document.getElementById("content").classList.add("show")
    document.getElementById("main").scrollTop = 0
  }
}
</script>
<style lang="scss">
.usdValue, .offerTime{
  color: #9b859a;
  @media(max-width: 768px){
    display: block;
  }
}
#art_detail .plyr{
  pointer-events: initial;
}
#art_detail .plyr--video .plyr__controls, #art_detail .plyr__control{
  opacity: 0.5!important;
  transition: opacity .4s ease!important;
  &:hover{
    opacity: 0.9!important;
  }
  @media(max-width: 1024px){
    opacity: 0.7!important;
  }
}
.change_required{
  color:red;
  font-size: 14px;
}
.offerStatus .fa-check{
  color: green;
}
.offerStatus .fa-times{
  color: #f43f3f;
}
</style>
<style lang="scss" scoped>
#visuel{
  .main{
    max-width: max-content;
  }
}
#qrCode{
  position: fixed;
  z-index: 800;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.6);
  left: 0;
}
.cancelOffer{
  background-color: #f43f3f;
  transition: opacity .4s ease;
  opacity: 0.6;
  &:hover{
    opacity:1;
  }
}
acceptOffer, .cancelOffer{
  border-radius: 5px;
  padding: 5px 8px;
  font-size: 12px;
  line-height: 12px;
}
.list_icon{
  font-size: 22px;
  position: absolute;
  right: 0;
  top: 12px;
}
.mention{
  font-style: italic;
  color: #949494;
}
.noartwork{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #57137b;
}
.wrap .loading{
  margin-top: 0;
  position: relative;
}
#user_page{
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
#art_detail{
  flex: 1;
  margin-top: 10px;
}
.added{
  display: flex;
  justify-content: space-between;
  line-height: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #8F8D8D;
  align-items: center;
  margin-bottom: 10px;
  .wrap_share{
    font-size: 18px;
    // margin-bottom:5px;
    .mr-3{
      margin-right: 0.7rem !important;
    }
    span{
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #1E1A1A;
      margin-right: 5px;
    }
    a{
      display: flex;
      align-items: center;
    }
    .fa-telegram-plane{
      font-size: 20px;
    }
    .share-network-twitter{
      transition: all .4s ease;
      &:hover{
        color: #57137b;
      }
    }
  }
  @media(max-width: 480px){
    flex-direction: column;
    .wrap_share{
      margin-left: 0!important;
      margin-top: 10px;
    }
  }
}

.buyBtn{
  background-color: #ca26c0;
  color: white;
  a{
    color: white;
  }
}
.edit{
  font-size: 16px;
  margin-left: 10px;
  text-decoration: underline;
}
#selectFeeAssetId{
  width: auto;
}
.seeCollection{
  color: white;
  text-decoration: underline;
  font-weight: 700;
  &:hover{
    text-decoration: none;
  }
}
#currentowner, #txhistory{
  a{
    color: #57137b;
    font-weight: normal;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
}
.fa-copy, .fa-qrcode{
  cursor: pointer;
  color: #ca26c0;
}

</style>