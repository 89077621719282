<template>           
  <div class="row h-100">
    <div class="col-12 wrapper h-100 align-items-center" v-if="!isLoaded">
      <app-loading :label="'NFT infos'"></app-loading>
    </div>
    <div class="col-12 wrapper h-100" :class="{'minh': transferPopinOpen}" v-else>
      <div class="h-100 d-flex flex-column position-relative nft_wrap" v-if="flag =='ILLEGAL'" >
        <div class="illegal">ILLEGAL CONTENT - Ref: {{ nftInfoLocal.name }}</div>
        <div class="description mt-4 p-1">This content was removed for non respect of SIGN Art terms and policy.</div>
      </div>
      <div class="h-100 d-flex flex-column position-relative nft_wrap" v-else-if="flag == 'CONSENT'">
        <div class="illegal">
          SENSITIVE CONTENT - Ref: {{ nftInfoLocal.name }} 
          <span class="consentDisplay text-center mt-2 d-block" @click="showDisplay"><strong>CLICK HERE TO DISPLAY</strong></span>
        </div>
        <div class="description mt-4 p-1">This content was marked as sensitive, this may includes nudity or any content not suitable for anyone.</div>
      </div>
      <div class="h-100 d-flex flex-column position-relative nft_wrap" v-else>
        <div v-if="nft.art_flag =='FLAGGED'" class="flagged_icon" v-b-tooltip.hover.top title="This artwork have been flagged">
          <i class="fas fa-exclamation-triangle" ></i>
        </div>

        <app-sellpopin :nftInfo="nftInfoLocal" :nft="nft" :isUpdate="isUpdate"></app-sellpopin>
        <app-transferpopin :nftInfo="nftInfoLocal" :nft="nft"></app-transferpopin>
        <app-burnpopin :nftInfo="nftInfoLocal" :nft="nft"></app-burnpopin>
        <app-cancelpopin :nftInfo="nftInfoLocal" :nft="nft"></app-cancelpopin>
        
        <div class="position-relative">

          <router-link :to="'/user/'+nft.user_addr+'/artwork/'+nft.txid+'/edition/'+issueNumber[0]" tag="a" exact class="thumb">
            <div class="overDisplay" :data-creator="nft.user_addr" :data-artid="nft.txid" @mouseenter="showArtistDetails($event)">
              <div class="loading d-none">Loading infos...</div>
              <div class="detail">
                <img :src="emptyThumb" class="thumbnail" />
                <div class="author font-weight-light">Creator:<br/><span class="font-weight-600"></span></div>
                <div class="plus">+</div>
              </div>
            </div>
          </router-link>
          <div class="spiner" ref="spiner" v-if="showSpiner">
            <img :src="spinerSrc" />
          </div>

          <div class="wrap_display" >
            <app-plyr :cid="cidToUse(nft)" :poster="nft.art_display_cid" @metaloaded="metaLoaded()" v-if="nft.hasOwnProperty('art_display_cid') && isVideo(nft)" />
            <img 
              :data-url="nft.hasOwnProperty('art_display_cid') && nft.art_display_cid != '' ? '/'+proxyDisplay : emptySrc"
              src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
              :data-imageboss-src="nft.hasOwnProperty('art_display_cid') && nft.art_display_cid != '' ? '/'+proxyDisplay : emptySrc"
              sizes="(max-width: 500px) 100vw, 500px"
              data-imageboss-options="animation:true" 
              class="d-block img-fluid display" 
              @load="metaLoaded()" v-else
            />
          </div>
        </div>
        
        <router-link :to="'/user/'+nft.user_addr+'/artwork/'+nft.txid+'/edition/'+issueNumber[0]" tag="a" class="mt-3 mt-lg-0 d-block detail-bottom overflow-hidden" exact>
          <h4 class="title mt-1 mb-1">{{nft.hasOwnProperty('art_display_cid') ? nft.art_name : 'ILLEGAL CONTENT'}}</h4>
          <div class="description" v-show="nft.art_desc">{{ nft.art_desc | trim(70) }}</div>
        </router-link>

        <div class="mt-auto p-1">
          <div class="wrap_buy" v-if="nftInfoLocal.secondaryInfo && nftInfoLocal.secondaryInfo.forsale">
            <div class="price mb-2" v-if="nftInfoLocal.secondaryInfo.price != 0">Price: <strong>{{ setPrice() }}</strong></div>
            <div class="price mb-2" v-else><strong>OFFER ONLY</strong></div>
          </div>

          <div class="date-issue">
            <div class="sa_reference">Ref: <span>{{ nftInfoLocal.name }}</span></div>
            <div class="issue">Issue: <span>{{ issueNumber[0] }}/{{ issueNumber[1] }}</span></div>
            <div class="date">Issued <span>{{ nftInfoLocal.issueTimestamp | setDate}}</span></div>
          </div>

          <div class="wrap_actions mb-3" v-if="$store.state.user.userAddress == $route.params.addr">
            <button @click="isUpdate = false; openSetSalePopin()" v-if="!nftInfoLocal.secondaryInfo && $store.state.user.userAddress == $route.params.addr">SET FOR SALE</button>
            <button @click="isUpdate = true; openSetSalePopin()" v-if="nftInfoLocal.secondaryInfo && nftInfoLocal.secondaryInfo.forsale && $store.state.user.userAddress == $route.params.addr">UPDATE</button>
            <button @click="openCancelPopin()" v-if="nftInfoLocal.secondaryInfo && nftInfoLocal.secondaryInfo.forsale && $store.state.user.userAddress == $route.params.addr" class="red ml-3">CANCEL</button>
          </div>
          <div class="wrap_actions mb-3" v-if="nftInfoLocal.secondaryInfo && $store.state.user.userAddress != $route.params.addr && showBuyOption">
            <button @click="chooseSigningMethod()" >BUY THIS NFT</button>
          </div>
          <div :class="{error: haveError}" v-show="responseMessage !=''" class="response container w-100 mx-auto text-center mt-3">
            <div class="wrap">{{responseMessage}}</div>
          </div>

          <ul class="action-links">
            <li v-show="nft.hasOwnProperty('art_signid')"><a :href="$store.state.config.signwebappUrl + '/explorer/' + nft.art_signid" target="_blank" class="d-block"><img src="../../../assets/images/certificate_sec_icon.svg" v-b-tooltip.hover.top title="SIGN Certificate" /></a></li>
            <li v-show="nft.hasOwnProperty('art_licence_cid')"><a :href="getIpfsLink(nft.art_licence_cid)" target="_blank" class="d-block"><img src="../../../assets/images/copyright_sec_icon.svg" v-b-tooltip.hover.top title="Licence" /></a></li>
            <li class="waves"><a :href="getExplorerUrl()+'/assets/'+nftInfoLocal.assetId" target="_blank" class="d-block"><img src="../../../assets/images/waves_sec_icon.svg" v-b-tooltip.hover.top title="See on Waves explorer"  /></a></li>
            <li v-b-tooltip.hover.top title="Find more NFTs from this artist"><router-link :to="'/marketplace/1?search='+nft.user_addr+'&status='+$store.state.filtering.marketplace.statusArtist" exact tag="a" class="d-block"><img src="../../../assets/images/search-black.svg" class="small_icon_search" /></router-link> </li>
            <li v-show="issueNumber[1] > 1" v-b-tooltip.hover.top title="Find other editions for this NFT"><router-link :to="'/marketplace/1?search='+nft.txid+'&status='+$store.state.filtering.marketplace.statusArtist" exact tag="a" class="d-block"><img src="../../../assets/images/find_reseller_icon.svg" /></router-link> </li>
            <li class="transfer" @click="openTransferPopin" v-show="$store.state.user.userAddress == $route.params.addr && !nftInfoLocal.secondaryInfo">
              <img src="../../../assets/images/arrow-up.png" v-if="!transferPopinOpen" v-b-tooltip.hover.top title="Transfer this NFT" />
              <img src="../../../assets/images/close.png"  v-b-tooltip.hover.top title="Close transfer form." v-else />
            </li>
            <li class="burn" @click="openBurnPopin" v-show="$store.state.user.userAddress == $route.params.addr && !nftInfoLocal.secondaryInfo">
              <img src="../../../assets/images/flame.png" v-if="!burnPopinOpen" v-b-tooltip.hover.top title="Burn this NFT"/> 
              <img src="../../../assets/images/close.png"  v-b-tooltip.hover.top title="Close burn form." v-else />
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js';
import orderedArtworks from "@/mixins/orderedArtworks.js"
import Loading from '@/components/elements/Loading.vue'
import Plyr from '@/components/elements/Plyr.vue'
import SellPopin from '@/components/content/owned/sellPopin.vue'
import TransferPopin from '@/components/content/owned/transferPopin.vue'
import BurnPopin from '@/components/content/owned/burnPopin.vue'
import CancelPopin from '@/components/content/owned/cancelPopin.vue'
import encodeUrl from 'encodeurl';

export default {
  mixins: [globalMixin, orderedArtworks],
  props: ["nftInfo","index"],
  data: function(){
    return {
      nft: [],
      nftInfoLocal: this.nftInfo,
      issueNumber: null,
      maxissuable: null,
      isLoaded: false,
      flag: null,
      transferPopinOpen: false,
      burnPopinOpen: false,
      setSalePopinOpen: false,
      cancelPopinOpen:false,
      haveError:"",
      responseMessage: "",
      isUpdate: false,
      showSpiner: true,
      showBuyOption: false,
      proxyDisplay: null
    }
  },
  components:{
    'app-loading': Loading,
    'app-plyr': Plyr,
    'app-sellpopin': SellPopin,
    'app-transferpopin': TransferPopin,
    'app-burnpopin': BurnPopin,
    'app-cancelpopin': CancelPopin
  },
  created(){
    this.init()
  },
  mounted(){},

  methods: {
    init: async function(){
      await this.getNFTArtinfo()
    },
    showDisplay: function(){
      if(this.flag == 'CONSENT'){
        this.flag = null
      }
    },
    getProxyImage: async function(cid){
      let imgixData =[{id: null, url: this.getIpfsLink(cid)}]
      let response = await fetch(this.$store.state.config.signArtApiURL+"/imageboss", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          },
          body: JSON.stringify(imgixData)
        });
        const imgix = await response.json();
      return imgix.res[0].url
    },
    setPrice: function(){
      let priceData = this.getPriceWithTicker(this.nftInfoLocal.secondaryInfo.asset, this.nftInfoLocal.secondaryInfo.price)
      return this.numberWithSpaces(priceData[1]) + " " + priceData[0]
    },
    metaLoaded: function(){
      this.hideSpiner()
    },
    hideSpiner: function(){
      if(this.$refs.spiner){
        this.showSpiner = false
      }
    },
    openTransferPopin: function(){
      this.transferPopinOpen = !this.transferPopinOpen
      this.burnPopinOpen = false
      this.setSalePopinOpen = false
      this.cancelPopinOpen = false
    },
    openSetSalePopin: function(){
      this.setSalePopinOpen = !this.setSalePopinOpen
      this.burnPopinOpen = false
      this.transferPopinOpen = false
      this.cancelPopinOpen = false
    },
    openBurnPopin: function(){
      this.burnPopinOpen = !this.burnPopinOpen
      this.transferPopinOpen = false
      this.setSalePopinOpen = false
      this.cancelPopinOpen = false
    },
    openCancelPopin: function(){
      this.cancelPopinOpen = !this.cancelPopinOpen
      this.transferPopinOpen = false
      this.setSalePopinOpen = false
      this.burnPopinOpen = false
    },
   
    getNFTArtinfo: async function(){
      let _this =this
      let regex
      try{
        if(this.nftInfo.forsale){
          let secondaryInfo = _this.nftInfo
          await fetch(this.$store.state.config.nodeURL+"/assets/details?id="+this.nftInfo.assetId).then(res => res.json()).then(data => {
            _this.nftInfoLocal = data[0]
            _this.nftInfoLocal.secondaryInfo = secondaryInfo
            regex = encodeUrl("^art(.*)"+ secondaryInfo.art_id +"_"+ secondaryInfo.creator_addr)
          })  
        }else{
          let originTx = await fetch(this.$store.state.config.nodeURL+"/transactions/info/"+this.nftInfo.originTransactionId)
          originTx = await originTx.json()
          originTx = originTx.payload ? originTx.payload : originTx
          regex = encodeUrl("^art(.*)"+ originTx.call.args[0].value +"_"+ originTx.call.args[1].value)
        }
        
        let nftInfo = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+this.$store.state.config.dapp_addr_artworks+"?matches="+regex)
        nftInfo = await nftInfo.json()
        let nftFiltered = this.getOrderedArtworks(nftInfo)
        if(nftFiltered[0]){
          this.flag = nftFiltered[0].art_flag
          this.nft = nftFiltered[0]
        }
        this.proxyDisplay = await this.getProxyImage(nftFiltered[0].art_display_cid)

        // Get issued nft for this artwork to retrieve the issue number 
        let getSold = Object.keys(nftFiltered[0]).filter( function(key) {
          return key.includes("sold") 
        })
        let getSoldMapped = getSold.map(function(key){ return { key: key, value : nftFiltered[0][key]} })
        let issueNumber
        getSoldMapped.map( function(obj){
          return obj.value.includes(_this.nftInfo.assetId) ? issueNumber = [obj.key.split("_")[2], obj.key.split("_")[4]] : false
        })

        if(issueNumber){
          _this.issueNumber = issueNumber
        }else{
          _this.issueNumber = "N/A"
        }

        this.isLoaded = true
        // this.getCurrentUrl()
      }catch( err ){
         console.log(err) 
      }
    },
    showArtistDetails: async function(e){
      e.target.querySelector(".loading").classList.remove("d-none")
      let artist = await this.getCreator(e.target.dataset.creator)
      e.target.querySelector(".loading").classList.add("d-none")
      e.target.querySelector(".thumbnail").src = this.getIpfsLink(artist[e.target.dataset.creator].user_thumb)
      e.target.querySelector(".author span").innerHTML = artist[e.target.dataset.creator].user_name
    },
    getCreator: async function(addr){
      let regex = encodeUrl("^user(.*)"+addr)
        return fetch(this.$store.state.config.nodeURL+"/addresses/data/"+this.$store.state.config.dapp_addr_users+"?matches="+regex).then(res => res.json()).then(data => {
            let newData = Object.values(data).reduce((acc, curr) => {
              const _key = curr.key;
              const getAddr = _key.substr(_key.lastIndexOf("_") + 1, _key.length);
              const getKeyWithoutAddr = _key.substr(0, _key.lastIndexOf("_"));
              if (!acc[getAddr]) {
                acc[getAddr] = [];
                acc[getAddr]["user_addr"] = getAddr;
              }
              acc[getAddr]["" + getKeyWithoutAddr] = curr.value;
              return acc;
            }, {});

            return newData;

          }).catch((err) => console.error(err));
    },
    chooseSigningMethod: function(){
       if(this.$store.state.user.isLogged){
          this.buyNFT()
       }else{
         this.$store.state.signingChoice.showChoice = true
       }
    },
    buyNFT: function(){
      let _this = this
      this.$store.state.alert=true
      this.$store.state.alertMessage='Processing the sale, Please wait...'

      const dataTx = {
        dApp: this.$store.state.config.dapp_addr_secondary,
          call: {
              function: "buyNFT",
                args:[
                  { type:"string", value: _this.nftInfo.assetId }
              ]
          }
      }

      if(_this.nftInfo.asset != ""){
        dataTx.payment = [{ assetId: _this.nftInfo.asset, amount: _this.nftInfo.price }]
      }else{
        dataTx.payment = [{ assetId: null,amount: _this.nftInfo.price }]
      }
      
      this.$store.dispatch("signerInvoke", dataTx).then(res => { 
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          if(res.error){
            // siplay error
            this.responseMessage = res.error.message ? res.error.message : res.error
            this.haveError = true
          }else{
            // display confirmation message of success
            this.$store.state.alert=false
            this.$store.state.alertMessage=''
            this.haveError = false
            this.responseMessage = 'NFT bought successfully!'
            //_this.$parent.getAllNFT2ndMarket()
            _this.$emit('removeNFT', _this.nftInfo.assetId)
            setTimeout(function(){
              this.responseMessage = ''
            }, 3000)
          }
      }) 
    }
  }
}
</script>
<style lang="scss" scoped>
  .small_icon_search{
    width: 17px;
  }
  .added_date{
    font-size: 13px;
    color: #979797;
  }
  .wrap{
    .loading{
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
    }
  }
  .fa-copy{
    color: #57137b;
  }
  .price{
    font-size: 12px;
    color: #000;
    strong{
      display: block;
      font-style: normal;
      font-weight: 700!important;
      font-size: 18px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }
  .feeWrapper{
    .form-control{
      height: 30px;
      padding: 0;
      max-width: 100px;
    }
  }
</style>
<style lang="scss">
  .red{
    background-color: #c52020;
  }
  .wrap_actions{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    button{
      padding: 5px 15px;
      border-radius: 50px;
      font-size: 14px;
    }
  }
  .nft_wrap{
    background-color: #ffffff;
    box-shadow: 0px 8px 24px rgba(7, 7, 18, 0.1);
    padding: 15px 15px 8px 15px;
  }
  .wrap_display{
      overflow: hidden;
      height: 200px;
      display: flex;
      align-items: center;
      background-color: #f9f9f9;
      @media(max-width:768px){
        height: auto;
      }
      .position-relative{
        height: 200px;
        @media(max-width:768px){
          height: auto;
        }
        overflow: hidden;
        display: flex;
        align-items: center;
        width: 100%;
        .fa-play, .fa-volume-mute, .fa-pause, .fa-volume-off {
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: #a3a3a3;
        }
        .playBtn{
          right: 35px;
          bottom: 3px!important;
        }
        .playBtn, .muteBtn{
              bottom: 4px;
        }
        .plyr{
          height: 100%!important;
          min-height: 200px!important;
          width: 100%!important;
          background-color: transparent!important;
          .plyr__video-wrapper, .plyr__poster{
            background-color: transparent!important;
          }
        }
      }
  }
  .wrapper{
    &.minh{
        min-height: 400px;
      }
  }
  .illegal{
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff0fe;
  }
  .consentDisplay{
    cursor: pointer;
  }
  .transfer, .burn{
    cursor: pointer;
  }
  .transferPopin, .burnPopin, .setSalePopin{
    position: absolute;
    z-index: 650;
    background-color: rgba(29, 3, 43, 0.8);
    width: 100%;
    height: calc(100% - 43px);
    padding: 25px;
    color: white;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    &.flexstart{
      align-items: flex-start!important;
    }
    overflow-y: auto;
    overflow-x: hidden;
    .wrapper{
      z-index:2;
      pointer-events: none;
    }
  }
  .setSalePopin{
    align-items: center;
  }
  .burnPopin{
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .attachment{
    min-height: 50px !important;
    height: 50px !important;
  }
  .pointer-events-auto{
    pointer-events: auto;
  }
  .clickZone{
    position:absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    left:0;
    top:0;
    pointer-events: auto;
  }
  .selectFeeAssetId{
    max-width: 50%;
    margin-left: 10px;
  }
  .tickerLabel{
    width: auto;
  }
  .saleAssetId{
    margin-left: 10px;
  }

  @media(max-width: 1350px){
    .transferPopin{
      padding: 15px;
    }
    .feeWrapper{
      flex-direction:column;
      .tickerLabel{
        margin-bottom: 5px;
        text-align: center;
      }
      .selectFeeAssetId{
        margin-left: 0;
        max-width: 100%;
      }
    }
  }
  @media(max-width: 992px){
    .transferPopin{
      height:calc(100% - 35px);
    }
  }
</style>