<template>
  <div class="col-md-8 col-lg-8 col-xl-12 px-1 termsPage" id="content">
    <div id="terms_page" class="wrap">
      <transition name="fade">
        <div class="container" v-show="show">
          <div class="row">
            <div class="col-lg-8 offset-lg-2 pt-4 pb-4">
              <h2>Terms of Service</h2>
              <div class="date mb-0">Effective Date: January 15, 2021</div>
              <div class="date">Last edited on January 18, 2021</div>
              <ul>
                <li><a href="https://www.sign-web.app/explorer/eevX1ZR9urZrk8zef4uUToPzVz2LvtHTAVwMXeQcq8Y" target="_blank">Certification</a></li>
                <li><a href="https://ipfs.infura.io/ipfs/QmNQuX5EVzvNaaeeKHZXtac9T8WsuzS6qy7hqtwTraP42r" target="_blank">PDF</a></li>
              </ul>
              <h3>1. Introduction</h3>
              <p>
                SIGN Art is a platform that allows artists to showcase DIGITAL ART
                certified on the Waves Blockchain, as well as buy and sell
                tokenized Digital Art as Non-Fungible Token (NFT).
              </p>
              <p>
                The following terms and conditions govern your use of (including
                any access to) the SIGN Art website, including any content,
                functionality, and services offered on or through
                <a href="https://www.sign-art.app" target="_blank">https://www.sign-art.app</a> (the “Site”). This Agreement hereby
                incorporates by this reference any additional terms and conditions
                with respect to the Site posted by SIGN Art to the Site, or
                otherwise made available to you by SIGN Art.
              </p>
              <p>
                We are not a Broker, a Financial Institution or a Creditor. SIGN
                ART is an administrative platform only. The service is to offer a
                non-exclusive online platform for artists to display and sell
                Digital Art and for collectors to purchase as non-Fungible Token
                (NFT) through the Waves Blockchain.
              </p>
              <p>
                This Agreement is between you (the “User”) and Signature Chain.
                (“SIGN Art,” “Company,” “we,” or “us”).
              </p>
              <p>
                Please read these Terms of Service (this “Agreement”) carefully.
                Your use or access of the Site or the dApp Smart Contracts
                constitutes your consent to this Agreement, the current version of
                which is available at the Site, and which may be modified from
                time to time at our sole discretion.
              </p>
              <p>
                If you do not agree to this Agreement, you may not access or use
                the Site, the dApp Smart Contract, and purchase Non-Fungible Token
                (NFT).
              </p>
              <p>
                Changes to these Terms will be made known to users by updating the
                “Updated Date” at the beginning of these Terms. By continuing to
                access the Site or use the Service, you confirm your acceptance of
                the revised Terms and all of the terms incorporated therein by
                reference.
              </p>
              <h3>2.Your SIGN Art Account</h3>
              <h4>A.Registration</h4>
              <ol>
                <li>
                  Artists/Creators can use the SIGN Art platform.
                  <ol type="A">
                    <li>
                      Collectors need not to register an account with SIGN Art.
                      Collectors will only be required to have a Waves Account, to
                      be able to collect and display NFT collections.
                    </li>
                    <li>
                      All artists are required to register. Generally, all
                      registered artists will be able to display, tokenize and sell
                      artworks on the application.
                    </li>
                    <li>
                      To register, fill out the registration form:<br/>
                      <a href="https://sign-art.app/register" target="_blank">REGISTER AS ARTIST</a>
                    </li>
                  </ol>
                </li>

                <li>
                  Registration will require submission of Name, Waves address, social media link(s), proper introduction
                  and some artworks as proof of artistry and intent to post and/or
                  sell. You will also be required to agree with the current terms of use.
                </li>
                <li>
                  Account verification may require 24-48 hours
                </li>
                <li>
                  Some additional steps are required to get
                  “Approved” status. Furthermore, only the accounts of Approved
                  Artists and their corresponding artworks will be showcased on
                  the SIGN Art website homepage
                </li>
                <li>Approved Artists are arbitrated by the SIGN Art team.</li>
                <li>
                  Fill out the following form to get an account “Approved”:<br/>
                  <a href="https://sign-art.app/forms/approved-status-form" target="_blank">APPROVED FORM</a> <br/>
                  Artist accounts must have a complete profile with at least 3 sold and/or on-sale artworks at the
                  time of application.
                </li>
              </ol>
              <h4>B.Access</h4>
              <p>
                Usage and access of your SIGN Art account is limited solely to
                you. You agree that you will not sell, rent, lease, or grant
                access to your account to any person without our prior written
                permission.
              </p>
              <h4>C.Ownership</h4>
              <p>
                SIGN Art attempts our best to make sure that all artworks created
                on the platform are done so by their original creator, but we are
                not liable if someone breaks our terms and creates items of which
                they are not the original creator.
              </p>
              <p>
                All artists are required to certify the sources of their creation
                prior to adding any artwork on SIGN Art, the immutable sha256 hash
                of these sources is registered on the Waves Blockchain through
                <a href="https://www.sign-web.app" target="_blank">https://www.sign-web.app</a> Smart Contract. In case of any conflict
                or claim of plagiarism from a 3rd party, the certified proof will
                prove that the artist had these sources at the time the artwork
                was added. Sources include any material (.psd, assets, .ai etc)
                used to create the publicly posted file (Export file) which is the
                one representing the NFT once minted.
              </p>
              <p>
                Any asset that is downloaded via the “Download” button does not
                include intellectual property rights such as copyright claims,
                ability to produce commercially, and create merchandise therefrom
                etc, unless it specifically states that Full Commercial Rights are
                granted with Art Token ownership in the Licence file attached to
                the artwork.
              </p>
              <h4>D. User Content</h4>
<p>The Site allows for (1) Collectors to create a profile where they can post certain information about themselves, link to other websites, and display NFTs they own (collectively, “Profile”); and for Artists or Creators to post their Digital Art or NFT, creations and bios. All User Content must comply with these Terms of Use.</p>
<p>Any Profile Information posted on the Site will be considered non-confidential and non-proprietary. By providing any User Content on the Website, you grant us and our affiliates and our respective licensees, successors, and assigns the right to use, reproduce, modify, perform, display, distribute, retransmit, publish, broadcast, and otherwise disclose to third parties any such material for any purpose.</p>
<p>You represent and warrant that (1) you own and control all rights in and to your User Content and have the right to grant such licenses to us and our affiliates and our respective licensees, successors, and assigns; and (2) all of your User Content do and will comply with these Terms of Use.</p>
<p>You understand and agree that you are responsible for any User Content you submit or contribute, and only you have the full responsibility for such content, including its legality, reliability, accuracy, and appropriateness. We are not responsible or liable to any third party for the content, accuracy, or appropriateness of any User Content posted by you or any other user on the Site.</p>
<p>You hereby grant SIGN Art the right to use your name and image for marketing or promotional purposes, without notice.</p>
<p>As a Creator, you acknowledge, understand, and agree that:</p>
<ol>
  <li>You are responsible for the accuracy and content of your creation.</li>
  <li>You acknowledge that all artworks on your profile are solely published and sold on the SIGN Art platform, in the declared limited edition. In the event that an artwork is published on another NFT platform, I agree to clearly indicate on the description.</li>
  <li>You acknowledge that you are the authentic creator of all artworks in your profile and can provide the certified creation sources, when required.</li>
  <li>You agree that we may use or modify images from the Digital Artwork you create for marketing or promotional purposes. </li>
  <li>You agree that we can use your profile to promote your NFT collection.</li>
  <li>Creation that violates any of SIGN Art’s policies may be deleted by SIGN Art, in its sole discretion.</li>
  <li>You will respect the intellectual property of others.</li>
</ol>
<p>We reserve the right, in our sole discretion, to prohibit you from uploading your digital art or NFT. Although we are not required to monitor any User Content, we may, in our sole discretion, remove User Content at any time and for any reason without notice. We may monitor User Content to detect and prevent fraudulent activity or violation of these Terms of Use.</p>

              <h4>E.Security</h4>
              <p>
                The artist as the account owner is solely responsible for
                maintaining the security of their SIGN Art account and control
                over any user names, seeds, passwords, or any other codes that you
                use to access the Services.
              </p>
              <p>
                You further understand and agree that we are not responsible (and
                you will not hold us responsible) for any unauthorized access to
                or use of your account. You are responsible for monitoring your
                account. If you notice any unauthorized or suspicious activity in
                your account, please notify us immediately.
              </p>
              <p>
                SIGN Art allows you to connect and interact with your waves
                account to use the platform through Waves Signer or Waves Keeper
                (Metamask equivalent on the Waves Blockchain). Only you have
                access to these accounts and the SIGN Art team won't be able to
                retrieve it in case of seed loss. Always backup your seed,
                directly from Waves Keeper or, if using Waves Signer, from
                <a href="https://www.waves.exchange" target="_blank">https://www.waves.exchange</a>.
              </p>
              <h4>F.Communication</h4>
              <p>
                You agree and understand that we will communicate with you via
                electronic means. To ensure that you receive all of our
                communication, you agree to keep your email address and social
                media current and notify us if there are any changes. You agree
                that any notices, agreements, disclosures, or other communication
                delivered to the account user's email address on record or the
                initial way of reaching out to the SIGN Art team for registration
                is considered valid.
              </p>
              <h4>G.Account Suspension</h4>
              <p>
                You agree that we have the right to immediately suspend your
                account, pause or cancel your access to the Services, or close
                your account if we suspect, in our sole discretion, that (1) your
                account is being used for money laundering or any illegal
                activity; (2) you have concealed or provided false identification
                information or other details; (3) you have engaged in fraudulent
                activity; or (4) you have engaged in transactions in violation of
                these Terms of Use.
              </p>
              <h3>3.The SIGN Art Application</h3>
              <h4>A.Usage</h4>
              <ol>
                <li>
                  Interact with the App using the Waves Keeper Extension. On your
                  browser, you will needto install a browser extension called
                  Waves Keeper to hold your wallet seed. Otherwise,users can use
                  the Waves Signer, which doesn’t require any browser extension
                  but instead, users will be directed to
                  <a href="https://www.waves.exchange" target="_blank">https://www.waves.exchange</a> at the time of account creation to
                  retrieve and backup their wallet seed.
                </li>
                <li>
                  Transactions that take place on the App are processed and
                  managed via Wavesblockchain and validated by a Smart Contract.
                  You understand that your Waves Addresswill be made publicly
                  visible whenever you engage in any transaction on the SIGN Art
                  application.
                </li>
              </ol>
              <h4>B.Modification of Agreement</h4>
              <p>
                You shall be responsible for reviewing and becoming familiar with
                any such modifications. You waive any right you may have to
                receive specific notice of such changes or modifications. Use of
                the App by you after any modification to the Agreement constitutes
                your acceptance of the Agreement as modified.
              </p>
              <p>
                If you do not agree to the Agreement in effect when you access or
                use the App. We may, at any time and without liability or prior
                notice, modify or discontinue all or part of the App (including
                access to the App via any third-party links).
              </p>
              <h4>C.Fees and Payment</h4>
              <ol>
                <li>
                  If you elect to post and/or sell art on the Application, any
                  financial transactions that you engage in will be conducted
                  solely through the Waves blockchain.<br /><br />We will have no
                  insight into or control over these payments or transactions, nor
                  do we have the ability to reverse any transactions. With that in
                  mind, we will have no liability to you or to any third party for
                  any claims or damages that may arise as a result of any
                  transactions that you engage in via the SIGN Art application.
                </li>
                <li>
                  Each time you post an artwork in the application, a posting fee
                  of ~1 USD in $SIGN will be collected on top of the regular
                  transaction fee going to Waves node miners.
                </li>
                <li>
                  You authorize us to collect a commission of 7% of the total
                  value of a sale transaction (each, a “Commission”) if the
                  payment is requested in $SIGN token. Otherwise, a 10%commission
                  if the artwork is sold in either $USDN, $USDT-PP or $WAVES. You
                  acknowledge and agree that the Commission will be transferred
                  directly to us through the Waves Blockchain as part of your
                  payment.
                </li>
                <li>
                  As between us, you will be solely responsible to pay any and all
                  sales, use, value-added and other taxes, duties, and assessments
                  (except taxes on our net income) now or hereafter claimed or
                  imposed by any governmental authority (collectively, “Taxes”)
                  associated with your use of the App (including, without
                  limitation, any Taxes that may become payable as the result of
                  your ownership, transfer, or creation of any artworks). Except
                  for income taxes levied on SIGN Art, you: (i) will pay or
                  reimburse us for all national, federal, state, local or other
                  taxes and assessments of any jurisdiction, including value added
                  taxes and taxes as required by international tax treaties,
                  customs or other import or export taxes, and amounts levied in
                  lieu thereof based on charges set, services performed or
                  payments made hereunder, as are now or hereafter may be imposed
                  under the authority of any national, state, local or any other
                  taxing jurisdiction; and (ii) shall not be entitled to deduct
                  the amount of any such taxes, duties or assessments from
                  payments made to us pursuant to these Terms.
                </li>
              </ol>
              <h4>D.Termination</h4>
              <p>
                We hereby grant you a limited, non-exclusive, non-transferable,
                revocable license to access and use the Site and Content. Our
                grant of such license is subject to the following conditions:
              </p>
              <ol type="A">
                <li>
                  Unlawful Activity: you agree not to engage, or assist, in any
                  activity that violates any law, statute, ordinance, regulation,
                  or sanctions program.
                </li>
                <li>
                  Abusive Activity: you agree not to engage in any activity that
                  poses a threat to SIGN Art, for example by distributing a virus
                  or other harmful code, or through unauthorized access to the
                  Site or other users’ accounts.
                </li>
                <li>
                  Inappropriate Behavior: you agree not to interfere with other
                  users’ access to or use of the Services.
                </li>
                <li>
                  Communication: you agree not to communicate with other users for
                  purposes of(1)sending unsolicited advertising or promotions,
                  requests for donations, or spam;(2)harassing or abusing other
                  users; (3) interfering with transactions of other users. You
                  agree not to use data collected from the Site to contact
                  individuals, companies, or other persons or entities outside the
                  Site for any purpose, including but not limited to marketing
                  activity.
                </li>
                <li>
                  Fraud: you agree not to engage in any activity which operates to
                  defraud SIGN Art, other users, or any other person; or to
                  provide any false, inaccurate, or misleading information to SIGN
                  Art
                </li>
                <li>
                  Gambling: you agree not to utilize the Services to engage in any
                  lottery, bidding fee auctions, contests, sweepstakes, or other
                  games of chance.
                </li>
                <li>
                  Taxes: you agree that you are solely responsible for determining
                  what, if any, taxes apply to your SIGN Art transactions. Neither
                  SIGN Art nor any other SIGN Art Party is responsible for
                  determining the taxes that apply to your SIGN Art transactions.
                </li>
                <li>
                  Intellectual Property Infringement: you agree not to (1) engage
                  in transactions involving items that infringe or violate any
                  copyright, trademark, right of publicity or privacy or any other
                  proprietary right under the law; (2) use SIGN Art without
                  express written consent from SIGN Art; or (3) engage in any
                  action that implies an untrue endorsement or affiliation with
                  SIGN Art.
                </li>
                <li>
                  Hyperlinks: you are hereby granted a limited, non-exclusive,
                  non-transferable, revocable license to create a text hyperlink
                  to the Site for noncommercial purposes, provided that such link
                  does not portray SIGN Art, our affiliates, or the Services in a
                  false, misleading, derogatory, or otherwise defamatory manner,
                  and provided further that the linking site does not contain any
                  adult or illegal materials, or other materials that is
                  offensive, harassing, or otherwise objectionable.
                </li>
                <li>
                  Additional Prohibitions: you agree not to (1) distribute,
                  publish, broadcast,reproduce, copy, retransmit, or publicly
                  display any Content; (2) modify or createderivative works from
                  the Site or Content, or any portion thereof; (3) use any
                  datamining, robots, or similar data gathering or extraction
                  methods on the Site orContent; (4) download any portion of the
                  Site or Content, other than for purposesof page caching, except
                  as expressly permitted by us.
                </li>
                <li>
                  You agree that we, in our sole discretion and for any or no
                  reason, may terminatethese Terms and suspend and/or block your
                  account(s) for the App. You agreethat any suspension or
                  termination of your access to the App may be without
                  priornotice, and that we will not be liable to you or to any
                  third party for any suchsuspension or termination.
                </li>
                <li>
                  If we terminate these Terms or suspend or terminate your access
                  to or use of theApp due to your breach of these Terms or any
                  suspected fraudulent, abusive, orillegal activity, then
                  termination of these Terms will be in addition to any
                  otherremedies we may have at law or in equity. Upon any
                  termination or expiration ofthese Terms, whether by you or us,
                  you may no longer have access to informationthat you have posted
                  on the App or that is related to your account, and
                  youacknowledge that we will have no obligation to maintain any
                  such information inour databases or to forward any such
                  information to you or to any third party.
                </li>
              </ol>
              <h3>4.Intellectual Property</h3>
              <p>
                Outside the SIGN Art Content, all other trademarks, product names,
                and logos on the Site are the property of their respective owners
                and may not be copied, imitated, or used, in whole or in part,
                without the permission of the applicable trademark holder. Without
                limiting the foregoing, if you believe that third-party material
                hosted by SIGN Art infringes your copyright or trademark rights,
                please file a notice of infringement by contacting the SIGN Art
                Team.
              </p>
              <p>
                In such event, please provide the following information: (1) an
                electronic or physical signature of the person authorized to act
                on behalf of the owner of the copyright interest; (2) a
                description of the copyrighted work that you claim has been
                infringed; (3) a description of the location on Site of the
                material that you claim is infringing; (4) your address, telephone
                number and e-mail address; (5)a written statement by you that you
                have a good faith belief that the disputed use is notauthorized by
                the copyright owner, its agent or the law; and (6) a statement by
                you, made underpenalty of perjury, that the above information in
                your notice is accurate and that you are thecopyright owner or
                authorized to act on the copyright owner’s behalf.
              </p>
              <p>
                SIGN Art’s policy is to suspend or terminate the account of
                infringers.
              </p>
              <h3>5.Privacy</h3>
              <p>
                You acknowledge and agree to the collection, use, and disclosure
                of your personal information in accordance with SIGN Art’s Privacy
                Policy, which is incorporated into this User Agreement by
                reference.
              </p>
              <h3>6.Modifications</h3>
              <p>
                You agree and understand that we may modify part or all of SIGN
                Art or the Services without notice.
              </p>
              <h3>7.Risks</h3>
              <p>
                Please note the following risks in accessing or using SIGN Art.
              </p>
              <ol type="A">
                <li>
                  The price and liquidity of blockchain assets, are extremely
                  volatile and may be subject to large fluctuations; Fluctuations
                  in the price of other digital assets could materially and
                  adversely affect NFTs, which may also be subject to significant
                  price volatility; Legislative and regulatory changes or actions
                  at the state, federal, or international level may adversely
                  affect the use, transfer, exchange, and value of NFTs; NFTs are
                  not legal tender and are not backed by the government;
                  Transactions in NFTs may be irreversible, and, accordingly,
                  losses due to fraudulent or accidental transactions may not be
                  recoverable; Some transactions in NFTs shall be deemed to be
                  made when recorded on a public ledger, which is not necessarily
                  the date or time that you initiated the transaction; The value
                  of NFTs may be derived from the continued willingness of market
                  participants to exchange fiat currency or digital assets for
                  NFTs, which may result in the potential for permanent and total
                  loss of value of a particular NFT should the market for that NFT
                  disappear.
                </li>
                <li>
                  You agree and understand that you are solely responsible for
                  determining the nature, potential value, suitability, and
                  appropriateness of these risks for yourself, and that SIGN Art
                  does not give advice or recommendations regarding NFTs,
                  including the suitability and appropriateness of, and investment
                  strategies for, NFTs.
                </li>
                <li>
                  You agree and understand that you access and use SIGN Art at
                  your own risk; however, this brief statement does not disclose
                  all of the risks associated with NFTs and other digital assets.
                  You agree and understand that SIGN Art will not be responsible
                  for any communication failures, disruptions, errors, distortions
                  or delays you may experience when using NFTs, however caused.
                </li>
              </ol>
              <h3>8.Third Parties</h3>
              <p>
                SIGN Art may rely on third-party platforms, including but not
                limited to the WAVES Blockchain to perform SIGN Art transactions.
                Our Site may contain links to third-party websites or applications
                (collectively, “Third Party Sites”). SIGN Art does not own or
                control Third Party Sites.
              </p>
              <p>
                You understand and agree that your use of any Third Party Site is
                subject to any terms of use and/or privacy policy provided by such
                Third Party Site. SIGN Art is not a party to any such agreement.
                You should review any terms of use and/or privacy policy provided
                by such Third Party Site and should make whatever investigation
                you feel necessary or appropriate before proceeding with any
                transaction with any third party.
              </p>
              <p>
                SIGN Art provides these Third Party Sites only as a convenience
                and does not review, approve, monitor, endorse, warrant, or make
                any representations with respect to Third Party Sites, or their
                products or services. You use all links in Third Party Sites at
                your own risk. When you leave our Site, our Agreement and policies
                no longer govern. We may, but are not obligated to, warn you that
                you are leaving our Site.
              </p>
              <h3>9.Disclaimers</h3>
              <p>
                Except as expressly provided to the contrary in a writing by sign
                art, the site, content contained therein, and NFTs listed therein
                are provided on an “as is” and “as available” basis without
                warranties or conditions of any kind, either express or implied.
                SIGN Art (and its suppliers) make no warranty that the site will
                (1) meet your requirements; (2) be available on an uninterrupted,
                timely, secure, or error-free basis; or (3) be accurate, reliable,
                complete, legal, or safe.
              </p>
              <p>
                We will not be liable for any loss of any kind from any action
                taken or taken in reliance on material or information contained on
                the site. SIGN Art does not represent or warrant that content on
                the site is accurate, complete, reliable, current or error-free.
              </p>
              <p>
                While SIGN Art attempts to make your access to and use of the site
                and content safe, SIGN Art cannot and does not represent or
                warrant that the site, content, any NFTs listed on our site or our
                servers are free of viruses or other harmful components. We cannot
                guarantee the security of any data that you disclose online. You
                accept the inherent security risks of providing information and
                dealing online over the internet and will not hold us responsible
                for any breach of security unless it is due to our gross
                negligence.
              </p>
              <p>
                We will not be responsible or liable to you for any loss and take
                no responsibility for, and will not be liable to you for, any use
                of NFTs including but not limited to any losses, damages or claims
                arising from: (1) user error such as forgotten passwords,
                incorrectly constructed transactions, or mistyped addresses; (2)
                server failure or data loss; (3) corrupted wallet files; (4)
                unauthorized access to applications; (5) any unauthorized third
                party activities, including without limitation the use of viruses,
                phishing, brute forcing or other means of attack against the site
                or NFTs.
              </p>
              <p>
                NFTs are intangible digital assets. They exist only by virtue of
                the ownership record maintained in the Waves network. Any transfer
                of title that might occur in any unique digital asset occurs on
                the decentralized ledger within the Waves Blockchain. We do not
                guarantee that sign art or any sign art party can effect the
                transfer of title or right in any NFTs.
              </p>
              <p>
                SIGN Art is not responsible for sustained losses due to
                vulnerability or any kind of failure, abnormal behavior of
                software (e.g., wallet, smart contract), blockchains, or any other
                features of NFTs. SIGN Art is not responsible for sustained losses
                due to late reports by developers or representatives (or no report
                at all) of any issues with the blockchain supporting NFTs
                including forks, technical node issues or any other issues having
                fund losses as a result.
              </p>
              <p>
                Nothing in this Agreement shall exclude or limit liability of
                either party for fraud, death or bodily injury caused by
                negligence, violation of laws, or any other activity that cannot
                be limited or excluded by legitimate means.
              </p>
              <p>
                TO THE FULLEST EXTENT PROVIDED BY LAW, SIGN ART HEREBY DISCLAIMS
                ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY,
                OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
                NON-INFRINGEMENT AS TO THE SITE AND CONTENT CONTAINED THEREIN.
              </p>
              <p>
                THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE
                EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </p>
              <h3>10.Limitation of Liability</h3>
              <p>
                To the fullest extent permitted by law, in no event will SIGN Art
                be liable to you or any third party for any lost profit or any
                indirect, consequential, exemplary, incidental, special or
                punitive damages arising from this agreement, the site, products
                or third party sites and products, or for any damages related to
                loss of revenue, loss of profits, loss of business or anticipated
                savings, loss of use, loss of goodwill, or loss of data, and
                whether caused by tort (including negligence), breach of contract,
                or otherwise, even if foreseeable and even if SIGN Art has been
                advised of the possibility of such damages. Access to, and use of,
                the site, products or third party sites and products are at your
                own discretion and risk, and you will be solely responsible for
                any damage to your computer system or mobile device or loss of
                data resulting therefrom.
              </p>
              <p>
                Not withstanding anything to the contrary contained herein, in no
                event shall the maximum aggregate liability of SIGN Art arising
                out of or in any way related to this agreement, the access to and
                use of the site, content, nfts, or any products or services
                purchased on the site exceed the greater of (a) $100 or b) the
                amount you have paid to sign art for the services in the last six
                months out of which liability arose. The foregoing limitations of
                liability shall not apply to liability of SIGN Art for (a) death
                or personal injury caused by a member of SIGN Art’s negligence; or
                for (b) any injury caused by a member of SIGN Art’s fraud or
                fraudulent misrepresentation.
              </p>
              <p>
                Some jurisdictions do not allow the exclusion or limitation of
                incidental or consequential damages, so the above limitation or
                exclusion may not apply to you. Some jurisdictions also limit
                disclaimers or limitations of liability for personal injury from
                consumer products, so this limitation may not apply to personal
                injury claims.
              </p>
              <h3>11.Indemnification</h3>
              <p>
                You agree to indemnify, defend and hold harmless SIGN Art and its
                subsidiaries, affiliates, officers, agents, employees,
                advertisers, licensors, suppliers or partners from and against any
                claim, liability, loss, damage (actual and consequential) of any
                kind or nature, suit, judgment, litigation cost, and attorneys’
                fees arising out of or in any way related to (i) your breach of
                these Terms, (ii) your misuse of the App, (iii) your violation of
                applicable laws, rules, regulations or the rights of any third
                party and (iv) any of your acts or omissions that implicate
                publicity rights, defamation or invasion of privacy in connection
                with your access to or use of the App. SIGN Art reserves the
                right, at its own expense, to assume exclusive defense and control
                of any matter otherwise subject to indemnification by you and, in
                such case, you agree to cooperate with SIGN Art in the defense of
                such matter.
              </p>
              <h3>12.Disputes</h3>
              <p>
                You agree that any dispute or claim relating in any way to: your
                access, use, or attempted access or use of the Site; any products
                sold or distributed through the Site; or any aspect of your
                relationship with SIGN Art will be resolved by binding
                arbitration, except that (1) you may assert claims in small claims
                court if your claims qualify; and (2) you or SIGN Art may seek
                equitable relief in court for infringement or other misuse of
                intellectual property rights (such as trademarks, trade dress,
                domain names, trade secrets, copyright, or patent).
              </p>
              <p>
                You understand that by agreeing to this Arbitration Agreement, you
                and SIGN Art are each waiving their right to trial by jury and to
                participate in a class action or class arbitration.
              </p>
              <p>
                If any part of this Arbitration Agreement is found to be invalid
                or unenforceable, then such part shall be of no force and effect
                and shall be severed and the remainder of the Arbitration
                Agreement shall continue to be in full force and effect.
              </p>
              <p>
                This Arbitration Agreement shall survive the termination of your
                relationship with SIGN Art.
              </p>
              <h3>13.Termination</h3>
              <p>
                We reserve the right, without notice and in our sole discretion,
                to terminate your license to access or use the Site or Content, at
                any time and for any reason. You understand and agree that we
                shall have no liability or obligation to you in such an event.
              </p>
              <h3>14.Severability</h3>
              <p>
                If any term, clause, or provision of these Terms of Use is held
                invalid or unenforceable, then that term, clause, or provision
                shall be severable from these Terms of Use and will not affect the
                validity or enforceability of any remaining part of that term,
                cause, or provision, or any other terms, clause, or provision of
                these Terms of Use.
              </p>
              <p>
                These Terms of Use comprise the entire agreement between you and
                SIGN Art relating to your access to and use of the Site and
                Content, and supersede any and all prior discussions agreements,
                and understandings of any kind (including without limitation prior
                versions of this User Agreement). Except as otherwise provided
                herein, these Terms of Use are intended solely for the benefit of
                the parties and are not intended to confer third party beneficiary
                rights upon any other person or entity.
              </p>
              <h3>15.Survival</h3>
              <p>
                You agree and understand that all provisions of these Terms of Use
                shall survive the termination or expiration of these Terms of Use.
              </p>
              <h3>16.Contact Information</h3>
              <p>
                If you have any questions, would like to provide feedback, or
                would like more information about SIGN Art, please feel free to
                email us at <a href="mailto:contact@sign-art.app">contact@sign-art.app</a>.
              </p>
              <p>
                If you would like to lodge a complaint, please contact us at
                <a href="mailto:contact@sign-art.app">contact@sign-art.app</a>
              </p>
              <h3>END OF AGREEMENT</h3>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

export default {
  data: function() {
    return {
      show:false
    };
  },
  methods: {},
  mounted(){
    this.show=true
  }
};
</script>

<style lang="scss" scoped>
.termsPage {
  color: #57137b;
  text-align: justify;
  padding-top: 50px;
  h2{
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 0;
  }
  .date{
    margin-bottom: 25px;
  }
  h3{
    font-size: 20px;
    font-weight: bold;
    margin-top: 35px;
    margin-bottom: 25px;
  }
  h4{
    font-size: 16px;
    font-weight: bold;
  }
  ol{
    li{
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      };
    }
  }
}
</style>
