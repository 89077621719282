<template>
  <div class="col-md-8 col-lg-8 col-xl-12 px-1 userPage wrap show" id="content">
    <div v-if="$store.state.user.isLogged">
        <app-account-tabs></app-account-tabs>
        <router-view id="tabs-details"></router-view>
    </div>
    <div v-else id="connectFirst">
      <div class="wrap">
        <!-- {{ redirectUserPublic() }} -->
        Please connect to your account first
      </div>
    </div>
  </div>
</template>

<script>
import UserAccountTabs from '@/components/content/userAccount/tabs'
import globalMixin from '@/mixins/global.js';

export default {
  mixins: [globalMixin],
  data: function(){
    return {
      
    }
  },

  methods: {
    redirectUserPublic: function(){
     this.$router.push('/user/'+this.$route.params.addr);
    }
  },
  components: {
    'app-account-tabs':UserAccountTabs
  }
}
</script>
<style lang="scss" scoped>

</style>