export default {
  methods: {
    getOrderedArtworks: function(data){

        let newData = Object.values(data).reduce((acc, curr) => {
        const _key = curr.key;
        let getAddress = _key.substr(_key.lastIndexOf("_")+1, 35);
        let getId = _key.substr(0, _key.lastIndexOf("_"));
        getId = getId.substr(getId.lastIndexOf("_")+1, 44);
        const getKeyWithoutAddr = _key.substr(0, _key.lastIndexOf("_"));
        const getKeyWithoutTxid = getKeyWithoutAddr.substr(0, getKeyWithoutAddr.lastIndexOf("_"));
        if (!acc[getId]) {
          acc[getId] = [];
        }
          acc[getId]["" + getKeyWithoutTxid] = curr.value;
          acc[getId].user_addr = getAddress
          acc[getId].txid = getId
          
        return acc;
      }, {});
      let orderedData = Object.values(newData).sort(function(a,b){
        return new Date(b.art_date) - new Date(a.art_date);
      });
  
    return orderedData
  }
  }
}