<template>
  <div class="pt-1">
    <transition name="fade">
      <app-artworks-list-add-to-collection v-if="showAddToCollection" :art_id="itemIdToAddToCollection"/>
    </transition>
      <div v-if="loading" class="loading">
        <app-loading :label="'data'"></app-loading>
      </div>
      <form class="mt-lg-4" v-else>
        <div class="form-group container-fluid">
          <div class="row">
            <div class="col-lg-4 col-md-6 mb-4" v-for="(artwork, key) in artworks" :key="key">

                <app-artworks-item :artwork="artwork" :collection_id="null" :addToCollection="addToCollection"></app-artworks-item>

            </div>
          </div>
          
        </div>
      </form>
      <button class="btn btn-primary mb-2 bigger loadNFT" v-if="loadmore" @click="scrollLoad">Load more</button>
      <div v-else class="text-center nft-result">{{ artworks && artworks.length ? 'All artworks loaded' : 'No artwork found' }}</div>

  </div> 
</template>

<script>
import globalMixin from '@/mixins/global.js';
import orderedArtworks from "@/mixins/orderedArtworks.js"
import Loading from '@/components/elements/Loading.vue'
import artworksItem from '@/components/content/userAccount/artworks/item.vue'
import artworksListAddToCollection from '@/components/content/userAccount/artworks/listAddToCollection.vue'
import encodeUrl from 'encodeurl';

export default {
  mixins: [globalMixin, orderedArtworks],
  data: function(){
    return {
      showAddToCollection: false,
      itemIdToAddToCollection: null,
      loading: false,
      loadmore: false,
      allArtworks: undefined,
      artworks: undefined,
      artworksIndex: 0,
      elementsInit: 15,
      elementsInitMobile: 10,
      elementsPerScroll: 10,
      elementsPerScrollMobile: 6 
    }
  },
  components:{
    'app-loading': Loading,
    'app-artworks-item': artworksItem,
    'app-artworks-list-add-to-collection': artworksListAddToCollection
  },
  computed: {
    windowWidth: function() {
      return this.$store.state.windowWidth;
    }
  },
  mounted(){
    document.querySelector(".wrap").classList.add("show")
    document.getElementById("content").addEventListener('scroll', this.scrollLoad, false)
    if(this.windowWidth < 769){
      this.elementsInit = this.elementsInitMobile
      this.elementsPerScroll = this.elementsPerScrollMobile
    }
    this.loadArtworks()
  },
  methods: {
    addToCollection: async function(id){

      this.showAddToCollection = true
      this.itemIdToAddToCollection = id
    },
    scrollLoad: function(){

        this.artworksIndex = this.artworks.length + this.elementsPerScroll
        this.artworks = this.allArtworks.slice(0, this.artworksIndex); 
        if(this.allArtworks.length == this.artworks.length){
          this.loadmore = false
        }
      //}
    },
    loadArtworks: function(){
        this.loading = true
        const _this = this
        //let regex = "^art(.*)" + _this.$store.state.user.userAddress
        let regex = encodeUrl(`^art_name(.*)${_this.$route.params.addr}|^art_date(.*)${_this.$route.params.addr}|^art_desc(.*)${_this.$route.params.addr}|^art_display_cid(.*)${_this.$route.params.addr}|^art_export_cid(.*)${_this.$route.params.addr}|art_flag(.*)${_this.$route.params.addr}|art_crawler_cid(.*)${_this.$route.params.addr}`)
        fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.dapp_addr_artworks+"?matches="+regex).then(res => res.json()).then(data => {
          let orderedData = this.getOrderedArtworks(data)
          _this.allArtworks = orderedData
          _this.artworks = _this.allArtworks.slice(0, _this.elementsInit);
          if(_this.allArtworks.length > _this.elementsInit){
            _this.loadmore = true
          }else{
            _this.loadmore = false
          }
          _this.artworksIndex = _this.artworks.length
          _this.loading = false
        }).catch(err => console.error(err))
    }
  }
}
</script>
<style lang="scss" scopped>
  .plyr{
    pointer-events:none;
    min-width: auto!important;
  }
  .artwork{
    max-height: 150px;
    @media(max-width:768px){
      max-height: inherit;
    }
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  .edit-detail{
    font-size: 14px;
    color: black;
    h4{
      font-weight: 600;
      font-size: 20px;
    }
    .date{
      font-size: 12px;
    }
    .description{
      font-size: 13px;
    }
  }
</style>