<template>
  <div class="top_area mb-4">
    <button class="py-2" @click="addCollection=!addCollection" v-html="button_label"></button>
      <transition name="fade">
      <div id="add_collection" class="mt-3 container-fluid" v-if="addCollection" @click="detectClickOutOfAddCollection">
        <div class="wrapper row">
          <div class="col-lg-12 mb-3">
            <label for="name">Name <span class="mandatory">*</span> <span v-b-tooltip.hover.top title="Enter the required payment in selected tokens to confirm the sale."><i class="fas fa-question-circle"></i></span></label>
            <input type="text" class="form-control" id="name" placeholder="Name of the collection" v-model="new_collection.name" >      
          </div>
          <div class="col-lg-12 mb-3">
            <label for="description">Description <span class="mandatory">*</span> <span v-b-tooltip.hover.top title="Enter the required payment in selected tokens to confirm the sale."><i class="fas fa-question-circle"></i></span></label>
            <textarea class="form-control" id="description" placeholder="Description of the collection" maxlength="200" v-model="new_collection.description" ></textarea>     
          </div>

          <div class="col-lg-12 d-flex">
            <div v-if="action_type=='edit'" class="mr-4">
              <label >Current cover</label>
              <div class="preview">
                <img :src="getIpfsLink(new_collection.cover)" class="d-block w-100"/>
              </div>
            </div>
            <div class="wrap_input">
              <label class="d-flex w-100 align-items-center" for="coverFile">
                Select cover file <span class="mandatory">*</span> 
                <span v-b-tooltip.hover.top :title="`This is the cover for the collection, image only: ${$store.state.acceptedFiles.collection_cover.extImg}`" class="pl-1 pr-1"><i class="fas fa-question-circle"></i></span>  
                <div class="mention mt-0 ml-1">(1Mb max.)</div>
              </label>
              <div class="wrap_file_field w-100 h-100">
                <div class="wrap_filed-block w-100 h-100">
                  <input type="file" class="form-control w-100 h-100" id="coverFile" placeholder="Select a file" :accept="$store.state.acceptedFiles.collection_cover.accept" @change="checkCoverCollection($event)">
                </div>
              </div>
              <div class="input_error" v-if="new_collection.error">
                {{ new_collection.error_label}}
              </div>
            </div>
          </div>

          <div class="col-lg-12 mt-4">
            <div class="alert" v-if="new_collection.alert!=''">
                {{ new_collection.alert}}
            </div>
            <button class="d-flex py-2 px-4 mx-auto" @click="addEdit">VALIDATE</button>
          </div>
        </div>
      </div>
      </transition>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js';
import { wavesAddress2eth } from '@waves/node-api-js';
export default {
  mixins: [globalMixin],
  props: ["action_type", "collection_details", "reloadCollection"],
  data: function(){
    return {
      addCollection: false,
      button_label: "",
      new_collection: {
        name: "",
        description: "",
        cover: "",
        error: false,
        error_label: "",
        maxsize: 1000000,
        alert: ""
      }
    }
  },
  created(){
    this.init()
  },
  watch: {
    collection_details: function(){
      this.init()
    }
  },
  methods: {
    init: function(){
      if(this.action_type == "edit" && this.collection_details){
        this.button_label = 'Edit collection details'
        this.new_collection.name = this.collection_details.name
        this.new_collection.description = this.collection_details.description
        this.new_collection.cover = this.collection_details.cover
      }else{
        this.button_label = 'New collection <span class="plus">+</span>'
      }
    },
    addEdit: async function(){
      let id = this.$route.params.id || null
      this.checkCollectionFields()
      if(this.new_collection.error) return
      await this.checkUploadCoverFiles()
      if(this.new_collection.error) return
      this.manageCollection(this.$store.state.user.publicKey, id)
    },
    resetNewCollection: function(){
      this.new_collection = {
        name: "",
        description: "",
        cover: "",
        error: false,
        error_label: "",
        maxsize: 1000000,
        alert: ""
      }
    },
    checkCollectionFields: function(){
      let cover = document.getElementById("coverFile");
      this.new_collection.error = false;
      this.new_collection.error_label = ""
      if (!cover.files[0] && this.new_collection.cover == '') {
        this.new_collection.error = true;
        this.new_collection.error_label = "Please add the cover file";
        return;
      }
      if (this.new_collection.name == '') {
        this.new_collection.error = true;
        this.new_collection.error_label = "Please add a name to the collection";
        return;
      }
      if (this.new_collection.description == '') {
        this.new_collection.error = true;
        this.new_collection.error_label = "Please add a description to the collection";
        return;
      }
    },
    checkUploadCoverFiles: async function(){
      this.new_collection.error = false;
      this.new_collection.error_label = ""
      let addCoverToIpfs
      let cover = document.getElementById("coverFile")
      
      if(cover.files[0]){
        this.$store.state.alert=true
        this.$store.state.alertMessage='Sending your files to IPFS, Please wait...'

        let ext = this.getFileExtension(cover.files[0].name)
        let new_cover = new File([cover.files[0]], 'cover.'+ext);
        if(ext.toLowerCase() == "mp4"){
          this.throwError("MP4 Cover no accepted")
          return
        }
        if(new_cover.size > this.new_collection.maxsize){
          this.throwError("Cover Image is too big, "+(this.new_collection.maxsize/1000000)+"Mb maximum")
          return
        }
        this.$store.state.alertMessage = "Sending your cover file: <strong>"+cover.files[0].name+"</strong>";
        document.getElementById("msg-layer").querySelector(".progress").classList.add("show")
        addCoverToIpfs = await this.$store.state.ipfs.upload(new_cover, this.progress)
        document.getElementById("msg-layer").querySelector(".progress").classList.remove("show")
        if(!addCoverToIpfs.error){
          this.new_collection.cover = addCoverToIpfs.cid.string+"/"+new_cover.name
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
        }else{
          this.throwError(addCoverToIpfs.error)
          return
        }
        return
      }
    },
    manageCollection: async function(pubkey, id = null){
      
      this.new_collection.alert = "Processing request..."
      let type = "signer"
      let newPubKey = pubkey
      if(this.$store.state.signer.isMetamask){
        newPubKey = wavesAddress2eth(this.$store.state.user.userAddress);
        type = "metamask"
      }

      let request = {
        type:type,
        publickey: newPubKey,
        signature: this.$store.state.user.sessionData.signedMessage,
        token: this.$store.state.user.sessionData.token,
        collection_id: id,
        deleteEntry: false,
        name: this.new_collection.name,
        description: this.new_collection.description,
        cover: this.new_collection.cover,
        position: 1
      }

      try{
        let createCollections = await fetch(
          this.$store.state.config.signArtApiURL + "/collections/manage/",
          {
            method: 'post',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
              request
            )
          }
        )
        createCollections = await createCollections.json()
        if(!createCollections.error){
          this.new_collection.alert = this.action_type == "edit" ? "Collection updated!" : "Collection created!"
          if(this.action_type!="edit"){
            this.resetNewCollection()
          }
          this.addCollection = false
          this.reloadCollection()
        }else{
          this.new_collection.alert = createCollections.text
        }
      }catch(e){
        this.new_collection.alert = e
        console.log(e)
      }
    },
    detectClickOutOfAddCollection: function(e){  
      if(this.addCollection){
        if (!document.getElementById('add_collection').querySelector(".wrapper").contains(e.target)){
          this.addCollection = false
        }
      }
    },
  },
  
}
</script>
<style lang="scss" scopped>
.preview{
    max-width: 300px;
    max-height: 200px;
    overflow: hidden;
    display: flex;
    align-items: center;
}
.wrap_input{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
#add_collection{
    position: fixed;
    cursor: pointer;
    top: 0;
    left: 0;
    margin-top: 0!important;
    width: 100%;
    min-height: 100%;
    background-color: rgba(0,0,0,0.7);
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .wrapper{
      background-color: #f7f7f7;
      padding: 25px;
      width: 100%;
      max-width: 1024px;
      cursor: initial;
      input[type="file"]{
        cursor: pointer;
      }
    }
}
  .cover{
    max-height: 200px;
    object-fit: cover;
  }
  .top_area{
    button{
      display: flex;
      align-items: center;
      .plus{
        font-size: 24px!important;
        margin-left: 10px;
      }
    }
  }
  .input_error{
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
</style>