import encodeUrl from "encodeurl";

export default {
  data: function() {
    return {
      artistInfos: {},
      collectorInfos: {},
      infosLoaded: false,
      isBanned: false,
      userNote: '',
      userExist: true,
      hasProfile: false,
      hasName: true,
      fromUrl: '',
      controller: null
    };
  },
  async created() {
    this.getArtistInfos();
  },
  beforeRouteEnter  (to, from, next) {
    next(vm => {
      vm.fromUrl = from.name
    })
  },
   watch: {
    '$route.params.addr'(){
      this.artistInfos={}
      this.getArtistInfos()
    }
  },
  beforeDestroy: function () {
    if( this.controller){
      try{
        this.controller.abort()
      }catch(err){
        console.log(err)
      }
    }
  },
  methods: {
    reduceData: function(data){
      let newData = Object.values(data).reduce((acc, curr) => {
        const _key = curr.key;
        const getAddr = _key.substr(_key.lastIndexOf("_") + 1, _key.length);
        const getKeyWithoutAddr = _key.substr(0, _key.lastIndexOf("_"));
        if (!acc[getAddr]) {
          acc[getAddr] = [];
          acc[getAddr]["user_addr"] = getAddr;
        }
        acc[getAddr]["" + getKeyWithoutAddr] = curr.value;
        return acc;
      }, {});
      return newData
    },
    orderData: function(data){
      let orderedData = Object.values(data).sort(function(a, b) {
        return new Date(b.user_date) - new Date(a.user_date);
      });
      return orderedData
    },
    processStatus: function(orderedData){
      if(orderedData[0] && orderedData[0]["user_status"]){
        if (orderedData[0].user_status == "SUSPENDED" || orderedData[0].user_status == "REMOVED" ||orderedData[0].user_status == "CHANGE_REQUIRED") {
          this.isBanned = true;
          this.userNote = orderedData[0].user_note
        }
        if (orderedData[0].user_status == "ALLOWED"){
          this.hasProfile = false
        }else{
          this.hasProfile = true
        }
        this.infosLoaded = true;
        this.userExist = true;
        
      }else{
        this.infosLoaded = false;
        this.userExist = false;
      }
      return
    },
    processStatusCollector: async function(){
      this.hasProfile = false
      let regex = encodeUrl("^user(.*)"+this.$route.params.addr)
      let getCollectorData = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+this.$store.state.config.dapp_addr_collectors+"?matches="+regex)
      getCollectorData = await getCollectorData.json()

      let newData = this.reduceData(getCollectorData)
      let orderedData = this.orderData(newData)
      this.collectorInfos = orderedData[0];
      this.processStatus(orderedData)
      if(this.$route.path != '/user/'+this.$route.params.addr+'/owned' && !this.showArtist){
        if(this.fromUrl == "user-owned-front"){
          this.$router.go(-1)
        }else{
          this.$router.push('/user/'+this.$route.params.addr+'/owned')
        }
      }
    },
    getArtistInfos: async function(){
      if(this.$route.params.addr){
        this.controller = new AbortController()
        const signal = this.controller.signal
        this.infosLoaded = false;
        this.isBanned = false;
        let regex = encodeUrl("^user(.*)"+this.$route.params.addr)
        let data = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+this.$store.state.config.dapp_addr_users+"?matches="+regex, {signal: signal})
        data = await data.json()
        let newData = this.reduceData(data)
        let orderedData = this.orderData(newData)
        this.artistInfos = orderedData[0];
        if(orderedData[0] && orderedData[0].user_status != 'ALLOWED' && orderedData[0].user_status != 'SUSPENDED' && orderedData[0].user_status != 'REMOVED'){
          this.processStatus(orderedData)
        }else{
          this.processStatusCollector()
        }
      }
    }
  },
};
