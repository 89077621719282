<template>
  <div class="row h-100">
    <transition name="fade">
      <div id="deleteConfirm" v-if="showDeleteConfirm" @click="detectClickOutOfDeleteCollection">
        <div class="wrapper">
          <p class="mb-2">Do you really want to delete this artwork from the collection?:</p>
          <h3 class="d-block mt-1">"{{ itemToDelete.name }}"</h3>
          <div class="mt-2" v-if="alert!= ''">{{alert}}</div>
          <button class="py-2 mt-2" @click="actionItem()">CONFIRM</button>
          <span class="d-block mention red-txt">This will not delete the artwork from SIGN Art, only from the collection.</span>
        </div>
      </div>
    </transition>
    <div class="col-lg-5 col-md-12 artwork">
      <router-link :to="'/user/'+$store.state.user.userAddress+'/account/artworks/list/'+artwork.txid" tag="a" exact class="w-100 h-100">
        <img :src="display" class="d-block img-fluid" @error="replaceByDefaultImg"/>
      </router-link>
    </div>
    <div class="col-lg-7 col-md-12">
      <router-link :to="'/user/'+$store.state.user.userAddress+'/account/artworks/list/'+artwork.txid" tag="a" class="edit-detail mt-3 mt-lg-0 d-block" exact>
        <div class="date">{{ (artwork.art_date || artwork.date) | setDate}}</div>
        <h4 class="title">{{ artwork.art_name || artwork.name }}</h4>
        <div class="description">{{ (artwork.art_desc || artwork.desc) | trim(50) }}...</div>
      </router-link>
      
      <router-link :to="'/user/'+$store.state.user.userAddress+'/account/artworks/list/'+artwork.txid" tag="a" class="edit edit-detail mt-2 d-block" exact>
        <i class="fa-solid fa-pen-to-square"></i> Edit artwork
      </router-link>
      <div class="addToCollection" v-if="collection_id==null">
        <div @click="addToCollection(artwork.txid)"><i class="fa-solid fa-layer-group"></i> Manage collection</div>
      </div>
      <div class="removeFromCollection" v-if="collection_id!=null" @click="initDeleteItem()"><i class="fa-solid fa-trash-can"></i> Remove from collection</div>
      
    </div>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js';
import encodeUrl from 'encodeurl';
import collections from '@/mixins/collections.js';

export default {
  mixins: [globalMixin, collections],
  props: ["artwork", "collection_id", "addToCollection", "reloadCollection"],
  data: function(){
    return {
      display: null,
      alert: "",
      showDeleteConfirm: false,
      itemToDelete: {
        id: null,
        name: null
      }
    }
  },
  mounted(){
    this.getThumbnail(this.artwork)
  },
  methods: {
    getThumbnail: async function(artwork){
      let url 
      let display = artwork.art_display_cid || artwork.display_cid

      let ext = this.artworkExension(display)
      if(ext == "mp4"){
        let regex = encodeUrl(`^art_crawler_cid_${artwork.txid}_${this.$route.params.addr}`)
        let getCrawler = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+this.$store.state.config.dapp_addr_artworks+"?matches="+regex)
        getCrawler = await getCrawler.json()
        display = getCrawler[0].value
      }
      if(display != ""){
        url = this.getIpfsLink(display)
      }else{
        url = this.emptySrc
      }

       this.display = url
    },
    initDeleteItem: async function(){
      this.showDeleteConfirm = true
      this.itemToDelete.id = this.artwork.collections_artworks.id
      this.itemToDelete.name = this.artwork.name
    },
    actionItem: async function(){
      this.alert = ""
      this.$store.state.alert=true
      this.$store.state.alertMessage='Removing from collection...'
      let action = await this.addRemoveItem(-1, this.artwork.txid, this.artwork.collections_artworks.id)
      this.$store.state.alert=false
      this.$store.state.alertMessage=''
      if(!action.error){
        this.alert = ""
        this.reloadCollection()
      }else{
        this.alert = action.message
      }
    },
    detectClickOutOfDeleteCollection: function(e){  
      if(this.showDeleteConfirm){
        if (!document.getElementById('deleteConfirm').querySelector(".wrapper").contains(e.target)){
          this.showDeleteConfirm = false
          this.itemToDelete.id = null
          this.itemToDelete.name = null
        }
      }
    },
  }
}
</script>
<style lang="scss" scopped>

.artwork{
  max-height: 200px;
}
.edit, .addToCollection, .removeFromCollection{
  font-size: 15px !important;
  font-weight: 600 !important;
  margin-top: 10px !important;
  color: #57137b !important;
  cursor: pointer !important;
  transition: opacity .4s ease;
  &:hover{
    opacity: 0.7;
  }
}
</style>