<template>
  <div>
      <h3 class="text-center mt-4 mb-lg-0">Collection: {{collection_details.name}}</h3>
      <p class="text-center mb-4">An artwork can be attached to one collection only.</p>
      <app-collections-add-edit action_type="edit" :collection_details="collection_details" :reloadCollection="init"></app-collections-add-edit>

      <h4>Manage collection items</h4>

      <div v-if="loading" class="loading">
        <app-loading :label="'data'"></app-loading>
      </div>
      <div class="mt-lg-4" v-else>
        <div id="nocontent" class="mb-4" v-if="!loading && Object.keys(artworks).length === 0">
          <div >There are no artworks available</div>
        </div>

        <div class="wrap_data row" v-if="$store.state.user.sessionData && $store.state.user.sessionData.waves_addr">
          
          <div class="col-lg-6 col-xl-4 col-md-6 mb-4" v-for="(artwork, key) in artworks" :key="key">
            <app-artworks-item :artwork="artwork" :collection_id="collection_details.id" :reloadCollection="init"></app-artworks-item>
          </div>
        </div>
      </div>

      <div class="wrap_pagination d-flex justify-content-center mt-2">
          <button class="btn btn-primary mb-2 bigger" :class="{'mr-3': artworks.length}"  v-if="this.$route.params.pageIndex && this.$route.params.pageIndex > 1" @click="prevNext('prev')">PREVIOUS</button>
          <button class="btn btn-primary mb-2 bigger" @click="prevNext('next')" v-if="artworks && artworks.length == perPage">NEXT</button>
      </div>
  </div> 
</template>

<script>
import globalMixin from '@/mixins/global.js';
import session from '@/mixins/session.js';
import collections from '@/mixins/collections.js';
import orderedArtworks from "@/mixins/orderedArtworks.js"
import Loading from '@/components/elements/Loading.vue'
import collectionsAddEdit from '@/components/content/userAccount/artworks/collectionsAddEdit.vue'
import artworksItem from '@/components/content/userAccount/artworks/item.vue'

export default {
  mixins: [globalMixin, orderedArtworks, session, collections],
  data: function(){
    return {
      loading: false,
      messageInfo: '',
      artworks: null,
      perPage: 20,
      collection_details: {
        name: "",
        description: "",
        cover: "",
        id: null
      }
    }
  },
  components:{
    'app-loading': Loading,
    'app-collections-add-edit': collectionsAddEdit,
    'app-artworks-item': artworksItem
  },
  computed: {
    windowWidth: function() {
      return this.$store.state.windowWidth;
    }
  },
  created(){
    
  },
  mounted(){
    this.init()
  },
  watch: {
    '$route.params.pageIndex'(){
      this.init()
    }
  },
  methods: {
    init: async function(){
      
      await this.loginSession()
      this.loading = true
      let collection_details = await this.getCollections(this.$store.state.user.userAddress, this.$route.params.id)

      this.collection_details = collection_details[0]
      let artworks = await this.getCollectionItems(this.$store.state.user.userAddress, this.$route.params.id, this.perPage)

      this.artworks = artworks
      this.loading = false
    },
    prevNext: function(direction){

      let index
      if(direction == "prev"){
        index = +Number(this.$route.params.pageIndex)-1
      }else{
        index = +Number(this.$route.params.pageIndex)+1
      }
      this.$router.push({ name:  'user-artworks-collections-detail', params: { id: this.collection_details.id, pageIndex: index } }).catch(()=>{});
    },
  }
}
</script>
<style lang="scss" scopped>
  .cover{
    max-height: 200px;
    object-fit: cover;
  }
</style>