<template>
  <div class="wrap_filters" >
    <div class="filters_btn mx-auto" @click="showFilters" :class="{'mb-2': filtersOpen}">
      <span class="mr-2">Filters</span>
      <i class="fas fa-sort-up" v-if="filtersOpen"></i>
      <i class="fas fa-sort-down" v-else></i>
    </div>
    <div id="filters" :class="{'mt-0': $route.name=='user-infos-front'}">

      <div class="gauche">
        <select id="artistStatus" v-model="filters.status" v-if="showArtistStatus" class="select-css">
          <option value="all">Any artist status</option>
          <option value="verified">Approved artists</option>
          <option value="registered">Registered artists</option>
        </select>
        <select id="onsale" v-model="filters.onsale" class="select-css">
          <option value="all">Any sale status</option>
          <option value="true">Instant buy</option>
          <option value="offerOnly">Offer only</option>
          <option value="false">Not for sale</option>
        </select>
        <select id="currency" v-model="filters.currency" class="select-css" v-if="filters.onsale != 'offerOnly'" @change="changedFilter">
          <option value="all">Any currency</option>
          <option :value="$store.state.config.assetID">SIGN</option>
          <option :value="$store.state.config.wavesAssetID">WAVES</option>
          <option :value="$store.state.config.usdnAssetID">XTN</option>
          <option :value="$store.state.config.usdtPPAssetID">USDT-PP</option>
        </select>
      </div>

      <div class="droite">
        <div class="mr-1" id="priceRangeWrap" v-if="filters.currency != 'all' && this.filters.onsale != 'offerOnly'">
            <input type="text" class="select-css range mr-1" v-model="fromRange" placeholder="Price from" @keyup="updateRange($event)"/>
            <input type="text" class="select-css range" v-model="toRange" placeholder="Price to" @keyup="updateRange($event)"/>
        </div>
        <div class="d-flex sort_btn">
          <div class="wrapSort" >
            <div class="wrapIcons cursor-pointer" @click="showSort = !showSort; showCrosschainChoice=false" v-b-tooltip.hover.top title="Sort results">
              <i class="fas fa-sort-amount-up" v-if="filters.sort == 'dateDESC'"></i>
              <i class="fas fa-sort-amount-down" v-if="filters.sort == 'dateASC'"></i>
              <div class="coinUp" v-if="filters.sort == 'priceDESC'">
                <i class="fas fa-long-arrow-alt-up"></i>
                <i class="fas fa-coins"></i>
              </div>
              <div class="coinDown" v-if="filters.sort == 'priceASC'">
                <i class="fas fa-coins"></i>
                <i class="fas fa-long-arrow-alt-down"></i>
              </div>
            </div>
            <ul class="sortChoice" v-if="showSort" @click="updateSort($event)">
              <li data-choice="dateDESC" :class="{'active': filters.sort == 'dateDESC'}">Added: Newest first</li>
              <li data-choice="dateASC" :class="{'active': filters.sort == 'dateASC'}">Added: Oldest first</li>
              <li data-choice="priceDESC" :class="{'active': filters.sort == 'priceDESC'}" v-if="filters.currency != 'all'">Price: High to Low</li>
              <li data-choice="priceASC" :class="{'active': filters.sort == 'priceASC'}" v-if="filters.currency != 'all'">Price: Low to High</li>
            </ul>  
            
          </div>
          <div class="wrapCrosschainChoice">
            <i class="fa-brands fa-ethereum" @click="showCrosschainChoice = !showCrosschainChoice; showSort = false" v-b-tooltip.hover.top title="Cross Chain NFTs only?"></i>
            <div class="crosschainChoice" v-if="showCrosschainChoice">
              Cross Chain NFTs only?
              <div class="d-flex justify-content-center">
                <div class="form-check mr-4">
                  <input class="form-check-input" type="radio" value="yes" id="crosschainyes" v-model="filters.crosschainOnly" >
                  <label class="form-check-label" for="crosschainyes" :class="{'active': filters.crosschainOnly=='yes'}">
                    Yes
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="no" id="crosschainno" v-model="filters.crosschainOnly" >
                  <label class="form-check-label" for="crosschainno" :class="{'active': filters.crosschainOnly=='no'}">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <button @click="filterParent()">GO</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js';
export default {
  props: ["showArtistStatus", "artistStatus", "resetFilter"],
  mixins: [globalMixin],
  data: function(){
    return {
      showSort: false,
      showCrosschainChoice: false,
      fromRange: null,
      toRange: null,
      filtersOpen: false,
      filters: {
        onsale: "all",
        currency: "all",
        status: "verified",
        fromRange: null,
        toRange: null,
        sort: 'dateDESC',
        crosschainOnly: null
      }
    }
  },
  mounted(){
    document.querySelector("body").addEventListener('mousedown', this.detectClickOutOfFilters);
  },
  beforeDestroy() {
    document.querySelector("body").removeEventListener('mousedown', this.detectClickOutOfFilters);
  },
  created(){
    this.init()
  },
  watch: {
    '$route.params.addr': function(){
      this.init()
    },
    artistStatus: function(artistStatus){
      if(artistStatus){
        this.filters.status = 'all'
        this.filterParent()
      }
    }
  },
  methods: {
    init: function(){
      if(this.$route.name == "user-paginate" || this.$route.name == "user-infos-front"){
        this.filters = JSON.parse(JSON.stringify(this.$store.state.filtering.artist))
      }else{
        this.filters = JSON.parse(JSON.stringify(this.$store.state.filtering.home))
      }
      this.fromRange = this.filters.fromRange
      this.toRange = this.filters.toRange
      
      this.filterParent()
    },
    showFilters: function(){
      this.filtersOpen = !this.filtersOpen
      document.getElementById("filters").classList.toggle("open");
    },
    updateSort: function(event){
      if(event.target.getAttribute("data-choice")){
        this.filters.sort = event.target.getAttribute("data-choice")
        this.showSort = !this.showSort
      }
    },
    updateRange: function(event){
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ' '];
      const keyPressed = event.key;
      if (!keysAllowed.includes(keyPressed)) {
            event.preventDefault()
            return
      }
      if(!event.target.value){
        this.filters.fromRange = null
        this.filters.toRange = null
        this.fromRange = null
        this.toRange = null
        return
      }else{
        let numTemp = event.target.value.split(" ").join("")
        event.target.value = this.numberWithSpaces(numTemp)
        
      }
      event.target.dispatchEvent(new Event('input'));

    },
    updateFiltersRange: function(){

      if((this.fromRange || this.toRange) && this.filters.onsale != "offerOnly"){
        this.filters.onsale = true
      }

      if(this.fromRange){
        this.filters.fromRange = typeof this.fromRange == "string" ? Number(this.fromRange.split(" ").join("")) : this.fromRange
      }
      if(this.toRange){
        this.filters.toRange = typeof this.toRange == "string" ? Number(this.toRange.split(" ").join("")) : this.toRange
      }
      
      
    },
    changedFilter: function(){
      if(this.filters.onsale == "offerOnly"){
        this.filters.currency = "all"
        this.filters.fromRange = null
        this.filters.toRange = null
      }
      if(this.filters.currency != "all"){
        this.filters.onsale = true
      }
    },
    filterParent: function(){
      this.updateFiltersRange()
      if(this.$route.params.pageindex != 1){
        this.$router.push({ name: this.$route.name, params: {pageindex:"1"} }).catch(()=>{});
      }
      if(this.filters.currency == 'all' || this.filters.onsale == "offerOnly"){
        this.filters.fromRange = null
        this.filters.toRange = null
        this.fromRange = null
        this.toRange = null
        this.filters.sort = this.filters.sort != "dateDESC" && this.filters.sort != "dateASC" ? "dateDESC" : this.filters.sort
        
      }else{
        this.filters.onsale = true
      }
      if(this.$route.name == "user-paginate" || this.$route.name == "user-infos-front"){
        this.$store.state.filtering.artist = JSON.parse(JSON.stringify(this.filters))
      }else{
        this.$store.state.filtering.home = JSON.parse(JSON.stringify(this.filters))
      }
      
      if(this.$route.name == "user-paginate" || this.$route.name == "user-infos-front"){
        this.$parent.loadArtworksFromApi(this.$route.params.addr, true)
      }else{
        console.log("this.$route.query.l", this.$route.query.l)
        this.$router.push({ name: this.$route.name, query: { search: this.$store.state.searchStr, status: this.$store.state.filtering.home.status, l: this.$route.query.l } }).catch(()=>{});
        this.$parent.loadArtworksFromApi()
      } 
      this.filters.crosschainOnly = this.filters.crosschainOnly != 'yes' && this.filters.crosschainOnly != 'no' ? 'no' : this.filters.crosschainOnly
    },
    detectClickOutOfFilters: function(e){  
      if(this.showSort){
        this.showCrosschainChoice = false
        if (!document.getElementById('filters').querySelector(".sortChoice").contains(e.target) && !document.getElementById('filters').querySelector(".wrapIcons").contains(e.target)){
          this.showSort = !this.showSort
        }
      }
      if(this.showCrosschainChoice){
        this.showSort = false
        if (!document.getElementById('filters').querySelector(".crosschainChoice").contains(e.target) && !document.getElementById('filters').querySelector(".wrapIcons").contains(e.target)){
          this.showCrosschainChoice = !this.showCrosschainChoice
        }
      }
    }
  }
}
</script>
<style lang="scss">
  #priceRangeWrap{
    display: flex;
    .select-css{
      width: 125px;
      background-image: none;
      padding-right: 10px;
    }
  }
  .wrap_filters{
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    //overflow: hidden;
    .filters_btn{
      width: 100%;
      display: none;
    }
  }
  #filters{
    display: flex;
    justify-content: center;
    margin-left: 5px;
    margin-right: 5px;
    //transition: all .4s ease;

    .gauche, .droite{
      display: flex;
      align-items: center;
    }
    .gauche{
      justify-content: flex-end;
    }
    .droite{
      justify-content: flex-start;
    }
    select{
      border: 0px;
      margin-right:0px;
      cursor: pointer;
      &:focus-visible {
          outline: -webkit-focus-ring-color auto 0px;
      }
      text-align: center;
    }
    .wrapSort, .wrapCrosschainChoice{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      color: #1E1A1A;
      position: relative;
      .fa-ethereum{
        cursor: pointer;
      }
      label{
        cursor: pointer;
      }
      .sortChoice, .crosschainChoice{
        position: absolute;
        width: 170px;
        background-color: white;
        padding: 10px;
        top: 30px;
        z-index: 50;
        font-size: 14px;
        box-shadow: 0px 4px 5px -2px rgba(0,0,0,0.44);
        border-radius: 10px;
        li{
          border-bottom: dotted 1px #ccc;
          padding: 5px;
          text-align: center;
          cursor: pointer;
          &.active{
            font-weight: bold;
          }
          &:last-child{
            border-bottom: 0;
          }
        }
      }
      .crosschainChoice{
        text-align: center;
        label{
          &.active{
            font-weight: bold;
          }
        }
      }
    }
  }
  @media(max-width: 1200px){
    #filters{
      flex-direction: column;
      #currency{
        margin-right: 0!important;
      }
      .gauche,.droite{
        justify-content: center;
      }
      .droite{
        margin-top:15px;
      }
    }
  }
  @media(max-width:992px){
    .wrap_filters{
      //overflow: hidden;
      .filters_btn{
        cursor: pointer;
        color: #1E1A1A;
        font-weight: 500;
        display: flex;
        align-items: center;
        display: flex;
        justify-content: center;
        .fas.fa-sort-up{
          height: 8px;
        }
        .fas.fa-sort-down{
          height: 20px;
        }
      }
    }
    #filters{
      justify-content: flex-start;
      height: 0px;
      opacity: 0;
      pointer-events: none;
      &.open{
        height: auto;
        opacity: 1;
        pointer-events: auto;
        //overflow: hidden;
        .filters_btn{
          display: none;
        }
      }
    }
  }
  @media(max-width: 550px){
    .filter_btn{
      display:block;
      margin-bottom: 10px;
    }
    #filters{

      flex-direction: column; 
      align-items: center; 
      .gauche{
        flex-direction: column; 
        align-items: center; 
        width: 100%;
      }
      .droite{
        margin-top: 0;
        flex-direction: column; 
        align-items: center; 
        #priceRangeWrap{
          margin-right: 0px!important;
          margin-bottom: 10px!important;
          .select-css{
            width: 140px;
            margin-right: 20px;
            &:last-child{
              margin-right: 0px!important;
            }
          }
        }
      }
      select{
        margin-bottom: 10px!important;
        padding: 5px 25px;
        width: 300px!important;
        background-color: white!important;
        margin-right:0!important;
        text-align: center;
      }
    }
  }
</style>