<template>
  <div class="wrap_filters mx-0">
    <div class="filters_btn mx-auto" @click="showFilters" :class="{'mb-2': filtersOpen}">
      <span class="mr-2 ">
        <span>
          Filters
        </span>
      </span>
      <i class="fas fa-sort-up" v-if="filtersOpen"></i>
      <i class="fas fa-sort-down" v-else></i>
    </div>
    <div id="filters" :class="{'mt-0': $route.name=='user-infos-front'}">

      <div class="gauche">
        <select id="artistStatus" v-model="filters.status" v-if="showArtistStatus" class="select-css">
          <option value="all">Any artist status</option>
          <option value="verified">Approved artists</option>
          <option value="registered">Registered artists</option>
        </select>
      </div>

      <div class="droite">
        <div class="d-flex sort_btn">
          <div class="wrapSort" >
            <div class="wrapIcons cursor-pointer" @click="showSort = !showSort" v-b-tooltip.hover.top title="Sort results">
              <i class="fas fa-sort-amount-up" v-if="filters.sort == 'dateDESC'"></i>
              <i class="fas fa-sort-amount-down" v-if="filters.sort == 'dateASC'"></i>
            </div>
            <ul class="sortChoice" v-if="showSort" @click="updateSort($event)">
              <li data-choice="dateDESC" :class="{'active': filters.sort == 'dateDESC'}">Added: Newest first</li>
              <li data-choice="dateASC" :class="{'active': filters.sort == 'dateASC'}">Added: Oldest first</li>
            </ul>  
          </div>
          <div class="wrapCrosschainChoice">
            <i class="fa-brands fa-ethereum" @click="showCrosschainChoice = !showCrosschainChoice; showSort = false" v-b-tooltip.hover.top title="Cross Chain NFTs only?"></i>
            <div class="crosschainChoice" v-if="showCrosschainChoice">
              Cross Chain NFTs only?
              <div class="d-flex justify-content-center">
                <div class="form-check mr-4">
                  <input class="form-check-input" type="radio" value="yes" id="crosschainyes" v-model="filters.crosschainOnly" >
                  <label class="form-check-label" for="crosschainyes" :class="{'active': filters.crosschainOnly=='yes'}">
                    Yes
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="no" id="crosschainno" v-model="filters.crosschainOnly" >
                  <label class="form-check-label" for="crosschainno" :class="{'active': filters.crosschainOnly=='no'}">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <button @click="$store.state.collectionsScrollState.scrollToId = null; filterParent(1);">GO</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js';
export default {
  props: ["showArtistStatus", "filterDecimal", "status"],
  mixins: [globalMixin],
  data: function(){
    return {
      listenFilters: false,
      filtersOpen: false,
      showSort: false,
      showCrosschainChoice: false,
      filters: {
        status: "verified",
        sort: 'dateDESC',
        crosschainOnly: null
      }
    }
  },
  mounted(){
    this.listenFilters = true
    document.querySelector("body").addEventListener('mousedown', this.detectClickOutOfFilters);
  },
  beforeDestroy() {
    document.querySelector("body").removeEventListener('mousedown', this.detectClickOutOfFilters);
  },
  created(){
    this.filters = JSON.parse(JSON.stringify(this.$store.state.filtering.collections))
    this.filterParent()
  },
/*   watch: {
    status: function(artistStatus){

      if(artistStatus){
        this.filters.status = 'all'
        this.filterParent()
      }
    }
  }, */
  methods: {
    showFilters: function(){
      this.filtersOpen = !this.filtersOpen
      document.getElementById("filters").classList.toggle("open");
    },
    updateSort: function(event){
      if(event.target.getAttribute("data-choice")){
        this.filters.sort = event.target.getAttribute("data-choice")
        this.showSort = !this.showSort
      }
    },
    filterParent: function(index = this.$route.params.pageIndex){
      this.$store.state.filtering.collections = JSON.parse(JSON.stringify(this.filters))
      this.$store.state.filtering.collections.crosschainOnly = this.filters.crosschainOnly != 'yes' && this.filters.crosschainOnly != 'no' ? 'no' : this.filters.crosschainOnly
      this.$router.push({ name: this.$route.name,  params: {pageIndex: index}, query: { search: this.$store.state.searchStr, status: this.$store.state.filtering.collections.status} }).catch(()=>{});
      
    },
    detectClickOutOfFilters: function(e){  
      if(this.showSort){
        this.showCrosschainChoice = false
        if (!document.getElementById('filters').querySelector(".sortChoice").contains(e.target) && !document.getElementById('filters').querySelector(".wrapIcons").contains(e.target)){
          this.showSort = !this.showSort
        }
      }
      if(this.showCrosschainChoice){
        this.showSort = false
        if (!document.getElementById('filters').querySelector(".crosschainChoice").contains(e.target) && !document.getElementById('filters').querySelector(".wrapIcons").contains(e.target)){
          this.showCrosschainChoice = !this.showCrosschainChoice
        }
      }
    }
  },
}
</script>
<style lang="scss">
  #collection #filters{
    display: flex;
    justify-content: center;
    margin-left: 10px;
    margin-bottom: 5px;
    margin-right: 10px;
    
    select{
      border: 0px;
      margin-right:0px;
      cursor: pointer;
      &:focus-visible {
          outline: -webkit-focus-ring-color auto 0px;
      }
      text-align: center;
    }
  }
  @media(max-width:992px){
    /* #filters{
      justify-content: center;
    } */
    
  }
  @media(max-width: 550px){
    .filter_btn{
      display:block;
      margin-bottom: 10px;
    }
    #filters{

      flex-direction: column; 
      align-items: center; 
      select{
        margin-bottom: 10px;
        padding: 5px 25px;
        width: 50%;
        background-color: transparent;
        margin-right:0;
        text-align: center;
      }
    }
  }
</style>