import globalMixin from '@/mixins/global.js';
import encodeUrl from 'encodeurl';
export default {
  mixins: [globalMixin],
  data: function(){
    return {
      total_not_for_sale: 0
    }
  },

  methods: {
    getCreatedAmount: async function(){
      let created_amount = await fetch(this.$store.state.config.signArtApiURL+"/artworks/count/all/"+this.$route.params.addr)
      created_amount = await created_amount.json()
      return created_amount.count
    },
    getCollectionsAmount: async function(){
      let collections_amount = await fetch(this.$store.state.config.signArtApiURL+"/collections/count/"+this.$route.params.addr)
      collections_amount = await collections_amount.json()
      return collections_amount.count
    },
    getOwnedAmount: async function(){
      let owned_for_sale_amount = await this.getOwnedForSale()
      let owned_not_for_sale_amount = await this.getOwnedNotForSale()
      return owned_for_sale_amount + owned_not_for_sale_amount
    },
    getOwnedNotForSale: async function(after_id){
      let _this = this
      let limit = 500
      let after = after_id ? '?after='+after_id : ''
      let nft_list = await fetch(this.$store.state.config.nodeURL+"/assets/nft/"+this.$route.params.addr+"/limit/"+limit+after)       
      nft_list = await nft_list.json()
      // filter to issuer
      let filtered = nft_list.filter(function(nft){
        return nft.issuer == _this.$store.state.config.dapp_addr_artworks
      })
      // count
      this.total_not_for_sale += filtered.length
      // call after
      if(nft_list.length == limit){
        this.getOwnedNotForSale(nft_list[nft_list.length-1].assetId)
      }else{
        return this.total_not_for_sale
      }
    },
    getOwnedForSale: async function(){
      let regex = encodeUrl(`sale2nd_info_${this.$route.params.addr}_(.*)_OPEN`)
      let nft_list = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+this.$store.state.config.dapp_addr_secondary+"?matches="+regex)
      nft_list = await nft_list.json()
      return nft_list.length
    }
  }
};