<template>
  <div id="balance" :class="{show: this.$store.state.user.isLogged}">
    <div class="content text-center">
      <div class="wrap">
        <router-link :to="'/user/'+this.$store.state.user.userAddress" exact class="profile mb-2 mb-sm-0" v-if="whiteListStatus" @click.native="resetArtistFilter(); resetArtistScroll();">
          <i class="fas fa-globe-americas"></i> Public page
        </router-link>
        <router-link :to="'/user/'+this.$store.state.user.userAddress+'/owned'" exact class="profile mb-2 mb-sm-0" v-else @click.native="resetArtistFilter(); resetArtistScroll();">
          <i class="fas fa-globe-americas"></i> Public page
        </router-link>
        <div class="address mr-lg-3 mb-2 mb-sm-0 ml-2">
          <i class="fas fa-user-circle"></i> <span class="addr trimMobile" :data-content="this.$store.state.user.userAddress">{{ this.$store.state.user.userAddress }} </span><i class="far fa-copy ml-2 mr-0" @click="copyAddr" v-b-tooltip.hover.bottom :title="copyAddrTitle"></i>
        </div>
        <!-- <div class="balance"><i class="fas fa-coins"></i> <strong>{{ numberWithSpaces($store.state.user.signBalance) }}</strong> SIGN</div> -->
        <div class="wrap_currencies_logout d-flex mb-2 mb-sm-0">
          <div class="balance d-flex align-items-center justify-content-center mt-1 mt-lg-0">
            <i class="fas fa-coins mr-1"></i>
            <select class="form-control">
              <option> <strong>{{ numberWithSpaces($store.state.user.signBalance) }}</strong> SIGN</option>
              <option><strong>{{ numberWithSpaces($store.state.user.wavesBalance) }}</strong> WAVES</option>
              <option><strong>{{ numberWithSpaces($store.state.user.usdnBalance) }}</strong> XTN</option>
              <option><strong>{{ numberWithSpaces($store.state.user.usdtPPBalance) }}</strong> USDT-PP</option>
            </select> 
          </div>
          <div class="logout ml-3" @click="logout()" v-b-tooltip.hover.bottom title="Log out"><i class="fas fa-power-off"></i></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js';
import VueCookies from "vue-cookies";

export default {
  mixins: [globalMixin],
  data: function(){
    return {
      copyAddrTitle: "Click to copy"
    }
  },
  mounted(){
    this.trimUntrim()
    window.addEventListener("resize", this.trimUntrim)
  }, 
  destroyed() {
    window.removeEventListener("resize", this.trimUntrim);
  }, 
  computed: {
    whiteListStatus() {
        return this.$store.getters.getWhiteListStatus
    }
  },
  methods: {
    logout: function(){
      VueCookies.remove("loginChoice");
      location.reload();
    },
    selectToggle: function(){
      this.$refs.selectBox.classList.toggle('closed')
    },
    trimUntrim: function(){
      if(this.$store.state.isMobile){
        this.trimLongString()
      }else{
        this.untrimLongString()
      }
    },
    copyAddr: function(){
      let _this = this
      var textArea = document.createElement("textarea");
      textArea.value = this.$store.state.user.userAddress;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log(msg);
      } catch (err) {
        console.log("Oops, unable to copy");
      }
      document.body.removeChild(textArea);
      _this.copyAddrTitle = "copied!";
      setTimeout(function() {
        _this.copyAddrTitle = "Click to copy";
      }, 2000);
    }
  }
}
</script>
<style lang="scss" scoped>
.fa-copy{
    color: white;
  }
</style>
<style lang="scss">
.wrap_currencies_logout{
  .logout{
    cursor: pointer;
    transition: opacity .4s ease;
    &:hover{
      opacity: 0.8
    }
  }
  @media(max-width: 1024px){
    margin: auto;
    margin-top: 5px;
    .logout{
      margin-top: 3px;
    }
  }
}
#balance{
  color: white;
  background-color: #1e1a1a;

  .form-control{
    padding: 0 0.3rem;
    line-height: 1;
    height: auto;
    background-color: #464141;
    color: white;
    border: 0;
    cursor: pointer;
    width: auto;
    font-size: 14px;
/*     -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; */
  }
  .profile{
    margin-right: 5px;
    color: white;
    &:hover{
      color: white;
    }
  }
  .fa-copy{
    cursor: pointer;
    transition: opacity .4s ease;
    &:hover{
      opacity: 0.8
    }
  }
  .wrap{
    font-size: 14px;
    display: flex;
    justify-content: center;
    padding: 10px;
    margin-bottom: 20px;
    .copied{
      color: white;
      padding-right: 10px;
    }
    .balance{

    }
  }
}
</style>