<template>
  <div class="col-md-8 col-lg-8 col-xl-10 px-1 userPage" id="content" style="background-color:black;">
    <h1>List all users here with advance search</h1>
    <button @click="navigateToHome">Back to home detail</button>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js';
export default {
  mixins: [globalMixin],
  data: function(){
    return {
      
    }
  },
  mounted(){
    document.getElementById("content").classList.add("show")
  }
}
</script>