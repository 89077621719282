import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import VueRouter from "vue-router";
import App from './App.vue'
import { routes } from './routes'
import { store } from './store/store'
import Vue2TouchEvents from 'vue2-touch-events'
import VueSocialSharing from 'vue-social-sharing'
import VueMeta from 'vue-meta'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import VueLazyload from 'vue-lazyload'
import linkify from 'vue-linkify'
import VueSanitize from "vue-sanitize";
import VueDragscroll from 'vue-dragscroll'
import LazyLoadDirective from "./directives/LazyLoadDirective";

Vue.directive("lazyload", LazyLoadDirective);
let defaultOptions = {
  allowedTags: ['a', 'strong'],
  allowedAttributes: {
    'a': [ 'href' ]
  }
};
Vue.use(VueDragscroll)
Vue.use(VueSanitize, defaultOptions);
Vue.directive('linkified', linkify)
Vue.use(VuePlyr)
Vue.use(VueLazyload)
Vue.use(VueMeta)
Vue.use(VueSocialSharing);
Vue.use(Vue2TouchEvents)
Vue.use(VueRouter)
Vue.config.productionTip = false 

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

// Manage redirect from admin collection to public collection if not logged
// artists tend to share their admin collection page to public...
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    //let parentRoute = to.matched[0].name
    if (to.matched[0].name == "account" && !store.state.user.isLogged) {
      let newPath = to.path.substring(0, to.path.indexOf("account/"))
      if(to.path.includes("owned")){
        newPath = newPath+"owned"
      }
      next({ path: newPath })
    } else {
      next() 
    }
  } else {
    next() 
  }
})

export const eventBus = new Vue();

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");

import './assets/scss/styles.scss'
