<template>
  <div class="balance">
    
    <div class="connected" v-if="$store.state.user.isLogged">
      <div class="text-center d-block position-relative" v-if="!initBalanceLoaded">Loading balance for: {{ shortTxAddr(this.$store.state.user.userAddress) }} ...</div>
      <div v-else class="wrap_balance w-100 d-flex justify-content-center">
        <div class="text-center d-block position-relative">
          <div class="copied"></div>
          <span :data-content="userAddress">{{ shortTxAddr(this.$store.state.user.userAddress) }} </span>
          <img src="../../assets/images/copy_icon.svg" class="reverseColor ml-1 mr-0"  @click="copyString(userAddress, $event)" v-b-tooltip.hover.bottom :title="copyAddrTitle"/>
        </div>
        <div class="ml-md-2 ml-0 wrap_blance d-flex justify-content-center">
          <select id="tokens_bal" class="ml-0">
            <option>SIGN: <span class="bal">{{ numberWithSpaces(signBal) }}</span></option>
            <option>XTN: <span class="bal">{{ numberWithSpaces(usdnBal) }}</span></option>
            <option>USDT-PP: <span class="bal">{{ numberWithSpaces(usdtPPBal) }}</span></option>
            <option>WAVES: <span class="bal">{{ numberWithSpaces(wavesBal) }}</span></option>
            <option>ETH: <span class="bal">{{ numberWithSpaces(ethBal) }}</span></option>
          </select>
        </div>
      </div>
    </div>
    <div class="not-connected" v-else>
      <span @click="chooseSigningMethod()">Connect to see your balance</span></div>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js';
export default {
  props: ["chooseSigningMethod", "signBal", "usdnBal", "usdtBal", "usdtPPBal", "wavesBal", "ethBal", "balanceLoaded"],
  mixins: [globalMixin],
  data: function(){
    return {
      copyAddrTitle: "Copy address",
      userAddress: this.$store.state.user.userAddress,
      initBalanceLoaded: false
    }
  },
  watch: {
    'balanceLoaded': function(){
      this.initBalanceLoaded = true
    },
    '$store.state.user.userAddress'(addr){
      this.userAddress = addr
    }
  },
  methods:{}
}
</script>
<style lang="scss" scoped>
  select:focus-visible{
    outline: none;
  }
  .fa-copy{
    color: white;
  }
  .reverseColor{
    filter: brightness(0) invert(1);
    width: 14px;
    cursor: pointer;
    transition: opacity .4s ease;
    &:hover{
      opacity: 0.8;
    }
  }
  .copied{
    left: inherit;
    right: 10px;
  }
  .balance{
    border-radius: 15px 15px 0 0;
    padding: 10px;
    background-color: rgba(58,10,83, 1);
    color: white;
    #tokens_bal{
      background-color: #3a0a53;
      color: white;
      border: none;
    }
    .connected{
      display: flex;
      justify-content: center;
    }
    .not-connected{
      text-align: center;
      cursor: pointer;
    }
  }
  @media(max-width: 768px){
    #buyToken{
      .wrap{
        .balance{
          .connected{
            flex-wrap: wrap;
            text-align: center;
            .current_blanace{
              width: 100%;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
  @media(max-width: 580px){
    #buyToken{
      .wrap{
        .balance{
          .connected{
            flex-direction: column;
            .wrap_balance{
              flex-direction: column;
            }
          }
        }
      }
    }
  }
</style>