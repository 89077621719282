import globalMixin from '@/mixins/global.js';
// import dJSON from 'dirty-json';
import Loading from '@/components/elements/Loading.vue'
import Nft from '@/components/content/owned/userNft.vue'
import encodeUrl from 'encodeurl';

export default {
  mixins: [globalMixin],
  data: function(){
    return {
      loading: false,
      loadMore: false,
      loadAfter: undefined,
      allNFT: [],
      nftList: [],
      nftIndex: 0,
      elementsInit: 1000,
      elementsInitMobile: 1000,
      elementsPerScroll: 10,
      elementsPerScrollMobile: 6,
      nftforsale: null
    }
  },
  components: {
    'app-owned': Nft,
    'app-loading': Loading
  },
  created: async function(){
    await this.getAllNFT2ndMarket()
  },
  watch: {
    nftforsale: function(){
      if(this.nftforsale==false){
        this.toggleSwitch = "NOTFORSALE"
      }
    }
  },
  methods: {
    getNextNFTs: function(){
      let _this = this;
      if( _this.nftIndex == 0){
        _this.nftList = this.allNFT.slice(0, this.elementsPerScroll);
      }else{
        _this.nftList = this.allNFT.slice(0, _this.nftIndex + _this.elementsPerScroll);
      }
      _this.nftIndex = _this.nftList.length
      if(_this.nftList.length < this.allNFT.length){
        _this.loadMore = true
      }else{
        _this.loadMore = false
      }
    },
    filterNFT: function(data){
      const _this = this
      let newDataDappFiltered  = Object.values(data).filter(({issuer}) => issuer == _this.$store.state.config.dapp_addr_artworks)
      return newDataDappFiltered
    },
    orderNFT: function(data){
      let orderedNFTList = Object.values(data).sort(function(a,b){
        if(b.issueTimestamp){
          return +b.issueTimestamp - +a.issueTimestamp;
        }
      });
      return orderedNFTList;
    },
    resetNFT: function(){
      this.allNFT = []
      this.nftList = []
      this.nftIndex = 0
      this.loading = false
      this.loadMore = false
      this.loadAfter = undefined
    },
    getAllNFT: async function(filter = ""){
      this.loading = true
      const _this = this
      await fetch(this.$store.state.config.nodeURL+"/assets/nft/"+_this.$route.params.addr+"/limit/"+this.elementsInit+"?after="+filter).then(res => res.json()).then(data => {
        let filteredData = _this.filterNFT(data) // filter on dapp addr
        if(filteredData){
          let tempNFTList = [..._this.allNFT, ...Object.values(filteredData)] // append to list on every call with after id
          let orderedList = _this.orderNFT(tempNFTList) // reorder by DESC date 
          _this.allNFT = orderedList
        }
        if(data.length == this.elementsInit){
          _this.getAllNFT(data[data.length-1].assetId)
        }else{
          this.getNextNFTs()
          _this.loading = false
        }
        
        
      }).catch(err => console.error(err))
      
    },
    getAllNFT2ndMarket: async function(){
      this.loading = true
      const _this = this
      let regex = encodeUrl(`^sale2nd_info_${_this.$route.params.addr}_(.*)_OPEN`)
      await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.dapp_addr_secondary+"?matches="+regex).then(res => res.json()).then(data => { 
        let filteredData = []
        data.forEach((elm) => {
          let splitVal = elm.value.split("_")
          filteredData.push({forsale: true, assetId: splitVal[3], issueTimestamp: splitVal[0], art_id: splitVal[4], creator_addr: splitVal[5], price: splitVal[1], asset: splitVal[2]}) // issueTimestamp == added date on secondary
        })
        if(filteredData.length){
          let tempNFTList = [..._this.allNFT, ...Object.values(filteredData)]
          let orderedList = _this.orderNFT(tempNFTList) // reorder by DESC date 
          _this.allNFT = orderedList
          this.nftforsale=true
        }else{
          this.nftforsale=false
        }
        this.getNextNFTs()
          _this.loading = false
      }).catch(err => console.error(err))
    },
    removeNFTfromChild: function(issueId){
      var removeIndex = this.nftList.map(function(item) { return item.assetId; }).indexOf(issueId);
      this.nftList.splice(removeIndex, 1);
      this.allNFT.splice(removeIndex, 1);
    }
  }
};