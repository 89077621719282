<template>
  <div id="popinMessage" :class="{show: (this.$store.state.user.firstLogin && this.$store.state.user.isLogged)}">
    <div class="content text-center">
      <h4 class="w-lg-75 mx-auto">You are now connected with the account: <span class="addr">{{ this.$store.state.user.userAddress }} </span></h4>
      <p>You can access your account at any time with the upper right icon</p>
      <p>Make sure to save your private 15 words seed from <a href="https://www.waves.exchange" target="_blank">Waves.exchange</a> if you are using Waves Signer with the seed account option.</p>
      <p>Waves signer email account don't require to save any seed but your credentials.</p>
      <ul>
        <li><a href="#" class="goAccount" @click.prevent="close">I UNDERSTAND AND SAVED MY CREDENTIALS</a></li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  data: function(){
    return {}
  },
  methods: {
    close(){
      this.$cookies.set("firstLogin", false)
      this.$store.state.user.firstLogin = false
    }
  }
}
</script>
<style lang="scss">
  .w-lg-75 {
    @media(min-width: 768px){
      width: 75%!important;
    }
    @media(max-width: 768px){
      width: 100%!important;
    }
  }
  #popinMessage a{
    color: white;
    font-weight: bold;
    &:hover{
      color: white;
    }
  }
</style>