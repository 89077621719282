<template>
  <div>
    <div v-if="$store.state.user.isWhiteListed">
      <h2 class="text-center mt-3 mb-4">Add new artworks</h2>
      <div id="response" :class="{error: haveError}" v-show="responseMessage!=''" class="container w-100 mx-auto text-center mt-3">
        <div class="wrap">{{responseMessage}}</div>
      </div>
      <form class="container mt-2" id="formCertify">
        <div class="row">
          <div class="col-lg-8 offset-lg-2" v-show="!alreadyCertified">
            <h3>STEP 1 - CERTIFY THE SOURCES</h3>
            <p>Certify the source of your creation on the Blockchain</p>
            <div class="form-check mb-3">
              <input class="form-check-input" type="checkbox" value="" id="alreadyCertified" :checked="alreadyCertified" @click="alreadyCertified = !alreadyCertified">
              <label class="form-check-label" for="alreadyCertified">
                You have to certify your artwork's sources which can be done below, but if you already certified the artwork using the existing <a :href="$store.state.config.signwebappUrl" target="_blank">sign-web.app</a> check this to use its transaction ID
              </label>
            </div>

            <div id="toCertify" class="container py-4" v-show="!alreadyCertified">
              <div class="row">
                <form class="col-lg-12 files">
                  <div class="form-group">
                    <label for="sourceFile">
                      Source file to certify <span class="mandatory">*</span> - ( <a href="/files/The-Signart-process-v4.pdf" target="_blank">What is the Certification?</a> )
                      <div class="fileHash" :data-hash="source.fileHash" :class="{show: source.fileHash != ''}">
                        <span @click.prevent="copyHash('source', $event)" class="copyHash" v-b-tooltip.hover.top title="Copy file hash"><i class="far fa-copy"></i><div class="copied"></div></span>
                      </div>
                      <span v-show="source.alreadyUsed" class="warning"><i class="fas fa-exclamation-triangle"></i> This file have already been certified.</span>
                    </label>

                    <div class="wrap_file_field">
                        <div class="wrap_file w-100">
                          <div class="top" v-html="source.label"></div>
                          <input type="file" class="form-control" id="sourceFile" placeholder="Select a file" @change="calculateHash('source', $event); source.alreadyUsed = false;">
                        </div>
                        <div class="progression hide"><div class="counter"></div></div>
                    </div>

<!--                     <div class="wrap_file_field">
                      <input type="file" class="form-control" id="sourceFile" placeholder="Select a file" @change="calculateHash('source', $event)">
                      <div class="fileHash" :data-hash="source.fileHash" :class="{show: source.fileHash != ''}"><span @click.prevent="copyHash('source', $event)" class="copyHash" v-b-tooltip.hover.top title="Copy file hash"><div class="copied"></div><i class="far fa-copy"></i></span></div>
                    </div>
                    <div class="progression hide"><div class="counter"></div></div> -->
                    
                    
                  </div>
                  <div class="form-group">
                    <label for="displayFile">Certificate reference <span class="mandatory">*</span> (<span>{{source.reference.length}} / 100)</span></label>
                    <input type="texte" class="form-control" id="reference" placeholder="Reference" maxlength="100" v-model="source.reference">
                  </div>

                  <div class="text-center">
                    <div class="cost mb-3" v-if="!isWhiteListedCertifications.isWhiteListed">Fee: <strong>{{ $store.state.certificationPrice }}</strong> SIGN (0.5$)</div>
                    <div class="cost mb-3" v-else >Fee: <strong>Free</strong></div>
                    <button class="btn btn-primary mb-2" form="formCertify" @click.prevent="certifySource">Certify the sources Now</button>
                  </div>
                </form>
                
              </div>
            </div>

            <div class="w-100 mt-4 pastCertifications">
              <h4>Your 5 last certifications <i class="fas fa-sync-alt" @click="getCertificationList"></i></h4> 
              <div class="wrapCertification" v-if="!pastCertificationLoading">
                <div v-if="pastCertification.length">
                  <ul>
                    <li v-for="certification in pastCertification" :key="certification.id">
                      <strong>Reference: {{ certification.title }} - {{ certification.timestamp | setDate }}</strong><br/>Transaction ID: <a :href="$store.state.config.signwebappUrl+'/explorer/'+certification.txid" target="_blank">{{ certification.txid }}</a>
                    </li>
                  </ul>
                <a class="d-block text-center mt-3" :href="$store.state.config.signwebappUrl" target="_blank" v-if="pastCertification.length == 5">SEE ALL YOUR CERTIFICATIONS</a>
                </div>
                <div v-else>
                  No certifications found.
                </div>
              </div>
              <div v-else>
                Loading ...
              </div>
            </div>
          </div>

          <div class="col-lg-8 offset-lg-2" v-show="alreadyCertified">
              <div class="d-flex align-items-center"><h3>STEP 2 - ADD TO SIGN ART </h3><strong class="goback ml-4 justify-center" @click="goBackToStep(1)">Go back to step 1</strong></div>
              <p>Add your creation on SIGN Art<br/>Newly added works take around one minute to show on the listing pages</p>
              <div class="row">
                <div class="col-lg-12">
                  <div id="certified">
                    <form class="form-group" id="formVerify">
                      <label for="txid">Enter the certification transaction id <span class="mandatory">*</span> <span v-b-tooltip.hover.top title="This have to be the exact same file as the certified one and that it has been certified from the exact same waves account."><i class="fas fa-question-circle"></i></span></label>
                      <div class="row">
                        <div class="col-lg-8">
                          <input type="texte" class="form-control" id="txid" placeholder="Certification ID" v-model="signID" @change="verifySignTxid">
                          <div class="txid-validate" v-show="source.validate != ''">
                            <div class="wrap_validations">
                              <div class="d-flex">
                                <div v-html="source.validate"></div>
                                <div class="fileHash" :data-hash="source.fileHash" :class="{show: source.fileHash != ''}"><span @click.prevent="copyHash('source', $event)" class="copyHash" v-b-tooltip.hover.top title="Copy source hash"><i class="far fa-copy"></i><div class="copied"></div></span></div>
                              </div>
                              <div class="d-flex">
                                <div v-html="source.validateSignArt"></div>
                                <router-link :to="'/user/'+$store.state.user.userAddress+'/account/artworks/'+source.artId" exact v-show="source.artId != ''" class="edit">
                                  - Edit this artwork
                                </router-link>
                              </div>
                            </div>
                           
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <button class="btn btn-primary mb-2 mt-lg-0 mt-3 mx-lg-0 mx-auto d-block" form="formVerify" @click.prevent="verifySignTxid">Verify certification</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <form id="formAdd">
                    <div class="form-group">
                      <label for="title">Title <span class="mandatory">*</span> (Max 100 characters)</label>
                      <input type="texte" class="form-control" id="title" placeholder="Reference" v-model="name" maxlength="100">
                    </div>
                    <div class="form-group">
                      <label for="description">Description <span class="mandatory">*</span> (1000 characters)</label>
                      <textarea id="description" class="form-control"  placeholder="Enter the description" v-model="description" maxlength="1000"></textarea>
                    </div>
  <!--                   <div class="form-group d-flex" id="creationTypeWrap">
                      <div>Creation type <span v-b-tooltip.hover.top title="Use the export for listing and details pages"><i class="fas fa-question-circle"></i></span></div>
                      <label>
                        <input class="form-check-input" type="radio" value="image" name="creationType" id="image" v-model="creationType"> Images (PNG/JPG/GIF)
                      </label>
                      <label>
                        <input class="form-check-input" type="radio" value="video" name="creationType" id="video" v-model="creationType"> Video (MP4)
                      </label>
                    </div> -->
                      <div class="row files ">
                        <div class="form-group col-lg-4">
                          <label for="exportFile">Export file <span class="mandatory">*</span> <span v-b-tooltip.hover.top :title="`This is the file that will be displayed in detail page and associated to the token if artwork get tokenized. Supported:  ${$store.state.acceptedFiles.exported.extImg + ','+$store.state.acceptedFiles.exported.extVid}`" class="pl-1 pr-1"><i class="fas fa-question-circle"></i></span>
                            <div class="fileHash pl-1 pr-1" :data-hash="exported.fileHash" :class="{show: exported.fileHash != ''}"><span @click.prevent="copyHash('exported', $event)" class="copyHash" v-b-tooltip.hover.top title="Copy file hash"><i class="far fa-copy"></i><div class="copied"></div></span></div>
                            </label>
                          <div class="wrap_file_field">
                            <div class="wrap_file">
                              <div :class="{error: exported.error}" class="top" v-html="exported.label"></div>
                              <input type="file" class="form-control" id="exportFile" :accept="$store.state.acceptedFiles.exported.accept" placeholder="Select a file" @change="checkExported('exported',$event)">
                            </div>
                            <div class="progression hide"><div class="counter"></div></div>
                          </div>
                          
                          <div class="mention">(70Mb max.)</div>
                        </div>
                        <div class="form-group col-lg-4 display_wrap">
                          <label for="displayFile">Preview file <span class="mandatory">*</span> <span v-b-tooltip.hover.top :title="`This is the file that will be displayed in listing pages only. Supported: ${$store.state.acceptedFiles.display.extImg}`"><i class="fas fa-question-circle"></i></span></label>
                          <div class="wrap_file_field">
                            <div class="wrap_file">
                              <div :class="{error: display.dimensionError}" class="top" v-html="display.label"></div>
                              <input type="file" class="form-control" id="displayFile" placeholder="Select a file" :accept="$store.state.acceptedFiles.display.accept" @change="fileDimension('display', $event)">
                            </div>
                            <div class="progression hide" v-if="!display.dimensionError"><div class="counter"></div></div>
                          </div>
                          
                          <div class="mention">(PNG/JPG/GIF/MP4 5Mb) </div>
                        </div>
                        <div class="form-group col-lg-4">
                          <label for="licence">
                            License file 
                            <span v-b-tooltip.hover.top title="License document should include what rights are associated with this creation if it get tokenized and to who own the token. Default license used if empty."><a href="/files/License.docx" download v-b-tooltip.hover.top title="Download our template" class="pl-1 pr-1"><i class="fas fa-download"></i></a><i class="fas fa-question-circle pl-1 pr-1"></i></span>   
                            <span class="fileHash pl-1 pr-1" :data-hash="licence.fileHash" :class="{show: licence.fileHash != ''}"><span @click.prevent="copyHash('licence', $event)" class="copyHash" v-b-tooltip.hover.top title="Copy file hash"><i class="far fa-copy"></i><div class="copied"></div></span></span>
                          </label>
                          <div class="wrap_file_field">
                            <div class="wrap_file">
                              <div class="top" v-html="licence.label"></div>
                              <input type="file" class="form-control" id="licenceFile" :accept="$store.state.acceptedFiles.licence.accept" placeholder="Select a file" @change="calculateHash('licence', $event)">
                            </div>
                            <div class="progression hide"><div class="counter"></div></div>
                          </div>
                          <div class="mention">(10Mb max.)</div>
                        </div>

                        <div v-if="display.isMP4" class="col-12 crawler_wrap">
                            <div id="crawlerPreview" >
                            <p><strong>You are using a video as preview</strong>, this require a thumbnail for crawlers <span class="mandatory">*</span></p>
                            <div class="wrap_file_field">
                              <div class="wrap_file">
                                <div :class="{error: crawler.dimensionError}" class="top" v-html="crawler.label"></div>
                                <input type="file" class="form-control" id="crawlerFile" placeholder="Select a file" :accept="$store.state.acceptedFiles.crawler.accept" @change="fileDimensionCrawler('crawler', $event)">
                              </div>
                              <div class="progression hide"><div class="counter"></div></div>
                            </div>
                            <div class="mention">(100Kb max.)</div>
                          </div>
                        </div>

                        <div class="col-12 mb-3" v-show="mp4Alert">
                          <div class="mp4Alert p-2 d-flex align-items-center">
                            <i class="fas fa-exclamation-triangle mr-2"></i> <p class="mb-0">MP4 with height <strong>over 2500px may not play</strong> on every mobile devices.<br/>It is also recommended to <strong>use h264</strong> generic codec.</p>
                          </div>  
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="type">Export type (100 char.) <span v-b-tooltip.hover.top title="Include here all extra infos such as file type, size, dimensions etc."><i class="fas fa-question-circle"></i></span></label>
                        <input type="text" id="fileType" class="form-control" maxlength="100" placeholder="Describe the file" v-model="exported.type"/>
                      </div>
                      <div class="form-group">
                        <label for="title">Tags (5 single words max, separated by comma ',')</label>
                        <input type="text" class="form-control" id="tags" placeholder="Tags" v-model="tags" >
                        <div v-show="tagsValidate != ''" class="mt-1 error">{{tagsValidate}}</div>
                        <ul class="tags" v-show="tagFormated.length > 0">
                            <li v-for="(tag, key) in tagFormated" :key="key">{{ tag }}</li>
                        </ul>
                      </div>

                      <div class="form-group">
                        <label for="title">Resell royalties % <span v-b-tooltip.hover.top title="The percentage you will received on each resale on our secondary market for this NFT"><i class="fas fa-question-circle"></i></span></label>
                        <input type="number" class="form-control" id="royalties" placeholder="royalties" v-model="royalties" >
                      </div>
                    
                      <div class="form-check mb-3 mt-4">
                        <input class="form-check-input" type="checkbox" value="" id="imtheowner" :checked="imtheowner" @click="imtheowner = !imtheowner">
                        <label class="form-check-label" for="imtheowner">
                          I acknowledge that the artwork will solely be sold as limited edition(s) on SIGN Art.<br/>I certify that <strong>I'm the authentic creator</strong> of this artwork and that <strong>it doesn't includes FanArt</strong>, <strong>I didn't use any copyrighted content</strong> and can provide the certified creation sources when required. <span class="mandatory">*</span>
                        </label>
                      </div>
                      
                      <div class="col-lg-12 text-center mt-4">
                        <p class="mandatory mb-2">* Mandatory fields</p>
                        <div class="cost mb-3 text-center" v-if="!isWhiteListedDrops.isWhiteListed">Fee: <strong>{{ $store.state.certificationPrice }}</strong> SIGN (0.5$)</div>
                        <div class="cost mb-3 text-center" v-else>Fee: <strong>Free</strong></div>
                        <button class="btn btn-primary mb-2 bigger" form="formAdd" @click.prevent="processAdd" :disabled="validationError">ADD TO SIGN ART</button>
                      </div>
                    </form>
                </div>
              </div>
          </div>
        </div>
      </form>
    </div>
    <register v-else></register>
  </div> 
</template>

<script>
import globalMixin from '@/mixins/global.js';
import addEditWorkMixin from '@/mixins/addEditWork.js';
import Register from '@/components/elements/Register.vue'
import { v1 as uuidv1 } from "uuid";
import encodeUrl from 'encodeurl';
import VueScrollTo from "vue-scrollto";

var options = {
  container: "#content",
  easing: "ease-in"
};


export default {
  mixins: [globalMixin, addEditWorkMixin],
  data: function(){
    return {
      ...this.initialState(),
      
    }
  },
  components:{
    "register": Register
  },
  async created(){
    this.init()
  },
  methods: {
    init: async function(){
      this.getArtistProfileRoyalties()
      this.isWhiteListedDrops = await this.whitelistedDrops(this.$store.state.user.userAddress)
      this.isWhiteListedTxs = await this.whitelistedTxs(this.$store.state.user.userAddress)
      this.isWhiteListedCertifications = await this.whitelistedCertification(this.$store.state.user.userAddress)
    },
    goBackToStep: function(type){
      this.getCertificationList()
      this.alreadyCertified = false
      if(type == 1){
        document.getElementById("alreadyCertified").checked=false
      }
    },
    getArtistProfileRoyalties: async function(){
      let regex = encodeUrl("user_royalties_"+ this.$route.params.addr)       
      let royalties = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+this.$store.state.config.dapp_addr_users+"?matches="+regex)
      royalties = await royalties.json()
      this.royalties = royalties[0] ? this.setRoyaltiesBase10With2Decimals(royalties[0].value) : 10
    },
    resetWindow: function (){
        document.getElementById("sourceFile").value = ""
        document.getElementById("displayFile").value = ""
        document.getElementById("licenceFile").value = ""
        document.getElementById("exportFile").value = ""
        document.getElementById("royalties").value = null
        if(document.getElementById("crawlerFile")){
          document.getElementById("crawlerFile").value = ""
        }
        document.querySelectorAll(".progression").forEach(function(elm){elm.classList.add("hide"); elm.querySelector(".counter").textContent=""})
        document.querySelector(".fileHash").classList.remove("show")
        document.querySelector(".fileHash").setAttribute("data-hash", "")
        Object.assign(this.$data, this.initialState());
        this.init()
    },
    artIdExistAlready: async function(){
      let _this = this
      let regex = encodeUrl("get_artidbysignid_"+ _this.signID + "_" + _this.$route.params.addr)       
      let checkSignIdOnSignArt = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.dapp_addr_artworks+"?matches="+regex)
      let checkSignIdOnSignArtRes = await checkSignIdOnSignArt.json()
      if(checkSignIdOnSignArtRes.length == 0 || Object.keys(checkSignIdOnSignArtRes).length === 0 && checkSignIdOnSignArtRes.constructor === Object){ 
        return {res:false, data: checkSignIdOnSignArtRes}
      }else{
        return {res:true,  data: null}
      }
    },
    processAdd: async function(){
      let _this = this
      VueScrollTo.scrollTo('#content', 600, options)
      this.checkMandatoryFields()
      if(this.haveError){
        this.$store.state.alert=false
        this.$store.state.alertMessage=''
        return
      }
      await this.checkUploadFiles()

      if(this.haveError){
        this.$store.state.alert=false
        this.$store.state.alertMessage=''
        return
      }

      let checkIfArtExist =  await _this.artIdExistAlready()
      if(checkIfArtExist.res){
        this.$store.state.alert=false
        this.$store.state.alertMessage=''
        this.haveError = true;
        this.responseMessage = "This file is already on SIGN Art";
      }

      this.$store.state.alertMessage='Sending your data to the Waves Blockchain...'
/*       if(this.licence.fileHash == "" && this.licence.cid == ""){
        this.licence.fileHash = this.$store.state.defaultLicence.hash
        this.licence.cid = this.$store.state.defaultLicence.cid
      } */
      // let invokeFee = _this.$store.state.sponsoredFee * 5 * 10 ** 8;

      const dataTx = {
        dApp: this.$store.state.config.dapp_addr_artworks,
          /* fee: invokeFee, 
          feeAssetId: this.$store.state.config.assetID,*/
          call: {
              function: "addArtwork",
                args:[
                  { type:"string", value: this.source.fileHash },
                  { type:"string", value: this.signID },
                  { type:"string", value: this.name },
                  { type:"string", value: this.description },
                  { type:"string", value: this.tags },
                  { type:"string", value: this.exported.type },
                  { type:"string", value: this.display.cid },
                  { type:"string", value: this.exported.fileHash },
                  { type:"string", value: this.exported.cid },
                  { type:"string", value: this.licence.fileHash },
                  { type:"string", value: this.licence.cid },
                  { type:"string", value: this.crawler.cid },
                  { type:"integer", value: this.royalties *  Math.pow(10,2) }
                  
              ]
          }
      }

      if(this.isWhiteListedTxs.isWhiteListed && this.isWhiteListedDrops.isWhiteListed){
        dataTx.call.args.push({ type:"string", value: _this.$store.state.user.publicKey})
        dataTx.senderPublicKey = this.$store.state.config.dapp_pubkey_artworks
        dataTx.fee = 500000
        dataTx.feeAssetId = null
      }else{
        dataTx.call.args.push({ type:"string", value: "" })
      }

      if(!this.isWhiteListedDrops.isWhiteListed){
        dataTx.payment = [{ assetId: _this.$store.state.config.assetID, amount: (_this.$store.state.certificationPrice * Math.pow(10,8)).toFixed(0) }] 
      }

      this.$store.dispatch("signerInvoke", dataTx).then(res => { 
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          if(res.error){
            // siplay error
            this.throwError(res.error.message ? res.error.message : res.error)
          }else{
            // display confirmation message of success
            this.haveError = false
            this.mp4Alert = false
            this.resetWindow()
            this.responseMessage = 'Artwork successfully added!'
            setTimeout(function(){
              this.responseMessage = ''
            }, 3000)
          }
      })

    },
    verifySignTxid: async function(){
      if(this.signID == ''){
        return
      }else{
        const _this = this
        this.source.validate = 'Checking tx infos...'
        this.source.fileHash = ''
        try{
          let regex = encodeUrl("data_fc_"+ _this.signID +"_"+ _this.$route.params.addr)
          let checkSignId = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.sign_dapp_addr+"?matches="+regex)
          let checkSignIdRes = await checkSignId.json()
          if(checkSignIdRes.length == 0 || Object.keys(checkSignIdRes).length === 0 && checkSignIdRes.constructor === Object){ 
            this.source.validate = '<span class="error"><i class="fas fa-times-circle"></i> This certification ID doesnt match your account</span>'
          }else{
            this.source.fileHash = JSON.parse(checkSignIdRes[0].value).hash 
            this.source.validate = '<span><i class="fas fa-check-circle"></i> This certification ID is correct</span>'
            try{
              let checkIfArtExist = await _this.artIdExistAlready() // on sign art

              if(!checkIfArtExist.res){ 
                this.source.validateSignArt = '<span><i class="fas fa-check-circle"></i> You can use this certification on SIGN Art</span>'
              }else{
                this.source.validateSignArt = '<span class="error"><i class="fas fa-times-circle"></i> You already used this certification on SIGN Art</span>'
                this.source.artId = checkIfArtExist.res.data[0].value
              }
            }catch(err){
              console.log(err)
            }
          }
        }catch(err) {
          console.error(err)
        }

        
      }
    },
    certifySource: async function(){

      this.$store.state.alert=true
      this.$store.state.alertMessage='Certification in progress... Please wait...'

      let _this = this

      if(this.source.fileHash ==""){
        this.throwError("Select a file, hash cannot be empty")
        return
      }
      if(this.source.reference ==""){
        this.throwError("Reference cannot be empty")
        return
      }

      /* let timestamp = new Date().getTime();
      let data_certification = {
        hash: this.source.fileHash,
        timestamp: timestamp,
        title: this.source.reference, // max length 64
      }; */

      //let invokeFee = _this.$store.state.sponsoredFee * 5 * 10 ** 8;

      let txData = {
        /* fee: invokeFee, 
        feeAssetId: this.$store.state.config.assetID,*/
        dApp: this.$store.state.config.sign_dapp_addr,
        type: 16,
        call: {
          function: "fileCertification",
          args: [
            {
              type: "string", // hash
              value: this.source.fileHash,
            },
            {
              type: "string", // title
              value: this.source.reference,
            },
            {
              type: "string", // uuid
              value: uuidv1(),
            },
            {
              type: "string", // ipfs
              value: "",
            },
            {
              type: "string", //
              value: _this.$store.state.user.publicKey,
            },
          ],
        },
      };

      if(this.isWhiteListedCertifications.isWhiteListed){

        txData.senderPublicKey = this.$store.state.config.sign_dapp_pubKey
        txData.fee = 500000
        txData.feeAssetId = null
      }

      if(!this.isWhiteListedCertifications.isWhiteListed){
        txData.payment = [{ assetId: this.$store.state.config.assetID, amount: (_this.$store.state.certificationPrice * Math.pow(10,8)).toFixed(0) }] 
      }
      

      this.$store.dispatch("signerInvoke", txData).then(data => { 
          if(data.error){
            this.throwError(data.error.message ? data.error.message : data.error)
            return
          }
          this.responseMessage = 'Certification id: '+data.id
          this.haveError = false
          _this.$store.state.alert=false
          _this.$store.state.alertMessage=''

          _this.alreadyCertified = true
          _this.signID = data.id

        }).catch(error => {
          this.throwError(error.message)
        });
    },
    getCertificationList: async function(){
      let _this = this   
      this.pastCertificationLoading = true
      this.pastCertification = []
      let getPreviousCertifications = await fetch(this.$store.state.config.signArtApiURL+"/signweb", {
        method: "POST",
        body: JSON.stringify({
            addr: _this.$store.state.user.userAddress,
            perPage: 5,
            page: 1
        }),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
      })
      let certificationsJson = await getPreviousCertifications.json()
      this.pastCertification = certificationsJson
      this.pastCertificationLoading = false
    }
  },
  computed: {
    sourceHashChanged() {
      return this.source.fileHash;
    }
  },
  watch: {
    sourceHashChanged: async function(){
      if(this.source.fileHash != ""){
        let hashExist = await this.$store.dispatch("checkSignHashExist", this.source.fileHash)
        if(hashExist.length){
          this.source.alreadyUsed = true
        }
      }
    },
  },
  mounted(){
    document.querySelector(".wrap").classList.add("show")
    this.getCertificationList()
  }
}
</script>
<style lang="scss" scoped>
#crawlerPreview{
  padding: 15px;
  margin-bottom: 20px;
  background-color: #fff1f6;
}
h4{
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
}
.pastCertifications{
  border-top: dotted 1px #dfa9fa;
  padding-top: 15px;
  li{
    padding: 8px 0px;
    border-bottom: dotted 1px #dfa9fa
  }
}
.fa-sync-alt{
  cursor: pointer;
  transition: opacity .4s ease;
  &:hover{
    opacity: 0.8
  }
}
#creationTypeWrap{
  .form-check-input{
    position: relative;
    margin-left: 10px;
  }
}
.warning{
  color: red;
}
.edit{
  font-weight: bold;
  color: red;
}
  #toCertify{
    padding-top:10px;
    background-color: #fbf3ff;
    .wrap_file_field{
      flex-direction: column;
      .copyHash{
        justify-content: center;
        display: flex;
        align-items: center;
        width: 100%;
      }
    }
  }
  #addFile{
    color: black;
  }
  .fileHash{
    display: none;
  }
  .fileHash.show{
    display: inherit;
    cursor: pointer;
    margin-left: 10px;
  }
  .progression{
    width: 0%;
    height: 5px;
    background-color: #57137b;
    z-index: 3;
    bottom: 0;
    margin-bottom: 10px;
    margin-top: 10px;
    position: relative;
  }
  .copied{
    display: inline;
    padding-left: 10px;
    margin-right: 10px;
  }
  .goback{
cursor: pointer;
    background-color: #ca26c0;
    color: white;
    padding: 3px 5px;
    border-radius: 3px;
    font-weight: 400 !important;
    transition: all 0.4s ease;
    height: auto;
    margin-top: -8px;
    &:hover{
      background-color: #57137b;
    }
  }
  .progression .counter {
    position: absolute;
    bottom: -20px;
    right: 0;
    color: #57137b;
    font-size: 14px;
  }
  textarea{
    min-height: 116px;
  }
  .txid-validate{
    color:green;
    padding-top:5px;
    display: flex;
    .fileHash{
      margin-left: 10px;
      cursor: pointer;
      transition: all .4s ease;
      &:hover{
        color: #57137b;
      }
    }
    .error{
      color:#e32b2b;
    }
  }

</style>