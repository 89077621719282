<template>
<div class="col-md-8 col-lg-8 col-xl-12 px-1 userPage wrap" id="content">

    <div>
      <app-user-infos :artistInfos="artistInfos" :infosLoaded="infosLoaded" :userExist="userExist" :hasProfile="hasProfile" :userNote="userNote" :collectorInfos="collectorInfos"></app-user-infos>
      
        <div v-if="loading" class="loading">
          <app-loading :label="'data'"></app-loading>
        </div>
        
        <div id="collectionList" class="form-group mt-2 mb-2" v-else>
          <div class="row">
            <div class="action-msg col-12" v-if="actionRes">
              <div class="wrap d-flex align-items-center">
                {{ actionMsg }}
              </div>
            </div>
            <div class="col-12">
              <div class="switchForSale">
                <span 
                  class="sales mr-1" 
                  :class="{bold: toggleSwitch=='FORSALE'}"
                  >For sale <span class="count">{{amount_for_sale}}</span></span> 

                <i title="Switch" 
                  class="fas fa-toggle-on" 
                  :class="{on: toggleSwitch=='FORSALE'}" 
                  @click="toggleForSale" ref="switcher"></i> 

                <span class="bids ml-2" 
                  :class="{bold: toggleSwitch=='NOTFORSALE'}">Not for sale <span class="count">{{amount_not_for_sale}}</span></span>
              </div>
            </div>
            <div class="col-lg-4 col-xl-3 col-xxl-3 col-md-6 mb-3 px-2" v-for="(nft, index) in nftList" :key="nft.assetId">
              <app-owned
                :nftInfo="nft" 
                :index="index"
                :toggleswitch="toggleSwitch"
                @removeNFT="removeNFTfromChild"></app-owned>
            </div>
          </div>
          <button class="btn btn-primary mb-2 bigger loadNFT" v-if="loadMore" @click="getNextNFTs()">Load more</button>
          <div v-else class="text-center nft-result">{{ nftList.length ? 'All collectibles loaded' : 'No collectible found' }}</div>
        </div>
      </div>
  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js';
import userMixin from '@/mixins/userMixin.js';
import UserInfos from '@/components/elements/UserInfos.vue'
import ownedMixin from '@/mixins/ownedMixin.js';
import tabsCount from '@/mixins/tabsCount.js';

export default {
  mixins: [globalMixin, userMixin, ownedMixin, tabsCount],
  data: function(){
    return {
      toggleSwitch: "FORSALE",
      actionRes: false,
      actionMsg: "",
      amount_for_sale: 0,
      amount_not_for_sale: 0
    }
  },
  async created(){
    this.amount_for_sale = await this.getOwnedForSale()
    this.amount_not_for_sale = await this.getOwnedNotForSale()
  },
  watch: {
    toggleSwitch: function(){
      this.resetNFT()
      if(this.toggleSwitch == "FORSALE"){
        this.getAllNFT2ndMarket()
      }else{
        this.getAllNFT()
      }
    }
  },
  methods: {
    toggleForSale: function(){
      this.toggleSwitch = this.toggleSwitch == "FORSALE" ? "NOTFORSALE" : "FORSALE"
      //this.$router.push(this.$router.currentRoute.path+"#"+this.toggleSwitch)
    }
  },
  components: {
    'app-user-infos': UserInfos
  }
}
</script>
<style lang="scss" scoped>
  .action-msg {
    .wrap{
      background-color: rgb(78, 228, 163);
      color: white;
      border-bottom: solid white 10px;
      padding: 10px;
      text-align: center;
      justify-content: center;
      .error{
        background-color: rgb(220, 56, 56);
      }
    }
  }
  .switchForSale{
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f7f7f7;
    padding: 7px 0;
    border-radius: 10px;
    margin-bottom: 20px;
    .bold{
      font-weight: bold;
    }
    .fa-toggle-on {
      color: #ba53f0;
      transform: rotate(0deg);
      font-size: 23px;
      &.on{
        transform: rotate(180deg);
        
      }
    }
    .count{
      font-size: 18px;
      font-weight: 400;
      color: #767676;
    }
  }
</style>