<template>
  <transition name="fade" >
    <div id="bidPopin" ref="bidPopin" >
      <div class="wrapper">
        <div class="form-group mb-0">
          <div class="row">
            <div class="col-lg-12">
              
              <div v-if="artwork.art_price == 0"><h4 class="text-center mb-2 dottedBottom"><span>OFFER ONLY</span></h4></div>
              <div v-else><h4 class="text-center mb-2 dottedBottom">Current listing price:<br/><span>{{ numberWithSpaces(getCurrentArtPrice()) }} <app-UsdPrice :artwork="artwork" class="bleu"/></span></h4></div>
              
              <p v-if="currentHighestBid" class="text-center dottedBottom"><strong class="d-block">Current highest OFFER:</strong> {{ numberWithSpaces(currentHighestBid.price) }} {{ currentHighestBid.assetId }} (${{ numberWithSpaces(currentHighestBid.priceInUsd.toFixed(2)) }})</p>
              <p v-if="haveBid" class="haveBid mb-3"><strong>You already have a pending offer</strong>, new offers will be added but won't replace your existing one. <span v-if="artwork.maxmint-artwork.issued > 1">This is a multiple edition, the owner will be able to accept multiple offers.</span></p>
              <label for="currency">Choose the currency <span class="mandatory">*</span> <span v-b-tooltip.hover.top title="Select the offer currency."><i class="fas fa-question-circle" ></i></span></label>
              <select class="form-control" v-model="bid.assetId" @change="setTicker($event); setMinSellPrice()">
                <option :value="$store.state.config.assetID">SIGN</option>
                <option :value="$store.state.config.wavesAssetID">WAVES</option>
                <option :value="$store.state.config.usdnAssetID">XTN</option>
                <option :value="$store.state.config.usdtPPAssetID">USDT-PP</option>
              </select>
              <div class="smallBalance mt-1">
                Available balance:
                <span v-show="bid.assetId == $store.state.config.assetID"> {{ numberWithSpaces($store.state.user.signBalance) }}</span>
                <span v-show="bid.assetId == $store.state.config.wavesAssetID"> {{ numberWithSpaces($store.state.user.wavesBalance) }}</span>
                <span v-show="bid.assetId == $store.state.config.usdnAssetID"> {{ numberWithSpaces($store.state.user.usdnBalance) }}</span>
                <span v-show="bid.assetId == $store.state.config.usdtPPAssetID"> {{ numberWithSpaces($store.state.user.usdtPPBalance) }}</span>
              </div>
            </div>
            <div class="col-lg-12 mt-3">
              <label for="price">Enter the offered price <span class="mandatory">*</span> <span v-b-tooltip.hover.top title="Enter the amount you offer."><i class="fas fa-question-circle"></i></span></label>
              <input type="number" class="form-control" id="price" placeholder="Price per NFT" v-model="bid.price" :min="0">
              <div class="smallBalance mt-1" v-show="offerInUsdn!=0">Equivalent to ${{ offerInUsdn.toFixed(2) }}</div>
            </div>
            <div class="col-12">
              <div :class="{error: bid.haveError}" v-show="bid.errorMessage!=''" class="response container w-100 mx-auto text-center mt-3">
                <div class="wrap">{{bid.errorMessage}}</div>
              </div>
              <button class="btn btn-primary bigger mb-0 mt-3 mx-auto d-block bleu" @click.prevent="processBid">{{ bid.price != 0 && bid.edit ? 'EDIT OFFER' : 'MAKE AN OFFER' }}</button>
              <p class="text-center mt-2 mention mb-0" v-show="currentMinSellPrice != ''">Minimum offer allowed: {{ currentMinSellPrice }} {{ bid.ticker }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </transition>
</template>
<script>
import globalMixin from '@/mixins/global.js';
import VueScrollTo from "vue-scrollto";
import UsdPrice from '@/components/elements/UsdPriceApi.vue' 
import encodeUrl from 'encodeurl';

var options = {
  container: "#content",
  easing: "ease-in",
};

export default {
  mixins: [globalMixin],
  props: ["artwork", "showBid", "currentHighestBid"],
  data: function(){
    return {
      bid: {
        popinOpen: this.showBid,
        price: 0,
        edit: false,
        assetId: this.$store.state.config.assetID,
        ticker: "SIGN",
        haveError: false,
        errorMessage: ""
      },
      feeAssetId: this.$store.state.config.assetID,
      feeCost: this.$store.state.sponsoredFee * 5,
      //feeTicker: 'SIGN',
      wavesMinPrice: undefined,
      signMinPrice: undefined,
      usdnMinPrice: 1,
      usdtMinPrice: 1,
      usdtPPMinPrice: 1,
      currentMinSellPrice: '',
      //invokeFee: undefined,
      finalPrice: 0,
      responseMessage: '',
      haveBid: false,
      offerInUsdn: 0
    }
  },
  components: {
    'app-UsdPrice': UsdPrice
  },
  watch: {
    'bid.price'(){
      this.updateOfferToUsd()
    },
    'bid.assetId'(){
      this.updateOfferToUsd()
      //this.setFeeTicker(null, true)
    },
    showBid: function(){
      this.showBid ? document.getElementById("content").style.overflowY="hidden" : document.getElementById("content").style.overflowY="auto"
    },
    /* '$store.state.sponsoredFee'(){
      this.feeCost = this.$store.state.sponsoredFee * 5
    } */
  },
  created(){
    this.init()
  },
  mounted(){
    document.getElementById('bidPopin').addEventListener('mousedown', this.detectClickOutOfBidPopin);
  },
  beforeDestroy() {
    if(document.getElementById('bidPopin')){
      document.getElementById('bidPopin').removeEventListener('mousedown', this.detectClickOutOfBidPopin);
    }
  },
  methods: {
    init: function(){
      this.getMinSellPrice()
      this.calculatePriceAndTxFee()
      this.userAlreadyHaveBid()
    },
    updateOfferToUsd: function(){
      if(this.bid.price != 0){
        switch(this.bid.assetId){
          case "WAVES":
          case this.$store.state.config.wavesAssetID: // WAVES
          this.offerInUsdn = this.bid.price  * this.$store.state.priceInUSDN.wavesPriceInUsd
          break;

          case this.$store.state.config.assetID: // SIGN
          this.offerInUsdn = this.bid.price * this.$store.state.priceInUSDN.signPriceInUsd
          break;

          case this.$store.state.config.usdnAssetID: // SIGN
          this.offerInUsdn = this.bid.price * this.$store.state.priceInUSDN.xtnPriceInUsd
          break;

          default:
          this.offerInUsdn = 0
        }
      }else{
        this.offerInUsdn = 0
      }
    },
    userAlreadyHaveBid: function(){
      const _this = this
        let regex = encodeUrl("^bid_(.*)"+ _this.$route.params.txid +"_"+ _this.$route.params.addr+"_"+this.$store.state.user.userAddress+"(.*)_OPEN")
        fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.dapp_addr_artworks+"?matches="+regex).then(res => res.json()).then(data => {
          _this.haveBid = data.length > 0
        })
    },
    getCurrentArtPrice: function(){ // is bid higher than current asked price?
      let decimal = this.getDecimalByAssetId(this.artwork.art_assetAccepted)
      let currentArtPrice = this.artwork.art_price / Math.pow(10, decimal)
      return currentArtPrice + " " + this.getTickerByAssetId(this.artwork.art_assetAccepted)
    },
    setTicker: function(e){
      this.bid.ticker = e.target.options[e.target.selectedIndex].text == "" ? "WAVES" : e.target.options[e.target.selectedIndex].text
      this.calculatePriceAndTxFee()
    },
    getMinSellPrice: async function(){
      let _this = this
      let regex = encodeUrl("certification_fee_"+_this.$store.state.config.assetID)
      let getSignMinSellPrice = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.signOracle+"?matches="+regex)
      getSignMinSellPrice = await getSignMinSellPrice.json()
      _this.signMinPrice = getSignMinSellPrice[0].value / Math.pow(10, 8) * 2

      let regex2 = encodeUrl("waves_min_sell")
      let getWavesMinSellPrice = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.signOracle+"?matches="+regex2)
      getWavesMinSellPrice = await getWavesMinSellPrice.json()
      _this.wavesMinPrice = getWavesMinSellPrice[0].value / Math.pow(10, 8)
      _this.setMinSellPrice()
    },
    setMinSellPrice: function(){
      if(this.bid.assetId == this.$store.state.config.assetID){
        this.currentMinSellPrice = this.signMinPrice
      }else if(this.bid.assetId == this.$store.state.config.usdnAssetID){
        this.currentMinSellPrice = this.usdnMinPrice
      }else if(this.bid.assetId == this.$store.state.config.usdtAssetID){
        this.currentMinSellPrice = this.usdtMinPrice
      }else if(this.bid.assetId == this.$store.state.config.usdtPPAssetID){
        this.currentMinSellPrice = this.usdtPPMinPrice
      }else{
        this.currentMinSellPrice = this.wavesMinPrice
      }
    },
    calculatePriceAndTxFee: function(){
        if(this.bid.ticker == 'SIGN' || this.bid.ticker == 'WAVES' || this.bid.ticker == ''){
          this.finalPrice = this.bid.price * 10 ** 8
        }
        if(this.bid.ticker == 'XTN' || this.bid.ticker == 'USDT' || this.bid.ticker == 'USDT-PP'){
          this.finalPrice = this.bid.price * 10 ** 6
        }
    },
    processBid: function(){
        VueScrollTo.scrollTo('#content', 600, options)
        let _this = this;
        this.$store.state.alert=true
        this.$store.state.alertMessage='Processing the offer, Please wait...'

        this.calculatePriceAndTxFee()

        const dataTx = {
          dApp: this.$store.state.config.dapp_addr_artworks,
            call: {
                function: "setBid",
                args:[
                    { type:"string", value: _this.$route.params.txid },
                    { type:"string", value: _this.$route.params.addr }
                ]
            }
        }

        if(_this.bid.assetId != ""){
          dataTx.payment = [{ assetId: _this.bid.assetId, amount: this.finalPrice }]
        }else{
          dataTx.payment = [{ assetId: "WAVES", amount: this.finalPrice }]
        }

        this.$store.dispatch("signerInvoke", dataTx).then(res => { 
            this.$store.state.alert=false
            this.$store.state.alertMessage=''
            if(res.error){
              // siplay error
              this.bid.errorMessage = res.error.message ? res.error.message : res.error
              this.bid.haveError = true
            }else{
              // display confirmation message of success
              _this.$store.state.alert=false
              _this.$store.state.alertMessage=''
              _this.$parent.haveError = false
              _this.$parent.responseMessage = 'Offer set successfully!'
              _this.$parent.showBid = false
              _this.$parent.getOffersList(_this.$route.params.txid, _this.$route.params.addr)
              setTimeout(function(){
                _this.$parent.responseMessage = ''
              }, 3000)
            }
        }) 
      
    },
    detectClickOutOfBidPopin: function(e){   
      if(this.showBid){
        if (!document.getElementById('bidPopin').querySelector(".wrapper").contains(e.target)){
          this.$parent.showBid = false
          this.bid.errorMessage = ''
          this.bid.haveError = false
          document.getElementById("content").style.overflowY="auto"
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.smallBalance{
  font-size: 14px;
  color: #979797;
}
  .usdPrice.bleu, .mandatory{
    color: #0085FF!important;
  }
  .dottedBottom{
    border-bottom: dotted 1px black;
    padding-bottom: 10px;
  }
  .haveBid{
    color: #57137b;
    text-align: center;
  }
  h4{
    font-weight: 600;
    font-size: 20px;
    span{
      color: #0085FF;
      font-size: 24px;
    }
  }
  .btn.bleu{
    background-color: #0085FF;
    border-radius: 50px;
    &:hover{
      background-color: #1E1A1A;
    }
  }
</style>
<style lang="scss">
.response .wrap{
  overflow: hidden;
}
.alertBid{
  color: red;
  margin-top: 5px;
  font-size: 14px;
}
</style>