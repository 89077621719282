<template>
  <figure v-lazyload class="image__wrapper w-100">
    <img
      ref="visuel"
      class="image__item"
      :data-url="imgix"
      src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
      :data-imageboss-src="'/'+imgix"
      sizes="(max-width: 500px) 100vw, 500px"
      data-imageboss-options="animation:true"
      :data-name="altname"
      alt="Loading..."
     @load="$parent.hideSpiner(); $parent.callMSRNY()"
     @error="replaceByDefaultImg" v-if="$store.state.config.useIMGIX=='true'">


     <img
      ref="visuel"
      class="image__item"
      :data-url="source"
      :data-name="altname"
      alt="Loading..."
     @load="$parent.hideSpiner(); $parent.callMSRNY()"
     @error="replaceByDefaultImg" v-else>
  </figure>
</template>
<script>
import globalMixin from '@/mixins/global.js';
export default {
  mixins: [globalMixin],
  name: "ImageItem",
  props: {
    source: {
      type: String,
      required: true
    },
    altname: {
      type: String,
      required: true
    },
    imgix: {
      type: String
    }
  },

  beforeDestroy(){
    let _this=this
    _this.$refs.visuel.srcset = _this.emptySrc
    _this.$refs.visuel.src = _this.emptySrc
  }
};
</script>
<style scoped lang="scss">
figure{
  margin-bottom: 0;
}
.image {
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    &.loaded {
      .image {
        &__item {
          visibility: visible;
          opacity: 1;
          border: 0;
        }

        &__spinner {
          display: none;
          width: 100%;
        }
      }
    }
  }

  &__item {
    width: 100%;
    border-radius: 4px;
    transition: all 0.4s ease-in-out;
    opacity: 0;
    //visibility: hidden;
  }
}
</style>
