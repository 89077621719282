<template>
    <div>
      <h2 class="text-center mt-3 mb-0 pb-0">Manage your notifications</h2>
      <div class="container mt-4">
        <div class="row">
          <div class="col-12 text-center" v-if="!$store.state.user.sessionData || !$store.state.user.sessionData.waves_addr">
            <p><strong>Notification management requires you to sign a message with your Waves account:</strong></p>
            
          </div>
          <div class="col-12 col-lg-6 offset-lg-3 text-center">
            <div class="notification_alert mt-2 mb-3" v-if="messageInfo != ''" v-html="messageInfo"></div>
            <div class="wrap_from" v-if="$store.state.user.sessionData && $store.state.user.sessionData.waves_addr">
              <div class="form-group mb-4">
                <label for="email_addr">Your email address:</label>
                <input type="email" v-model="new_email" class="form-control text-center" id="email_addr"/>
                <div id="activation" class="mt-2" v-show="!$store.state.user.sessionData.is_valid_email && $store.state.user.sessionData.mail_addr!=''">Your E-Mail is not verified yet, click the link you received by E-Mail. <a href="#" @click.prevent="manageEmail">Send again</a></div>
              </div>
              <div class="form-group mb-0">
                <label for="notif_offer">Notify me when I receive an offer:</label>
                <input type="checkbox" id="notif_offer" v-model="$store.state.user.sessionData.notif_offer" class="ml-2"/>
              </div>
              <div class="form-group">
                <label for="notif_sale">Notify me when I sell a NFT:</label>
                <input type="checkbox" id="notif_sale" v-model="$store.state.user.sessionData.notif_sale" class="ml-2"/>
              </div>
            </div>
            <div class="btn btn-primary mb-2 bigger" @click="loginSession" v-if="!$store.state.user.sessionData && !disableLoginBtn">Login to manage</div>
            <div class="btn btn-primary mb-2 bigger" @click="manageEmail" v-if="$store.state.user.sessionData && $store.state.user.sessionData.mail_addr==''">Register</div>
            <div class="btn btn-primary mb-2 bigger" @click="updateNotifications" v-if="$store.state.user.sessionData && !$store.state.user.sessionData.mail_addr==''">{{ new_email != "" ? 'Update notifications' : 'Delete information' }}</div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import globalMixin from '@/mixins/global.js';
import { wavesAddress2eth } from '@waves/node-api-js';

export default {
  mixins: [globalMixin],
  data: function(){
    return {
        messageInfo: '',
        disableLoginBtn: false,
        expiration: 3600000, // 1h
        new_email: null,
        signingChoice: null
    }
  },
  created(){
    this.init()
  },
  methods: {
    init: async function(){
      this.signingChoice = this.$store.state.signingChoice.keeper ? 'keeper' : 'signer'
      if(this.$store.state.user.sessionData && this.$store.state.user.sessionData.waves_addr == this.$store.state.user.userAddress){
        this.new_email = this.$store.state.user.sessionData.mail_addr
        this.loginSession()
      }
    },
    loginSession: async function(){
      this.disableLoginBtn = true 
      let { token, signedMessage, timestamp } = await this.checkActiveSession()

      this.messageInfo = "Retrieving your info..."
      let notificationInfo = await this.getNotificationInfo(signedMessage, token)

      if(notificationInfo.error){

        this.messageInfo = notificationInfo.text
        switch(notificationInfo.error_code){
          case 1: // signature invalid
          this.messageInfo += "<br/>Make sure to sign with the correct account." 
          break

          case 5: // user not registered
          this.messageInfo = "User not registered. <br/>Enter your email address to register" 
          this.$store.state.user.sessionData = {}
          this.$store.state.user.sessionData.waves_addr = this.$store.state.user.userAddress
          this.$store.state.user.sessionData.notif_offer = false
          this.$store.state.user.sessionData.notif_sale = false
          this.$store.state.user.sessionData.mail_addr=""
          this.$store.state.user.sessionData.token = null
          break

          default:
        }
      }else{

        this.$store.state.user.sessionData = notificationInfo
        this.$store.state.user.sessionData.token = token
        this.$store.state.user.sessionData.timestamp = timestamp
        this.new_email = notificationInfo.mail_addr
        this.$store.state.user.sessionData.signedMessage = signedMessage
        this.messageInfo = ""
      }

      this.disableLoginBtn = false
    },
    checkActiveSession: async function(){
      let tokenData = {
        token: null,
        timestamp: null
      }
      let signedMessage

      if(this.$store.state.user.sessionData && this.$store.state.user.sessionData.token){
        if((Date.now() - this.$store.state.user.sessionData.timestamp) >= this.expiration){
          this.$store.state.user.sessionData = null
          this.disableLoginBtn = false
          this.messageInfo = ""
          return
        }else{
          tokenData.token = this.$store.state.user.sessionData.token
          tokenData.timestamp = this.$store.state.user.sessionData.timestamp
          signedMessage = this.$store.state.user.sessionData.signedMessage
        }
      }else{
        this.messageInfo = "Initializing session, please wait..."
        tokenData = await this.getSessionToken()
        this.messageInfo = "Wait for signin window..."
        if(!this.$store.state.user.isLogged && this.$store.state.signer.isMetamask){
          await this.$store.state.signer.signer.login()
        }

        signedMessage = await this.$store.dispatch("signMessage", tokenData.token)
      }

      return {token: tokenData.token, signedMessage: signedMessage, timestamp: tokenData.timestamp,}
    },
    getSessionToken: async function(){

      let getToken = await fetch(this.$store.state.config.signArtApiURL+"/notification/token/"+this.$store.state.user.userAddress)
      getToken = await getToken.json()

      return getToken
    },
    getNotificationInfo: async function(signedMessage, token){
      let publicKey = this.$store.state.user.publicKey
      let signingChoice = this.signingChoice
      if(this.$store.state.signer.isMetamask){
        publicKey = wavesAddress2eth(this.$store.state.user.userAddress);
        signingChoice = "metamask" 
      }

      let getUserNotifications = await fetch(this.$store.state.config.signArtApiURL+"/notification/infos/"+signingChoice+"/"+publicKey+"/"+signedMessage+"/"+token)
      getUserNotifications = await getUserNotifications.json()
      return getUserNotifications
    },
    updateNotifications: async function(){
      
      this.messageInfo = "Updating your informations, please wait..."
      let _this = this
      let { token, signedMessage } = await this.checkActiveSession()
      if(this.new_email != this.$store.state.user.sessionData.mail_addr){
        await this.manageEmail()
        
      }
      if(this.new_email==""){
          this.$store.state.user.sessionData.waves_addr = this.$store.state.user.userAddress
          this.$store.state.user.sessionData.notif_offer = false
          this.$store.state.user.sessionData.notif_sale = false
          this.$store.state.user.sessionData.mail_addr=""
          return
      }else{
        let notif_offer = Number(this.$store.state.user.sessionData.notif_offer)
        let notif_sale = Number(this.$store.state.user.sessionData.notif_sale)
        let publicKey = this.$store.state.user.publicKey

        let signingChoice = this.signingChoice
        if(this.$store.state.signer.isMetamask){
          publicKey = wavesAddress2eth(this.$store.state.user.userAddress);
          signingChoice = "metamask" 
        }

        let updateUser = await fetch(this.$store.state.config.signArtApiURL+"/notification/toggle/"+signingChoice+"/"+publicKey+"/"+signedMessage+"/"+token+"/"+notif_offer+"/"+notif_sale)
        updateUser = await updateUser.json()
        if(!updateUser.error){
          if(this.new_email == this.$store.state.user.sessionData.mail_addr){
            this.messageInfo = "Informations updated"
            setTimeout(function(){
              _this.messageInfo = ""
            }, 3500)
          }
        }else{
          this.messageInfo = updateUser.text
        }
        return updateUser
      }
    },
    manageEmail: async function(){
        this.messageInfo = "Updating your informations, please wait..."
        let _this = this
        let signingChoice = this.signingChoice
        let { token, signedMessage } = await this.checkActiveSession()
        let email = this.new_email != "" ? this.new_email : "delete"

        let publicKey = this.$store.state.user.publicKey

        if(this.$store.state.signer.isMetamask){
          publicKey = wavesAddress2eth(this.$store.state.user.userAddress);
          signingChoice = "metamask" 
        }
        
        let updateUser = await fetch(this.$store.state.config.signArtApiURL+"/notification/register/"+signingChoice+"/"+publicKey+"/"+signedMessage+"/"+token+"/"+email)
        updateUser = await updateUser.json()
        if(!updateUser.error && this.new_email != this.$store.state.user.sessionData.waves_addr){
          console.log(updateUser.error)
          this.messageInfo = email == "delete" ? "Your information have been deleted" : "Please confirm your email by clicking on the link sent to: <br/>"+this.new_email
          setTimeout(function(){
            _this.messageInfo = ""
          }, 3500)
        }else{
          this.messageInfo = updateUser.text
          this.$store.state.user.sessionData.mail_addr = this.new_email
        }
        return updateUser
    }
  },
  mounted(){
    document.querySelector(".wrap").classList.add("show")
  }
}
</script>
<style lang="scss" scoped>
.notification_alert {
    position: relative;
    padding: .75rem 1.25rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    color: #35074d!important;
    background-color: #f7edfc!important;
    border-color: #e7c7f8!important;
    font-size: 14px;
}
#activation{
  font-size: 13px;
}

</style>