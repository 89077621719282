<template>
  <transition name="fade">
    <div class="transferPopin" v-show="$parent.cancelPopinOpen" @click="closeSendForm($event)" :class="{'flexstart':haveError}">
      <i class="fa-solid fa-xmark close_popin_nft"></i>
      <div class="col-12 px-0 wrapper">
        <p class="text-center">Cancel sale of NFT:<br/>"{{ (nft.art_name || nft.primary.name) | shorten }}" - ref: {{ nftInfo.name }}</p>
        <button class="btn btn-primary bigger mb-0 mt-4 mx-auto d-block pointer-events-auto" @click.prevent="cancelSale">CANCEL SALE</button>
        <p v-show="cancelResult!=''" class="text-center mt-1">{{ cancelResult }}</p>
      </div>
      <div class="clickZone pointer"></div>
    </div>
  </transition>
</template>
<script>
import globalMixin from '@/mixins/global.js';

export default {
  mixins: [globalMixin],
  props: ["nftInfo", "nft", "transferPopinOpen"],
  data: function(){
    return {
      cancelResult: '',
      attachment: '',
      recipient: '',
      haveError: false
    }
  },
  filters:{
    shorten: function(e){
      if(e.length > 40){
        return e.slice(0, 40)+'...'
      }else{
        return e
      }
    }
  },
  methods: {
    closeSendForm: function(e){
      if(e.target.closest('.clickZone') || e.target.closest('.close_popin_nft')){
        this.$parent.cancelPopinOpen = false;
      }
    },
    cancelSale: function(){
      let _this = this
      this.$store.state.alert=true
      this.$store.state.alertMessage='Cancelling the sale, Please wait...'
      const dataTx = {
        dApp: this.$store.state.config.dapp_addr_secondary,
        call: {
            function: "cancelSale",
              args:[
                { type:"string", value: _this.nftInfo.assetId }
            ]
        }        
      }

      this.$store.dispatch("signerInvoke", dataTx).then(res => { 
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          if(res.error){
            // siplay error
            this.cancelResult = res.error.message ? res.error.message : res.error
            this.haveError = true
          }else{
            // display confirmation message of success
            this.$store.state.alert=false
            this.$store.state.alertMessage=''
            this.$parent.$parent.actionRes = true
            this.$parent.$parent.actionMsg = 'NFT sale cancelled successfully!'
            this.haveError = false
            _this.$parent.$emit('removeNFT', _this.nftInfo.assetId)
            setTimeout(function(){
              _this.$parent.$parent.actionRes = false
              _this.$parent.$parent.actionMsg = ''
              
            }, 3000)
          }
      }) 
    }
  }
}
</script>
<style lang="scss">

</style>