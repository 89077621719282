<template>
<transition name="fade">
  <div id="buyToken" ref="buyToken" :class="{'flexTop':flexTop}" @mousedown="detectClickOutOfBuyPopin($event)">
    <div class="wrap" ref="wrap">
      <i class="fas fa-times position-absolute" @click="$store.state.showBuyPopin = !$store.state.showBuyPopin"></i>
      
      <app-buy-token-balance :balanceLoaded="balanceLoaded" :signBal="signBal" :usdtPPBal="usdtPPBal" :usdnBal="usdnBal" :wavesBal="wavesBal" :ethBal="ethBal" :chooseSigningMethod="chooseSigningMethod" />

      <div class="choiceTabs">
        <button @click="swapTab='buy'" :class="{'on':swapTab=='buy'}">Buy</button>
        <button @click="swapTab='swap'" :class="{'on':swapTab=='swap'}">Swap</button>
        <button @click="swapTab='transfer'" :class="{'on':swapTab=='transfer'}">Transfer</button>
        <button @click="swapTab='gateways'" :class="{'on':swapTab=='gateways'}">Gateways</button>
      </div>

      <div class="content p-0" v-if="swapTab == 'buy'">
        <app-buy-token-card />
      </div>

      <div class="content" v-else-if="swapTab == 'swap'">
        <app-buy-token-swap 
        :updateBalances="updateBalances" 
        :signBal="signBal" 
        :usdnBal="usdnBal" 
        :usdtBal="usdtBal"
        :usdtPPBal="usdtPPBal" 
        :wavesBal="wavesBal" 
        :ethBal="ethBal" 
        :onResizeWrapper="onResizeWrapper"/>
      </div>
      <div class="content" v-else-if="swapTab == 'transfer'">
        <app-buy-token-transfer 
        :updateBalances="updateBalances" 
        :signBal="signBal" 
        :usdnBal="usdnBal"
        :usdtPPBal="usdtPPBal" 
        :wavesBal="wavesBal" 
        :ethBal="ethBal" 
        :onResizeWrapper="onResizeWrapper" />
      </div>   
      <div class="content" v-else>
        <app-buy-token-crosschain 
        :chooseSigningMethod="chooseSigningMethod" 
        :agreed="agreed" 
        :updateAgreed="updateAgreed" 
        :onResizeWrapper="onResizeWrapper"/>
      </div>     

     <!--  <a href="https://waves.exchange/buycrypto/buyusdn" target="_blank" id="buyOnWX">
        <span class="mr-3">Buy USDN with Credit Card on</span>
        <img src="https://waves.exchange/img/icons/logo-exchange.svg" />
      </a> -->

    </div>
  </div>
</transition>
</template>

<script>
import globalMixin from '@/mixins/global.js';
import BuyTokenBalance from '@/components/elements/BuyTokenBalance'
import BuyTokenCard from '@/components/elements/BuyTokenCard'
//import BuyTokenSwap from '@/components/elements/BuyTokenSwap'
import BuyTokenSwapV2 from '@/components/elements/BuyTokenSwapV2'
//import BuyTokenCrosschain from '@/components/elements/BuyTokenCrosschain'
import BuyTokenCrosschainV2 from '@/components/elements/BuyTokenCrosschainV2'
import BuyTokenTransfer from '@/components/elements/BuyTokenTransfer'

export default {
  mixins: [globalMixin],
  data: function(){
    return {
      userBalance: {},
      signBal: 0,
      usdnBal: 0,
      usdtBal: 0,
      usdtPPBal: 0,
      wavesBal: 0,
      ethBal: 0,
      swapTab: "buy",
      agreed: false,
      listenToBalance: null,
      balanceLoaded:false,
      flexTop: false
    }
  },
  components: {
    'app-buy-token-balance': BuyTokenBalance,
    'app-buy-token-card': BuyTokenCard,
    'app-buy-token-swap': BuyTokenSwapV2,
    'app-buy-token-crosschain': BuyTokenCrosschainV2,
    'app-buy-token-transfer': BuyTokenTransfer
  },
  created(){
    if(this.$store.state.user.isLogged){
       this.updateBalances()
    }
    window.addEventListener('resize', this.onResizeWrapper)
  },
  mounted(){
    let _this = this
    this.listenToBalance = setInterval(function(){
      
      _this.updateBalances()
    }, 5000)
    _this.updateBalances()
    this.onResizeWrapper()
    if(this.$store.state.isMobile){
      this.flexTop = true
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResizeWrapper)
  },
  destroyed(){
    clearInterval(this.listenToBalance)
  },
  watch:{
    '$store.state.user.isLogged': function(){
      this.updateBalances()
    }
  },
  methods: {
    onResizeWrapper: function() {
      if(this.$store.state.isMobile){
        this.flexTop = true
      }else{
        if (window.innerHeight < document.getElementById("buyToken").querySelector(".wrap").offsetHeight) {
          this.flexTop = true
        } else {
          this.flexTop = false
        }
      }
    },
    updateAgreed: function(bool){
      this.agreed = bool
    },
    chooseSigningMethod: function(){
       if(!this.$store.state.user.isLogged){
         this.$store.state.signingChoice.showChoice = true
       }
    },
    getTokenBlance: async function(assetId){
      this.balanceLoaded=false
      let data
      if(assetId == "WAVES"){
        data = await fetch(`${this.$store.state.config.nodeURL}/addresses/balance/${this.$store.state.user.userAddress}`)
      }else{
        data = await fetch(`${this.$store.state.config.nodeURL}/assets/balance/${this.$store.state.user.userAddress}/${assetId}`)
      }
      data = await data.json()
      this.balanceLoaded = true
      return data.balance
    },
    updateBalances: async function(){
      if(this.$store.state.user.isLogged && this.$store.state.showBuyPopin){
        this.userBalance.sign = await this.getTokenBlance(this.$store.state.config.assetID)
        this.userBalance.usdn = await this.getTokenBlance(this.$store.state.config.usdnAssetID)
        this.userBalance.usdt = await this.getTokenBlance(this.$store.state.config.usdtAssetID)
        this.userBalance.usdtpp = await this.getTokenBlance(this.$store.state.config.usdtPPAssetID)
        this.userBalance.waves = await this.getTokenBlance('WAVES')
        this.userBalance.eth = await this.getTokenBlance(this.$store.state.config.ethAssetID)
        this.signBal = this.userBalance.sign / Math.pow(10,8)
        this.usdnBal = this.userBalance.usdn / Math.pow(10,6)
        this.usdtBal = this.userBalance.usdt / Math.pow(10,6)
        this.usdtPPBal = this.userBalance.usdtpp / Math.pow(10,6)
        this.wavesBal = this.userBalance.waves / Math.pow(10,8)
        this.ethBal = this.userBalance.eth / Math.pow(10,8)
      }
    },
    detectClickOutOfBuyPopin: function(e){   
      if(this.$store.state.showBuyPopin){
        if (!document.getElementById('buyToken').querySelector(".wrap").contains(e.target)){
          this.$store.state.showBuyPopin = false
        }
      }
    }
  }
}
</script>
<style lang="scss">
  .testnet_alert{
    background-color: #b91c1c;
    color: white;
    padding: 15px;
    margin-bottom: 15px;
    text-align: center;
  }
  @media(max-width: 580px){
    #dropdown .dropdown-menu{
      left: 0!important;
      width: 100%;
    }
  }
</style>
<style lang="scss" scoped>
  .fa-times{
    right: 10px;
    font-size: 18px;
    top: 8px;
    padding: 5px;
    cursor: pointer;
    color: white;
    z-index: 500;
  }
  .content{
    padding: 25px;
  }
  #buyOnWX{
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #19202E;
    color: white;
    border-radius: 0 0 15px 15px;
    font-size: 14px;
    img{
      width: 120px;
    }
    
  }

  #buyToken{
    color: rgba(87,19,123, 1);
    position: absolute;
    z-index: 980;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.7);
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    max-height: 100%;
    &.flexTop{
      justify-content: flex-start!important;
    }

    .choiceTabs{
      width: 100%;
      display: flex;
      justify-content: center;
      border-bottom: solid 1px #9f9f9f;
      margin-top: 15px;
      align-items: center;
      button{
        margin-right: 15px;
        background-color: white;
        color: black;
        border: solid 1px #9f9f9f;
        
        border-radius: 10px 10px 0 0!important;
        padding: 6px 15px!important;
        width: auto!important;
        border-radius: 5px 5px 0 0!important;
        margin-bottom: -1px;
        &.on{
          border-bottom-color: white;
        }
        &:last-child{
          margin-right: 0;
        }
      }
    }
    .wrap{
      background-color: white;
      max-width: 600px;
      width: 100%;
      border-radius: 18px;
      min-height: auto;
      position: relative;    
      
    }
  }
  @media(max-width: 768px){
    #buyToken{
      
      .wrap{
        width: 95%;
      }
    }
  }
  @media(max-width: 580px){
    #buyToken{
      .choiceTabs button{
        padding: 6px 8px !important;
      }
      .wrap{
        #sign_bal, #usdn_bal{
          display: flex;
          flex-direction: column;
        }
      }
    }
    #buyOnWX{
      flex-direction: column;
      span{
        margin-bottom: 5px;
      }
    }
  }
</style>