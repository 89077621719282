<template>
  <div>
      <div class="container-fluid">
        <div>
          <app-filters-collections :showArtistStatus="true" :status="status"></app-filters-collections>

          <div v-if="loading" class="loading mb-4">
            <app-loading :label="'data'"></app-loading>
          </div>
          <div class="wrap_data row mt-3" v-else>
            <div class="col-12 mb-4 text-center text-black" v-if="collections && !collections.length">
              <div v-if="loading">Loading data</div>
              <div v-else>No collections found.</div>
            </div>
            <div class="col-lg-3 col-xl-3 col-md-6 mb-4" v-for="(collection, key) in collections" :key="key" :data-index="key" :id="'collection_id_'+key" @mousedown="storeCollectionClickedIndex($event)">
              <app-collections :collection="collection"></app-collections>
            </div>
          </div>
        </div>
      </div>

      <div class="wrap_pagination d-flex justify-content-center mt-2">
          <button class="btn btn-primary mb-2 bigger" :class="{'mr-3': collections && collections.length}"  v-if="this.$route.params.pageindex && this.$route.params.pageindex > 1" @click="scrollToId = false; prevNext('prev')">PREVIOUS</button>
          <button class="btn btn-primary mb-2 bigger" @click="scrollToId = false; prevNext('next')" v-if="collections && collections.length == perPage">NEXT</button>
       </div>

  </div> 
</template>

<script>
import globalMixin from '@/mixins/global.js';
import collections from '@/mixins/collections.js';
import orderedArtworks from "@/mixins/orderedArtworks.js"
import Loading from '@/components/elements/Loading.vue'
import userMixin from '@/mixins/userMixin.js';
import collectionsItem from '@/components/content/collections/item.vue'
import FiltersCollections from '@/components/elements/FiltersCollections.vue'

export default {
  mixins: [userMixin, globalMixin, orderedArtworks, collections],
  data: function(){
    return {
      collections: null,
      loading: false,
      perPage: 20,
      status: null,
      scrollToId: true
    }
  },
  components:{
    'app-loading': Loading,
    'app-collections': collectionsItem,
    'app-filters-collections': FiltersCollections,
  },

  created(){
   this.init()
  },
  watch: {
    '$route.params.pageindex'(){
      this.init()
    },
    '$store.state.filtering.collections.status'(){
      this.init()
    },
    '$store.state.filtering.collections.sort'(){
      this.init()
    },
    '$store.state.filtering.collections.crosschainOnly'(){
      this.init()
    }
  },
  methods: {
    init: async function(){
       let _this = this
      this.loading = true
      let collectionsList = await this.getCollections(null, null, this.perPage, this.$store.state.filtering.collections.status, this.$store.state.filtering.collections.sort, this.$store.state.searchStr, this.$store.state.filtering.collections.crosschainOnly)
      this.collections = collectionsList
      this.loading = false

      this.$nextTick(() => {
      if(document.getElementById(_this.$store.state.collectionsScrollState.scrollToId) && _this.$store.state.collectionsScrollState.scrollToId !=0 && _this.scrollToId){
        document.getElementById(_this.$store.state.collectionsScrollState.scrollToId).scrollIntoView()
        _this.$store.state.collectionsScrollState.scrollToId = 0
        _this.scrollToId = false
      }
    })
    },
    storeCollectionClickedIndex: function(e){
      this.$store.state.activeMarket = "home-collections"
      this.$store.state.collectionsScrollState.scrollToId = e.currentTarget.id
    },
    resetListing: function(){
      this.loading = true
      this.collections = []
    },
    prevNext: function(direction){
      let query = { search: this.$store.state.searchStr }
      query.status = this.$route.query.status || this.$store.state.filtering.collections.status
      let index
      if(direction == "prev"){
        index = +Number(this.$route.params.pageindex)-1
      }else{
        index = +Number(this.$route.params.pageindex)+1
        
      }
      this.$router.push({ path: '/collections/'+index, query: query }).catch(()=>{});
    }, 
  }
}
</script>
<style lang="scss" scoped>
  .text-black{
    color: #1E1A1A;
  }
</style>