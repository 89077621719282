<template>
  <div class="position-relative w-100" ref="player">
    <div class="playBtn" @click="playVideo">
      <i class="fas fa-play" v-if="!isPlaying"></i>
      <i class="fas fa-pause" v-else></i>
    </div>
    <div class="muteBtn" @click="muteVideo">
      <i class="fas fa-volume-off" v-if="!isMuted"></i>
      <i class="fas fa-volume-mute" v-else></i>
    </div>    
    <vue-plyr ref="plyr">
      <video
        crossorigin
        playsinline
        muted
        loop
        @loadedmetadata="metaLoaded()"
        :data-poster="getPoster()"
      >
        <source
          :src="getVideoLink()"
          :type="'video/'+setVideoExtension()"
        />
      </video>
    </vue-plyr>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js';
export default {
  props: ["cid", "poster", "imgix"],
  mixins: [globalMixin],
  data: function(){
    return {
      isPlaying: false,
      isMuted: true,
      displayIsMp4: false,
      autoplay: false
    }
  },
  created(){
    this.checkDisplayIsMp4()
  },
  mounted(){
    this.initPlayer()
  },
  beforeDestroy: async function () {
    if(this.$refs.plyr.player){
      try{
        await this.$refs.plyr.player.destroy()
      }catch(err){
        console.log(err)
      }
    }
    
  },
  methods:{
    setVideoExtension(){
      let ext
      if(this.displayIsMp4){
        ext = this.artworkExension(this.poster).toLowerCase()
      }else{
        ext = this.artworkExension(this.cid).toLowerCase()
      }
      return ext
    },
    getPoster:function(){

      if(this.displayIsMp4){
        return ""
      }else{
        return this.$store.state.config.useIMGIX && this.imgix ? this.getGifFromImg(this.imgix) : this.getIpfsLink(this.poster)
      }
    },
    checkDisplayIsMp4: function(){
      let ext = this.poster.substr(this.poster.lastIndexOf(".")+1, this.poster.length)
      if(ext == "mp4"){
        this.displayIsMp4 = true
        this.autoplay = true
        this.isPlaying = true
        
      }else{
        this.displayIsMp4 = false
        this.autoplay = false
        this.isPlaying = false
        
      } 
      return this.displayIsMp4
    },
    getVideoLink: function(){
      if(this.displayIsMp4){
        return this.getIpfsLink(this.poster)
      }else{
        return this.getIpfsLink(this.cid)
      }
    },
    metaLoaded: function(){
      this.$emit('metaloaded')
    },
    initPlayer: function(){
      if(this.$refs.plyr){
        this.$refs.plyr.player.on('waiting seeking', () => this.$parent.showSpiner = true )
        this.$refs.plyr.player.on('playing seeked pause play ended', () => this.$parent.showSpiner = false )
        this.$refs.plyr.player.on('ended', () => this.isPlaying = false)
        this.$refs.plyr.player.on('ready', () => {
          this.isPlaying = this.displayIsMp4 ? true : this.$refs.plyr.player.playing
          if(this.displayIsMp4){
            this.$refs.plyr.player.play()
          }
          this.$refs.plyr.player.muted = true
          this.isMuted = this.$refs.plyr.player.muted
        })
      }
    },
    getGifFromImg: function(imgix){
      let gifUrlarr = imgix.split(",")
      let gifUrl = gifUrlarr[gifUrlarr.length-1].split(" ")
      return gifUrl[0].replace(/\n/g, "");
    },
    playVideo: function(){
      if(this.$refs.plyr){
        this.isPlaying ? this.$refs.plyr.player.pause() : this.$refs.plyr.player.play()
        this.isPlaying = this.$refs.plyr.player.playing
      }
    },
    muteVideo: function(){
      if(this.$refs.plyr){
        this.$refs.plyr.player.muted = !this.$refs.plyr.player.muted
        this.isMuted = this.$refs.plyr.player.muted
      }
    }
  }
}
</script>
<style lang="scss">
  .plyr{
    height: auto!important; 
  }
  #art_detail .plyr--video .plyr__controls, #art_detail .plyr__control{
    color: white!important;
  }
  .playBtn, .muteBtn{
    position: absolute;
    z-index: 601;
    bottom: 15px;
    padding: 15px;
    cursor: pointer;
    transition: opacity .4s ease;
    opacity: .7;
    color: white;
    &:hover{
      opacity: 1;
    }
  }
  .playBtn{
    right:45px;
    padding-right: 10px;
  }
  .muteBtn{
    right: 0px;
    font-size: 20px;
    padding-bottom: 10px;
    padding-left: 10px;
  }
  .fa-volume-off{
    margin-right: 5px;
  }
</style>

