<template>
  <div class="round-corner">
    <div v-if="flag =='CONSENT'">
        <div class="sensitive" >
          SENSITIVE CONTENT
          <span class="consentDisplay text-center mt-2 d-block" @click="showSensitive" v-b-tooltip.hover.top title="This content was marked as sensitive, this may includes nudity or any content not suitable for anyone."><strong>CLICK HERE TO DISPLAY</strong></span>
        </div>
    </div>
    <div :class="{'wrap_item': artwork.userinfo.address != $route.params.addr}" v-else>

      <div class="on_sale w-100" v-if="artwork.maxmint != 0 && artwork.onsale">
        <div class="green_circle"></div>
        <div class="sale_infos w-100 justify-content-between">
            <div class="left"><span class="prix">{{ numberWithSpaces(artwork.price / Math.pow(10, getDecimalByAssetId(artwork.assetAccepted)))}}</span> <span class="ticker">{{ getTickerByAssetId(artwork.assetAccepted) }} </span><app-UsdPrice :artwork="artwork" color="white" /></div>
            <div class="text-right"><span class="align-self-end">{{ artwork.maxmint > 1 ? artwork.maxmint - artwork.issued+' of '+artwork.maxmint : '1 of 1'}} available</span></div>
        </div>
      </div>
        <div class="on_sale w-100" v-else-if="!artwork.onsale && (artwork.price == 0 || artwork.price == null) && artwork.maxmint == 0">
        <div class="grey_circle"></div>
        <div class="sale_infos">
          <span class="prix">NOT FOR SALE</span>
        </div>
      </div>
      <div class="on_sale w-100" v-else-if="!artwork.onsale && (artwork.price == 0 || artwork.price == null) && artwork.maxmint != 0 && artwork.maxmint != artwork.issued">
        <div class="blue_circle"></div>
        <div class="sale_infos w-100 justify-content-between">
          <div class="left"><span class="prix">OFFER ONLY</span></div>
          <div class="text-right"><span class="align-self-end">{{ artwork.maxmint > 1 ? artwork.maxmint - artwork.issued+' of '+artwork.maxmint : '1 of 1'}} available</span></div>
        </div>
      </div>
      <div class="on_sale w-100" v-else-if="!artwork.onsale && artwork.maxmint != 0">
        <div class="grey_circle"></div>
        <div class="sale_infos">
          <span class="prix">SOLD OUT</span>
        </div>
      </div>

      <div class="author_info">
        <div v-show="showArtist" class="wrap_thumb" :class="{'verified': artwork.userinfo.status=='VERIFIED'}">
          <img :src="artwork.userinfo.thumb != '' ? getIpfsLink(artwork.userinfo.thumb) : emptyThumb" class="thumbnail" />
        </div>
        <div v-show="showArtist" class="author">{{ artwork.userinfo.name }}</div>
      </div>

      <router-link :to="'/user/'+artwork.userinfo.address+'/artwork/'+artwork.txid" tag="div" class="detail" :id="'id_'+keyIndex" exact>
        <div class="date">{{ artwork.date | setDate}}</div>
        <div class="title"><strong>{{artwork.name | trim(90) }}</strong></div>
        <div class="plus">+</div>
      </router-link>
      <div class="spiner" ref="spiner" v-if="showSpiner">
        <img :src="spinerSrc" />
      </div>
      <div class="w-100">
        <app-plyr :poster="artwork.display_cid" :cid="cidToUse(artwork)" :imgix="$parent.imgix && $parent.imgix[keyIndex].url ? $parent.imgix[keyIndex].url : artwork.display_cid" @metaloaded="metaLoaded()" v-if="isVideo(artwork)"/>
        <app-image :source="artwork.display_cid ? getIpfsLink(artwork.display_cid) : emptySrc" :imgix="$parent.imgix && $parent.imgix[keyIndex].url ? $parent.imgix[keyIndex].url : artwork.display_cid" :altname="artwork.name" @load="hideSpiner(); callMSRNY()" v-else />
      </div>
      
    </div>
  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js';
//import userMixin from '@/mixins/userMixin.js';
import Plyr from '@/components/elements/Plyr.vue'
// import UsdPrice from '@/components/elements/UsdPrice.vue' 
import UsdPrice from '@/components/elements/UsdPriceApi.vue' 

import imageLoader from '@/components/content/artwork/ImageItem.vue' 

export default {
  mixins: [globalMixin],
  props: ["artwork", "keyIndex", "showArtist"],
  data: function(){
    return {
      flag: null,
      showSpiner: true 
    }
  },
  components: {
    'app-plyr': Plyr,
    'app-UsdPrice': UsdPrice,
    'app-image': imageLoader
  },
  created(){
    this.init()    
  },

  methods: {
    init: function(){
      if(this.artwork.flag == 'CONSENT'){
        this.flag = "CONSENT"
      }
    },
    showSensitive: function(){
      this.flag = null
      this.callMSRNY()
    },
    metaLoaded: function(){
      this.callMSRNY()
      this.hideSpiner()
    },
    callMSRNY: function(){
      if(window.msnry){
        window.msnry.layout()
      }
    },
    hideSpiner: function(){
      if(this.$refs.spiner){
        this.showSpiner = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .author_info{
    position: absolute;
    top: 5px;
    left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
    pointer-events: none;
    .author{
      text-shadow: 1px 1px 2px rgba(0,0,0,0.4);
      font-size: 14px;
    }
    .wrap_thumb{
      width: 20px;
      margin-right: 8px;
      height: 20px;
      display: flex;
      position: relative;
      img{
        width: 100%;
        max-width: 20px;
        border-radius: 50%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .consentDisplay{
    cursor: pointer;
  }
  .detail{
    z-index: 10!important;
   
  }
  .detail, .image__item, .plyr{

  }
  .sensitive{
    height: 300px;
    background-color:#675372;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  /* .playBtn, .muteBtn{
    position: absolute;
    z-index: 49;
    bottom: 15px;
    padding: 15px;
    cursor: pointer;
    transition: opacity .4s ease;
    opacity: .7;
    color: white;
    &:hover{
      opacity: 1;
    }
  }
  .playBtn{
    right:45px;
    padding-right: 10px;
  }
  .muteBtn{
    right: 0px;
    font-size: 20px;
    padding-bottom: 10px;
    padding-left: 10px;
  }
  .fa-volume-off{
    margin-right: 5px;
  } */
  .on_sale{
    position: absolute;
    bottom: 5px;
    display: flex;
    align-items: center;
    z-index: 11;
    min-height: 22px;
    padding: 0 10px;
    pointer-events: none;
    .green_circle, .grey_circle, .blue_circle{
      background-color: #F200BD;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
    .grey_circle{
      background-color: #d3d3d3;
    }
    .blue_circle{
      background-color: #0085FF;
    }
    .sale_infos{
      padding-left: 5px;
      display: none;
      font-size: 13px;
    }
  }
  .grid-item{
    min-height:120px;
    &:hover{
      .sale_infos{
        display: flex;
      }
    }
  }
  .wrap_item{
    max-height: 700px;
    min-height: 120px;
    @media(max-width:768px){
      max-height: 480px;
    }
    overflow-y: hidden;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .wrap_thumb{
    position: relative;
    &.verified:before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      //content: "";
      position: absolute;
      top: 0;
      right: -8px;
      font-size: 10px;
      color: #7F00FF;
      background-color: white;
      border-radius: 50%;
      top: 0;
      line-height: 8px;
      width: 8px;
      top: 2px;
    }
  }

</style>