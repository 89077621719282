<template>
  <div id="collection">
      
      <app-filters-marketplace 
        :showArtistStatus="true" 
        :filterDecimal="filterDecimal"
        :artistStatus="artistStatus"
        @resetListing="resetListing"
        @getAllNFT2ndMarket="getAllNFT2ndMarket">
      </app-filters-marketplace>
     
      <div v-if="loading" class="loading">
        <app-loading :label="'data'"></app-loading>
      </div>
      <div id="collectionList" class="form-group" v-else>
        
        <div class="row mx">
          <div class="col-lg-4 col-xl-3 col-xxl-3 col-md-6 mb-3 px-2" v-for="(nft, index) in allNFT" :key="nft.assetId+'_'+index" :id="'id_'+index" @mousedown="storeArtworkClickedIndex($event)">
            <app-owned
              :nftInfo="nft"
              :index="index"
              @removeNFT="removeNFTfromChild"
              ></app-owned>
          </div>
        </div>

        <div class="wrap_pagination d-flex justify-content-center mt-2">
          <button class="btn btn-primary mb-2 bigger loadNFT loadprev mr-3"  v-if="this.$route.params.pageindex && this.$route.params.pageindex > 1" @click="scrollToId = false; prevNext('prev')">PREVIOUS</button>
          <button class="btn btn-primary mb-2 bigger loadNFT loadnext" v-if="allNFT.length == perpage" @click="scrollToId = false; prevNext('next')">NEXT</button>
        </div>
        <div v-if="allNFT.length < perpage && allNFT.length > 0" class="text-center nft-result">All collectibles loaded</div>
        <div v-if="allNFT.length == 0 && $store.state.filtering.marketplace.statusArtist=='all' && !loading" class="text-center nft-result">No collectible found</div>
        <div v-if="allNFT.length == 0 && $store.state.filtering.marketplace.statusArtist=='verified' && !loading" class="noNFT" @click="switchArtistStatus">Nothing found.<br/><span>Try search among non approved artist status?</span></div>
      </div>

  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js';
import Loading from '@/components/elements/Loading.vue'
/* import Nft from '@/components/content/collection/userNft.vue' */
import Nft from '@/components/content/owned/userNftFront.vue'
import FiltersMarketplace from '@/components/elements/FiltersMarketplace.vue'

export default {
  mixins: [globalMixin],
  data: function(){
    return {
      loading: true,
      /*loadMore: true,
       loadnext: false,
      loadprev: false, */
      scrollToId: true,
      allNFT: [],
      perpage: 30,
      currentPage: 1,
      filterDecimal: 8,
      artistStatus: null
    }
  },
  components: {
    'app-owned': Nft,
    'app-loading': Loading,
    'app-filters-marketplace': FiltersMarketplace,
  },
  created: function(){
    this.currentPage = this.$route.params.pageindex
    this.init()
  },

  methods: {
    init: async function(){
      await this.getAllNFT2ndMarket()
      this.$parent.showMenuTop = false
      
    },
    switchArtistStatus: function(){
      this.artistStatus = null
      this.artistStatus = "all"
    },
    prevNext: function(direction){
      let query = { search: this.$store.state.searchStr }
      query.status = this.$route.query.status || this.$store.state.filtering.marketplace.statusArtist
      let index
      if(direction == "prev"){
        index = +Number(this.$route.params.pageindex)-1
      }else{
        index = +Number(this.$route.params.pageindex)+1
        
      }
      this.$router.push({ path: '/marketplace/'+index, query: query }).catch(()=>{});
    },
    resetListing: function(){
      this.loading = true
      // this.loadMore = true
      /* this.loadnext = false
      this.loadprev = false */
      this.allNFT = []
      this.currentPage = 1
    },
    storeArtworkClickedIndex: function(e){
      this.$store.state.activeMarket = "marketplace"
      this.$store.state.marketplaceScrollState.scrollToId = e.currentTarget.id
    },
    getAllNFT2ndMarket: async function(artistAddr=null, showBanned=false){
      // this.loadMore = false
      /* this.loadnext = false
      this.loadprev = false */
      let decimal = this.getDecimalByAssetId(this.$store.state.filtering.marketplace.currency)
      let filtered = {
          "page": this.currentPage,
          "perpage": this.perpage,
          "statusArtist": this.$store.state.filtering.marketplace.statusArtist,
          "status": this.$store.state.filtering.marketplace.status,
          "stringSearch": this.$store.state.searchStr,
          "assetAccepted": this.$store.state.filtering.marketplace.currency,
          "creator_address": artistAddr,
          "onsale": this.$store.state.filtering.marketplace.onsale,
          "showBanned": showBanned,
          "sort": this.$store.state.filtering.marketplace.sort,
          "fromRange": this.$store.state.filtering.marketplace.fromRange * Math.pow(10, decimal),
          "toRange": this.$store.state.filtering.marketplace.toRange * Math.pow(10, decimal),
          "crosschainOnly": this.$store.state.filtering.marketplace.crosschainOnly
        }

      try{
        let getArtworks = await fetch(
          this.$store.state.config.signArtApiURL + "/secondary/paginate-filter",
          {
            method: 'post',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
              filtered
            )
          }
        )
      getArtworks = await getArtworks.json()
      let nftsIdStr = ""
      for(const info in getArtworks){
        if(nftsIdStr!=""){
          nftsIdStr+= "&"
        }
        nftsIdStr += "id="+getArtworks[info].secondaryinfo.nft_id
      }

      let getNftInfos = await fetch(this.$store.state.config.nodeURL + "/assets/details?"+nftsIdStr)
      getNftInfos = await getNftInfos.json()
      for(const nftInfo in getNftInfos){
        getArtworks[nftInfo].nft = getNftInfos[nftInfo]
      }

      if(getArtworks.length){
        this.allNFT.push(...getArtworks.map(d => ({
          forsale: true, 
          assetId: d.secondaryinfo.nft_id, 
          issueTimestamp: d.date, 
          art_id: d.secondaryinfo.artwork_id, 
          creator_addr: d.secondaryinfo.creator_addr, 
          price: d.secondaryinfo.price, 
          asset: d.secondaryinfo.price_asset, 
          owner: d.secondaryinfo.seller,
          added_date: d.secondaryinfo.date,
          nft: d.nft,
          primary: d,
          secondary: d.secondaryinfo
        })))
      }
      this.currentPage++
      this.loading = false
      /* if(getArtworks.length < this.perpage){
        // this.loadMore = false
        this.loadnext = false
        this.loadprev = false
      }else{
        this.loadnext = true
        if(this.currentPage > 1){
          this.loadprev = true
        }
      } */
     
     
      }catch(e){
        console.log(e)
      }

    },
    removeNFTfromChild: function(issueId){
      var removeIndex = this.allNFT.map(function(item) { return item.assetId; }).indexOf(issueId);
      this.allNFT.splice(removeIndex, 1);
    },
    /*scrollLoad: async function(){

        let bottomOfWindow = Math.round(document.getElementById("content").scrollTop + window.innerHeight) >= (document.querySelector(".grid").offsetHeight - 50);
        if (bottomOfWindow) {
            this.loadprev = true
            this.loadnext = true
        }
      
    } ,
    reset: function(){
      document.getElementById('content').scrollTop = 0
      if(this.scrollToId){
        document.getElementById("content").addEventListener('scroll', this.scrollLoad, false)
        if(document.getElementById(this.$store.state.marketplaceScrollState.scrollToId)){
          document.getElementById(this.$store.state.marketplaceScrollState.scrollToId).scrollIntoView()
        }
      }
    } */
  },
  watch: {
    loading: function(){
      let _this = this
      this.$nextTick(() => {
          setTimeout(function(){
            if(document.getElementById(_this.$store.state.marketplaceScrollState.scrollToId) && _this.$store.state.marketplaceScrollState.scrollToId != 0 && _this.scrollToId){
              document.getElementById(_this.$store.state.marketplaceScrollState.scrollToId).scrollIntoView()
              _this.$store.state.marketplaceScrollState.scrollToId = 0
              _this.scrollToId = false
            }
          }, 300)
        })
    }
  }
};
</script>
<style lang="scss" scoped>
.noNFT{
  text-align: center;
  padding-bottom: 15px;
  cursor: pointer;
  padding-top: 0px;
  color: black;
  span{
    color: #ca26c0;
    text-decoration: underline;
  }
}
.loadNFT{
  margin: inherit;
}
</style>