<template>

    <router-link :to="url" tag="a" exact class="w-100 h-100 d-flex flex-column"  >
      <div class="wrap_cover">
        <img :src="getIpfsLink(collection.cover)" class="cover d-block img-fluid w-100"/>
      </div>
      <div class="wrap_detail">
        <div class="creator">
            <img :src="getIpfsLink(collection.userinfo.thumb)" />
            <h4>{{collection.userinfo.name | trim(30)}}</h4>
          </div>
        <h3 class="mb-1">{{collection.name}}</h3>
        <div class="date">{{collection.date | setDate}} - ({{ collection.item_count }} {{ collection.item_count > 1 ? 'items' : 'item'}})</div>
        <div class="mt-2 description">{{collection.description | trim(40)}}</div>
      </div>
    </router-link>

</template>

<script>
import globalMixin from '@/mixins/global.js';
export default {
  mixins: [globalMixin],
  props: ["collection"],
  data: function(){
    return {

    }
  },
  methods: {
  },
  computed: {
    url: function(){
      let url
      if(this.$route.name == "user-artworks-collections"){
        url = '/user/'+this.$store.state.user.userAddress+'/account/artworks/collections/'+this.collection.id+'/page/1'
      }
      if(this.$route.name == "user-collections"){
        url = '/user/'+this.$route.params.addr+'/collection/'+this.collection.id+'/page/1'
      }
      if(this.$route.name == "home-collections"){
        url = '/user/'+this.collection.artist_addr+'/collection/'+this.collection.id+'/page/1'
      }
      return url
    }
  }
}
</script>
<style lang="scss" scopped>
.creator {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  h4{
    font-size: 14px;
    margin-bottom: 0;
    margin-left: 5px;
    color:#606060;
  }
  img{
    width:25px;
    height: 25px;
    border-radius: 50%;
  }
}
.wrap_detail{
  border-radius: 0 0 15px 15px;
  border: solid 1px #ccc;
  padding: 15px 20px;
  flex:1;
}
.wrap_cover{
  border-radius: 15px 15px 0 0;
  background-color: #f9f9f9;
  overflow: hidden;
  border: solid 1px #ccc;
  border-bottom: 0;
  img.cover{
    transition: all .4s ease;
    height: 300px;
    max-height: inherit;
    &:hover{
      transform: scale(1.03);
    }
  }
}
a .description{
  color: rgb(43, 43, 43);
}
.wrap_data{
  h3{
    font-size: 22px!important;
    color: #1E1A1A!important;
    font-weight: 500!important;
  }
  .date{
    color: #aaa;
    font-size: 14px;
  }
  .description{
    font-size: 15px;
  }
}

</style>