<template>
  <div class="col-md-8 col-lg-8 col-xl-10 px-1 userPage wrap" id="content">
    <div class="wrap_email_message">
      <div>
        <p>To manage your notifications please go to your account.</p>
        <button @click="chooseSigningMethod">{{ this.$store.state.user.isLogged ? 'GO TO ACCOUNT' : 'CONNECT' }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import gotoMixin from '@/mixins/goto.js';
export default {
  mixins: [gotoMixin],
  data: function(){
    return {}
  },

  created(){
    this.init()
  },

  methods: {
    init: function(){},
    chooseSigningMethod(){
       if(this.$store.state.user.isLogged){
         this.goToAccount()
       }else{
         this.$store.state.signingChoice.showChoice = true
       }
    }
  }
}
</script>
<style lang="scss" scoped>
  .wrap_email_message{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    div{
      padding: 20px;
      color: white;
      background-color: #57137b;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 5px;
    }
  }
</style>