<template>
  <div id="add_to_collection" @click="detectClickOutOfAddCollection">
    <div class="wrapper" >

      <div v-if="loading">Loading collections list...</div>
      <div v-else class="content">
        <div class="mb-3">Select a collection:</div>
        <select class="select-css" v-model="selectedCollection">
          <option value="-1" selected>No collection...</option>
          <option v-for="(collection, index) in collections" 
            :key="collection.id+'_'+index" 
            :id="'id_'+index" 
            :value="collection.id">
          {{ collection.name }}
          </option>

        </select>
        <div class="mt-2 mb-2" v-if="alert!=''">{{alert}}</div>
        <button class="mt-3 d-block py-2" @click="actionItem()">CONFIRM</button>
      </div>
    </div>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js';
import session from '@/mixins/session.js';
import collections from '@/mixins/collections.js';

export default {
  mixins: [globalMixin, session, collections],
  props: ["art_id"],
  data: function(){
    return {
      loading: true,
      collections: {},
      itemIdInCollection: null,
      selectedCollection: -1, 
      alert: ""
    }
  },
  async created(){
    await this.getCurrentCollection()
    this.init()
  },
  methods: {
    init: async function(){
      await this.loginSession()
      let collections = await this.getCollections(this.$store.state.user.userAddress)
      this.collections = collections
      // CHECK CURRENT COLLECTION FOR THIS ARTWORK
      // DECIDE IF CAN BE IN MULTIPLE COLLECTIONS OR NOT
      // PROBABLY NOT AT FIRST
      this.loading = false
    },
    actionItem: async function(){
      this.alert = ""
      this.$store.state.alert=true
      this.$store.state.alertMessage='Adding to collection...'
      let action = await this.addRemoveItem(this.selectedCollection, this.art_id, this.itemIdInCollection)
      this.$store.state.alert=false
      this.$store.state.alertMessage=''
      if(!action.error){
        this.alert = "Added to collection!"
        this.$parent.showAddToCollection = false
        this.loading = true
      }else{
        this.alert = action.message
      }
    },
    getCurrentCollection: async function(){
        let currentCollection = await this.getCollectionItem(this.$store.state.user.userAddress, this.art_id)
        if(currentCollection[0]){
          this.selectedCollection = currentCollection[0].collection_id
          this.itemIdInCollection = currentCollection[0].id
        }
    },
    
    detectClickOutOfAddCollection: function(e){  
      if (!document.getElementById('add_to_collection').querySelector(".wrapper").contains(e.target)){
        this.$parent.showAddToCollection = false
        this.loading = true
      }
    },
  }
}
</script>
<style lang="scss" scoped>
  #add_to_collection{
    position: fixed;
    cursor: pointer;
    top: 0;
    z-index: 50;
    left: 0;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    min-height: 100%;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .wrapper{
      color: black;
      background-color: white;
      padding: 20px;  
      text-align: center;
      width: 100%;
      max-width: 300px;
      .content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        .select-css{
          margin-right: 0!important;
        }
      }
    }
  }
</style>