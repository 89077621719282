<template>
  <transition name="slide-fade" :duration="3000">
    <div id="promotional" v-show="alert && promoActive">
      <img src="../../assets/images/promotion/sign_inda_logos.jpg" class="mt-3"/>
      <div class="buy_with_card">
        <span>Buy <span class="bold">$SIGN</span> Token with </span>
        <img src="../../assets/images/promotion/cards_logos.jpg" />
      </div>
      <div class="row w-100 wrapper_flex">
        <div class="col-lg-6 h-100 d-flex align-items-center justify-content-center">
          <ul class="text-center">
            <li class="mb-3"><a href="https://buy.sign.indacoin.io" class="btn" target="_blank">Buy on Indacoin!</a></li>
            <li class="telegram">Or via telegram: <a href="https://t.me/SignatureChain_bot" target="_blank">@SignatureChain_bot</a></li>
          </ul>
        </div>
        <div class="col-lg-6"></div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data: function(){
    return {
      promoActive: true,
      alert: false
    }
  },
  created(){
    this.alert=true
  }
}
</script>
<style lang="scss" scoped>
  #promotional{
    max-width: 768px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.92);
    padding: 10px 0;
    z-index: 1000;
    transition: all 0.4s ease;
    min-height: 420px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    background-image: url('../../assets/images/promotion/cards.jpg');
    background-color: #ccc3d1;
    background-position: right bottom;
    background-repeat: no-repeat;
    margin: 5px 0 0 0;
    border-radius: 0 0 15px 15px;
    &.slide-fade-enter-active {
      transition: all .3s ease;
    }
    &.slide-fade-leave-active {
      transition: all .6s ease;
    }

    &.slide-fade-enter-to{
      opacity: 1;
      top: 40px;
    }
    &.slide-fade-leave-to{
      opacity: 0;
      top: 0px;
    }

    .buy_with_card{
      margin: 30px 0 0 0;
      padding: 10px 25px;
      background-color: white;
      width: 100%;
      text-align: center;
      color: #545454;
      font-size: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        margin-left: 10px;
      }
    }

    .wrapper_flex{
      flex:1;
      align-items: center;
    }
    .bold{
      font-weight: bold;
    }

    ul{
      padding: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      li{
        font-size: 22px;
        color: #3f3f3f;
        .btn{
          padding: 5px 25px;
          font-size: 18px;
          &:hover{
            color: white;
          }
        }
        &.telegram{
          font-size: 16px;
          a{
            display: block;
            font-weight: bold;
            color: #3f3f3f;
            &:hover{
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  @media(max-width: 992px){
    #promotional .buy_with_card{
      flex-direction: column;
      font-size: 22px;
      
    }
    #promotional{
      background-image: none;
      min-height: 325px;
    }
    img{
      margin-left: 0;
    }
  }
</style>