<template>
  <div class="col-md-8 col-lg-8 col-xl-12 px-1 art_detail_wrap wrap" id="content">
    <div v-if="artwork && artwork.art_flag == 'ILLEGAL'" class="suspended">
      <div class="wrap">ILLEGAL CONTENT<br/> {{ artwork.art_note }}</div>
    </div>
    <div v-else id="user_page">
     
      <app-bid :artwork="artwork" :showBid="showBid" :currentHighestBid="currentHighestBid" v-if="showBid"></app-bid>
      <app-bid-actions :bidData="bidData" :showbidData="showbidData" v-if="showbidData"></app-bid-actions>
      <transition name="fade">
        <div id="qrCode" v-show="showQrcode"><img src="" /></div>
      </transition>
      <!-- <app-user-infos :isBanned="isBanned"  :artistInfos="artistInfos" :infosLoaded="infosLoaded" :userExist="userExist" :hasProfile="hasProfile" :userNote="userNote"></app-user-infos> -->
      <div class="container-fluid" id="art_detail">
        <div v-if="artwork==undefined" class="loading">
          <app-loading :label="'data'"></app-loading>
        </div>
        <div class="row" v-else>
          <div class="col-lg-12 col-xl-6" id="visuel"  :data-display="artwork.art_display_cid" :data-export="artwork.art_export_cid">
            <div id="extend" @click="imageFitScreen" v-if="!isVideo(artwork, 'export')" v-b-tooltip.hover.bottom title="See in High Definition"><i class="fa fa-expand" aria-hidden="true"></i></div>
            <img :src="spinerSrc" class="spiner" v-show="spinerShow" ref="spiner"/>
            <vue-plyr v-if="isVideo(artwork, 'export')" ref="plyr">
              <video
                crossorigin
                playsinline
                controls
                muted
                autoplay
                loop
                @loadedmetadata="hideSpiner();"
              >
                <source
                  :src="getIpfsLink(cidToUse(artwork, 'export'))"
                  type="video/mp4"
                />
              </video>
            </vue-plyr>
            <img ref="mainDisplay"
              :src="getIpfsLink(cidToUse(artwork, 'export')) || emptySrc" 
              @load="imgLoaded"
              class="img-fluid w-100 d-block main"  @error="replaceByDefaultImg" v-else/>
          </div>
          <div class="col-lg-12 col-xl-6 pr-lg-3 pr-xl-5 pt-3 pt-lg-0">
            <div id="wrapper_detail_infos">
              <h3 class="detailTitle">
                {{ artwork.art_name }} 
                
                <router-link :to="'/user/'+artistInfos.user_addr+'/account/artworks/list/'+txid" exact v-show="artistInfos.user_addr == $store.state.user.userAddress" class="edit" v-b-tooltip.hover.top title="Edit this artwork">Edit</router-link> 
                <router-link :to="'/user/'+artistInfos.user_addr" exact @click.native="resetArtistScroll();"><img src="../../assets/images/back_list_icon.svg" v-b-tooltip.hover.right title="Back to artist page" class="list_icon"/></router-link>
              </h3>
              <div class="added">
                <span>Added on: {{ artwork.art_date | setDate }}</span> 
                <div class="wrap_share d-flex align-items-center position-relative ml-3">
                  <div class="copied" @click="copyShortLink"></div>
                  <span class="mr-3">Share:</span>
                  <div class="cursor-pointer mr-3" @click="showQrcode = true" v-b-tooltip.hover.top title="Show QR Code"><img src="../../assets/images/qr_code_icon.svg" class="pointer-event-none"/></div>
                  <div class="cursor-pointer mr-3" :data-link="currentURL" @click="copyShortLink" v-b-tooltip.hover.top title="Generate short link"><img src="../../assets/images/copy_icon.svg" class="pointer-event-none"/></div>
                  
                  <ShareNetwork
                    network="twitter"
                    :url="currentURL"
                    :title="artwork.art_name +' via @signArtApp - '"
                    :hashtags="getTagString()"
                    class="mr-3"
                  >
                    <i class="fab fa-twitter"
                    v-b-tooltip.hover.top 
                    title="on Twitter"></i>
                </ShareNetwork>
                <ShareNetwork
                    network="facebook"
                    :url="currentURL"
                    :title="artwork.art_name"
                    :quote="artwork.art_name"
                    :hashtags="getTagString()"
                    class="mr-3"
                  >
                  <i class="fab fa-facebook-f"
                  v-b-tooltip.hover.top 
                  title="on Facebook"
                  ></i>
                </ShareNetwork>
                <ShareNetwork
                    network="telegram"
                    :url="currentURL"
                    :title="artwork.art_name +' via @SIGNArtApp - '"
                    :hashtags="getTagString()"
                    class="mr-3"
                  >
                    <i class="fab fa-telegram-plane"
                    v-b-tooltip.hover.top 
                    title="on Telegram"
                    ></i>
                </ShareNetwork>
                </div>
              </div>
              <ul id="tags" v-show="taglist.length > 0">
                <li v-for="(tag, index) in taglist" :key="index" @click="clickTag(tag)">{{ tag }}</li>
              </ul>
              <h4 class="creation-detail">Details:</h4>
              <div class="description" v-html="$sanitize(artwork.art_desc)" v-linkified:options="{ className: 's-link' }">
                
              </div>
              <div class="spacer" v-show="artwork.art_flag == 'FLAGGED'"></div>
              <div id="flagged" v-show="artwork.art_flag == 'FLAGGED'">
                Status: {{ artwork.art_flag }}<br/>Reason: {{ artwork.art_note }}
              </div>
              <div class="spacer"></div>

              <ul id="extra">
                <li><span>Artwork type:</span> {{ artwork.art_type }}</li>
                <li>
                  <ul class="wrap_sold_max d-flex justify-content-between mt-2">
                    <li><span>Total sold:</span> {{ artwork.art_issued }} of {{ artwork.art_maxmint }} {{ editionMessage() }}</li>
                    <li><span>Maximum for sale:</span> {{ artwork.art_maxmint == 0 ? 'Not for sale' : artwork.art_maxmint }}</li>
                    <li v-if="artwork.art_royalties"><span>Artist royalty:</span> {{ setRoyaltiesBase10With2Decimals(artwork.art_royalties) }}% <span v-b-tooltip.hover.top :title="`When reselling the NFT on the secondary market, a commission of ${setRoyaltiesBase10With2Decimals(artwork.art_royalties)}% is applied for the artist as a royalty. A 3% fee is also applied for SIGN Art as commission; -${(setRoyaltiesBase10With2Decimals(artwork.art_royalties)) + 3}%`"><i class="fas fa-question-circle pl-1 pr-1"></i></span></li>
                    <li v-else><span>Royalty:</span> 10% <span v-b-tooltip.hover.top :title="`When reselling the NFT on the secondary market, a commission of 10% is applied for the artist as a royalty. A 3% fee is also applied for SIGN Art as commission; -13%`"><i class="fas fa-question-circle pl-1 pr-1"></i></span></li>
                  </ul>
                </li>
                <li v-show="artwork.art_price && artwork.art_price != 0 && artwork.art_maxmint != 0">
                  <ul class="wrap_price d-flex justify-content-start align-items-xl-start align-items-md-center">
                    <li v-show="artwork.art_price && artwork.art_price != 0 && artwork.art_maxmint != 0" class="price"><span>Price:</span> {{ artwork.art_price / Math.pow(10, decimal) ? numberWithSpaces(artwork.art_price / Math.pow(10, decimal)) : 0 }} {{ getTickerByAssetId(artwork.art_assetAccepted)}} <app-UsdPrice :artwork="artwork" /></li>
                  </ul>
                </li>
              </ul> 
              <div id="response" :class="{error: haveError}" v-show="responseMessage !=''" class="container w-100 mx-auto text-center mt-3">
                <div class="wrap">{{responseMessage}} <span v-if="buyerAddress != ''">- <router-link :to="'/user/'+buyerAddress+'/account/owned'" exact class="seeCollection">See your owned NFTs</router-link></span></div>
              </div>
                                   
              <div id="offerTimer"  v-if="timerStrBlock!=''">
                <div class="spacer mb-3"></div>
                <strong>End in:</strong> <strong class="purple"> {{ timerStrBlock }}</strong> <span class="tooltip_wrap" v-b-tooltip.hover.top title="Time on the blockchain is calculated in blocks with an average time of 1 block = 1 minute. Human time can only be an estimation."><i class="fas fa-question-circle pl-1 pr-1"></i></span>
                <strong class="estimatedTime" v-if="timerStr!=''">Estimated: {{timerStr}}</strong>
                <div class="spacer mt-3"></div>
              </div>

              <ul class="links">
                <li class="buyBtn" v-show="artwork.art_onsale && artwork.art_flag != 'ILLEGAL' && artwork.art_flag != 'CHANGE_REQUIRED' && artistInfos.user_status != 'SUSPENDED'"><a href="#" @click.prevent="chooseSigningMethod('buy')" class="d-flex align-items-center"><img src="../../assets/images/dollar_icon.svg" class="mr-1"/> Buy this NFT</a></li>
                <li class="bidBtn" v-show="artwork.art_maxmint != 0 && artwork.art_issued < artwork.art_maxmint && artwork.art_flag != 'ILLEGAL' && artwork.art_flag != 'CHANGE_REQUIRED' && saleEnded == false  && artistInfos.user_status != 'SUSPENDED'"><a href="#" @click.prevent="chooseSigningMethod('bid')" class="d-flex align-items-center"><img src="../../assets/images/dollar_icon.svg"  class="mr-1"/> Make an offer</a></li>
                <li v-show="artwork.art_export_cid != ''"><a :href="getIpfsLink(artwork.art_export_cid)" target="_blank" class="d-flex align-items-center"><img src="../../assets/images/download_icon.svg" class="mr-2"/> Download</a></li>
                <li><a :href="$store.state.config.signwebappUrl + '/explorer/' + artwork.art_signid" target="_blank" class="d-flex align-items-center"><img src="../../assets/images/certificate_icon.svg" class="mr-2"/> Certificate</a></li>
                <li v-show="artwork.art_licence_cid != ''"><a :href="getIpfsLink(artwork.art_licence_cid)" target="_blank" class="d-flex align-items-center"><img src="../../assets/images/licence_icon.svg" class="mr-2"/> License</a></li>
                <li v-show="artwork.art_display_cid != ''"><a :href="'https://tineye.com/search/?url='+getIpfsLink(artwork.art_display_cid)" target="_blank" class="d-flex align-items-center" v-b-tooltip.hover.top title="Check this artwork on the Tineye image recognition tool."><img src="../../assets/images/tineye_icon.svg" class="mr-2"/> Tineye *</a></li>
                <li v-show="artwork.art_display_cid != ''">
                  <a :href="isVideo(artwork, 'display') ? 'https://yandex.com/images/search?url='+getIpfsLink(artwork.art_crawler_cid)+'&rpt=imagelike' : 'https://yandex.com/images/search?url='+getIpfsLink(artwork.art_display_cid)+'&rpt=imagelike'" target="_blank" class="d-flex align-items-center" v-b-tooltip.hover.top title="Check this artwork on the Yandex image recognition tool."><i class="fab fa-yandex-international mr-2"></i> Yandex *</a>
                </li>            
                <li v-if="artwork.art_issued > 0">
                  <router-link :to="'/marketplace/1?search='+txid+'&status='+(artistInfos.user_status == 'VERIFIED' ? 'verified' : 'all')" exact tag="a" class="d-flex align-items-center"><img src="../../assets/images/find_reseller_icon.svg" class="mr-2"/>Find resellers</router-link> 
                </li>
              </ul>
              <div class="mention cursor-pointer d-inline" v-b-tooltip.hover.top title="Tineye and Yandex are image recognition tool. It helps collectors to detect if the artwork uses any existing online content. Most unique creations should have no search results on Tineye and/ or Yandex unless it uses a copyright free image as a base. When in doubt, please ask the link to the original image license from the artist before buying.">
                * What are Tineye and Yandex?
              </div>
              <div class="change_required" v-if="artwork.art_flag == 'CHANGE_REQUIRED'">
                  This artwork requires modification or is under review, it cannot be sold at the moment.
              </div>
              <app-user-infos-lite :isBanned="isBanned"  :artistInfos="artistInfos" :infosLoaded="infosLoaded" :userExist="userExist" :hasProfile="hasProfile" :userNote="userNote" :collection="collection"></app-user-infos-lite>
            </div>
          </div>
        </div>
        
      </div>

      <div id="art_tx" v-touch:start="disableSwipe" v-touch:end="enableSwipe">

        <b-card no-body>
          <b-tabs card>

            <b-tab title="Offers" active>
              <b-card-text>
                <div id="currentowner">
                  <table class="table table-striped" v-if="offersList!=undefined">
                    <thead>
                      <tr>
                        <td>DATE</td>
                        <td>OFFER FROM</td>
                        <td>OFFERED PRICE</td>
                        <td>STATUS</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="offersList.length == 0 ">
                        <td colspan="4" v-if="bidsLoading">Checking offers...</td>
                        <td colspan="4" v-else>No offer(s) found.</td>
                      </tr>
                      <tr v-for="(offer, index) in offersList" :key="index" v-else>
                        <td>{{ offer.date | setDate }} <span class="offerTime">at {{ offer.date | setTime }}</span></td>
                        <td>
                          <router-link :to="'/user/'+offer.bidOwner" exact v-if="offer.bidOwner != undefined">{{ shortTxAddr(offer.bidOwner) }}</router-link>
                          <span v-else>Checking address...</span>
                        </td>
                        <td><strong>{{ numberWithSpaces(offer.price) }} {{ offer.assetId }}</strong> <!-- <span class="usdValue">({{numberWithSpaces(offer.priceInUsd.toFixed(2))+"$"}})</span> --><app-UsdPrice :offer="offer" :color="'grey'"/></td>
                        <td>
                         
                          <div v-if="offer.status == 'OPEN' && offer.owner == $store.state.user.userAddress && artwork.art_maxmint != 0 && artwork.art_flag != 'ILLEGAL'">
                            <button class="acceptOffer mr-2" :data-artId="offer.artId" :data-saleOwner="offer.owner" :data-assetId="offer.assetId" :data-basePrice="offer.basePrice" :data-bidid="offer.bidid" :data-bidOwner="offer.bidOwner" @click.prevent="bidAction($event, 'accept', 'primary', '')" v-if="artwork.art_issued < artwork.art_maxmint">ACCEPT</button>
                            <button class="rejectOffer mr-2" :data-artId="offer.artId" :data-saleOwner="offer.owner" :data-assetId="offer.assetId" :data-basePrice="offer.basePrice" :data-bidid="offer.bidid" :data-bidOwner="offer.bidOwner" @click.prevent="bidAction($event, 'cancel', 'primary', '')">REJECT</button>
                          </div>
                          <div v-else-if="offer.status == 'OPEN' && offer.bidOwner == $store.state.user.userAddress">
                            <button class="cancelOffer" :data-artId="offer.artId" :data-saleOwner="offer.owner" :data-assetId="offer.assetId" :data-basePrice="offer.basePrice" :data-bidid="offer.bidid" :data-bidOwner="offer.bidOwner" @click.prevent="bidAction($event, 'cancel', 'primary', '')">CANCEL</button>
                          </div>
                           <div v-else v-html="setStatus(offer.status)" class="offerStatus"></div>
                        </td>
                      </tr> 
                    </tbody>
                  </table>
                </div>
              </b-card-text>
            </b-tab>

            <b-tab title="Transactions">
              <b-card-text>
                <div id="txhistory">
                  <table class="table table-striped" v-if="artwork!=undefined">
                    <thead>
                      <tr>
                        <td>DATE</td>
                        <td>TYPE</td>
                        <td>TRANSACTION ID</td>
                        <td>TO</td>
                        <td>PRICE</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(tx, index) in tokenHistory" :key="index">
                        <td>{{tx.issueDate | setDate}}</td>
                        <td>Issued NFT {{tx.issueNumber}} of {{tx.maxIssuable}} </td>
                        <td><a :href="getExplorerUrl()+'/tx/'+tx.issueId" target="_blank">{{shortTxAddr(tx.issueId)}}</a></td>
                        <td><router-link :to="'/user/'+tx.buyer" exact>{{shortTxAddr(tx.buyer)}}</router-link></td>
                        <td>{{ numberWithSpaces(tx.issuePrice) }} {{ tx.ticker }}</td>
                      </tr>
                      <tr>
                        <td>{{ artwork.art_date | setDate }}</td>
                        <td>Artwork Added</td>
                        <td><a :href="getExplorerUrl()+'/tx/'+$route.params.txid" target="_blank">{{shortTxAddr($route.params.txid)}}</a></td>
                        <td>SIGN Art dApp</td>
                        <td></td>
                      </tr>    
                    </tbody>
                  </table>
                </div>
              </b-card-text>
            </b-tab>

            <b-tab title="Owners" button-id="ownerTab" ref="ownerTab" @click="getOwnersList()">
              <b-card-text>
                <div id="currentowner">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <td class="min150">FOR SALE</td>
                        <td>OWNER ADDRESS</td>
                        <td class="min100">NFT ISSUE #</td>
                        <td>NFT ID</td>
                      </tr>
                    </thead>
                    <tbody>
                      
                      <tr v-if="nftIssuedList && nftIssuedList.length == 0">
                        <td colspan="4">No owner(s) found.</td>
                      </tr>
                      <tr v-for="(owner, index) in nftIssuedListDisplay" :key="index" v-else v-show="owner.hasOwnProperty('currentOwner')">
                        <td>
                          <div v-if="owner.saleDetail.price">
                            <router-link :to="'/user/'+$route.params.addr+'/artwork/'+$route.params.txid+'/edition/'+owner.issueNumber" class="secondaryBtn">{{ numberWithSpaces(owner.saleDetail.price) }} {{ owner.saleDetail.ticker }}</router-link>
                          </div>
                          <div v-else>
                            <router-link :to="'/user/'+$route.params.addr+'/artwork/'+$route.params.txid+'/edition/'+owner.issueNumber">Not for sale</router-link>
                          </div>
                        </td>
                        <td>
                          <router-link :to="'/user/'+getOwnerAddress(owner)" v-if="owner.currentOwner != undefined">{{ shortTxAddr(getOwnerAddress(owner)) }}</router-link>
                          <span v-else>{{ owner.isBurn ? 'NFT burned' : 'Waiting for confirmation...' }}</span>
                        </td>
                        <td>{{ owner.issueNumber }} of {{ owner.maxIssuable }}</td>
                        <td><a :href="getExplorerUrl()+'/assets/'+owner.issuedNftId" target="_blank">{{ shortTxAddr(owner.issuedNftId) }}</a></td>
                      </tr> 
                      <tr v-if="checkingOwner">
                        <td colspan="4">Checking data...</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <button class="btn btn-primary mb-4 bigger loadNFT" @click="loadMoreOwner" v-if="!allOwnerLoaded && nftIssuedListDisplay != undefined && !checkingOwner">LOAD MORE</button>
              </b-card-text>
            </b-tab>

          </b-tabs>
        </b-card>

      </div>
    </div>
  </div>
</template>

<script>
/* import UserInfos from '@/components/elements/UserInfos.vue' */
import UserInfosLite from '@/components/elements/UserInfosLite.vue'
import globalMixin from '@/mixins/global.js';
import userMixin from '@/mixins/userMixin.js';
import orderedArtworks from "@/mixins/orderedArtworks.js"
//import { mapState } from 'vuex';
import Loading from '@/components/elements/Loading.vue'
import BidWindow from '@/components/content/bid/BidWindow.vue'
import bidActions from '@/components/content/bid/bidActions.vue' 
import UsdPrice from '@/components/elements/UsdPriceApi.vue' 
import offerMixin from '@/mixins/offerMixin.js';
import QRCode from 'qrcode'
import encodeUrl from 'encodeurl';
import collections from '@/mixins/collections.js';

export default {
  mixins: [globalMixin, userMixin, orderedArtworks, offerMixin, collections],
  data: function(){
    return {
      addr: this.$route.params.addr,
      txid: this.$route.params.txid,
      spinerShow: true,
      detailOpen: false,
      artwork: undefined,
      taglist: undefined,
      tokenHistory: undefined,
      haveError: false,
      responseMessage: '',
      buyerAddress: '',
      feeCost: this.$store.state.sponsoredFee * 5, 
      feeTicker: 'SIGN',
      nftIssuedList: undefined,
            
      nftIssuedListDisplay: undefined,
      ownerListLoaded: false,
      ownerPaginateFrom: 0,
      ownerPaginateTo: 10,
      ownerPaginateStep: 10,
      allOwnerLoaded: false,
      checkingOwner: false,

      availableOnSecondary: false,
      decimal: 8,
      currentURL: "",
      isPlaying: false,
      isMuted: true,
      showBid: false,
      signMinPrice: null,
      showQrcode: false,

      endHeight: null,
      currentHeight: null,
      timerStr: "",
      timerStrBlock: "",
      runTimer: null,
      runBlockChecker: null,
      saleEnded: false,

      collection: null
    }
  },
  components: {
    /* 'app-user-infos': UserInfos, */
    'app-user-infos-lite': UserInfosLite,
    'app-loading': Loading,
    'app-bid': BidWindow,
    'app-bid-actions': bidActions,
    'app-UsdPrice': UsdPrice
  },
  created(){
        
    this.init()
  },
  metaInfo() { 
    const name = this.artwork != undefined ? this.artwork.art_name : "";
    const description = this.artwork != undefined ? this.artwork.art_desc : "";
    const img = this.artwork != undefined ? this.getIpfsLink(this.artwork.art_display_cid) : ""
    const url = this.artwork != undefined ? this.currentURL : ""
    return {
      title: `SIGN Art - ${name}`,
      meta: [
          { name: 'description', content: description},
          { property: 'og:title', content: name},
          { property: 'og:site_name', content: 'SIGN ART'},
          { property: 'og:description', content: description},
          { property: 'og:type', content: 'Artwork'},
          { property: 'og:url', content: url},
          { property: 'og:image', content: img },
          { property: 'twitter:title', content: name},
          { property: 'twitter:image', content: img},
          { property: 'twitter:card', content: "summary_large_image"}
      ]
    }
  },
  methods: {
    init: async function(){
      let _this = this
       this.$store.dispatch("getPriceInUsd") // put back await if any issue
      await this.loadArtwork()
      this.getCurrentUrl()
      this.getOffersList(this.$route.params.txid, this.$route.params.addr)
      this.signMinPrice = await this.getSignMinSellPrice()

      // timed offer
      await this.blockChecker()
      if(this.artwork && (this.artwork.art_onsale || this.artwork.art_maxmint == 1) && this.endHeight != 0){
        this.getEndHeight()
        this.runBlockChecker = setInterval(function(){
          _this.blockChecker()
        }, 5000)
      }

      let collectionItem = await this.getCollectionItem(this.$route.params.addr, this.$route.params.txid)
      if(collectionItem[0] && collectionItem[0].collection_id){
        let collection_detail = await this.getCollections(this.$route.params.addr, collectionItem[0].collection_id)
        this.collection = collection_detail[0]
      }
    },
    openOwnerTab: function(){
      document.getElementById("ownerTab").click();      
    },
    editionMessage: function(){
      let msg =""
      switch(this.artwork.art_maxmint){
        case 0:
          msg = "(No edition set)"
        break;

        case 1:
          msg = "(Single edition)"
        break;

        default:
          msg = "(Multiple edition)"
      }
      return msg
    },
    clickTag: function(tag){
      this.$store.state.searchStr = tag
      this.$store.state.homeScrollState.scrollToId = 0
      this.$router.push("/page/1?search="+this.$store.state.searchStr+"&status="+this.$store.state.filtering.home.status).catch(()=>{});
    },
    setStatus: function(e){
      if(e == "CANCELED"){e = "CANCELLED"}
      return e == "CLOSED" ? '<i class="fas fa-check mr-2"></i> ' + e : e == "OPEN" ? '<i class="far fa-clock mr-2"></i> ' + e : '<i class="fas fa-times mr-2"></i> ' + e
    },
    hideSpiner: function(){
      if(this.$refs.spiner){
        this.$refs.spiner.classList.add("d-none")
      }
    },
    getTagString: function(){
      let list = this.taglist.toString()
      list = list.split(",").join(",")
      return list.length ? list+",NFT,SignArt" : "NFT,SignArt"
    },
    imageFitScreen: function(){
      let url = this.artwork.art_export_cid != '' ? this.getIpfsLink(this.artwork.art_export_cid) : this.getIpfsLink(this.artwork.art_display_cid)
      this.$parent.$parent.loadImage(url)
      this.$store.state.imgFit = true
    },
    getOwnerAddress: function(owner){
      return owner.currentOwner == this.$store.state.config.dapp_addr_secondary ? owner.saleDetail.ownerOnDapp : owner.currentOwner
    },
    loadArtwork: async function(){
      const _this = this
        let regex = encodeUrl("^art(.*)"+ _this.$route.params.txid +"_"+ _this.$route.params.addr)
        try{
          let data = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.dapp_addr_artworks+"?matches="+regex)
          data = await data.json()

            if(data.length > 1){
              let orderedData = this.getOrderedArtworks(data)
              _this.artwork = orderedData[0]
              _this.getHistory(data)
              let splitTag = _this.artwork.art_tags.split(",")
              let cleanTags = splitTag.filter(function (el) {
                return el != "";
              });
              _this.taglist = cleanTags
              _this.decimal = _this.artwork.art_assetAccepted == _this.$store.state.config.usdnAssetID || _this.artwork.art_assetAccepted == _this.$store.state.config.usdtAssetID || _this.artwork.art_assetAccepted == _this.$store.state.config.usdtPPAssetID  ? 6 : 8
            }else{
              document.querySelector(".loading_wrap .text-center").innerHTML="This artwork doesn't exist on SIGN Art"
              document.querySelector(".loading_wrap img").style.display = "none"
            }
          }catch(err){
            console.error(err)
          }
    },
    getHistory: async function(data){
      let _this = this
      let txinfos = []
      Object.values(data).forEach((curr) => {
          const _key = curr.key;
          if(_key.includes("art_sold")){
            const getKeyWithoutAddr = _key.substr(0, _key.lastIndexOf("_"));
            const getKeyWithoutTxid = getKeyWithoutAddr.substr(0, getKeyWithoutAddr.lastIndexOf("_"));

            let val = curr.value
            let [ticker, issuePrice] = this.getPriceWithTicker(val.split("_")[4], val.split("_")[3])
            let res = {
              issueId: val.split("_")[2],
              issueNumber: getKeyWithoutTxid.split("_")[2],
              maxIssuable: getKeyWithoutTxid.split("_")[4],
              buyer: val.split("_")[0],
              issueDate: val.split("_")[1],
              issuePrice: issuePrice,
              issuePriceAssetid: val.split("_")[4], 
              ticker: ticker
            }
            txinfos.push(res);
          } 
          
        }, {});
        let orderedHistory = Object.values(txinfos).sort(function(a,b){
          return b.issueNumber - a.issueNumber;
        });
        _this.tokenHistory = orderedHistory
    },
    getLastBlock: async function(){
      let lastBlock = await fetch(this.$store.state.config.nodeURL+"/blocks/last").then(res => res.json()).then(async data => { 
        return data.height
      })
      return lastBlock-1
    },
    checkAllOwnerLoaded: function(){
      return this.nftIssuedListDisplay.length == this.nftIssuedList.length
    },
    loadMoreOwner: async function(){
      this.ownerPaginateFrom = this.ownerPaginateTo,
      this.ownerPaginateTo = this.ownerPaginateTo + this.ownerPaginateStep
      this.nftIssuedListDisplay = [...this.nftIssuedListDisplay, ...this.nftIssuedList.slice(this.ownerPaginateFrom, this.ownerPaginateTo)]
      await this.getCurrentOwner()
      await this.checkForSale()
      await this.checkBurned()
      this.allOwnerLoaded = this.checkAllOwnerLoaded()
      this.ownerListLoaded = true
    },
    getCurrentOwner: async function(){
      this.checkingOwner = true
      let tempData = this.nftIssuedListDisplay.slice(this.ownerPaginateFrom, this.ownerPaginateTo)
      let lastBlock = await this.getLastBlock()
      for(let nftid in tempData){
        let index = Number(this.ownerPaginateFrom) + Number(nftid)
            if(this.nftIssuedListDisplay[index].issuedNftId != undefined){
              let info = await fetch(this.$store.state.config.nodeURL+"/assets/"+this.nftIssuedListDisplay[index].issuedNftId+"/distribution/"+lastBlock+"/limit/1")
              let addr = await info.json()
              let isolateAddr = Object.keys(addr.items)[0]
              this.nftIssuedListDisplay[index].currentOwner = isolateAddr
            }
          }
      this.checkingOwner = false
    },
    getOwnersList: async function(){
      if(!this.ownerListLoaded){
        this.checkingOwner = true
        let _this = this
        let nftIssuedList = []
        //document.getElementById("ownerTab").scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        let regex = encodeUrl("^art_sold_(.*)"+ _this.$route.params.txid +"_"+ _this.$route.params.addr)
        await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.dapp_addr_artworks+"?matches="+regex).then(res => res.json()).then(async data => {  
          data.map(function(e){
            let nftKeyValues = e.key.split("_")
            let nftValues = e.value.split("_")
            let nftValuesObj = {
              buyer: nftValues[0],
              buyDate: nftValues[1],
              buyId: nftValues[2],
              buyPrice: nftValues[3],
              buyAssetId: nftValues[4],
              issuedNftId: nftValues[5],
              issueNumber: nftKeyValues[2],
              maxIssuable: nftKeyValues[4],
              isBurn: false,
              saleDetail: {}
            }
            nftIssuedList.push(nftValuesObj)
          })
          
          let sortedList = nftIssuedList.sort((a,b) => a.issueNumber - b.issueNumber);
          _this.nftIssuedList = sortedList;
          _this.nftIssuedListDisplay = _this.nftIssuedList.slice(this.ownerPaginateFrom, this.ownerPaginateTo)
        
        })
        
        //document.getElementById("ownerTab").scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        await _this.getCurrentOwner()
        _this.ownerListLoaded = true
        await _this.checkForSale()
        await _this.checkBurned()
        this.allOwnerLoaded = this.checkAllOwnerLoaded()
      }
    },
    checkBurned: async function(){
      let _this = this
        let tempData = _this.nftIssuedListDisplay.slice(this.ownerPaginateFrom, this.ownerPaginateTo)
        for(let nftid in tempData){
          let index = Number(this.ownerPaginateFrom) + Number(nftid)
          if(_this.nftIssuedListDisplay[index].issuedNftId != undefined){
            let checkAssetInfos = await fetch(this.$store.state.config.nodeURL+"/assets/details/"+_this.nftIssuedListDisplay[index].issuedNftId)
            checkAssetInfos = await checkAssetInfos.json()
            let isBurn = checkAssetInfos.quantity != 1
            if(isBurn){
              _this.nftIssuedListDisplay[index].isBurn = true
            }
          }
        }
        return
    },
    checkForSale: async function(){
      let _this = this
      let haveForSale = 0
      let tempData = _this.nftIssuedListDisplay.slice(this.ownerPaginateFrom, this.ownerPaginateTo)
        for(let nftid in tempData){
          let index = Number(this.ownerPaginateFrom) + Number(nftid)
          if(_this.nftIssuedListDisplay[index].issuedNftId != undefined){
            let regex = encodeUrl("^sale2nd_info_(.*)_"+ _this.nftIssuedListDisplay[index].issuedNftId +"_(.*)_OPEN")
            let checkForSale = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.dapp_addr_secondary+"?matches="+regex)
            checkForSale = await checkForSale.json()
            if(checkForSale.length){ 
              let saleKey = checkForSale[0].key.split("_")
              let saleValue = checkForSale[0].value.split("_")
              let [ticker, price] = this.getPriceWithTicker(saleValue[2], saleValue[1])
              let saleDetail = {
                date: saleValue[0],
                price: price,
                asset: saleValue[2],
                ticker: ticker,
                ownerOnDapp: saleKey[2]
              }
              _this.nftIssuedListDisplay[index].saleDetail = saleDetail
              haveForSale ++
            }
          }
        }
        _this.availableOnSecondary = haveForSale
        return
    }, 
    imgLoaded: function(){
      this.spinerShow = false;
    },
    chooseSigningMethod: function(action){
       if(this.$store.state.user.isLogged){
         if(action == "buy"){
          this.buyArtwork()
         }
         if(action == "bid"){
           this.showBid = true
         }
       }else{
         this.$store.state.signingChoice.showChoice = true
       }
    },
    buyArtwork: function(){
      let _this = this;
      this.$store.state.alert=true
      this.$store.state.alertMessage='Processing the sale, Please wait...'
      /* let invokeFee
      if(this.feeTicker == 'SIGN'){
        invokeFee = {
          fee: this.feeCost * 10 ** 8,
          feeAssetId: this.feeAssetId
        }
      }
      if (this.feeTicker == 'USDN'){
        invokeFee = {
          fee: this.feeCost * 10 ** 6,
          feeAssetId: this.feeAssetId
        }
      }
      if(this.feeTicker == 'WAVES'){
        invokeFee = {
          fee: 500000
        }
      } */
      const dataTx = {
        dApp: this.$store.state.config.dapp_addr_artworks,
        /* ...invokeFee, */
            call: {
                function: "buyArtwork",
                 args:[
                    { type:"string", value: _this.$route.params.txid },
                    { type:"string", value: _this.$route.params.addr }
                ]
            }
      }

        if(_this.$route.params.addr == _this.$store.state.user.userAddress){
          dataTx.payment = [{ assetId: _this.$store.state.config.assetID, amount: _this.signMinPrice }]
        }else{
          if(_this.artwork.art_assetAccepted != ""){
            dataTx.payment = [{ assetId: _this.artwork.art_assetAccepted, amount: _this.artwork.art_price }]
          }else{
          dataTx.payment = [{ assetId: null,amount: _this.artwork.art_price }]
          }
        }
      
      this.$store.dispatch("signerInvoke", dataTx).then(res => { 
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          if(res.error){
            // siplay error
            this.responseMessage = res.error.message ? res.error.message : res.error
            this.haveError = true
          }else{
            // display confirmation message of success
            this.$store.state.alert=false
            this.$store.state.alertMessage=''
            this.haveError = false
            this.responseMessage = 'Artwork bought successfully!'
            this.buyerAddress = this.$store.state.user.userAddress
            _this.loadArtwork()
            setTimeout(function(){
              this.responseMessage = ''
            }, 3000)
          }
      }) 
    },
    detectClickOutOfQRCodePopin: function(e){   
      if(this.showQrcode){
        if (!document.getElementById('qrCode').querySelector("img").contains(e.target)){
          this.showQrcode = false
        }
      }
    },
    clearTimers: function(){
        clearInterval(this.runBlockChecker)
        clearInterval(this.runTimer)
        this.timerStr = ''
        this.timerStrBlock = this.endHeight == 0 ? '' : 'Sale ended.'
        this.saleEnded = this.endHeight == 0 ? false : true
    },
    blockChecker: async function(){
      this.currentHeight =  await this.getCurrentHeight();
      this.endHeight = await this.getAuctionEndHeight(this.txid, this.addr);
      let remainingAuctionTime = this.endHeight - this.currentHeight
      this.timerStrBlock = remainingAuctionTime+" blocks"
      if(remainingAuctionTime <= 0){
        this.clearTimers()
      }
    },
    getEndHeight: async function(){
        let _this = this
        let remainingAuctionTime = this.endHeight - this.currentHeight
      
        let currentDate = new Date();
        let futureTimestamp = currentDate.getTime() + remainingAuctionTime*60000;
        this.runTimer = setInterval(function(){
          let nowTimestamp = new Date().getTime();
          let diff = _this.dateDiff(nowTimestamp, futureTimestamp);
          let stringTimer = diff.days > 0 ? diff.days + " days " : ""
          if(diff.hours > 0){
            stringTimer += diff.hours +" hours "
          }
          if(diff.minutes > 0){
            stringTimer += diff.minutes +" minutes "
          }
          if(diff.seconds > 0){
            stringTimer += diff.seconds +" seconds "
          }else{
            stringTimer += ''
          }
          _this.timerStr = stringTimer

        }, 1000);
        if(remainingAuctionTime <= 0){
          this.clearTimers()
        }
    }
  },

  watch: {
    'endHeight'(endHeight){
      if(endHeight <= 0){
        this.clearTimers()
      }
    },
    '$route'(to){
       this.addr = to.params.addr
       this.txid = to.params.txid
       this.loadArtwork()
    }
  },
  beforeDestroy(){
    //clearInterval( this.infoInterval )
    if(document.getElementById('qrCode')){
      document.getElementById('qrCode').removeEventListener('mousedown', this.detectClickOutOfQRCodePopin);
    }
    if(this.runTimer){
      clearInterval(this.runTimer)
    }
  },
  mounted(){
    if(this.$refs.plyr){
      this.$refs.plyr.player.on('ended', () => this.isPlaying = false)
    }
    
    document.getElementById("content").classList.add("show")
    document.getElementById("main").scrollTop = 0

    QRCode.toDataURL("https://"+location.host+this.$route.fullPath)
    .then(url => {
      document.getElementById('qrCode').querySelector("img").src=url
    })
    .catch(err => {
      console.error(err)
    })

    document.getElementById('qrCode').addEventListener('mousedown', this.detectClickOutOfQRCodePopin);

    /* this.infoInterval = setInterval(function(){
      _this.loadArtwork()
    }, 8000) */
  }
}
</script>
<style lang="scss">
.links li i.fa-yandex-international{
  font-size: 16px;
}
.usdValue, .offerTime{
  color: #9b859a;
  @media(max-width: 768px){
    display: block;
  }
}

#art_detail .plyr{
  pointer-events: initial;
}
#art_detail .plyr--video .plyr__controls, #art_detail .plyr__control{
  opacity: 0.5!important;
  transition: opacity .4s ease!important;
  &:hover{
    opacity: 0.9!important;
  }
  @media(max-width: 1024px){
    opacity: 0.7!important;
  }
}
.change_required{
  color:red;
  font-size: 14px;
}
.offerStatus .fa-check{
  color: green;
}
.offerStatus .fa-times{
  color: #f43f3f;
}
</style>
<style lang="scss" scoped>
#offerTimer{
  font-size: 18px;
  .purple{
    color: #ca26c0;
  }
  .estimatedTime{
    font-size: 15px;
    color: #949494;
    display: block;
  }
  .tooltip_wrap{
    font-weight: 700;
    color: #57137b;
    font-size: 15px;
  }
}
.secondaryBtn{
  background-color: #ca26c0;
  color: white!important;
  padding: 5px;
  border-radius: 5px;
  transition: all .4s ease;
  &:hover{
    background-color: #57137b;
    text-decoration: none!important;
  }
}
#visuel{
  .main{
    max-width: max-content;
    margin: auto;
  }
}
.list_icon{
  font-size: 22px;
  position: absolute;
  right: 0;
  top: 12px;
}
#qrCode{
  position: fixed;
  z-index: 800;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.6);
  left: 0;
}
.cancelOffer, .rejectOffer{
  background-color: #f43f3f;
  transition: opacity .4s ease;
  opacity: 0.6;
  &:hover{
    opacity:1;
  }
}
.acceptOffer, .cancelOffer, .rejectOffer{
  border-radius: 50px;
  padding: 5px 8px;
  font-size: 12px;
  line-height: 12px;
}
.mention{
  font-style: italic;
  color: #949494;
}
.noartwork{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #57137b;
}
.wrap .loading{
  margin-top: 0;
  position: relative;
}
#user_page{
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
#art_detail{
  flex: 1;
  margin-top: 10px;
}
.added{
  display: flex;
  justify-content: space-between;
  line-height: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #8F8D8D;
  align-items: center;
  margin-bottom: 10px;
  .wrap_share{
    font-size: 18px;
    // margin-bottom:5px;
    .mr-3{
      margin-right: 0.7rem !important;
    }
    span{
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #1E1A1A;
      margin-right: 5px;
    }
    a{
      display: flex;
      align-items: center;
    }
    .fa-telegram-plane{
      font-size: 20px;
    }
    .share-network-twitter{
      transition: all .4s ease;
      &:hover{
        color: #57137b;
      }
    }
  }
  @media(max-width: 480px){
    flex-direction: column;
    .wrap_share{
      margin-left: 0!important;
      margin-top: 10px;
    }
  }
}

.buyBtn{
  background-color: #F200BD;
  color: white;
  a{
    color: white;
  }
}
.edit{
  font-size: 16px;
  margin-left: 10px;
  text-decoration: underline;
}
#selectFeeAssetId{
  width: auto;
}
.seeCollection{
  color: white;
  text-decoration: underline;
  font-weight: 700;
  &:hover{
    text-decoration: none;
  }
}
#currentowner, #txhistory{
  a{
    color: #57137b;
    font-weight: normal;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
}

</style>