<template>
  <div>
      <transition name="fade">
        <app-bid-actions :bidData="bidData" :showbidData="showbidData" v-if="showbidData"></app-bid-actions>
      </transition>
      <div class="switchSalesBids mb-4" v-if="isWhiteListed">
        <span 
          class="sales mr-1" 
          :class="{bold: toggleSwitch=='SALES'}"
          >Sales</span> 

        <i title="Switch" 
          class="fas fa-toggle-on" 
          :class="{on: toggleSwitch=='SALES'}" 
          @click="toggleSalesBid" ref="switcher"></i> 

        <span class="bids ml-2" 
          :class="{bold: toggleSwitch=='OFFERS'}">Offers</span>
      </div>
      <div id="response" :class="{error: haveError}" v-show="responseMessage!=''" class="col-xl-10 mx-auto text-center mt-3" @click.prevent="closeResponseMessage()">
        <i class="fas fa-times"></i>
        <div class="wrap">{{responseMessage}}</div>
      </div>
      <div class="row">
        <div id="salesTab" class="col-12" v-if="toggleSwitch == 'SALES'">
          <ul id="soldList" v-if="soldList.length">
            <li v-for="(sold, key) in soldList" :key="sold.buyId" :data-index="key" class="sold">
              <div class="row">
                <div class="col-lg-3 col-xl-2 d-flex align-items-center justify-content-center">
                  {{ sold.date | setDate }}<br/>
                </div>
                <div class="col-xl-10 col-lg-9 pl-lg-0">
                  <strong>You SOLD the Artwork</strong>: 
                  <router-link :to="'/user/'+sold.issuerAddr+'/artwork/'+sold.artId" exact >{{ sold.artId }}</router-link><br/><strong>for {{ getPriceWithTicker(sold.buyAsset, sold.price)[2] }}</strong>
                  to <router-link :to="'/user/'+sold.buyerAddr" exact >{{ sold.buyerAddr }}</router-link>
                </div>
              </div>
            </li>
          </ul>
          <div v-else class="text-center">
            <div class="text-center" v-if="salesLoading">Loading sales...</div>
            <div v-else>No sale found.</div>
          </div>
        </div>

        <div id="BidTab" class="col-12" v-else>
          <div id="bidFilters" class="d-flex mb-3" v-if="offersList.length">
            <select class="select-css mr-3" @change="filterOfferListByType" v-model="offerSelect" v-show="isWhiteListed">
              <option value="ALL">ALL</option>
              <option value="RECEIVED an OFFER">RECEIVED OFFER</option>
              <option value="MADE an OFFER">MADE OFFER</option>
            </select>
            <select class="select-css" @change="filterOfferListByStatus" v-model="statusSelect">
              <option value="ALL">ALL</option>
              <option value="OPEN">OPEN</option>
              <option value="CLOSED">CLOSED</option>
              <option value="CANCELED">CANCELLED</option>
            </select>
          </div>
          <ul id="bidList" v-if="offersList.length">
            <li v-for="(offer, key) in filteredOffersList" :key="offer.bidid" :data-index="key" :data-status="offer.status" :data-type="offer.owner == $store.state.user.userAddress ? 'RECEIVED an OFFER':'MADE an OFFER'" class="sold">
              <div class="row">
                <div class="col-lg-3 col-xl-2 d-flex align-items-center justify-content-center">
                  {{ offer.date | setDate }}<br/>
                </div>
                <div class="col-xl-10 col-lg-9 pl-lg-0">
                  <div class="wrap_history">
                    <div class="d-flex">
                      <strong>You {{ offer.owner == $store.state.user.userAddress ? 'RECEIVED an OFFER' : 'MADE an OFFER' }} of {{ offer.price }} {{ offer.assetId }}</strong>
                      <div class="ml-2 d-flex align-items-end">
                        <router-link :to="'/user/'+offer.owner+'/artwork/'+offer.artId" exact class="icon_action" v-b-tooltip.hover.top title="See the item">
                          <i class="fas fa-eye"></i>
                        </router-link>
                        <a href="#" class="icon_action" 
                          v-if="offer.status != 'CANCELED' && offer.status != 'CLOSED' && offer.owner == $store.state.user.userAddress"
                          v-b-tooltip.hover.top title="Accept offer" 
                          :data-artId="offer.artId" 
                          :data-saleOwner="offer.owner" 
                          :data-assetId="offer.assetId" 
                          :data-basePrice="offer.basePrice" 
                          :data-bidid="offer.bidid" 
                          :data-bidOwner="offer.bidOwner" 
                          @click.prevent="bidAction($event, 'accept', 'primary', '')">
                            <i class="fas fa-check" ></i>
                        </a>
                        <a href="#" class="icon_action" 
                          v-if="offer.status != 'CANCELED' && offer.status != 'CLOSED'"
                          v-b-tooltip.hover.top :title="offer.owner == $store.state.user.userAddress ? 'Decline offer' :'Cancel offer'" 
                          :data-artId="offer.artId" 
                          :data-saleOwner="offer.owner" 
                          :data-assetId="offer.assetId" 
                          :data-basePrice="offer.basePrice" 
                          :data-bidid="offer.bidid" 
                          :data-bidOwner="offer.bidOwner" 
                          @click.prevent="bidAction($event, 'cancel', 'primary', '')">
                            <i class="fas fa-times"></i>
                        </a>
                      </div>
                    </div>

                    for: <router-link :to="'/user/'+offer.owner+'/artwork/'+offer.artId" exact >{{ offer.artId }}</router-link> <br/>
                    {{ offer.owner == $store.state.user.userAddress ? 'from' : 'to' }} 
                    <router-link :to="{ name: 'user-infos-front', params: { addr: offer.owner == $store.state.user.userAddress ? offer.bidOwner : offer.owner }}" >{{ offer.owner == $store.state.user.userAddress ? offer.bidOwner : offer.owner }} </router-link> 
                    <strong> - Status: {{ offer.status == "CANCELED" ? "CANCELLED" : offer.status }}</strong>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div v-else class="text-center">
            <div class="text-center" v-if="bidsLoading">Loading offers...</div>
            <div v-else>No Offer found.</div>
          </div>
        </div>
      </div> 
  </div> 
</template>

<script>
import globalMixin from '@/mixins/global.js';
import offerMixin from '@/mixins/offerMixin.js';
import encodeUrl from 'encodeurl';
import bidActions from '@/components/content/bid/bidActions.vue' 

export default {
  mixins: [globalMixin, offerMixin],
  data: function(){
    return {
      addr: this.$store.state.user.userAddress,
      responseMessage: "",
      haveError: false,

      soldList: [],
      filteredSoldList: [],
      salesLoading: false,

      filteredOffersList: [],
      offerSelect: "ALL",
      statusSelect: "ALL",
      toggleSwitch: null,
      showbidData: false,
      bidData: undefined
    }
  },
  components: {
    'app-bid-actions': bidActions
  },
  created(){
    this.init()    
  },
  mounted(){
    // Handle back button for artsits and collectors
    if(this.checkActivity() == "OFFERS"){
      this.toggleSwitch = "OFFERS"
    }else{
      if(this.isWhiteListed){
        this.toggleSwitch = "SALES"
      }
    }
  },
  watch: {
    toggleSwitch: async function(){
      if(this.toggleSwitch == "SALES"){
        this.getSoldList()
      }else{
        await this.getOffersList("", this.addr)
        this.filteredOffersList = this.offersList
        this.setFilterTo("OPEN")
      }
    }
  },
  computed: {
    isWhiteListed: function() {
      let user = this.$store.getters['getUserObj']
      return user.isWhiteListed
    }
  },
  methods: {
    init: async function(){
      this.toggleSwitch = this.isWhiteListed ? "SALES" : "OFFERS"
      this.isWhiteListed ? this.getSoldList() : await this.getOffersList("", this.addr); this.setFilterTo("OPEN")
      this.filteredOffersList = this.offersList
    },
    toggleSalesBid: function(){
      this.toggleSwitch = this.toggleSwitch == "SALES" ? "OFFERS" : "SALES"
      this.$router.push(this.$router.currentRoute.path+"#"+this.toggleSwitch+"#PRIMARY")
    },
    filterOfferListByType: function(e){
      let _this = this
      _this.filteredOffersList = _this.offersList.filter((offer) =>{
        if(e.target.value == "ALL"){ return true}
        if(e.target.value == "RECEIVED an OFFER"){
          return offer.owner == _this.$store.state.user.userAddress
        }
        if(e.target.value == "MADE an OFFER"){
          return offer.owner != _this.$store.state.user.userAddress
        }
       
      })
      this.filterOfferListByStatus()
    },
    filterOfferListByStatus: function(){
        let _this = this
        _this.filteredOffersList = _this.offersList.filter((status) =>{
          if(this.statusSelect == "ALL" && this.offerSelect == "ALL"){ return true}
          if(this.offerSelect=="ALL"){ return status.status == this.statusSelect}
          if(this.offerSelect=="RECEIVED an OFFER" && this.statusSelect == "ALL"){ return status.owner == _this.$store.state.user.userAddress}
          if(this.offerSelect=="RECEIVED an OFFER" && this.statusSelect != "ALL"){ return status.status == this.statusSelect && status.owner == _this.$store.state.user.userAddress}
          if(this.offerSelect=="MADE an OFFER" && this.statusSelect == "ALL"){ return status.owner != _this.$store.state.user.userAddress}
          if(this.offerSelect=="MADE an OFFER" && this.statusSelect != "ALL"){ return status.status == this.statusSelect && status.owner != _this.$store.state.user.userAddress}

        })
        _this.filteredOffersList.sort((a, b) => {
          return +b.date - +a.date;
        })
    },

    getSoldList: function(){
      this.salesLoading = true
      const _this = this
      let regex = encodeUrl("^art_sold(.*)" + _this.addr)
      fetch(this.$store.state.config.nodeURL+"/addresses/data/"+this.$store.state.config.dapp_addr_artworks+"?matches="+regex).then(res => res.json()).then(data => {
        if(data.length){
          let tempArray = []
          Object.values(data).forEach((elm) => {
            let artId = elm.key.split("_")[5]
            let issuerAddr = elm.key.split("_")[6]
            let [buyerAddr, date, buyId, price, buyAsset, NFTid] = elm.value.split("_")
            let soldObj = {
              artId:artId,
              issuerAddr:issuerAddr,
              buyerAddr:buyerAddr,
              date: date,
              buyId:buyId,
              price:price,
              buyAsset:buyAsset,
              NFTid:NFTid
            }
            tempArray.push(soldObj)
          })

          let orderedData = tempArray.sort(function(a,b){
            return new Date(Number(b.date)) - new Date(Number(a.date));
          });

          _this.soldList = orderedData
        }
        this.salesLoading = false
      }).catch((err) => console.error(err));
    },
    checkActivity: function(){
      let activity = this.$router.currentRoute.fullPath.split("#")
      return activity[1]
    },
    setFilterTo: function(filter){
      this.statusSelect = filter; 
      this.filterOfferListByStatus()
    }
  }
}
</script>