<template>
  <div>
    <transition name="fade">
      <div id="deleteConfirm" v-if="showDeleteConfirm" @click="detectClickOutOfDeleteCollection">
        <div class="wrapper">
          <p class="mb-2">Do you really want to delete this collection?:</p>
          <h3 class="d-block mt-1">"{{ collectionToDelete.name }}"</h3>
          <button class="py-2 mt-2" @click="deleteCollection(collectionToDelete.id)">CONFIRM</button>
          <span class="d-block mention red-txt">This action will be definitive. It will not delete any of the artworks</span>
        </div>
      </div>
    </transition>
      <div class="mt-lg-4 container-fluid">
        <div class="text-center" v-if="!$store.state.user.sessionData">
          <p>
            <strong>Collections management requires you to sign a message with your Waves account:</strong>
          </p>
          <div class="btn btn-primary mb-2 bigger py-2" @click="login" v-if="!disableLoginBtn">Login to manage</div>
          <div v-if="disableLoginBtn && !$store.state.user.sessionData">Waiting for connexion window...</div>
        </div>
        <div v-else>
          <h3 class="text-center mt-4 mb-lg-0">Your collections</h3>
          <p class="text-center mb-4">An artwork can be attached to one collection only.</p>
          <app-collections-add-edit action_type="add" :collection_detail="null" :reloadCollection="init"></app-collections-add-edit>
          <div v-if="loading" class="loading">
            <app-loading :label="'data'"></app-loading>
          </div>
          <div class="wrap_data row" v-else>
            <div class="col-12 text-center text-black mb-4" v-if="collections && !collections.length">
              <div v-if="loading">Loading data</div>
              <div v-else>No collections found.</div>
            </div>
            <div class="col-lg-3 col-xl-3 col-md-6 mb-4 d-flex flex-column" v-for="(collection, key) in collections" :key="key">
              <app-collections :collection="collection"></app-collections>
              <div class="removeCollection" v-if="collection.id!=null" @click="initDeleteCollection(collection)"><i class="fa-solid fa-trash-can"></i> Delete collection</div>
            </div>
          </div>
        </div>
      </div>

      <div class="wrap_pagination d-flex justify-content-center mt-2">
          <button class="btn btn-primary mb-2 bigger" :class="{'mr-3': collections.length}"  v-if="this.$route.params.pageIndex && this.$route.params.pageIndex > 1" @click="prevNext('prev')">PREVIOUS</button>
          <button class="btn btn-primary mb-2 bigger" @click="prevNext('next')" v-if="collections && collections.length == perPage">NEXT</button>
       </div>
  </div> 
</template>

<script>
import globalMixin from '@/mixins/global.js';
import session from '@/mixins/session.js';
import collections from '@/mixins/collections.js';
import orderedArtworks from "@/mixins/orderedArtworks.js"
import Loading from '@/components/elements/Loading.vue'
import collectionsAddEdit from '@/components/content/userAccount/artworks/collectionsAddEdit.vue'
import collectionsItem from '@/components/content/collections/item.vue'
import { wavesAddress2eth } from '@waves/node-api-js';

export default {
  mixins: [globalMixin, orderedArtworks, session, collections],
  data: function(){
    return {
      collections: null,
      loading: false,
      showDeleteConfirm: false,
      showPagination: false,
      perPage: 20,
      collectionToDelete: {
        id: null,
        name: null
      }
    }
  },
  components:{
    'app-loading': Loading,
    'app-collections-add-edit': collectionsAddEdit,
    'app-collections': collectionsItem
  },
  created(){
   this.init()
  },
  watch: {
    '$route.params.pageIndex'(){
      this.init()
    }
  },
  methods: {
    init: async function(){
      if(this.$store.state.user.sessionData){
        
        let login = await this.loginSession()
        if(!login.errorSession){
          this.loading = true
          let collectionsList = await this.getCollections(this.$store.state.user.userAddress, null, this.perPage)
          this.collections = collectionsList
          this.loading = false
        }
        
      }
      
    },
    prevNext: function(direction){
      let index
      if(direction == "prev"){
        index = +Number(this.$route.params.pageIndex)-1
      }else{
        index = +Number(this.$route.params.pageIndex)+1
        
      }
      this.$router.push({ name:  'user-artworks-collections', params: { pageIndex: index } }).catch(()=>{});
    },
    initDeleteCollection: async function(collection){
      this.showDeleteConfirm = true
      this.collectionToDelete.id = collection.id
      this.collectionToDelete.name = collection.name
    },
    deleteCollection: async function(id){
      this.$store.state.alert=true
      this.$store.state.alertMessage='Removing your collection...'

      let type = "signer"
      let pubkey = this.$store.state.user.publicKey
      if(this.$store.state.signer.isMetamask){
        pubkey = wavesAddress2eth(this.$store.state.user.userAddress);
        type = "metamask"
      }

      let request = {
        type:type,
        publickey: pubkey,
        signature: this.$store.state.user.sessionData.signedMessage,
        token: this.$store.state.user.sessionData.token,
        collection_id: id,
        deleteEntry: true,
        name: '',
        description: '',
        cover: '',
        position: 1
      }

      try{
        let createCollections = await fetch(
          this.$store.state.config.signArtApiURL + "/collections/manage/",
          {
            method: 'post',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
              request
            )
          }
        )
        createCollections = await createCollections.json()
        if(!createCollections.error){
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          this.init()
        }else{
          this.new_collection.alert = createCollections.text
        }
        
      }catch(e){
        this.new_collection.alert = e
      }
      this.showDeleteConfirm = false
      this.idToDelete = null
    },
    detectClickOutOfDeleteCollection: function(e){  
      if(this.showDeleteConfirm){
        if (!document.getElementById('deleteConfirm').querySelector(".wrapper").contains(e.target)){
          this.showDeleteConfirm = false
          this.collectionToDelete.id = null
          this.collectionToDelete.name = null
        }
      }
    },
    login: async function(){
      await this.loginSession()
      this.init()
    },
    
  }
}
</script>
<style lang="scss" scopped>

.removeCollection{
  cursor: pointer;
  margin-top: 10px;
  transition: opacity .4s ease;
  &:hover{
    opacity: 0.7;
  }
}
#deleteConfirm{
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 0!important;
    width: 100%;
    min-height: 100%;
    background-color: rgba(0,0,0,0.7);
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .wrapper{
      background-color: #f7f7f7;
      padding: 25px;
      width: 100%;
      max-width: 500px;
      text-align: center;
      cursor: initial;
      input[type="file"]{
        cursor: pointer;
      }
    }
}
button{
  .plus{
    font-size: 24px !important;
    margin-left: 10px !important;
    padding: 0 !important;
    line-height: 24px !important;
  }
}
</style>