<template>
  <div>

      <div id="buyOnePopin" v-show="buyOneConfirm">
        <div class="wrap">
          <p>This will purchase one edition of your own artwork and put it in your own collection. This is equivalent to buying it yourself.<br/><span class="irreversible">You won't be able to sell it on the primary market anymore.</span></p>
          <button @click.prevent="mintArtwork(); buyOneConfirm=false;">I UNDERSTAND</button>
        </div>
      </div>
      <h3 class="text-center mt-4 mb-lg-0">Edit artwork</h3>
      <p class="text-center mb-4">Updates take around one minute to display on the listing pages</p>
      <div id="response" :class="{error: haveError}" v-show="responseMessage!=''" class="container w-100 mx-auto text-center mt-3">
        <div class="wrap" ref="wrap">{{responseMessage}}</div>
      </div>
       <transition name="fade">
        <div id="salePopin" v-show="sale.salePopinOpen" ref="salePopin">
          <div class="wrapper">
            <div class="form-group mb-0">
              <div class="row">
                <div class="col-12">
                  <p class="nftIssuedCounter mb-0 text-center rouge" v-show="nft_issued>0">{{ nft_issued }} of {{ sale.maxmint }} NFT(s) issued already, you cannot change the maximum issuable anymore.</p>
                  <p class="nftIssuedCounter mt-3 mb-0 text-center" v-show="nft_issued == 0 && haveOrder > 0">The "Maximum NFT(s) issuable" cannot be changed due to active offer(s).</p>
                  
                  <p class="text-center mt-2 mention mb-4"><strong>Set price to 0 to accept OFFER only.</strong></p>
                  
                </div>
                <div class="col-lg-4">
                  <label for="maxmint">Maximum NFT(s) issuable <span class="mandatory">*</span> <span v-b-tooltip.hover.top title="Enter the maximum NFT collectors can buy between 1 to 100 max., this cannot be edited once the first sale is confirmed."><i class="fas fa-question-circle"></i></span></label>
                  <input type="number" class="form-control" id="maxmint" min="0" max ="100" placeholder="Amount maximum to mint" v-model="sale.maxmint" :disabled="nft_issued>0 || haveOrder>0">
                </div>
                <div class="col-lg-4">
                  <label for="currency">Accepted currency <span class="mandatory">*</span> <span v-b-tooltip.hover.top title="Select in which currency you want to be paid."><i class="fas fa-question-circle"></i></span></label>
                  <select class="form-control" v-model="sale.assetId" @change="setTicker($event); setMinSellPrice()">
                    <option :value="$store.state.config.assetID">SIGN</option>
                    <option :value="$store.state.config.wavesAssetID">WAVES</option>
                    <option :value="$store.state.config.usdnAssetID">XTN</option>
                    <option :value="$store.state.config.usdtPPAssetID">USDT-PP</option>
                  </select>
                </div>
                <div class="col-lg-4">
                  <label for="price">Requested price <span class="mandatory">*</span> <span v-b-tooltip.hover.top title="Enter the required payment in selected tokens to confirm the sale."><i class="fas fa-question-circle"></i></span></label>
                  <input type="number" class="form-control" id="price" placeholder="Price per NFT" v-model="sale.price" :min="0" :disabled="sale.offerOnly">      
                </div>

                <div class="col-lg-12 mt-3" >
                  <label for="offerOnly">
                    <input type="checkbox" v-model="sale.offerOnly" id="offerOnly"/> Offer only? <span v-b-tooltip.hover.top title="Check this if you want offer only, uncheck to set a fixed price + accept offer."><i class="fas fa-question-circle"></i></span>
                  </label>   
                  
                </div>

                <div class="col-lg-12 mt-1" >

                  <label for="auction">
                    <input type="checkbox" v-model="sale.auctionType" id="auction" :disabled="sale.maxmint != 1"/> Time limit? <span class="mention">(1/1 only)</span> <span v-b-tooltip.hover.top title="Check this if you want to add a timer limit. Only for unique edition in OFFER ONLY mode."><i class="fas fa-question-circle"></i></span>
                  </label>
                  <div class="wrap_end_height mt-2" v-if="sale.auctionType && sale.maxmint == 1">
                    
                    <label for="endHeight" >
                      When should this end? <span class="mandatory">*</span> <span v-b-tooltip.hover.top title="Select the date untill when this artwork should be open for offer."><i class="fas fa-question-circle"></i></span>
                    </label>
                    <date-picker v-model="sale.datePicked" valueType="timestamp" type="datetime" format="YYYY-MM-DD HH:mm" @clear="clearDatePicker" :editable="false" :disabledDate="disableDate" :disabledTime="disableTime" :show-second="false" :default-value="new Date().getTime() + (minEndHeight * 60000)" class="d-block"></date-picker>
                    <!-- <input type="number" class="form-control" id="endHeight" placeholder="Number of day" v-model="sale.endHeight" :min="0"> -->
                  </div> 
                </div>

                <div class="col-12">
                  <p class="text-center mt-4 mb-1">Edition of <strong>{{ sale.maxmint }} NFT(s)</strong> maximum <span v-show="sale.maxmint - nft_issued != 0">with <strong>{{sale.maxmint - nft_issued}} left</strong> </span> set at <strong>{{ sale.price == 0 ? 'OFFER ONLY' : numberWithSpaces(sale.price)}} {{ sale.price == 0 ? '' : sale.ticker }} <span v-show="priceInUsdn!=0">(${{ numberWithSpaces(parseFloat(priceInUsdn).toFixed(2)) }})</span> with {{ sale.ticker == 'SIGN' ? '7%' : '10%' }} fee</strong> {{ sale.maxmint > 1 ? 'each for this artwork' : '' }}</p>
                  <p class="text-center mt-4 mb-1" v-if="sale.auctionType && sale.datePicked">This is a <strong>timed sale</strong> ending in {{ sale.endHeight }} block(s) - approximately <strong>{{ sale.datePicked | setDate }}</strong> around <strong>{{ sale.datePicked | setTime }}</strong> <span class="tooltip_wrap" v-b-tooltip.hover.top title="Time on the blockchain is calculated in blocks with an average time of 1 block = 1 minute. Human time can only be an estimation."><i class="fas fa-question-circle pl-1 pr-1"></i></span></p>
                  <div :class="{error: sale.haveError}" v-show="sale.errorMessage!=''" class="response container w-100 mx-auto text-center mt-3">
                    <div class="wrap">{{sale.errorMessage}}</div>
                  </div>
                  <button class="btn btn-primary bigger mb-0 mt-4 mx-auto d-block" @click.prevent="processSale">{{ sale.onsale && sale.price != 0 ? 'EDIT SALE' : !sale.onsale ? 'SET FOR SALE' : 'UPDATE SALE' }}</button>
                  <p class="text-center mt-1 mention mb-0" v-show="currentMinSellPrice != ''">Minimum sell price: {{ currentMinSellPrice }} {{ sale.ticker }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
       </transition>
      <div class="container mt-lg-2" >
        <div class="row">
          <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-4 offset-lg-2 mb-4 currentDisplay">
                  <span>Current display</span>
                  
                  <div class="spiner" ref="spiner" v-if="showSpiner">
                    <img :src="spinerSrc" />
                  </div>
                  <div v-if="flag=='ILLEGAL'">
                    <img :src="emptySrc" />
                  </div>
                  <div v-else>
                    <app-plyr :poster="display.cid" :cid="display.cid" @metaloaded="metaLoaded()" v-if="display.isMP4" />
                    <img :src="getIpfsLink(display.cid)" v-show="display.cid != ''" class="d-block m-b3 img-fluid" v-else @load="hideSpiner()"/>
                  </div>
                </div>
                <div class="col-lg-4 mb-4">
                    <ul class="links">
                      <li v-show="exported.cid != ''"><a :href="getIpfsLink(exported.cid)" target="_blank"><img src="../../../assets/images/download_icon.svg" class="mr-2" /> Export</a></li>
                      <li><a :href="$store.state.config.signwebappUrl + '/explorer/' + signID" target="_blank"><img src="../../../assets/images/certificate_icon.svg" class="mr-2" /> SIGN Certificate</a></li>
                      <li v-show="licence.cid != ''"><a :href="getIpfsLink(licence.cid)" target="_blank"><img src="../../../assets/images/licence_icon.svg" class="mr-2" /> License</a></li>
                      <li><a href="#" class="pe-none"><i class="fas fa-coins"></i> Issued : {{nft_issued}} / {{sale.maxmint}}</a></li>
                      <li class="saleBtn completed" v-if="nft_issued != 0 && (sale.maxmint == nft_issued)"><a href="#" class="pe-none"><img src="../../../assets/images/dollar_icon.svg" class="mr-2 invert-color" /> Sale completed</a></li>
                      <li class="saleBtn" @click.prevent="saleWindow" v-else><a href="#"><img src="../../../assets/images/dollar_icon.svg" class="mr-2 invert-color" /> {{ sale.onsale && sale.price != 0 ? 'Edit sale' : 'Set for sale' }}</a></li>
                      <li class="saleBtn" v-b-tooltip.hover.top :title="'This is the equivalent of buying your own NFT (as gift, airdrop etc) on the primary market at the minimum sell price in SIGN.'" v-show="sale.maxmint != 0 && sale.maxmint != nft_issued"><a href="#" @click.prevent="buyOneConfirm=true"><img src="../../../assets/images/dollar_icon.svg" class="mr-2 invert-color" /> BUY ONE</a></li>
                      <li>
                        <router-link :to="'/user/'+$route.params.addr+'/artwork/'+$route.params.txid" exact>
                          <i class="fas fa-link"></i> Artwork page
                        </router-link>
                      </li>
                    </ul>
                    <div class="change_required" v-if="flag == 'CHANGE_REQUIRED'">
                      This artwork has been flagged and requires modification. Please contact us for more information.
                    </div>
                </div>
              </div>
              <div class="row" v-if="nft_issued == 0">
                <div class="col-lg-8 offset-lg-2">
                  <div id="certified">
                    <div class="form-group">
                      <label for="txid">Certification transaction id <span class="mandatory">*</span> <span v-b-tooltip.hover.top title="This cannot be edited"><i class="fas fa-question-circle"></i></span></label>
                      <div class="row">
                        <div class="col-12">
                          <input type="texte" class="form-control" id="txid" placeholder="Certification ID" v-model="signID" disabled>
                        </div>
                      </div>
                    </div>
                  </div>

                  <form id="formUpdate">
                  <div class="form-group">
                    <label for="title">Title <span class="mandatory">*</span> (Max 100 characters)</label>
                    <input type="texte" class="form-control" id="title" placeholder="Reference" v-model="name" maxlength="100">
                  </div>

                  <div class="form-group">
                    <label for="description">Description <span class="mandatory">*</span> (1000 characters)</label>
                    <textarea id="description" class="form-control"  placeholder="Enter the description" v-model="description" maxlength="1000"></textarea>
                  </div>

                  <div class="row files ">
                      <div class="form-group col-lg-4">
                        <label for="exportFile">
                          Export file <span class="mandatory">*</span> 
                          <span v-b-tooltip.hover.top :title="`This is the file that will be displayed in detail page and associated to the token if artwork get tokenized. Supported: ${$store.state.acceptedFiles.exported.extImg + ','+$store.state.acceptedFiles.exported.extVid}`" class="pl-1 pr-1"><i class="fas fa-question-circle"></i></span>  
                          <div class="fileHash pl-1 pr-1" :data-hash="exported.fileHash" :class="{show: exported.fileHash != ''}"><span @click.prevent="copyHash('exported', $event)" class="copyHash" v-b-tooltip.hover.top title="Copy file hash"><i class="far fa-copy"></i><div class="copied"></div></span></div>
                          <a :href="getIpfsLink(exported.cid)" target="_blank" v-if="exported.cid" class="ml-1" v-b-tooltip.hover.top :title="`See current export`"><i class="fas fa-search"></i></a>
                        </label>
                        <div class="wrap_file_field">
                          <div class="wrap_file">
                            <div :class="{error: exported.error}" class="top" v-html="getLabel('exported')"></div>
                            <input type="file" class="form-control" id="exportFile" placeholder="Select a file" :accept="$store.state.acceptedFiles.exported.accept" @change="checkExported('exported',$event)">
                          </div>
                          <div class="progression hide"><div class="counter"></div></div>
                        </div>
                        
                        <div class="mention">(70Mb max.)</div>
                      </div>
                      <div class="form-group col-lg-4 display_wrap">
                        <label for="displayFile">
                          Preview file <span class="mandatory">*</span> 
                          <span v-b-tooltip.hover.top :title="`This is the file that will be displayed in listing pages only. Supported: ${$store.state.acceptedFiles.display.extImg + ','+$store.state.acceptedFiles.display.extVid}`" class="ml-1"><i class="fas fa-question-circle"></i></span>
                          <a :href="getIpfsLink(display.cid)" target="_blank" v-if="display.cid" class="ml-2" v-b-tooltip.hover.top :title="`See current preview`"><i class="fas fa-search"></i></a>
                        </label>
                        <div class="wrap_file_field">
                        <div class="wrap_file">
                          <div :class="{error: display.dimensionError}" class="top" v-html="getLabel('display')"></div>
                          <input type="file" class="form-control" id="displayFile" placeholder="Select a file" :accept="$store.state.acceptedFiles.display.accept" @change="fileDimension('display', $event)">
                        </div>
                        <div class="progression hide"><div class="counter"></div></div>
                        </div>
                        <div class="mention">(PNG/JPG/GIF/MP4 - 5Mb) </div>
                      </div>
                      <div class="form-group col-lg-4">
                        <label for="licence">
                          License file 
                          <span v-b-tooltip.hover.top title="License document should include what rights are associated with this creation if it get tokenized and to who own the token."><a href="/files/License.docx" download v-b-tooltip.hover.top title="Download our template" class="pl-1 pr-1"><i class="fas fa-download"></i></a><i class="fas fa-question-circle pl-1 pr-1"></i></span>   
                          <span class="fileHash pl-1 pr-1" :data-hash="licence.fileHash" :class="{show: licence.fileHash != ''}"><span @click.prevent="copyHash('licence', $event)" class="copyHash" v-b-tooltip.hover.top title="Copy file hash"><i class="far fa-copy"></i><div class="copied"></div></span></span>
                           <a :href="getIpfsLink(licence.cid)" target="_blank" v-if="licence.cid" class="ml-2" v-b-tooltip.hover.top :title="`See current licence`"><i class="fas fa-search"></i></a>
                        </label>
                        <div class="wrap_file_field">
                          <div class="wrap_file">
                            <div class="top" v-html="getLabel('licence')"></div>
                            <input type="file" class="form-control" id="licenceFile" :accept="$store.state.acceptedFiles.licence.accept" placeholder="Select a file" @change="calculateHash('licence', $event)">
                          </div>
                          <div class="progression hide"><div class="counter"></div></div>
                        </div>
                        <div class="mention">(10Mb max.)</div>
                      </div>

                      <div v-if="display.isMP4" class="col-12 crawler_wrap">
                        <div id="crawlerPreview" >
                          <p><strong>You are using a video as preview</strong>, this require a thumbnail for crawlers <span class="mandatory">*</span></p>
                          <div class="wrap_file_field">
                            <div class="wrap_file">
                              <div :class="{error: crawler.dimensionError}" class="top" v-html="crawler.label"></div>
                              <input type="file" class="form-control" id="crawlerFile" placeholder="Select a file" :accept="$store.state.acceptedFiles.crawler.accept" @change="fileDimensionCrawler('crawler', $event)">
                            </div>
                            <div class="progression hide"><div class="counter"></div></div>
                          </div>
                          <div class="mention">(100Kb max.)</div>
                        </div>
                      </div>

                      <div class="col-12 mb-3" v-show="mp4Alert">
                        <div class="mp4Alert p-2 d-flex align-items-center">
                          <i class="fas fa-exclamation-triangle mr-2"></i> <p class="mb-0">MP4 with height <strong>over 2500px may not play</strong> on every mobile devices.<br/>It is also recommended to <strong>use h264</strong> generic codec.</p>
                        </div>  
                      </div>
                  </div>

                  <div class="form-group">
                    <label for="type">Export type (100 char.) <span v-b-tooltip.hover.top title="Include here all extra infos such as file type, size, dimensions etc."><i class="fas fa-question-circle"></i></span></label>
                    <input type="text" id="fileType" class="form-control" maxlength="100"  placeholder="Describe the file" v-model="exported.type" />
                  </div>

                  <div class="form-group">
                    <label for="title">Tags (5 single words max, separated by comma ',')</label>
                    <input type="texte" class="form-control" id="tags" placeholder="Tags" v-model="tags" >
                    <div v-show="tagsValidate != ''" class="mt-1 error">{{tagsValidate}}</div>
                    <ul class="tags" v-show="tagFormated.length > 0">
                        <li v-for="(tag, key) in tagFormated" :key="key">{{ tag }}</li>
                    </ul>
                  </div>

                  <div class="form-group">
                        <label for="title">Resell royalties % <span v-b-tooltip.hover.top title="The percentage you will received on each resale on our secondary market for this NFT"><i class="fas fa-question-circle"></i></span></label>
                        <input type="number" class="form-control" id="royalties" placeholder="royalties" v-model="royalties" >
                      </div>

                  <div class="form-check mb-3 mt-4">
                    <input class="form-check-input" type="checkbox" value="" id="imtheowner" :checked="imtheowner" @click="imtheowner = !imtheowner">
                    <label class="form-check-label" for="imtheowner">
                      I certify that I'm the authentic creator of this artwork and can provide the certified creation sources when required.. <span class="mandatory">*</span>
                    </label>
                  </div>
                  </form>

                  
                  <div class="col-lg-12 text-center mt-4">
                    <p class="mandatory">* Mandatory fields</p>
                    <div v-show="showDeleteConfirmation" class="mb-3">
                      <form id="formDelete">
                        <label for="confirmDelete" class="confirmDelete">
                          <input type="checkbox" v-model="deleteConfirmed" id="confirmDelete"/>
                          I confirm that i want completely delete this entry. <br/> This will remove it from SIGN Art but files will remain available on IPFS
                        </label>
                      </form>
                    </div>
                    <button class="btn btn-primary mb-2 bigger" form="formDelete" id="delete" @click.prevent="deleteArtworkConfirm()" v-show="nft_issued == 0">DELETE THIS ENTRY</button>
                    <button class="btn btn-primary mb-2 bigger" form="formUpdate" @click.prevent="processAdd" :disabled="validationError">UPDATE DETAILS</button>
                    
                  </div>
                </div>
              </div>
              <div class="minted col-lg-8 offset-lg-2 my-4 p-4" v-else>
                <div v-if="flag != 'ILLEGAL'">You cannot edit this artwork anymore, at least one NFT has already been minted..</div>
                <div v-else>Your artwork have been deleted in violation of our Terms Of Service.</div>
              </div>
          </div>
        </div>
      </div>

  </div> 
</template>

<script>
import globalMixin from '@/mixins/global.js';
import addEditWorkMixin from '@/mixins/addEditWork.js';
import orderedArtworks from "@/mixins/orderedArtworks.js"
import Plyr from '@/components/elements/Plyr.vue'
import VueScrollTo from "vue-scrollto";
import encodeUrl from 'encodeurl';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

var options = {
  container: "#content",
  easing: "ease-in",
};

export default {
  mixins: [globalMixin, addEditWorkMixin, orderedArtworks],
  data: function(){
    return {
      ...this.initialState(),
      ...{
        wavesMinPrice: undefined,
        signMinPrice: undefined,
        usdnMinPrice: 1,
        usdtMinPrice: 1,
        usdtPPMinPrice: 1,
        currentMinSellPrice: '',
        showSpiner: true,
        playerInitialize: false,
        haveOrder: 0,
        buyOneConfirm: false,
        priceInUsdn: 0,
        minEndHeight: 6 // 5 min +1
      }
    }
  },
  components: {
    'app-plyr': Plyr,
    DatePicker
  },
  created(){
    this.init()
  },
  methods: {
    init: async function(){
      this.getArtworkData()
      this.getMinSellPrice()
      this.haveOrders()
      this.checkEndHeight()
      this.isWhiteListedTxs = await this.whitelistedTxs(this.$store.state.user.userAddress)
    },
    updatePriceToUsd: function(){
      if(this.sale.price != 0){
        switch(this.sale.assetId){ 
          case "WAVES":
          case this.$store.state.config.wavesAssetID: // WAVES
          this.priceInUsdn = this.sale.price  * this.$store.state.priceInUSDN.wavesPriceInUsd
          break;

          case this.$store.state.config.assetID: // SIGN
          // result = (artPrice / Math.pow(10, 8) * this.$store.state.priceInUSDN.signPriceInUsd/Math.pow(10,8)) * this.$store.state.priceInUSDN.wavesPriceInUsd
         this.priceInUsdn = this.sale.price * this.$store.state.priceInUSDN.signPriceInUsd
          break;

          case this.$store.state.config.usdnAssetID: // USDN
          this.priceInUsdn = this.sale.price * this.$store.state.priceInUSDN.xtnPriceInUsd
          break;

          default:
          this.priceInUsdn = 0
        }
      }else{
        this.priceInUsdn = 0
      }
    },
    haveOrders: async function(){
      const _this = this
      let regex = encodeUrl("^current_bidamount_"+ _this.$route.params.txid)
      fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.dapp_addr_artworks+"?matches="+regex).then(res => res.json()).then(data => {
        if(data.length){
          this.haveOrder = data[0].value
        }
      }).catch(err => console.error(err))
    },
    getArtworkData: async function(){
      const _this = this
      const txid_addr = _this.$route.params.txid + "_" + _this.$route.params.addr
      let regex = encodeUrl("^art(.*)"+ txid_addr)
      fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.dapp_addr_artworks+"?matches="+regex).then(res => res.json()).then(data => {
        let orderedData = this.getOrderedArtworks(data)

        // check if display is mp4
        // orderedData[0].art_display_cid
        // if yes set _this.display.isMP4 to true
        if(this.artworkExension(orderedData[0].art_display_cid) == "mp4"){
          _this.display.isMP4 = true
        }
        if(this.artworkExension(orderedData[0].art_export_cid) == "mp4"){
          _this.exported.isMP4 = true
        }

        if(orderedData[0]["art_name"]){

          _this.name=orderedData[0].art_name
          _this.description=orderedData[0].art_desc
          _this.tags=orderedData[0].art_tags
          _this.signID=orderedData[0].art_signid
          _this.exported.type=orderedData[0].art_type
          _this.display.cid=orderedData[0].art_display_cid
          _this.licence.fileHash=orderedData[0].art_licence_hash
          _this.licence.cid=orderedData[0].art_licence_cid
          _this.exported.cid=orderedData[0].art_export_cid
          _this.exported.fileHash=orderedData[0].art_export_hash
          _this.nft_issued = orderedData[0].art_issued
          _this.crawler.cid = orderedData[0].art_crawler_cid ? orderedData[0].art_crawler_cid : ""
          _this.royalties = orderedData[0].art_royalties ? this.setRoyaltiesBase10With2Decimals(orderedData[0].art_royalties) : 10

          if(orderedData[0].art_onsale){
            _this.sale.onsale=orderedData[0].art_onsale
          }

          if(orderedData[0].art_flag){
            _this.flag=orderedData[0].art_flag
            if(_this.flag=='ILLEGAL'){
              _this.hideSpiner()
            }
          }
          
          if(orderedData[0].art_maxmint){
            _this.sale.maxmint=orderedData[0].art_maxmint
          }
          if(Object.prototype.hasOwnProperty.call(orderedData[0], 'art_assetAccepted')){
            _this.sale.assetId=orderedData[0].art_assetAccepted
            _this.sale.ticker = this.getTickerByAssetId(orderedData[0].art_assetAccepted)
          }
          if(orderedData[0].art_price){
            let decimal = _this.sale.assetId == this.$store.state.config.usdnAssetID || _this.sale.assetId == this.$store.state.config.usdtAssetID || _this.sale.assetId == this.$store.state.config.usdtPPAssetID  ? 6 : 8
            _this.sale.price=orderedData[0].art_price / Math.pow(10, decimal)
          }
        }

      }).catch(err => console.error(err))
    },
    saleWindow: function(){
      this.sale.salePopinOpen = !this.sale.salePopinOpen
      this.sale.salePopinOpen ? document.getElementById("content").style.overflow="hidden" : document.getElementById("content").style.overflow="auto"
    },
    getLabel: function(label){
      return this[label].label
    },
    isVideo: function(file){
      return this.$store.state.acceptedFiles.exported.extVid.includes(this.artworkExension(file).toLowerCase())
    },
    metaLoaded: function(){
      this.hideSpiner()
    },
    hideSpiner: function(){
      if(this.$refs.spiner){
        this.showSpiner = false
      }
    },
    getMinSellPrice: async function(){
      let _this = this
      let regex = encodeUrl("certification_fee_"+_this.$store.state.config.assetID)
      let getSignMinSellPrice = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.signOracle+"?matches="+regex)
      getSignMinSellPrice = await getSignMinSellPrice.json()
      _this.signMinPrice = getSignMinSellPrice[0].value / Math.pow(10, 8) * 2

      let regex2 = encodeUrl("waves_min_sell")
      let getWavesMinSellPrice = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.signOracle+"?matches="+regex2)
      getWavesMinSellPrice = await getWavesMinSellPrice.json()
      _this.wavesMinPrice = getWavesMinSellPrice[0].value / Math.pow(10, 8)
      _this.setMinSellPrice()
    },
    setTicker: function(e){
      this.sale.ticker = e.target.options[e.target.selectedIndex].text
    },
    setMinSellPrice: function(){
      if(this.sale.assetId == this.$store.state.config.assetID){
        this.currentMinSellPrice = this.signMinPrice
      }else if(this.sale.assetId == this.$store.state.config.usdnAssetID){
        this.currentMinSellPrice = this.usdnMinPrice
      }else if(this.sale.assetId == this.$store.state.config.usdtAssetID){
        this.currentMinSellPrice = this.usdtMinPrice
      }else if(this.sale.assetId == this.$store.state.config.usdtPPAssetID){
        this.currentMinSellPrice = this.usdtPPMinPrice
      }else{
        this.currentMinSellPrice = this.wavesMinPrice
      }
    },
    processSale: function(){
      this.updateOfferOnly()
      // MIGHT NEEDS TO HANDLE ALSO CUSTOM TX FEE HERE
      VueScrollTo.scrollTo('#content', 600, options)
      this.$store.state.alert=true
      this.$store.state.alertMessage='Sending data to the blockchain, Please wait...'
      //let invokeFee = this.$store.state.sponsoredFee * 5 * 10 ** 8;

      let decimal = this.sale.assetId == this.$store.state.config.usdnAssetID || this.sale.assetId == this.$store.state.config.usdtAssetID || this.sale.assetId == this.$store.state.config.usdtPPAssetID  ? 6 : 8

      const dataTx = {
        dApp: this.$store.state.config.dapp_addr_artworks,
        /* fee: invokeFee, 
        feeAssetId: this.$store.state.config.assetID,*/
            call: {
                function: "sellArtwork",
                 args:[
                    { type:"string", value: this.$route.params.txid },
                    { type:"integer", value: (this.sale.price * Math.pow(10, decimal)).toFixed(0)},
                    { type:"integer", value: this.sale.maxmint },
                    { type:"string", value: this.sale.assetId },
                    { type:"integer", value: this.sale.endHeight } // add *1440
                ]
            }
      }

      if(this.isWhiteListedTxs.isWhiteListed){
        dataTx.call.args.push({ type:"string", value: this.$store.state.user.publicKey})
        dataTx.senderPublicKey = this.$store.state.config.dapp_pubkey_artworks
        dataTx.fee = 500000
        dataTx.feeAssetId = null
      }else{
        dataTx.call.args.push({ type:"string", value: "" })
      }
      
      this.$store.dispatch("signerInvoke", dataTx).then(res => { 
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          if(res.error){
            this.sale.errorMessage = res.error.message ? res.error.message : res.error
            this.sale.haveError = true
          }else{
            // display confirmation message of success
            this.sale.onsale = true
            this.$store.state.alert=false
            this.$store.state.alertMessage=''
            this.haveError = false
           
            this.sale.errorMessage = ''
            this.saleWindow()
            //this.resetWindow()
            this.responseMessage = 'Sale successfully updated!'
            setTimeout(function(){
              this.responseMessage = ''
            }, 3000)
          }
      }) 
    },
    mintArtwork: function(){
        let _this = this;
        this.$store.state.alert=true
        this.$store.state.alertMessage='Processing the NFT Mint, Please wait...'
        //let invokeFee = _this.$store.state.sponsoredFee * 5 * 10 ** 8;
        
        const dataTx = {
          dApp: this.$store.state.config.dapp_addr_artworks,
        /*   fee: invokeFee, */
          /*feeAssetId: this.$store.state.config.assetID,
           ...invokeFee, */
              call: {
                  function: "buyArtwork",
                  args:[
                      { type:"string", value: _this.$route.params.txid },
                      { type:"string", value: _this.$route.params.addr }
                  ]
              }
        }
        
        dataTx.payment = [{ assetId: _this.$store.state.config.assetID, amount: _this.signMinPrice * Math.pow(10, 8) }]
        
        
        this.$store.dispatch("signerInvoke", dataTx).then(res => { 
            this.$store.state.alert=false
            this.$store.state.alertMessage=''
            if(res.error){
              // siplay error
              this.responseMessage = res.error.message ? res.error.message : res.error
              this.haveError = true
            }else{
              // display confirmation message of success
              this.$store.state.alert=false
              this.$store.state.alertMessage=''
              this.haveError = false
              this.responseMessage = 'NFT Minted successfully!'
              this.getArtworkData()
              setTimeout(function(){
                this.responseMessage = ''
              }, 3000)
            }
        }) 
    },
    resetWindow: function (){
        this.$data.display.label='Select the display file';
        this.$data.licence.label='Select the license file';
        this.$data.exported.label='Select the export file';
        document.getElementById("displayFile").value = ""
        document.getElementById("licenceFile").value = ""
        document.getElementById("exportFile").value = ""
        if(document.getElementById("crawlerFile")){
          this.$data.crawler.label='Select the crawler file';
          document.getElementById("crawlerFile").value = ""
        }
        this.imtheowner = false
        let progressElms = document.querySelectorAll(".progression")
        progressElms.forEach(function(elm){
          elm.classList.add("hide")
        })
    },
    processAdd: async function(){
      let _this = this
      VueScrollTo.scrollTo('#content', 600, options)
      this.checkMandatoryFields()
      if(this.haveError){
        this.$store.state.alert=false
        this.$store.state.alertMessage=''
        return
      }
      await this.checkUploadFiles()
      if(this.haveError){
        this.$store.state.alert=false
        this.$store.state.alertMessage=''
        return
      }

      this.$store.state.alertMessage='Sending your data to the Waves Blockchain...'

      
      //let invokeFee = _this.$store.state.sponsoredFee * 5 * 10 ** 8;
      const dataTx = {
        dApp: this.$store.state.config.dapp_addr_artworks,
        /* fee: invokeFee, 
        feeAssetId: this.$store.state.config.assetID,*/
            call: {
                function: "updateArtwork",
                 args:[
                    { type:"string", value: _this.$route.params.txid },
                    { type:"string", value: this.name },
                    { type:"string", value: this.description },
                    { type:"string", value: this.tags },
                    { type:"string", value: this.exported.type },
                    { type:"string", value: this.display.cid },
                    { type:"string", value: this.exported.fileHash },
                    { type:"string", value: this.exported.cid },
                    { type:"string", value: this.licence.fileHash },
                    { type:"string", value: this.licence.cid },
                    { type:"string", value: this.crawler.cid },
                    { type:"integer", value: this.royalties * Math.pow(10,2) }
                ]
            }
      }

      if(this.isWhiteListedTxs.isWhiteListed){
        dataTx.call.args.push({ type:"string", value: _this.$store.state.user.publicKey})
        dataTx.senderPublicKey = this.$store.state.config.dapp_pubkey_artworks
        dataTx.fee = 500000
        dataTx.feeAssetId = null
      }else{
        dataTx.call.args.push({ type:"string", value: "" })
      }

      this.$store.dispatch("signerInvoke", dataTx).then(res => { 
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          if(res.error){
            // siplay error
            this.responseMessage = res.error.message ? res.error.message : res.error
            this.haveError = true
          }else{
            // display confirmation message of success
            this.haveError = false
            this.mp4Alert = false
            this.resetWindow()
            this.responseMessage = 'Artwork successfully updated!'
            setTimeout(function(){
              this.responseMessage = ''
            }, 3000)
          }
      }) 

    },
    deleteArtworkConfirm: function(){
      this.showDeleteConfirmation = true
    },
    deleteArtwork: async function(){
      let _this = this
      this.haveError = false;
      this.responseMessage = ""
      this.$store.state.alert=true
      VueScrollTo.scrollTo('#content', 600, options)
      this.$store.state.alertMessage='Removing your artwork from data storage...'
      // let invokeFee = _this.$store.state.sponsoredFee * 5 * 10 ** 8;
      const dataTx = {
        dApp: this.$store.state.config.dapp_addr_artworks,
        /* fee: invokeFee, 
        feeAssetId: this.$store.state.config.assetID,*/
            call: {
                function: "deleteArtwork",
                 args:[
                    { type:"string", value: _this.$route.params.txid },
                    { type:"string", value: _this.$route.params.addr },
                ]
            }
      }
      this.$store.dispatch("signerInvoke", dataTx).then(res => { 
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          if(res.error){
            // siplay error
            this.responseMessage = res.error.message
            this.haveError = true
          }else{
            // display confirmation message of success
            this.haveError = false
            this.resetWindow()
            this.responseMessage = 'Artwork successfully removed!'
            this.$router.push('/user/'+_this.$route.params.addr+'/account/artworks/list');
            setTimeout(function(){
              this.responseMessage = ''
            }, 3000)
          }
      })
    },
    detectClickOutOfSalePopin: function(e){   
      if(this.sale.salePopinOpen){
        if (!document.getElementById('salePopin').querySelector(".wrapper").contains(e.target)){
          this.sale.salePopinOpen = false
          this.sale.errorMessage = ''
          this.sale.haveError = false
          document.getElementById("content").style.overflow="auto"
        }
      }
    },
    detectClickOutOfBuyOnePopin: function(e){   
      if(this.buyOneConfirm){
        if (!document.getElementById('buyOnePopin').querySelector(".wrap").contains(e.target)){
          this.buyOneConfirm = false
        }
      }
    },
    disableDate: function(date){
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    disableTime: function(date){
      return date.getTime() + 60000 < new Date().getTime() + this.minEndHeight * 60000
    },
    clearDatePicker: function(){
      this.sale.endHeight = 0
      this.sale.datePicked = null
    },
    checkEndHeight: async function(){
      let currentHeight =  await this.getCurrentHeight();
      let endHeight = await this.getAuctionEndHeight(this.$route.params.txid, this.$route.params.addr);
      if(endHeight > 0){
        let remainingMinutes = endHeight - currentHeight
        this.sale.endHeight = remainingMinutes
        this.sale.auctionType = true
        this.sale.datePicked = new Date().getTime() + remainingMinutes*60000
      }
    },
    updateOfferOnly: function(){
      if(this.sale.price == 0 && this.sale.price != ""){
          this.sale.offerOnly = true
        }
    }
  },
  watch: {
    'sale.datePicked'(date){
      if(date != null){
        var today = new Date();
        var diffMs = (date - today); // milliseconds between now & Christmas
        let minutes = Math.floor(diffMs/1000/60);
        this.sale.endHeight = minutes
      }
    },
    'sale.offerOnly'(offerOnly){
      this.sale.price = offerOnly ? 0 : this.sale.price
      if(!this.sale.offerOnly && this.sale.price == 0){
        this.sale.price = 1000
        this.sale.auctionType = false
        this.sale.endHeight = 0
      }
    },
    'sale.maxmint'(maxmint){
      if(maxmint > 1 || maxmint == 0){
        this.sale.auctionType = false
        this.sale.endHeight = 0
      }
    },
    deleteConfirmed: function(){
      if(this.deleteConfirmed){
        this.deleteArtwork()
      }
    },
    'sale.auctionType'(auctionType){
      if(auctionType){
        this.sale.offerOnly = true
        if(this.sale.endHeight == 0){
          this.sale.datePicked = new Date().getTime() + (this.minEndHeight * 60000)
        }
      }
      if(!auctionType){
        this.sale.endHeight = 0
      }
    },
    'sale.endHeight'(endHeight){
      if(this.sale.auctionType && endHeight == 0){
        this.sale.auctionType = false
        this.sale.endHeight = 0
        this.sale.datePicked = null
      }
    },
    'sale.price'(){
      this.updatePriceToUsd()
      if(this.sale.price != 0){
        this.sale.offerOnly = false
      }
      
    },
    'sale.assetId'(){
      this.updatePriceToUsd()
    },

  },

  mounted(){
    document.querySelector(".wrap").classList.add("show")
    document.getElementById('salePopin').addEventListener('mousedown', this.detectClickOutOfSalePopin);
    document.getElementById('buyOnePopin').addEventListener('mousedown', this.detectClickOutOfBuyOnePopin);
  },
  beforeDestroy() {
    if(document.getElementById('salePopin')){
      document.getElementById('salePopin').removeEventListener('mousedown', this.detectClickOutOfSalePopin);
    }
    if(document.getElementById('buyOnePopin')){
      document.getElementById('buyOnePopin').removeEventListener('mousedown', this.detectClickOutOfBuyOnePopin);
    }
  }
}
</script>
<style lang="scss">
  .mx-datepicker-popup{
    z-index: 6000!important;
  }
</style>
<style lang="scss" scoped>
#crawlerPreview{
  padding: 15px;
  margin-bottom: 20px;
  background-color: #fff1f6;
}
  .invert-color{
    -webkit-filter: invert(1) sepia(1) saturate(0) hue-rotate(0);
    filter: invert(1) sepia(1) saturate(0) hue-rotate(0);
  }
  .smallBalance{
    font-size: 14px;
    color: #979797;
    text-align: center;
  }
  .pe-none{
    pointer-events: none;
  }
  #buyOnePopin{
    background-color: rgba(0,0,0,0.8);
    height: 100%;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 50000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    .wrap{
      width: 100%;
      max-width: 400px;
      text-align: center;
      background-color: white;
      padding: 20px;
      min-height: inherit;
      p{
        color: #000000;
        .irreversible{
          color: red;
          font-weight: bold;
        }
      }
      button{
        padding: 5px 15px;
        border-radius: 5px;
      }
    }
  }
  .minted{
    color: #57137b;
    text-align: center;
    background-color: #feebfd;
    border-radius: 5px;
  }
  
  .nftIssuedCounter{
    font-size: 13px;
    color: #36942a;
    &.rouge{
      color: orangered;
    }
  }
  #delete{
    display: inline-block;
    color: white;
    background-color: #c60909;
    margin-right: 25px;
    cursor: pointer;
    @media(max-width:404px){
      margin-right: 0;
    }
  }
  .confirmDelete{
    font-weight: bold;
    color:#c60909;
  }
  #toCertify{
    padding-top:10px;
    background-color: #fbf3ff;
    .wrap_file_field{
      flex-direction: row;
      .copyHash{
        justify-content: center;
        display: flex;
        align-items: center;
        width: 100%;
      }
    }
  }
  #addFile{
    color: black;
  }
  .fileHash{
    display: none;
  }
  .fileHash.show{
    display: inherit;
    cursor: pointer;
  }
  .progression{
    width: 0%;
    height: 5px;
    background-color: #57137b;
    z-index: 3;
    bottom: 0;
    margin-bottom: 10px;
    margin-top: 10px;
    position: relative;
  }
  .goback{
    cursor: pointer;
  }
  .progression .counter {
    position: absolute;
    bottom: -20px;
    right: 0;
    color: #57137b;
    font-size: 14px;
  }
  textarea{
    min-height: 116px;
  }

  .currentDisplay{
    span{
      position: absolute;
      z-index: 2;
      background-color: #57137b;
      color: white;
      padding: 3px 10px;
      font-size: 12px;
    }
  }
  .links{
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0;
    flex-wrap: wrap;

    & li{
      margin-top: 5px;
      i{
            font-size: 14px;
      }
      a{
        display: flex;
        align-items: center;
      }
    }

    @media(max-width: 768px){
      width:100%;
      align-items: center;
      & li{
        margin-top: 0;
        margin-bottom: 10px;
      }
    }
  }
</style>