<template>
  <div class="col-md-8 col-lg-8 col-xl-12 px-1 faqPage" ref="faqPage" id="content">
    
      <div class="w-100 wrap_faq pt-4 centerH" ref="faqPageContainer" >
        <transition name="fade">
        <div class="container" v-show="show">
          <div class="row">

            <div class="col-lg-4 mb-lg-0 mb-3">
              <h3>GENERAL</h3>
              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1" v-for="(general, key) in generals" :key="key">
                  <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button block v-b-toggle="'accordion-general-'+key" variant="info">{{ general.ask }}</b-button>
                  </b-card-header>
                  <b-collapse :id="'accordion-general-'+key" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <b-card-text v-html="general.answer"></b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>

            <div class="col-lg-4 mb-lg-0 mb-3">
              <h3>ACCOUNT</h3>
              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1" v-for="(account, key) in accounts" :key="key">
                  <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button block v-b-toggle="'accordion-account-'+key" variant="info">{{ account.ask }}</b-button>
                  </b-card-header>
                  <b-collapse :id="'accordion-account-'+key" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <b-card-text v-html="account.answer"></b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>

            <div class="col-lg-4 mb-lg-0 mb-3">
              <h3>COST, TOKEN &amp; EXTRA</h3>
              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1" v-for="(signart, key) in signarts" :key="key">
                  <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button block v-b-toggle="'accordion-signart-'+key" variant="info">{{ signart.ask }}</b-button>
                  </b-card-header>
                  <b-collapse :id="'accordion-signart-'+key" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <b-card-text v-html="signart.answer"></b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>

          </div>
        </div>
        </transition>
      </div>
    
  </div>

</template>

<script>
import globalMixin from '@/mixins/global.js';

export default {
  mixins: [globalMixin],
  data: function(){
    return {
      show: false,
      generals:[
      {
        attr: 'v-b-toggle.accordion-1',
        ask: "What is SIGN Art?",
        answer: `SIGN Art is a web gallery for Waves Blockchain certified Digital Art and a marketplace for NFTs. Artists can create a portfolio of digital creations, tokenize and sell NFTs. Collectors can buy NFTs and display NFT-collection.`
      },
      {
        attr: 'v-b-toggle.accordion-2',
        ask: "What is an NFT?",
        answer: `NFT, also known as a “Non Fungible Token” is a unique cryptographic token which represents ownership of a digital asset. Artists create a single or limited copies of their creation, therefore, creating scarcity and value. NFT can be generated to show ownership, establishing the creator of the artwork as the issuer of the NFT. The possession of the artwork and the NFT means a verified and undisputable transfer of ownership and the corresponding value.`
      },
      {
        attr: 'v-b-toggle.accordion-3',
        ask: "Why the Waves Blockchain?",
        answer: `The Waves Blockchain is highly scalable and offers instant transactions at a low cost of >$0.01, which is a great advantage in the NFT Art Industry.`
      },
      {
        attr: 'v-b-toggle.accordion-4',
        ask: "Can I sell physical art?",
        answer: `Currently it’s not possible to sell physical art on SIGN Art in an official way. `
      },
      {
        attr: 'v-b-toggle.accordion-5',
        ask: "Why do I need to certify my art?",
        answer: `All Artists are required to certify the source of their creations in order to prove authenticity. The hash ID of the source file will be publicly stored in the blockchain, with a timestamp. No file is uploaded or stored, file hash is calculated locally on client side.`
      },
      {
        attr: 'v-b-toggle.accordion-6',
        ask: "How are files stored on SIGN Art?",
        answer: `SIGN Art data are stored using the blockchain and IPFS. All files submitted by users are stored on IPFS.`
      },
      {
        attr: 'v-b-toggle.accordion-7',
        ask: "What do I get when purchasing an NFT?",
        answer: `You will gain the ownership of the full HD export of the Art, as well as the license and the NFT. The NFT will be added to your collection in your profile. You can transfer this NFT to other people, and in the near future resell it on the secondary market.`
      }
      ],
      accounts:[
      {
        attr: 'v-b-toggle.accordion-8',
        ask: "How do I get an account?",
        answer: `On the SIGN Art application, go to the upper right corner icon and click on "Log In", then follow the account creation process, or select an account if you already have one on the. Accounts are linked to the <a href="https://waves.exchange" target="_blank">waves.exchange</a>, learn more about <a href="https://support.waves.exchange/en/articles/3903524-how-to-create-an-account" target="_blank">account creation here</a>.<br/><br/>
        <span class="red">Always backup your account passphrase (seed) if you choose a SEED account as clearing your browser cache will also clear the saved account.</span>`
      },
      {
        attr: 'v-b-toggle.accordion-9',
        ask: "How do I backup my account?",
        answer: `Go to waves.exchange and login. Go to Account Settings > Security, and securely save your seed phrase. The seed will be the access to your account, once removed from your browser local storage.`
      },
      {
        attr: 'v-b-toggle.accordion-10',
        ask: "How do I get access as an artist? **",
        answer: `Artists can submit an application to gain access to the platform by using the following form:<br/><br/>
        <a href="https://sign-art.app/forms/regular-access-form" target="_blank">REGISTRATION FORM</a>`
      },
      {
        attr: 'v-b-toggle.accordion-11',
        ask: "How do I get the APPROVED status? **",
        answer: `Registered artists can submit an additional application to get APPROVED, using the following form:<br/><br/> <a href="https://sign-art.app/forms/approved-status-form" target="_blank">APPROVED FORM</a><br/><br/>Be aware that our committee require applicant account to be at least 2 weeks old and to have at least 3 artworks sold or for sale to be eligible for approved status.`
      },
      {
        attr: 'v-b-toggle.accordion-12',
        ask: "How do I manage my funds?",
        answer: `Management of funds can be done on <a href="https://www.waves.exchange" target="_blank">Waves.exchange</a>. SIGN Art uses the same account which you have in waves.exchange. If you do not have a waves.exchange account before signing up to SIGN Art, the platform will automatically generate one for you on your first log in. Reminder to save your seed! You can transfer and trade your funds on the <a href="https://www.waves.exchange" target="_blank">waves.exchange</a>.`
      },
      {
        attr: 'v-b-toggle.accordion-13',
        ask: "How do I import my account using my seed?",
        answer: `Go to waves.exchange and click on Get Started> Create a Password> Import Accounts> Seed or Key. Enter your seed and continue.`
      },
      {
        attr: 'v-b-toggle.accordion-13',
        ask: "Why do I get an HTTPError?",
        answer: `In some very rare cases, an HTTPError prevents an artist from uploading their creation to the IPFS node, it is due to something blocking the connection to the IPFS Node from the artist's side.<br/><br/>
        Here is a list of different options that may resolve the issue:<br/><br/>
        <ul class="listDisc mb-3">
          <li>Check your upload speed (Slow upload under 2Mb is often the reason of HTTPError)</li>
          <li>Clear your browsing data (make sure you save your waves account seed)</li>
          <li>Clear Chrome/Brave Host Cache (go to chrome://net-internals/#dns then click "Clear host cache"</li>
          <li>Temporarily Disable your AntiVirus</li>
          <li>Temporarily Disable your Firewall</li>
          <li>Temporarily Disable your VPN</li>
          <li>Temporarily Disable any browser extensions that could be interfering</li>
          <li>Try a non Chromium browser (Opera or Edge)</li>
          <li>Try using a different device or internet network to identify if the issue is related to your device or your provider.</li>
        </ul>
        
        You can also read more about this problem <a href="https://windowshelper.co/err_empty_response/" target="_blank">here</a> and <a href="https://www.minitool.com/news/err-empty-response.html" target="_blank">here</a>`
      }
      ],
      signarts:[
      {
        attr: 'v-b-toggle.accordion-14',
        ask: "What commission does SIGN Art take?",
        answer: `SIGN Art takes a 7% commission on all successful sales that occur using $SIGN tokens, while 10% commission on sales that occur using $XTN, $USDT-PP and $WAVES.`
      },
      {
        attr: 'v-b-toggle.accordion-15',
        ask: "How much does it cost to add a creation?",
        answer: `SIGN Art takes a $1 fee in SIGN tokens to certify and mint an artwork (with up to 10 editions) on the platform.`
      },
      {
        attr: 'v-b-toggle.accordion-16',
        ask: "What is $SIGN token?",
        answer: `SIGN is a utility token across all Signature Chain products and services, including SIGN Art, SIGN Web App and all future services.`
      },
      {
        attr: 'v-b-toggle.accordion-17',
        ask: "How do I get $SIGN tokens?",
        answer: `SIGN can be purchased on the waves.exchange and STEX.com. To find a detailed guide on how to purchase SIGN using the waves.exchange find the following guide:<br/><br/>
        <a href="https://www.youtube.com/watch?v=o3T3b1jbm9o" target="_blank">https://www.youtube.com/watch?v=o3T3b1jbm9o</a>`
      },
      {
        attr: 'v-b-toggle.accordion-17',
        ask: "Can I get more details about the fees?",
        answer: `You can see the complete details of the transaction fees and service fee on the following image: <a href="/files/sign-art-fee.jpg" target="_blank">Fee detail</a>`
      },
      {
        attr: 'v-b-toggle.accordion-17',
        ask: "Are the identities of artists checked?",
        answer: `SIGN Art verifies that the social media accounts used by the applicant are indeed their accounts. For this we require them to reach out to us from one of these accounts or to add a message explicitly stating they are applying to SIGN Art. There is no identity check and collectors should always do their own research before buying from an artist.`
      }
      
      ]
    }
  },
  methods:{
    onResize: function(){
      if(this.$refs.faqPage.offsetHeight > this.$refs.faqPageContainer.querySelector(".container").offsetHeight){
        this.$refs.faqPageContainer.classList.add("centerH")
      }else{
        this.$refs.faqPageContainer.classList.remove("centerH")
      }
    }
  },
  mounted(){
    window.addEventListener('load', this.onResize)
    window.addEventListener('resize', this.onResize)
    this.show = true
    this.$nextTick(() => {
			window.dispatchEvent(new Event('resize'));
		});
  },
  beforeDestroy() {
    window.removeEventListener('load', this.onResize)
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss" >
  .listDisc{
    li{
      padding-left: 15px;
      position: relative;
      &:before{
        content: "";
        display: block;
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #ca26c0;
        left: 0px;
        top: 8px;
      }
    }
  }
  .faqPage{
    background-color: #6c497f;
    height: 100%!important;
    display: flex!important;
    align-items: center!important;
    padding-top: 50px;
    padding-bottom: 50px;
      .card-text{
        color: #57137b;
        padding: 20px;
        font-size: 14px;
        .red{
          color: red!important;
          background-color: transparent;
        }
      }
      h3{
        font-size: 1.3rem;
        margin-bottom: 10px;
      }


    .btn-info{
      background-color: white!important;
      color: #57137b!important; 
      border-radius: 0px;
      &:hover{
        background-color: #ca26c0!important;
        color: white!important;
      }
    }
    .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle{
      
      background-color: #ca26c0!important;
    }
    .btn-info:focus, .not-collapsed{
      background-color:#ca26c0!important;
      color: white!important;
    }
  }
  .centerH{
    min-height: 100%;
    display: flex;
    align-items: center;
  }
</style>