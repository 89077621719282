<template>
  <transition name="slide-fade" :duration="3000">
    <div id="connected" v-show="alert">
      Connected
    </div>
  </transition>
</template>

<script>
export default {
  data: function(){
    return {
      alert: false
    }
  },
  watch:{
    '$store.state.user.isLogged': function(){
      let _this = this
      this.alert = true
      setTimeout(function(){
        _this.alert = false
      }, 2000)
    }
  }
}
</script>
<style lang="scss">
  #connected{
    position: absolute;
    width: 300px;
    margin: auto;
    left: 50%;
    transform: translateX(-50%);
    top: 0px;
    background-color: rgba(186, 83, 240, 0.92);
    padding: 10px;
    z-index: 1000;
    text-align: center;
    border-radius: 5px;
    transition: all .4s ease;

    &.slide-fade-enter-active {
      transition: all .3s ease;
    }
    &.slide-fade-leave-active {
      transition: all .6s ease;
    }

    &.slide-fade-enter-to{
      opacity: 1;
      top: 40px;
    }
    &.slide-fade-leave-to{
      opacity: 0;
      top: 0px;
    }
  }


</style>