<template>
  <div>
      <div id="activity" class="container-fluid">
          <!-- <h2 class="mb-5 mt-5 text-center">Recent activity</h2> -->
          <div class="row">
            <div class="col-lg-12 d-flex justify-content-center mb-3 nav_activity">
              <button class="d-block btn btn-primary" :class="{'active': tabeToShow=='drops'}" id="new_drop_btn" @click="tabeToShow = 'drops'">{{ deskopXL ? 'New drops' : 'Drops' }}</button>
              <button class="d-block btn btn-primary" :class="{'active': tabeToShow=='marketplace'}" id="marketplace_btn" @click="tabeToShow = 'marketplace'">Marketplace</button>
              <button class="d-block btn btn-primary" :class="{'active': tabeToShow=='artists'}" id="artists_btn" @click="tabeToShow = 'artists'">Artists</button>
            </div>
            <div class="col-lg-12">
              <div id="data">
                  <activity-drops v-if="tabeToShow=='drops'"></activity-drops>
                  <activity-marketplace v-else-if="tabeToShow=='marketplace'"></activity-marketplace>
                  <activity-artists v-else></activity-artists>               
              </div>

            </div>
          </div>
      </div>
  </div> 
</template>

<script>
import ActivityDrops from '@/components/content/activity/drops.vue'
import ActivityMarketplace from '@/components/content/activity/marketplace.vue'
import ActivityArtists from '@/components/content/activity/artists.vue'
export default {
  data: function(){
    return {
      tabeToShow: "drops",
      deskopXL:  false
    }
  },
    components:{
    'activity-drops': ActivityDrops,
    'activity-marketplace': ActivityMarketplace,
    'activity-artists': ActivityArtists
  },
  mounted(){
    this.onResize()
  },
  created() {
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      if (window.innerWidth > 1335) {
        this.deskopXL = true
      } else {
        this.deskopXL = false
      }
    },
  }
}
</script>
<style lang="scss">
#activity{
  color: black;
}
#activity h2{
  margin-bottom: 10px;
}
#activity h3{
  font-size: 20px;
}
#activity h4{
  font-size: 16px;
}
#activity #data .new{
  padding: 10px 0;
  border-bottom: 1px solid rgb(231, 231, 231);
}
#activity #data .wrapper_img {
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  display: block;
  max-width: 80px;
  max-height: 80px;
}
#activity #data .wrapper_img img {
  width: 100%;
  vertical-align: top;
  position: absolute;
  top: 50%;
  left: 0;
  height: 100%;
  max-width: 80px;
  max-height: 80px;
  object-fit: cover;
  border-radius: 10px;
  transform: translateY(-50%);
}

#activity #data h3{
  margin-bottom: 0px;
}
#activity #data .date{
  font-size: 14px;
  color:rgb(150, 150, 150);
}
#activity #data .price strong{
  font-weight: 500;
}

#activity .nav_activity button{
  background-color: rgb(187, 187, 187);
  border: 0;
  margin-right: 15px;
  transition: all .4s ease;
  &:last-child{
    margin-right: 0;
  }
}
#activity .nav_activity button.active, #activity .nav_activity button:hover{
  background-color: rgb(108, 48, 145);
}
#activity .btn-check:active+#activity .btn-primary:focus, #activity .btn-check:checked+#activity .btn-primary:focus, #activity .btn-primary.active:focus, #activity .btn-primary:active:focus, #activity .show>.btn-primary.dropdown-toggle:focus{
  box-shadow: none;
}

@media(max-width: 992px){
  #activity{
    max-width: 940px;
  }
  #activity .nav_activity {
    margin-top: 20px;
  }
}

@media(max-width: 768px){
  #activity #data .wrapper_img{
    padding-top: 80px;
  }
  
}
</style>