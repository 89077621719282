<template>
  <div class="col-md-8 col-lg-8 col-xl-12 px-1 userPage wrap" id="content">
    <div>
      <div v-if="!userExist" id="missingUser">Redirect to owned...</div>
      <div v-else>
          <app-user-infos :isBanned="isBanned" :artistInfos="artistInfos" :infosLoaded="infosLoaded" :userExist="userExist" :hasProfile="hasProfile" :userNote="userNote" :collectorInfos="collectorInfos"></app-user-infos>
          <app-filters :showArtistStatus="false" class="mt-1"></app-filters>
          <div v-show="loading" class="loading">
            <app-loading :label="'data'"></app-loading>
          </div>
          <div id="nocontent" class="mb-4" v-if="!loading && Object.keys(allArtworks).length === 0">
            <div v-if="!this.$route.params.pageindex">There are no artworks available yet.</div>
            <div v-else>There are no artworks available</div>
          </div>
          <div class="grid" v-show="!loading && Object.keys(allArtworks).length">
          <div class="grid-sizer"></div>
          <div class="grid-item" v-for="(artwork, key) in allArtworks" :key="key" :data-index="key" @mousedown="storeArtworkClickedIndex($event)">
            <app-artwork-item :artwork="artwork" :showArtist="false" :keyIndex="key"></app-artwork-item>
          </div>
          </div>
          <div class="wrap_pagination" >
            <router-link :to="{ name: 'user-paginate', params: { pageindex: (+this.$route.params.pageindex-1) || 0 }, addr: this.$route.params.addr}" tag="button" class="btn btn-primary mb-2 bigger loadNFT loadprev" :class="{'no-mr': fullyLoaded}" v-if="this.$route.params.pageindex && this.$route.params.pageindex != 1" >PREVIOUS</router-link>
            <router-link :to="{ name: 'user-paginate', params: { pageindex: (+this.$route.params.pageindex+1) || 2 }, addr: this.$route.params.addr}" tag="button" class="btn btn-primary mb-2 bigger loadNFT loadnext" v-if="!fullyLoaded">NEXT</router-link>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Masonry from "masonry-layout";
import UserInfos from '@/components/elements/UserInfos.vue'
import Loading from '@/components/elements/Loading.vue'

import imagesLoaded from 'imagesloaded';
import globalMixin from '@/mixins/global.js';
import userMixin from '@/mixins/userMixin.js';

import ArtworkItemApi from '@/components/content/artwork/ArtworkItemApi.vue'
import Filters from '@/components/elements/Filters.vue'
import orderedArtworks from "@/mixins/orderedArtworks.js"
import listingArtworks from "@/mixins/listingArtworks.js"

export default {
  mixins: [userMixin, globalMixin, orderedArtworks, listingArtworks],
  data: function(){
    return {
      addr: this.$route.params.addr,
      detailOpen: false,
      imgix: undefined,
      tempPaddingTopContent: 0,
      allArtworks: undefined,
      loading: true,
      artPerPage: 30,
      queryType: 2, // 1 = 2 per artists overall, 2 = 2 per artist then 3 per artist etc, 3 = 2 per artists then all remaining by date
      fullyLoaded: false,
      callingApi: false,
      cursor: '',
      currentPage: 1,
      filters: this.$store.state.filtering.artist
    }
  },
  components: {
    'app-user-infos': UserInfos,
    'app-loading': Loading,
    'app-artwork-item': ArtworkItemApi,
    'app-filters': Filters
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    }
  },
  created(){
    this.init()
  },
  methods: {
    init: async function(){
      await this.$store.dispatch("getPriceInUsd")
      this.$nextTick(() => { // IF PERFORMANCE PROBLEM, see to run this once only
        if(window.msnry != undefined){
          this.$nextTick(() => {
            window.msnry.layout();
          }) 
        }
      })
    },
    storeArtworkClickedIndex: function(e){
      this.$store.state.artistScrollState.scrollToId = e.target.id
    },
    updateMsnryLayout: function(){
      if(window.msnry){
        window.msnry.layout();
        if(document.getElementById(this.$store.state.artistScrollState.scrollToId)){
          document.getElementById(this.$store.state.artistScrollState.scrollToId).scrollIntoView()
          this.$store.state.artistScrollState.scrollToId = 0
        }
        var imgLoad = imagesLoaded('.grid');
        imgLoad.on( 'progress', function(  ) {
          window.msnry.layout();
        });
      }
    }
  },
  watch: {
    allArtworks: function(){
      if(document.querySelector(".grid")){
        document.querySelector(".grid").classList.add("show")
      }
      this.$nextTick(() => { // IF PERFORMANCE PROBLEM, see to run this once only
        window.msnry = new Masonry('.grid', {
          percentPosition: true,
          initLayout: false,
          itemSelector: ".grid-item",
          columnWidth: ".grid-sizer",
        });
        
        this.updateMsnryLayout()
        this.$nextTick(() => {
          this.updateMsnryLayout()
        }) 
      })
    },
    $route(to){
      if(to.name == "user-paginate" || to.name == "user-infos-front"){
        this.loadArtworksFromApi(this.$route.params.addr, true)
      }
    },
    '$route.params.addr'(){
      this.artistInfos={}
      this.getArtistInfos()
    }

  },
  mounted(){
    document.getElementById('main').scrollTop = 0
    document.getElementById("main").classList.add("show")
    if(document.getElementById(this.$store.state.artistScrollState.scrollToId)){
      document.getElementById(this.$store.state.artistScrollState.scrollToId).scrollIntoView()
      this.$store.state.artistScrollState.scrollToId = 0
    }
  }
}
</script>
<style lang="scss" scoped>
  .wrap_pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    //position: sticky;
    margin-top: 15px;
    padding-bottom: 15px;
    bottom: 20px;
    z-index: 5000;
    pointer-events: none;
    .loadnext, .loadprev{
      pointer-events:all;
      margin: initial;
      margin-right: 30px;
      &.no-mr{ margin-right: 0px;}
    }
  }
  #homeGridWrapper{
    padding-bottom: 20px;
  }
  </style>