<template>
<div>
<div id="user_info" class="lite">
  <div class="wrap_infos row" v-if="infosLoaded">
    <router-link :to="'/user/'+artistInfos.user_addr" @click.native="resetArtistFilter(); resetArtistScroll();" exact class="d-flex col-md-6 px-0">
      <div class="thumb mr-0 mr-lg-3 mb-lg-0 mb-1 position-relative">
        
          <img :src="artistInfos.user_thumb != '' ? getIpfsLink(artistInfos.user_thumb) : emptyThumb" class="img-fluid" />
        
        <span v-show="artistInfos.user_status == 'VERIFIED'" v-b-tooltip.hover.right="{ customClass: 'toolTipSp' }" title="Artist approved" class="wrap_thumb"><i class="fas fa-check-circle"></i></span>
      </div>
      <div class="details mr-0">
        <div class="wrap_title_date">
          <h3 class="wrap_title">
            <span class="wrap_creator d-flex flex-column">
              <span class="creator mb-0">Creator <i class="fa-brands fa-ethereum crosschain mini ml-2" @click="showCrosschainChoice = !showCrosschainChoice; showSort = false" v-b-tooltip.hover.top title="NFTs from this artist can be sent to Ethereum" v-if="artistInfos.user_ERC20 && artistInfos.user_ERC20 != ''"></i></span>
              <span class="userName">

                  {{ artistInfos.user_name }} 

              </span>
              
            </span>
          </h3>
        </div>
      </div>
    </router-link>
    <div v-if="collection" class="collection d-flex align-items-center col-md-6 px-0">
      <router-link :to="'/user/'+this.$route.params.addr+'/collection/'+collection.id+'/page/1'" exact class="d-flex w-100">
        <img :srcset="imgix" class="cover"/>
        <div class="collection-text text-left d-flex flex-column justify-content-center"><div>Collection</div><span class="collection-name">{{collection.name}}</span></div>
      </router-link>
    </div>
  </div>
  </div>
  <div v-if="isBanned" id="suspended" class="text-center">
    <div v-if="artistInfos.user_status == 'SUSPENDED' || artistInfos.user_status == 'CHANGE_REQUIRED'"><span>This artist has been {{ artistInfos.user_status == 'SUSPENDED' ? 'SUSPENDED' : 'FLAGGED'}}.</span><span v-if="userNote!=''">Reason: {{ userNote }}</span><span v-if="artistInfos.user_status != 'SUSPENDED'">- Buy at your own risk.</span></div>
  </div>
</div>
</template>

<script>
import globalMixin from '@/mixins/global.js';

export default {
  mixins: [globalMixin],
  props: ['isBanned', 'artistInfos', 'infosLoaded', 'userExist', 'hasProfile', 'userNote', 'collection'],
  data: function(){
    return {
      detailOpen: false,
      canEdit: false,
      imgix: null
    }
  },
  created(){
    //this.init()

  },
  watch:{
    collection: function(){
      this.getImgix()
    }
  },
  methods: {
    getImgix: async function(){
      let imgixData = [{
        id: 0, url: this.getIpfsLink(this.collection.cover)
      }]
      try{
      let response = await fetch(this.$store.state.config.signArtApiURL+"/imageboss", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          },
          body: JSON.stringify(imgixData)
        });
        const imgix = await response.json();
        this.imgix = imgix.res[0].url
      }catch(e){
        console.log(e)
      }
    }
  }
}
</script>
<style lang="scss" scoped>

  #user_info .fa-check-circle{
    font-size: 22px;
    color: #7F00FF;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }
  #user_info{
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 20px;
    .collection{
      color: #1E1A1A;
      padding-left: 10px;
      .collection-text{
        line-height: 20px;
      }
      .cover{
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 50%
      }
      a{
        color: #1E1A1A;
        img{
          margin-right: 15px;
        }
        .collection-name{
          font-weight: bold;
        }
      }
    }
    .userName a{
      color: #1e1a1a;
    }
    strong{
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 130%;
      text-transform: uppercase;
      color: #1E1A1A;
      opacity: 0.5;
    }
    .thumb{
      img{
        width: 50px;
        height: 50px;
      }
    }
    .wrap_infos{
      padding: 10px;
      display: flex;
      align-items: stretch;
      background-color: white;
      border-top: 0;
      margin-bottom: 0px;
      padding-left: 0;
      /* border-bottom: solid 2px black; */
      padding-bottom: 20px;
      background-color: whitesmoke;
      padding: 10px;
      margin-top: 15px;
      border-radius: 5px;
      transition: all .4s ease;
      &:hover{
        opacity: 0.8;
      }
      h3{
        border: 0px!important;
        margin-bottom: 0!important;
        padding-bottom: 0!important;
        font-size: 18px!important;
      }
    }
  }

  .wrap_title_date{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wrap_thumb{
    position: absolute;
    right: -4px!important;;
    top: 5px;
    background-color: white;
    border-radius: 50%;
    .fa-check-circle{
      font-size: 18px!important;
    }
  }
  .banned{
    background-color: white;
    border-radius: 20px;
    padding: 3px 10px;
    margin-left: 10px;
    color: red;
    font-size: 14px;
  }
  .wrap_creator{
    font-size: 16px;
    line-height: 20px;
  }
  .creator{
    font-weight: 400;
    
   /*  background-color: #9A0E88;
    padding: 3px 15px 3px 15px;
    color: white;
    border-radius: 50px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 0px;
    margin-left: 15px;
    line-height: 14px;
    letter-spacing: 0.3px;
    font-style: normal;
    line-height: 147%; */
  }

  .noprofile{
    padding: 10px 0;
  }

  
  @media(max-width: 1200px){
    #user_info .details h3{
      flex-direction: column;
      align-items: flex-start;
    }
  }
  @media(min-width: 768px) and (max-width: 1024px){
    .wrap_infos{
      //flex-direction: column;
      .wrap_title_date{
        width: 100%;
        .wrap_title{
          //flex-direction: column;
          margin: auto;
          width: 100%;
        }
      }
      .thumb{
        text-align: center;
        flex: 0!important;
      }
    }
  }

  @media(max-width: 1024px){
    
    #user_info{
      .details h3{
        //flex-direction: column;
        align-items: start;
      }
      a{
        justify-content: center;
        align-items: center;
      }
      .details{
        flex: inherit!important;
      }
      .thumb{
        margin-left: inherit;
        margin-right: 15px !important;
      }
    }
  }

  @media(max-width: 768px){
    #user_info{
      .collection{
        margin-top: 20px;
      }
    }
    .creator{
      margin-bottom: 2px;
    }
    .wrap_title{
      margin-top: 5px;
      display: flex;
    }
    .wrap_title_date{
      flex-direction: column;
      h3{
        flex-direction: column;
      }
    }

    .noprofile{
      
      width: 70%!important;
      margin: auto;
      .d-flex{
        flex-direction: column;
        width: 100%;
        justify-content: center;
        span{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
      }
      
    }
  }
</style>