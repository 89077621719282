<template>
<div>

<div id="user_info" class="collector">
  <div class="wrap_infos" v-if="infosLoaded">
    <div class="thumb mr-0 mr-lg-4 mb-lg-0 mb-1 position-relative">
      <router-link :to="'/user/'+collectorInfos.user_addr" @click.native="resetArtistFilter(); resetArtistScroll()" exact>
        <img :src="collectorInfos.user_thumb != '' ? getIpfsLink(collectorInfos.user_thumb) : emptyThumb" class="img-fluid" />
      </router-link>
      <span v-show="collectorInfos.user_status == 'VERIFIED'" v-b-tooltip.hover.right="{ customClass: 'toolTipSp' }" title="Artist approved" class="wrap_thumb"><i class="fas fa-check-circle"></i></span>
    </div>
    <div class="details mr-0">
      <div class="wrap_title_date">
        <h3 class="wrap_title">
          <span class="wrap_creator d-flex align-items-center">
            <span class="userName">
              {{ collectorInfos.user_name }} 
            </span>
            <span class="creator">collector</span>
          </span>
          <span class="wrap_icons">
            <span class="joining_date">Since {{collectorInfos.user_date | setDate}}</span>
            <div class="social-tabs">
              <app-social :user_social="collectorInfos.user_social"></app-social>
            </div>
          </span>
        </h3>
        
      </div>
      <div class="address"><strong>Address:</strong> {{ collectorInfos.user_addr }}</div>
      <div class="intro"><strong>Introduction:</strong> <span v-show="!detailOpen">{{ shortDesc(collectorInfos.user_desc) }}...</span> <span class="more" @click="showDetail"><img src="../../assets/images/arrow-link.svg" :class="{'open':detailOpen}"/></span></div>
      <div :class="detailOpen ? 'desc_full pr-0 pr-lg-4 mb-4 show' : 'desc_full pr-0 pr-lg-4'">{{ collectorInfos.user_desc }}</div>
    </div>
  </div>
  <div v-else class="wrap_infos">
    <div v-if="userExist" class="w-100">
      <div v-if="hasProfile">
        Loading user infos...
      </div>
      <div v-else class="w-100 d-flex justify-content-between noprofile">
        <div class="d-flex align-items-center"><strong class="mr-2">Address:</strong> <span>{{ $route.params.addr }}</span></div>
      </div>
    </div>
    <div v-else class="w-100 d-flex justify-content-between noprofile">
      <div class="d-flex align-items-center"><strong class="mr-2">Address:</strong> <span>{{ $route.params.addr }}</span></div>
    </div>
  </div>
  </div>
  <div v-if="isBanned" id="suspended" class="text-center">
    <div v-if="collectorInfos.user_status == 'SUSPENDED' || collectorInfos.user_status == 'CHANGE_REQUIRED'"><span>This collector has been {{ collectorInfos.user_status == 'SUSPENDED' ? 'SUSPENDED' : 'FLAGGED'}}.</span><span v-if="userNote!=''">Reason: {{ userNote }}</span><span v-if="collectorInfos.user_status != 'SUSPENDED'">- Buy at your own risk.</span></div>
  </div>
</div>
</template>

<script>
import globalMixin from '@/mixins/global.js';
import Social from '@/components/elements/Social.vue'
import runes from 'runes'

export default {
  mixins: [globalMixin],
  props: ['isBanned', 'infosLoaded', 'collectorInfos', 'userExist', 'hasProfile', 'userNote'],
  data: function(){
    return {
      detailOpen: false,
      canEdit: false
    }
  },
  created(){
    //this.init()
  },
  components: {
    'app-social': Social
  },
  watch:{
    infosLoaded: function(){
      if(this.userExist && !this.hasProfile && this.infosLoaded){
        this.$router.push(`/user/${this.$route.params.addr}/owned`)
      }
    }
  },
  methods: {
    shortDesc: function(desc){
      return runes.substr(desc, 0, 80)
    },
    showDetail: function(){
      this.detailOpen = !this.detailOpen
    }
  }
}
</script>
<style lang="scss" scoped>
  #user_info .fa-check-circle{
    font-size: 22px;
    color: #7F00FF;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }
  #user_info{
    .wrap_infos{
      padding-bottom: 0;
    }
    strong{
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 130%;
      text-transform: uppercase;
      color: #1E1A1A;
      opacity: 0.5;
    }
    .extra-btn{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      a{
        padding: 0px 20px;
        border-radius: 50px;
        color: white;
        background-color: #7F00FF;
        display: flex;
        align-items: center;
        transition: all .4s ease;
        &:hover{
          background-color: #1E1A1A;
        }
      }
    }
  }

  .wrap_title_date{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wrap_icons{
    display: flex;
    align-items: center;
  }
  .wrap_thumb{
    position: absolute;
    right: -2px;
    top: 5px;
    background-color: white;
    border-radius: 50%;
    
  }
  .address{
    margin-bottom: 3px;
    margin-top: 3px;
  }
  .joining_date{
    display: flex;
    justify-content: center;
    align-items: center;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #8F8D8D;
    margin-left: 15px;
  }
  .banned{
    background-color: white;
    border-radius: 20px;
    padding: 3px 10px;
    margin-left: 10px;
    color: red;
    font-size: 14px;
  }
  .creator{
    background-color: #7F00FF;
    padding: 3px 15px 3px 15px;
    color: white;
    border-radius: 50px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 0px;
    margin-left: 15px;
    line-height: 14px;
    letter-spacing: 0.3px;
    font-style: normal;
    line-height: 147%;
  }
  .more{
    .open{
      transform: rotate(-180deg);
    }
  }
  .fix{
    .creator{
      margin-bottom: 3px;
    }
    .joining_date{
      margin-bottom: 0;
    }
  }
  .noprofile{
    padding: 10px 0;
  }
  @media(min-width: 1024px) and (max-width: 1500px){
    .userName{
      max-width: 200px;
    }
  }
  
  @media(max-width: 1200px){
    #user_info .details h3{
      flex-direction: column;
      align-items: flex-start;
      .joining_date{
        margin-left: 0;
      }
    }
  }
  @media(min-width: 768px) and (max-width: 1024px){
    .wrap_infos{
      flex-direction: column;
      .wrap_title_date{
        width: 100%;
        .wrap_title{
          flex-direction: column;
          margin: auto;
          width: 100%;
        }
      }
      .thumb{
        text-align: center;
      }
    }
  }

  @media(max-width: 1024px){
    .wrap_icons{
      margin-bottom: 0;
    }
    #user_info{
      .details h3{
        flex-direction: column;
        align-items: center;
        .joining_date{
          margin-left: 0;
        }
      }
      .wrap_infos{
        .social-tabs{
          flex-grow: 0;
          flex-basis: auto;
        }
        .tabs-links{
          width: 100%;
          justify-content: center;
          margin-top: 10px;
        }
      }
      .address, .intro{
        width: 100%;
        text-align: center;
        margin: 0!important;
        line-height: 20px;
      }
      &.fix{
        .address, .wrap_icons{
          display: none;
        }
      }
    }
    .btn3d{
      position: absolute;
      top: 15px;
      right: 25px;
      width: 40px;
      height: 40px;
      padding: 12px!important;
      border-radius: 25px!important;
      span{
        display: none;
      }
    }

  }

  @media(max-width: 768px){
    .creator{
      margin-bottom: 2px;
    }
    .wrap_title{
      margin-top: 5px;
      display: flex;
    }
    .wrap_icons{
      margin-top: 5px;
      display: flex;
    }
    .wrap_title_date{
      flex-direction: column;
      h3{
        flex-direction: column;
      }
    }
    .joining_date{
      margin-top: 0;
    }
    .noprofile{
      
      width: 70%!important;
      margin: auto;
      .d-flex{
        flex-direction: column;
        width: 100%;
        span{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
      }
      
    }
  }
</style>