let init = function(){
Image.prototype.load = function( url, callback ) {
  
  let thisImg = this,
  xmlHTTP = new XMLHttpRequest();
  console.log("url")
  console.log(url)
  thisImg.completedPercentage = 0;
  xmlHTTP.abort()
  xmlHTTP.open( 'GET', url , true );
  xmlHTTP.responseType = 'arraybuffer';

  xmlHTTP.onload = function() {
      var h = xmlHTTP.getAllResponseHeaders(),
          m = h.match( /^Content-Type:\s*(.*?)$/mi ),
          mimeType = m[ 1 ] || 'image/png';
          // Remove your progress bar or whatever here. Load is done.

      var blob = new Blob( [ this.response ], { type: mimeType } );
      thisImg.src = window.URL.createObjectURL( blob );
      if ( callback ) callback( this );
  };

  xmlHTTP.onprogress = function( e ) {

      if ( e.lengthComputable )
          thisImg.completedPercentage = parseInt( ( e.loaded / e.total ) * 100 );
          if(thisImg.parentNode.querySelector(".progress-txt")){
            thisImg.parentNode.querySelector(".progress-txt").innerHTML = "Loading HD: "+thisImg.completedPercentage+"%"
            thisImg.parentNode.querySelector(".progress-bar").style.width = thisImg.completedPercentage+"%"
          }
      // Update your progress bar here. Make sure to check if the progress value
      // has changed to avoid spamming the DOM.
      // Something like: 
      // if ( prevValue != thisImage completedPercentage ) display_progress();
  };

  xmlHTTP.onloadstart = function() {
      // Display your progress bar here, starting at 0
      thisImg.completedPercentage = 0;
  };

  xmlHTTP.onloadend = function() {
      // You can also remove your progress bar here, if you like.
      thisImg.completedPercentage = 100;
      thisImg.parentNode.querySelector(".progress").classList.remove("show")
      if(thisImg.parentNode.querySelector(".progress-txt")){
        thisImg.parentNode.querySelector(".progress-txt").innerHTML = "Loading HD: 100%"
        thisImg.parentNode.querySelector(".progress-bar").style.width = "100%"
      }
  }

  xmlHTTP.send();
};
}

module.exports = {
  init
};
