export default {
  data: function() {
    return {}
  },
  methods: {
    isVideoActivity: function(str){
      if(str.split(".").pop().toLowerCase() != "mp4"){
        return false
      }else{
        return true
      }
    },
    getDate: function(value){
      if (!value) return "";
        value = new Date(value);
        let months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        let year = value.getFullYear();
        let month = months[value.getMonth()];
        let date = value.getDate();
        let time = date + " " + month + " " + year + " at "+value.getHours() +":"+ (value.getMinutes()<10?'0':'') + value.getMinutes();
        return time;
    },
    getImgixUrl : async function(cid){
      let imgix = await fetch(this.$store.state.config.signArtApiURL+"/imageboss", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify([{id: 1, url: this.getIpfsLink(cid)}])
      })
      imgix = await imgix.json()
      return imgix
    },
    trimLongAddress : function (value) {
      let trimmed = ""
      if (value.length > 25) {
        if (value != "") {
          let shortAddrLeft = value.substring(0,8);
          let shortAddrRight = value.substr(-8);
          trimmed = shortAddrLeft + "..." + shortAddrRight;
        }
      } 
      return trimmed
    },
    trimLongText: function (value) {
      let trimmed = ""
      if (value.length > 80) {
        if (value != "") {
          trimmed = value.substring(0,80)+"...";
        }
      } else{
        trimmed = value
      }
      return trimmed
    },
    setBase10: function(value, assetid){
      let amount = 0
      switch(assetid){
        case "":
        case "WAVES":
          amount = value / Math.pow(10, 8)
        break;
  
        case this.$store.state.config.assetID:
          amount = value / Math.pow(10, 8)
        break;
  
        case this.$store.state.config.usdnAssetID:
        case this.$store.state.config.usdtAssetID:
        case this.$store.state.config.usdtPPAssetID:
          amount = value / Math.pow(10, 6)
        break;
  
        default:
      }
      return amount
    },
    humanPrice: function(amount, asset){
      let price = this.numberWithSpaces(this.setBase10(amount, asset))
      let ticker = this.getTickerByAssetId(asset)
      if(price != 0){
        return price+" "+ticker
      }else{
        return "OFFER ONLY"
      }
    }
  }
}