//import { fromImage } from 'imtool';

export default {
  methods: {
    initialState: function() {
      return {
        alreadyCertified: false,
        imtheowner: false,
        nft_issued: 0,
        showDeleteConfirmation: false,
        deleteConfirmed: false,
        source: {
          fileHash: "",
          reference: "",
          validate: "",
          validateSignArt: "",
          artId: "",
          label: "Select the source",
          alreadyUsed: false,
        },
        name: "",
        crawlerPreview: false,
        crawler: {
          cid: "",
          maxsize: 110000,
          maxsizeGif: 0,
          label: "Select the crawler file",
          dimensionError: false,
          imageToUpload: null,
        },
        display: {
          cid: "",
          maxsize: 5000000,
          maxsizeGif: 5000000,
          //maxsizevid: 50000000,
          label: "Select the display file",
          dimensionError: false,
          imageToUpload: null,
          isMP4: false,
        },
        flag: "",
        description: "",
        tags: "",
        tagFormated: "",
        tagsValidate: "",
        royalties: 10,
        signID: "",
        licence: {
          fileHash: "",
          cid: "",
          maxsize: 10000000,
          label: "Select the licence file",
        },
        exported: {
          fileHash: "",
          cid: "",
          type: "",
          maxsize: 75000000,
          error: false,
          label: "Select the export file",
          isMP4: false,
        },
        haveError: false,
        responseMessage: "",
        validationError: false,
        sale: {
          salePopinOpen: false,
          onsale: false,
          maxmint: 0,
          price: 0,
          assetId: this.$store.state.config.assetID,
          ticker: "SIGN",
          haveError: false,
          errorMessage: "",
          auctionType: false,
          offerOnly: true,
          endHeight: 0,
          datePicked: null,
        },
        pastCertification: [],
        pastCertificationLoading: true,
        isWhiteListedDrops: {
          isWhitelisted: false,
          amount: 0,
        },
        isWhiteListedCertifications: {
          isWhitelisted: false,
          amount: 0,
        },
        isWhiteListedTxs: {
          isWhitelisted: false,
          amount: 0
        },
      };
    },
    throwError: function(msg){
      this.responseMessage = msg
      this.haveError = true
      this.$store.state.alert=false
      this.$store.state.alertMessage=''
    },
    checkMandatoryFields: function(){
      let display = document.getElementById("displayFile");
      let exported = document.getElementById("exportFile");
      let crawler = document.getElementById("crawlerFile");

      this.haveError = false;
      this.responseMessage = ""

      if (!exported.files[0] && this.exported.cid == '') {
        this.haveError = true;
        this.responseMessage = "Please add the export file";
        return;
      }

      if (this.display.isMP4 && this.crawler.cid == '' && !crawler.files[0]) {
        this.haveError = true;
        this.responseMessage = "Please add a thumbnail image if you use a video as preview";
        return;
      }

      if (!display.files[0] && this.display.cid == '') {
        this.haveError = true;
        this.responseMessage = "Please add a preview image";
        return;
      }

      if (!this.imtheowner) {
        this.haveError = true;
        this.responseMessage =
          "Please confirm that you are the creator of this artwork";
        return;
      }
      if (this.signID == "") {
        this.haveError = true;
        this.responseMessage =
          "Please enter the certification ID or certify the source in step 1";
        return;
      }

      if (this.name == "") {
        this.haveError = true;
        this.responseMessage = "Please enter a title";
        return;
      }

      if (this.description == "") {
        this.haveError = true;
        this.responseMessage = "Please describe your artwork";
        return;
      }
    },
    checkUploadFiles: async function(){


/*       // GENERATE THUMBNAIL
        
      const thumbnail = await fromImage(this.display.imageToUpload);
      let newThumbnail =  await thumbnail.thumbnail(768).toDataURL();
      if(newThumbnail){
        console.log(newThumbnail)
        this.haveError = true
        this.$store.state.alert=false
        this.$store.state.alertMessage=''
        return
      }  */

      this.haveError = false;
      this.responseMessage = "";
      let addCrawlerToIpfs, addDisplayToIpfs, addLicenceToIpfs, addExportToIpfs
      //let display = document.getElementById("displayFile")
      let licence = document.getElementById("licenceFile")
      let exported = document.getElementById("exportFile")

      this.$store.state.alert=true
      this.$store.state.alertMessage='Sending your files to IPFS, Please wait...'

      if(this.crawler.dimensionError){
        this.throwError("Crawler Image should be 100Kb max")
        return
      }

      if(this.crawler.imageToUpload){
        let ext = this.getFileExtension(this.crawler.imageToUpload.name)
        let new_crawler = new File([this.crawler.imageToUpload], 'crawler.'+ext);

        if(ext.toLowerCase() == "gif"){
          this.throwError("Crawler GIF no accepted")
          return
        }

        if(new_crawler.size > this.crawler.maxsize){
          this.throwError("Display Image is too big, "+(this.crawler.maxsize/1000000)+"Mb maximum")
          return
        }

        this.$store.state.alertMessage = "Sending your crawler file: <strong>"+this.crawler.imageToUpload.name+"</strong>";
        document.getElementById("msg-layer").querySelector(".progress").classList.add("show")
        addCrawlerToIpfs = await this.$store.state.ipfs.upload(new_crawler, this.progress)
        document.getElementById("msg-layer").querySelector(".progress").classList.remove("show")
        if(!addCrawlerToIpfs.error){
          this.crawler.cid = addCrawlerToIpfs.cid.string+"/"+new_crawler.name
        }else{
          this.throwError(addCrawlerToIpfs.error)
          return
        }
      }

      if(this.display.dimensionError){
        this.throwError("Display Image should be 1024x1500px max")
        return
      }

      if(this.display.imageToUpload){
        let ext = this.getFileExtension(this.display.imageToUpload.name)
        let new_display = new File([this.display.imageToUpload], 'display.'+ext);

        if(ext.toLowerCase() == "gif" && new_display.size > this.display.maxsizeGif){
          this.throwError("Display Image is too big, "+(this.display.maxsizeGif/1000000)+"Mb maximum")
          return
        }

        if(ext.toLowerCase() != "mp4" && ext.toLowerCase() != "gif" && new_display.size > this.display.maxsize){
          this.throwError("Display Image is too big, "+(this.display.maxsize/1000000)+"Mb maximum")
          return
        }

/*         if(ext.toLowerCase() == "mp4" && new_display.size > this.display.maxsizevid){
          this.responseMessage = "Display video is too big, "+(this.display.maxsizevid/1000000)+"Mb maximum"
          this.haveError = true
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          return
        } */

        this.$store.state.alertMessage = "Sending your display file: <strong>"+this.display.imageToUpload.name+"</strong>";
        document.getElementById("msg-layer").querySelector(".progress").classList.add("show")
        addDisplayToIpfs = await this.$store.state.ipfs.upload(new_display, this.progress)
        document.getElementById("msg-layer").querySelector(".progress").classList.remove("show")
        if(!addDisplayToIpfs.error){
          this.display.cid = addDisplayToIpfs.cid.string+"/"+new_display.name
        }else{
          this.throwError(addDisplayToIpfs.error)
          return
        }
      }
      
      if(licence.files[0]){
        let ext = this.getFileExtension(licence.files[0].name)
        let new_licence = new File([licence.files[0]], 'licence.'+ext);        
        if(new_licence.size > this.licence.maxsize){
          this.throwError("Licence File is too big, "+(this.licence.maxsize/1000000)+"Mb maximum")
          return
        }
        this.$store.state.alertMessage = "Sending your licence file:  <strong>"+licence.files[0].name+"</strong>";
        document.getElementById("msg-layer").querySelector(".progress").classList.add("show")
        addLicenceToIpfs = await this.$store.state.ipfs.upload(new_licence, this.progress)
        document.getElementById("msg-layer").querySelector(".progress").classList.remove("show")
        if(!addLicenceToIpfs.error){
          this.licence.cid = addLicenceToIpfs.cid.string+"/"+new_licence.name 
        }else{
          this.throwError(addLicenceToIpfs.error)
          return
        }
        
      }
      
      if(exported.files[0]){
        let ext = this.getFileExtension(exported.files[0].name)
        let new_exported = new File([exported.files[0]], 'exported.'+ext);
        if(new_exported.size > this.exported.maxsize){
          this.throwError("Export File is too big, "+(this.exported.maxsize/1000000)+"Mb maximum")
          return
        }
        this.$store.state.alertMessage = "Sending your export file:  <strong>"+exported.files[0].name+"</strong>";
        document.getElementById("msg-layer").querySelector(".progress").classList.add("show")
        addExportToIpfs = await this.$store.state.ipfs.upload(new_exported, this.progress)
        document.getElementById("msg-layer").querySelector(".progress").classList.remove("show")
        if(!addExportToIpfs.error){
          this.exported.cid = addExportToIpfs.cid.string+"/"+new_exported.name 
        }else{
          this.throwError(addExportToIpfs.error)
          return
        }
        
      }
      return
    },
    /* progress: function(data){
      let completedPercentage = parseInt( ( data.loaded / data.total ) * 100 );
      if(document.getElementById("msg-layer").querySelector(".progress-txt")){
        if(!data.addingToIpfs){
          document.getElementById("msg-layer").querySelector(".progress-txt").innerHTML = completedPercentage+"%"
          document.getElementById("msg-layer").querySelector(".progress-bar").style.width = completedPercentage+"%"
        }else{
          document.getElementById("msg-layer").querySelector(".progress-txt").innerHTML = "Adding file to IPFS, please wait..."
          document.getElementById("msg-layer").querySelector(".progress-bar").style.width = "100%"
        }
      }
    } */
  },
  watch: {
    tags: function(){
      this.tags = this.tags.replace(/[^a-z0-9,]/gi,'')
      let tagList = this.tags.split(",")
      if(tagList.length > 5){
        this.tagsValidate = "5 words maximum separated by space"
        this.validationError = true
      }else{
        let tempList = []
        tagList.forEach(function(e){
          tempList.push(e)
        })
        var cleanTags = tempList.filter(function (el) {
          return el != "";
        });
        this.tagFormated = cleanTags
        this.tagsValidate = ""
        this.validationError =false
      }
    }
  },
};
