// Grouping is collections but collections term was already used for "owned"... yeah... i know :)
// import
import { wavesAddress2eth } from '@waves/node-api-js';
export default {
  data: function() {
    return {};
  },
  methods: {
    getCollections: async function(address, id = null, perPage = 20, status = "all", sort = "dateDESC", searchStr = "", crosschainOnly='no'){
      let page = this.$route.params.pageIndex ? this.$route.params.pageIndex : 1
      let request = {
        "address": address , // null for any artists
        "collection_id": id,
        "perpage": perPage,
        "page": page,
        "artistStatus": status,
        "sort": sort,
        "stringSearch": searchStr,
        "crosschainOnly": crosschainOnly
      }

      try{
        let getCollections = await fetch(
          this.$store.state.config.signArtApiURL + "/collections/list/",
          {
            method: 'post',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
              request
            )
          }
        )
        getCollections = await getCollections.json()
        return getCollections
      }catch(e){
        console.log(e)
      }
    },
    getCollectionItems: async function(address, id, perPage = 20){
      this.allArtworks = []
      let page = this.$route.params.pageIndex ? this.$route.params.pageIndex : 1
      let request = {
        "address": address,
        "collection_id": id,
        "perpage": perPage,
        "page": page
      }
      try{
        let collectionItems = await fetch(
          this.$store.state.config.signArtApiURL + "/collections/list/artworks",
          {
            method: 'post',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
              request
            )
          }
        )
        collectionItems = await collectionItems.json()
        return collectionItems

      }catch(e){
        console.log(e)
      }
    },
    getCollectionItem: async function(address, artwork_id){
      let request = {
        "address": address,
        "artwork_id": artwork_id
      }
      try{
        let collectionItem = await fetch(
          this.$store.state.config.signArtApiURL + "/collections/list/artworks/single",
          {
            method: 'post',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
              request
            )
          }
        )
        collectionItem = await collectionItem.json()
        return collectionItem

      }catch(e){
        console.log(e)
      }
    },
    addRemoveItem: async function(selectedCollection, artwork_id, itemIdInCollection){
      
        let publicKey = this.$store.state.user.publicKey
        let type = "signer"
        if(this.$store.state.signer.isMetamask){
          publicKey = wavesAddress2eth(this.$store.state.user.userAddress);
          type = "metamask"
        }
        let request = {
          type: type,
          publickey: publicKey,
          signature: this.$store.state.user.sessionData.signedMessage,
          token: this.$store.state.user.sessionData.token,
          collection_id: selectedCollection,
          artwork_id: artwork_id,
          id: itemIdInCollection,
          deleteEntry: false,
          position: 1
        }

        if(selectedCollection == -1){
          request.deleteEntry = true
        }

        try{
          let addToCollection = await fetch(
            this.$store.state.config.signArtApiURL + "/collections/manage/artworks",
            {
              method: 'post',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(
                request
              )
            }
          )
          addToCollection = await addToCollection.json()
          if(!addToCollection.error){
            return {error: false, message: ""}
          }else{
            return {error: true, message: addToCollection.text}
          }
        }catch(e){
          this.alert = e
          console.log(e)
        }
    },
  }
}