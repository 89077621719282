// INFURA BACKEND MIDDLEWARE
const axios = require('axios');

async function upload(file, callback) {
  const formData = new FormData()
  formData.append("file", file, file.name) 

  try{
    let add = await axios.post(process.env.VUE_APP_signArtApiURL+"/ipfs", formData, {
      maxBodyLength: 'Infinity', //this is needed to prevent axios from erroring out with large files
      onUploadProgress: function(progressEvent) {

        let addingToIpfs = false
        if(file.size <= progressEvent.loaded){
          console.log("Adding your file to IPFS")
          addingToIpfs = true
        }else{
          addingToIpfs = false 
        }
        callback({loaded: progressEvent.loaded , total: file.size, addingToIpfs: addingToIpfs})
        
      },
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    return { cid: { string: add.data.result } };
  }catch(err){
    console.log(err)
    return err
  }

}

module.exports = {
  upload
};


// INFURA FROM FRONT
/* let host = "ipfs.infura.io";
const ipfsClient = require("ipfs-http-client");
let ipfs;

// const projectId = process.env.VUE_APP_ipfs_projectId;
// const projectSecret = process.env.VUE_APP_ipfs_projectSecret;
// const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64') 

async function upload(file, callback) {
  // ipfs = ipfsClient({
  //  host: host,
  //  port: "5001",
  //  protocol: "https",
  //  timeout: '60m',
  //  headers: {
  //    authorization: auth
  // }
  // });
  ipfs = ipfsClient({
    host: host,
    port: "5001",
    protocol: "https",
    timeout: '60m'
  });

  const fileObj = { path: "/"+file.name, content: file };

  let progress_func = function(len) {
    callback({loaded:len, total: file.size})
  }; 

  try {
    let result = await ipfs.add(fileObj, { progress: progress_func, pin: true, wrapWithDirectory: true, cidVersion: 1 })
    return { cid: { string: result.cid } };
    
  } catch (err) {
    console.log("catched err");
    console.log(err);
    return { path: null, error: err };
  }
}

module.exports = {
  upload
}; */

/*
// PINATA FROM FRONT
const FormData = require('form-data');
const axios = require('axios');

async function upload(file, callback) {

  const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
  let data = new FormData();
  data.append('file', file);
  const pinataOptions = JSON.stringify({
      cidVersion: 1,
      wrapWithDirectory: true,
      customPinPolicy: {
          regions: [
              {
                  id: 'FRA1',
                  desiredReplicationCount: 1
              }
          ]
      }
  });
  data.append('pinataOptions', pinataOptions);
  const metadata = JSON.stringify({
      name: file.name
  });
  data.append('pinataMetadata', metadata)

  try{
    let test = await axios.post(url, data, {
        maxBodyLength: 'Infinity', //this is needed to prevent axios from erroring out with large files
        headers: {
            'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            pinata_api_key: "d1f8804190701b84a5b9",
            pinata_secret_api_key: "4473806de9eff328aafab9eaa28c284a1a14e6b68116eec61b723120dd959a09"
        },
        onUploadProgress: function(progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          console.log(percentCompleted)
          callback({loaded: progressEvent.loaded , total: file.size})
        }
       
    })
    console.log(test)
    return { cid: { string: test.data.IpfsHash } };
    
  }catch(err){
    console.log(err)
    return { path: null, error: err };
  }

}

module.exports = {
  upload
};
*/
