import globalMixin from '@/mixins/global.js';
import encodeUrl from 'encodeurl';

export default {
  mixins: [globalMixin],
  data: function(){
    return {
      showbidData: false,
      bidData: undefined,
      offersList: [],
      signToUsd: null,
      wavesToUsd: null,
      bidsLoading: false,
      currentHighestBid: null
    }
  },
  methods: {
    
    getOffersList: async function(artId, addr){ // artId and art issuer addr
      this.bidsLoading = true
      this.wavesToUsd = this.$store.state.priceInUSDN.wavesPriceInUsd
      this.signToUsd = this.$store.state.priceInUSDN.signPriceInUsd
      this.xtnToUsd = this.$store.state.priceInUSDN.xtnPriceInUsd
      let _this = this
      let tempOffersList = []
      let regex

      if(artId == ""){
        regex = encodeUrl("^bid_(.*)" + addr +"(.*)")
      }else{
        regex = encodeUrl("^bid_"+ artId +"_"+ addr+"_(.*)")
      }
      await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.dapp_addr_artworks+"?matches="+regex).then(res => res.json()).then(async data => {
        data.map(function(e){
          let offersKeyValues = e.key.split("_")
          let offersValues = e.value.split("_")
          let [ticker, issuePrice] = _this.getPriceWithTicker(offersValues[0], offersValues[1])
          let offersValuesObj = {
            artId: offersKeyValues[1],
            owner: offersKeyValues[2],
            bidOwner: offersKeyValues[3],
            bidid: offersKeyValues[4],
            status: offersKeyValues[5],
            assetId: ticker,
            price: issuePrice,
            basePrice:offersValues[1],
            date: offersValues[2]
          }
          if(offersValuesObj.assetId == "WAVES" || offersValuesObj.assetId == ""){
            offersValuesObj.priceInUsd = (offersValuesObj.basePrice / Math.pow(10, 8)) * _this.wavesToUsd
          }else if(offersValuesObj.assetId == "SIGN"){
            offersValuesObj.priceInUsd = offersValuesObj.basePrice / Math.pow(10, 8) * _this.signToUsd
          }else if(offersValuesObj.assetId == "XTN"){
            offersValuesObj.priceInUsd = offersValuesObj.basePrice / Math.pow(10, 6) * _this.xtnToUsd
          }else{
            offersValuesObj.priceInUsd = offersValuesObj.price
          }
          tempOffersList.push(offersValuesObj)
        })
        
        let sortedList = tempOffersList.sort((a,b) => new Date(Number(a.date)) - new Date(Number(b.date)));
        let sortedByPriceList = sortedList.sort((a,b) => b.priceInUsd - a.priceInUsd);
        // _this.offersList = sortedByPriceList // old version

        let openOnly = sortedByPriceList.filter(offer => {
          return offer.status == "OPEN"
        })
        openOnly = openOnly.sort((a,b) => b.priceInUsd - a.priceInUsd);
        _this.currentHighestBid = openOnly[0]

        let closedOnly = sortedByPriceList.filter(offer => {
          return offer.status == "CLOSED"
        })
        closedOnly = closedOnly.sort((a,b) => b.priceInUsd - a.priceInUsd);

        let canceledOnly = sortedByPriceList.filter(offer => {
          return offer.status == "CANCELED" || offer.status == "CANCELLED"
        })
        canceledOnly = canceledOnly.sort((a,b) => b.priceInUsd - a.priceInUsd);

        _this.offersList = [...openOnly, ...closedOnly, ...canceledOnly];
        //let openOnlyByPrice = openOnly.sort((a,b) => b.priceInUsd - a.priceInUsd);
        //_this.currentHighestBid = openOnlyByPrice[0]
        this.bidsLoading = false
      })
    },
    getOffersListSecondary: async function(NFTid, addr){ // artId and art issuer addr
      this.bidsLoading = true
      this.wavesToUsd = this.$store.state.priceInUSDN.wavesPriceInUsd
      this.signToUsd = this.$store.state.priceInUSDN.signPriceInUsd
      this.xtnToUsd = this.$store.state.priceInUSDN.xtnPriceInUsd
      let _this = this
      let tempOffersList = []
      let regex

      if(NFTid == ""){
        regex = encodeUrl("^offer2nd(.*)" + addr +"(.*)")
      }else{
        regex = encodeUrl("^offer2nd(.*)"+ NFTid +"(.*)")
      }
      
      await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.dapp_addr_secondary+"?matches="+regex).then(res => res.json()).then(async data => {
        data.map(function(e){
          let offersKeyValues = e.key.split("_")
          let offersValues = e.value.split("_")
          let [ticker, issuePrice] = _this.getPriceWithTicker(offersValues[2], offersValues[1])
          let offersValuesObj = {
            issuerAddr: offersValues[5],
            artId: offersValues[4],
            owner: offersKeyValues[3],
            bidOwner: offersKeyValues[1],
            bidid: offersKeyValues[4],
            status: offersKeyValues[5],
            assetId: ticker,
            price: issuePrice,
            basePrice:offersValues[1],
            date: offersValues[0],
            nftId: offersKeyValues[2]
          }
        
          
          if(offersValuesObj.assetId == "WAVES" || offersValuesObj.assetId == ""){
            offersValuesObj.priceInUsd = (offersValuesObj.basePrice / Math.pow(10, 8)) * _this.wavesToUsd
          }else if(offersValuesObj.assetId == "SIGN"){
            offersValuesObj.priceInUsd = offersValuesObj.basePrice / Math.pow(10, 8) * _this.signToUsd
          }else if(offersValuesObj.assetId == "XTN"){
            offersValuesObj.priceInUsd = offersValuesObj.basePrice / Math.pow(10, 6) * _this.xtnToUsd
          }else{
            offersValuesObj.priceInUsd = offersValuesObj.price
          }
          tempOffersList.push(offersValuesObj)
        })
        let sortedList = tempOffersList.sort((a,b) => new Date(Number(a.date)) - new Date(Number(b.date)));
        let sortedByPriceList = sortedList.sort((a,b) => b.priceInUsd - a.priceInUsd);
        // _this.offersList = sortedByPriceList // old version

        let openOnly = sortedByPriceList.filter(offer => {
          return offer.status == "OPEN"
        })
        openOnly = openOnly.sort((a,b) => b.priceInUsd - a.priceInUsd);
        _this.currentHighestBid = openOnly[0]

        let closedOnly = sortedByPriceList.filter(offer => {
          return offer.status == "CLOSED"
        })
        closedOnly = closedOnly.sort((a,b) => b.priceInUsd - a.priceInUsd);

        let canceledOnly = sortedByPriceList.filter(offer => {
          return offer.status == "CANCELED" || offer.status == "CANCELLED"
        })
        canceledOnly = canceledOnly.sort((a,b) => b.priceInUsd - a.priceInUsd);

        _this.offersList = [...openOnly, ...closedOnly, ...canceledOnly];
        this.bidsLoading = false
      })
    },
    bidAction: async function(e, type, market = "primary", nftid = null){

      let error = false
      let alert = "This NFT is not for sale anymore. You can reject the offer for a fee (0.005 Waves) or just wait for the offer owner to cancel it."
      let _this = this
      let [assetId, price] = this.getPriceWithTicker(e.currentTarget.getAttribute("data-assetId"), e.currentTarget.getAttribute("data-basePrice"))

      this.bidData = {
        assetId: e.currentTarget.getAttribute("data-assetId"),
        basePrice: e.currentTarget.getAttribute("data-basePrice"),
        bidId: e.currentTarget.getAttribute("data-bidId"),
        bidOwner: e.currentTarget.getAttribute("data-bidOwner"),
        artId: e.currentTarget.getAttribute("data-artId"),
        price: price +" "+ assetId,
        type: type,
        market: market,
        nftid: nftid,
        saleOwner: e.currentTarget.getAttribute("data-saleOwner")
      }

      // check if still own NFT in case of accepting offer for an NFT you already sold
      if(type=='accept'){
        if(market=="secondary"){
          let regex = encodeUrl("^current_owner_on_dapp_" + nftid)
          let owner = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+this.$store.state.config.dapp_addr_secondary+"?matches="+regex)
          let json = await owner.json()
          if(json.length == 0 || json[0].value != this.$store.state.user.userAddress){
            error = true
          }
        }

        if(market=="primary"){
          let artId = e.currentTarget.getAttribute("data-artId")
          let creator = e.currentTarget.getAttribute("data-saleOwner")
          let regex = encodeUrl("^art_issued_"+artId+"_"+creator+"|^art_maxmint_"+artId+"_"+creator)
          let owner = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+this.$store.state.config.dapp_addr_artworks+"?matches="+regex)
          let json = await owner.json()
          console.log(json)
          if(json[0].value == json[1].value){
            error = true
          }
        }
      }
      console.log(error)
      if(!error){
        this.haveError = false
        this.responseMessage = ""
        this.showbidData = true
      }else{
        this.haveError = true
        this.responseMessage = alert
        setTimeout(function(){
          _this.haveError=false
          _this.responseMessage=""
        }, 10000000)
      }

      
    }
  }
};