<template>
  <div class="loading_wrap text-center">
    <img :src="loaderSrc" />
    <div class="col-12 text-center">Loading {{ label }}...</div>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js';

export default {
  mixins: [globalMixin],
  props: ["label"],
  data: function(){
    return {}
  }
}
</script>
<style lang="scss">

</style>