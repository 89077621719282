<template>

      <div class="row w-100">
        <div class="col-md-3 d-flex justify-content-center flex-column">
          <div class="date" v-if="showDate">{{getDate(item.date)}}</div>
          <div class="d-flex justify-content-center justify-content-md-start">
            <h3 class="mr-2">{{item.label}}</h3>
            <a :href="getExplorerUrl()+'/tx/'+item.txid" target="_blank" v-b-tooltip.hover.right title="See transaction on Waves" class="see_on_waves"><i class="fa-solid fa-eye"></i></a>
          </div>
          <div class="price d-flex justify-content-center justify-content-md-start" v-if="item.price!=null">
            <strong>{{item.price}}</strong>
          </div>
        </div>
        <div class="col-md-2 col-lg-1 d-flex align-items-center justify-content-center justify-content-md-start mt-3 mb-3 mt-md-0 mb-md-0">
          <router-link :to="item.image_link" class="wrapper_img" v-if="item.image_link!=''">
            <img 
              :data-url="item.image_path"
              src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
              :data-imageboss-src="'/'+item.image_path"
              sizes="(max-width: 500px) 100vw, 500px"
              data-imageboss-options="animation:true" 
            />

          </router-link>
          <span class="wrapper_img" v-else>
            <img 
              :data-url="item.image_path"
              src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
              :data-imageboss-src="'/'+item.image_path"
              sizes="(max-width: 500px) 100vw, 500px"
              data-imageboss-options="animation:true" 
            />

          </span>
        </div>
        <div class="col-md-4 col-lg-5 d-flex align-items-center  justify-content-center justify-content-md-start">
          <span v-if="item.status">
            <div class="me-2">Account:</div> <router-link :to="item.status.account.path">{{item.status.account.name}}</router-link>
          </span>
          <span v-else>{{item.description}}</span>
        </div>
        <div class="col-md-3 d-flex flex-column align-items-center align-items-md-start justify-content-center">
          <div v-if="item.artist_name!=''">
            <span class="pe-2">Artist:</span> 
            <router-link :to="item.artist_link">
              {{ item.artist_name}}
            </router-link>
          </div>
          <div v-if="item.collector">
            <span class="pe-2">Collector:</span> 
            <router-link :to="item.collector.url">
              {{item.collector.name}}
            </router-link>
          </div>
          <div v-if="item.flag">
              <div>Flag: {{item.flag.label}}</div>
              <div v-if="item.flag.note!=''">
                <span class="pe-2">Note:</span> <span>{{item.flag.note}}</span>
              </div>
          </div>
          <div v-if="item.seller">
            <span class="pe-2">Seller:</span> 
            <router-link :to="item.seller.url">
              {{item.seller.name}}
            </router-link>
          </div>
          <div v-if="item.status">
            <div>
              <span class="pe-2">Status:</span> <router-link :to="item.status.account.path">{{item.status.label}}</router-link>
            </div>
            <div v-if="item.status.note!=''">
              <span class="pe-2">Note:</span> <span>{{item.status.note}}</span>
            </div>
          </div>
        </div>
      </div>

</template>

<script>
import globalMixin from '@/mixins/global.js';
import activityMixin from '@/mixins/activity.js';
export default {
  mixins: [globalMixin, activityMixin],
  props: ['item'],
  data: function(){
    return {
      showDate: false
    }
  },
}
</script>

<style lang="scss" scoped>

  .see_on_waves{
    display: inline-block;
    .fa-eye{
      color: #bbb;
      transition: color .4s ease;
      font-size: 14px;
      &:hover{
        color: #ca26c0;
      }
    }
  }

  @media(max-width: 768px){
    .new.artwork{
      .date{
        text-align: center;
      }
    }
  }
</style>