<template>
  <div class="col-md-8 col-lg-8 col-xl-10 notFound" id="content">
    <h1>This page doesn't exist.</h1>
    <button @click="navigateToHome">Back to home</button>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js';
export default {
  mixins: [globalMixin],
  data: function(){
    return {
      
    }
  },
  mounted(){
    document.getElementById("content").classList.add("show")
  }
}
</script>
<style lang="scss" scoped>
.notFound{
  color: #57137b;
  padding: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
button{
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 15px;
}
</style>