<template>
  <div>
      <div id="activity_marketplace" class="container-fluid">
          <!-- <h2 class="mb-5 mt-5 text-center">Recent activity</h2> -->
          <div class="row">
            <div class="col-lg-12 d-flex justify-content-start mb-3 nav">
              <div class="new artwork w-100" v-for="(item, key) in htmlMarketplaceData" :key="key">
                <marketplace-item  :item="item" />
              </div>
            </div>
            <div class="col-12">
              <div id="loading" class="mt-3 mb-3 text-center" v-if="isLoading">Loading historical activity...</div>
              <button class="btn btn-primary d-block mt-3 mb-3 mx-auto" id="loadmore" @click="getMarketplaceTxs()" v-else>Load more</button>
            </div>
          </div>
      </div>
  </div> 
</template>

<script>

import globalMixin from '@/mixins/global.js';
import activityMixin from '@/mixins/activity.js';
import ActivityMarketplaceItem from '@/components/content/activity/items/drop.vue'
export default {
  mixins: [globalMixin, activityMixin],
  data: function(){
    return {
        htmlMarketplaceData: [],
        after: null,
        isLoading: true,
        amountToGet: 20
    }
  },
  components:{
    'marketplace-item': ActivityMarketplaceItem
  },
  mounted(){
    this.getMarketplaceTxs()
  },
  methods: {
    getMarketplaceTxs: async function(){
    let nftId, artwork, thumb, imgix, getCrawler, bidData
    this.isLoading = true
    let url
    if(this.after){
      url = `${this.$store.state.config.nodeURL}/transactions/address/${this.$store.state.config.dapp_addr_secondary}/limit/${this.amountToGet}?after=${this.after}`
    }else{
      url = `${this.$store.state.config.nodeURL}/transactions/address/${this.$store.state.config.dapp_addr_secondary}/limit/${this.amountToGet}`
    }
    let dataRes = await fetch(url)
    let resJSON = await dataRes.json()
    for( var tx of resJSON[0]){
      if(tx.type == 16 || (tx.type == 18 && tx.payload.type=="invocation")){
        if(tx.type==18){
          tx = {...tx,...tx.payload}
        }
        switch(tx.call.function){
          case "sellNFT":
            nftId = tx.payment[0].assetId
            artwork = await fetch(this.$store.state.config.signArtApiURL+"/secondary/nftid/"+nftId)
            artwork = await artwork.json()
            if(artwork[0]){
              if(!this.isVideoActivity(artwork[0].display_cid)){
                thumb = artwork[0].display_cid 
              }else{
                getCrawler = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_artworks}?matches=art_crawler_cid_${artwork[0].txid}_${artwork[0].address}`)
                getCrawler = await getCrawler.json()
                thumb = getCrawler[0].value
              } 
              imgix  = await this.getImgixUrl(thumb)
              this.htmlMarketplaceData.push({
                txid: tx.id,
                function: "sellNFT",
                date: tx.timestamp,
                label: "For sale",
                price: this.humanPrice(tx.call.args[0].value, tx.call.args[1].value),
                image_link: `/user/${artwork[0].userinfo.address}/artwork/${artwork[0].txid}/edition/${nftId}`,
                image_path: imgix.res[0].url,
                artist_link: `/user/${artwork[0].userinfo.address}`,
                artist_name: artwork[0].userinfo.name,
                description: this.trimLongText(artwork[0].name),
                seller:{
                  name: tx.sender == artwork[0].userinfo.address ? artwork[0].userinfo.name : this.trimLongAddress(tx.sender),
                  url: `/user/${tx.sender}`
                }
              })
            }
          break;

          case "updateSalePrice":
            nftId = tx.call.args[0].value
            artwork = await fetch(this.$store.state.config.signArtApiURL+"/secondary/nftid/"+nftId)
            artwork = await artwork.json()
            if(artwork[0]){
              if(!this.isVideoActivity(artwork[0].display_cid)){
                thumb = artwork[0].display_cid 
              }else{
                getCrawler = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_artworks}?matches=art_crawler_cid_${artwork[0].txid}_${artwork[0].address}`)
                getCrawler = await getCrawler.json()
                thumb = getCrawler[0].value
              } 
              imgix  = await this.getImgixUrl(thumb)
              this.htmlMarketplaceData.push({
                txid: tx.id,
                function: "updateSalePrice",
                date: tx.timestamp,
                label: "Price updated",
                price: this.humanPrice(tx.call.args[1].value, tx.call.args[2].value),
                image_link: `/user/${artwork[0].userinfo.address}/artwork/${artwork[0].txid}/edition/${nftId}`,
                image_path: imgix.res[0].url,
                artist_link: `/user/${artwork[0].userinfo.address}`,
                artist_name: artwork[0].userinfo.name,
                description: this.trimLongText(artwork[0].name),
                seller:{
                  name: tx.sender == artwork[0].userinfo.address ? artwork[0].userinfo.name : this.trimLongAddress(tx.sender),
                  url: `/user/${tx.sender}`
                }
              })
            }
          break;

          case "cancelSale":
            nftId = tx.call.args[0].value
            artwork = await fetch(this.$store.state.config.signArtApiURL+"/secondary/nftid/"+nftId)
            artwork = await artwork.json()
            if(artwork[0]){
              if(!this.isVideoActivity(artwork[0].display_cid)){
                thumb = artwork[0].display_cid 
              }else{
                let getCrawler = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_artworks}?matches=art_crawler_cid_${artwork[0].txid}_${artwork[0].userinfo.address}`)
                getCrawler = await getCrawler.json()
                thumb = getCrawler[0].value
              } 
              imgix  = await this.getImgixUrl(thumb)
              this.htmlMarketplaceData.push({
                txid: tx.id,
                function: "cancelSale",
                date: tx.timestamp,
                label: "Sale canceled",
                price: null,
                image_link: `/user/${artwork[0].userinfo.address}/artwork/${artwork[0].txid}/edition/${nftId}`,
                image_path: imgix.res[0].url,
                artist_link: `/user/${artwork[0].userinfo.address}`,
                artist_name: artwork[0].userinfo.name,
                description: this.trimLongText(artwork[0].name),
                seller:{
                  name: tx.sender == artwork[0].userinfo.address ? artwork[0].userinfo.name : this.trimLongAddress(tx.sender),
                  url: `/user/${tx.sender}`
                }
              })
            }

          break;

          case "buyNFT":
            nftId = tx.call.args[0].value
            artwork = await fetch(this.$store.state.config.signArtApiURL+"/secondary/nftid/"+nftId)
            artwork = await artwork.json()
            if(artwork[0]){
              if(!this.isVideoActivity(artwork[0].display_cid)){
                thumb = artwork[0].display_cid 
              }else{
                let getCrawler = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_artworks}?matches=art_crawler_cid_${artwork[0].txid}_${artwork[0].userinfo.address}`)
                getCrawler = await getCrawler.json()
                thumb = getCrawler[0].value
              } 
              imgix  = await this.getImgixUrl(thumb)
              this.htmlMarketplaceData.push({
                txid: tx.id,
                function: "buyNFT",
                date: tx.timestamp,
                label: "Sale canceled",
                price: this.humanPrice(artwork[0].secondaryinfo.price, artwork[0].secondaryinfo.price_asset),
                image_link: `/user/${artwork[0].userinfo.address}/artwork/${artwork[0].txid}/edition/${nftId}`,
                image_path: imgix.res[0].url,
                artist_link: `/user/${artwork[0].userinfo.address}`,
                artist_name: artwork[0].userinfo.name,
                description: this.trimLongText(artwork[0].name),
                seller:{
                  name: this.trimLongAddress(artwork[0].secondaryinfo.seller),
                  url: `/user/${artwork[0].secondaryinfo.seller}`
                },
                collector:{
                  name: tx.sender == artwork[0].userinfo.address ? artwork[0].userinfo.name : this.trimLongAddress(tx.sender),
                  url: `/user/${tx.sender}`
                }
              })
            }
          break;

          case "setOffer":
            nftId = tx.call.args[0].value
            artwork = await fetch(this.$store.state.config.signArtApiURL+"/secondary/nftid/"+nftId)
            artwork = await artwork.json()
            if(artwork[0]){
              if(!this.isVideoActivity(artwork[0].display_cid)){
                thumb = artwork[0].display_cid 
              }else{
                let getCrawler = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_artworks}?matches=art_crawler_cid_${artwork[0].txid}_${artwork[0].userinfo.address}`)
                getCrawler = await getCrawler.json()
                thumb = getCrawler[0].value
              } 
              imgix  = await this.getImgixUrl(thumb)
              this.htmlMarketplaceData.push({
                txid: tx.id,
                function: "buyNFT",
                date: tx.timestamp,
                label: "Sale canceled",
                price: this.humanPrice(tx.payment[0].amount, tx.payment[0].assetId), //this.numberWithSpaces(tx.payment[0].amount)+" "+this.getTickerByAssetId(tx.payment[0].assetId),
                image_link: `/user/${artwork[0].userinfo.address}/artwork/${artwork[0].txid}/edition/${nftId}`,
                image_path: imgix.res[0].url,
                artist_link: `/user/${artwork[0].userinfo.address}`,
                artist_name: artwork[0].userinfo.name,
                description: this.trimLongText(artwork[0].name),
                seller:{
                  name: this.trimLongAddress(artwork[0].secondaryinfo.seller),
                  url: `/user/${artwork[0].secondaryinfo.seller}`
                },
                collector:{
                  name: tx.sender == artwork[0].userinfo.address ? artwork[0].userinfo.name : this.trimLongAddress(tx.sender),
                  url: `/user/${tx.sender}`
                }
              })
            }
          break;

          case "acceptOffer":
            nftId = tx.call.args[0].value
            artwork = await fetch(this.$store.state.config.signArtApiURL+"/secondary/nftid/"+nftId)
            artwork = await artwork.json()
            if(artwork[0]){
              if(!this.isVideoActivity(artwork[0].display_cid)){
                thumb = artwork[0].display_cid 
              }else{
                let getCrawler = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_artworks}?matches=art_crawler_cid_${artwork[0].txid}_${artwork[0].userinfo.address}`)
                getCrawler = await getCrawler.json()
                thumb = getCrawler[0].value
              } 

              bidData = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_secondary}?matches=offer2nd_${tx.call.args[2].value}_${tx.call.args[0].value}_${tx.sender}_${tx.call.args[1].value}_CLOSED`)
              bidData = await bidData.json()
              bidData = bidData[0].value.split("_")

              imgix  = await this.getImgixUrl(thumb)
              this.htmlMarketplaceData.push({
                txid: tx.id,
                function: "acceptOffer",
                date: tx.timestamp,
                label: "Offer accepted",
                price: this.humanPrice(bidData[1], bidData[2]),
                image_link: `/user/${artwork[0].userinfo.address}/artwork/${artwork[0].txid}/edition/${nftId}`,
                image_path: imgix.res[0].url,
                artist_link: `/user/${artwork[0].userinfo.address}`,
                artist_name: artwork[0].userinfo.name,
                description: this.trimLongText(artwork[0].name),
                seller:{
                  name: this.trimLongAddress(artwork[0].secondaryinfo.seller),
                  url: `/user/${artwork[0].secondaryinfo.seller}`
                },
                collector:{
                  name: tx.call.args[2].value == artwork[0].userinfo.address ? artwork[0].userinfo.name : this.trimLongAddress(tx.call.args[2].value),
                  url: `/user/${tx.call.args[2].value}`
                }
              })
            }
          break;

          case "cancelOffer":
            nftId = tx.call.args[0].value
            artwork = await fetch(this.$store.state.config.signArtApiURL+"/secondary/nftid/"+nftId)
            artwork = await artwork.json()
            if(artwork[0]){
              if(!this.isVideoActivity(artwork[0].display_cid)){
                thumb = artwork[0].display_cid 
              }else{
                let getCrawler = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_artworks}?matches=art_crawler_cid_${artwork[0].txid}_${artwork[0].userinfo.address}`)
                getCrawler = await getCrawler.json()
                thumb = getCrawler[0].value
              } 
              bidData = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_secondary}?matches=offer2nd_${tx.call.args[2].value}_${tx.call.args[0].value}_${tx.call.args[3].value}_${tx.call.args[1].value}_CANCELLED`)
              bidData = await bidData.json()
              bidData = bidData[0].value.split("_")

              imgix  = await this.getImgixUrl(thumb)
              this.htmlMarketplaceData.push({
                txid: tx.id,
                function: "acceptOffer",
                date: tx.timestamp,
                label: "Offer canceled",
                price: this.humanPrice(bidData[1], bidData[2]),
                image_link: `/user/${artwork[0].userinfo.address}/artwork/${artwork[0].txid}/edition/${nftId}`,
                image_path: imgix.res[0].url,
                artist_link: `/user/${artwork[0].userinfo.address}`,
                artist_name: artwork[0].userinfo.name,
                description: this.trimLongText(artwork[0].name),
                seller:{
                  name: this.trimLongAddress(artwork[0].secondaryinfo.seller),
                  url: `/user/${artwork[0].secondaryinfo.seller}`
                },
                collector:{
                  name: tx.call.args[2].value == artwork[0].userinfo.address ? artwork[0].userinfo.name : this.trimLongAddress(tx.call.args[2].value),
                  url: `/user/${tx.call.args[2].value}`
                }
              })
            }
          break;

          default:

        }
      }
      this.after = tx.id
    }
    
    this.isLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>

</style>