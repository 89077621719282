<template>
  <transition name="fade">
    <div class="transferPopin" v-show="$parent.transferPopinOpen" @click="closeSendForm($event)" :class="{'flexstart':haveError}">
      <i class="fa-solid fa-xmark close_popin_nft"></i>
      <div class="col-12 px-0 wrapper">
        <div class="mb-3 pointer-events-auto">
          <label :for="'recipient_'+nftInfo.name" class="w-100">Send NFT "{{nftInfo.name}}" to: <i class="fas fa-question-circle float-right" v-b-tooltip.hover.top :title="'Asset ID: ' + nftInfo.assetId"></i></label>
          <input type="text" class="form-control " :id="'recipient_'+nftInfo.name" placeholder="Waves address: 3P..." v-model="recipient">
        </div>
        <div class="mb-3 pointer-events-auto">
          <label :for="'attachment_'+nftInfo.name">Message:</label>
          <textarea class="form-control attachment" :id="'attachment_'+nftInfo.name" v-model="attachment" placeholder="140 Characters maximum"></textarea>
        </div>
        <button class="btn btn-primary bigger mb-0 mt-4 mx-auto d-block pointer-events-auto" @click.prevent="transfer">TRANSFER</button>
        <p v-show="transferResult!=''" class="text-center mt-1">{{ transferResult }}</p>
      </div>
      <div class="clickZone pointer"></div>
    </div>
  </transition>
</template>
<script>
import globalMixin from '@/mixins/global.js';

export default {
  mixins: [globalMixin],
  props: ["nftInfo", "nft", "transferPopinOpen"],
  data: function(){
    return {
      transferResult: '',
      attachment: '',
      recipient: '',
      haveError: false
    }
  },
  created(){},
  methods: {
    setTicker: function(e){
      this.feeTicker = e.target.options[e.target.selectedIndex].text
      if(this.feeTicker == 'SIGN'){
        this.feeCost = this.$store.state.sponsoredFee
      }else if(this.feeTicker == 'XTN'){
        this.feeCost = this.$store.state.sponsoredFeeUSDN
      }else if(this.feeTicker == 'USDT'){
        this.feeCost = this.$store.state.sponsoredFeeUSDT
      }else if(this.feeTicker == 'USDT-PP'){
        this.feeCost = this.$store.state.sponsoredFeeUSDTPP
      }else{
        this.feeCost = 0.001
      }
    },
    closeSendForm: function(e){
      if(e.target.closest('.clickZone') || e.target.closest('.close_popin_nft')){
        this.$parent.transferPopinOpen = false;
      }
    },
    transfer: function(){
      let _this = this
      _this.transferResult = "Transfer in progress..."
      let transferTx
      if(this.$store.state.signingChoice.keeper){
        transferTx = {amount: 1, recipient: _this.recipient, assetId: _this.nftInfo.assetId, attachment: _this.attachment, feeAssetId: "WAVES", fee: 0.001}
      }else{
        const attachment_base58 = _this.$store.state.cryptolibs.base58Encode(_this.$store.state.cryptolibs.stringToBytes(_this.attachment));
        transferTx = {amount: 1, recipient: _this.recipient, assetId: _this.nftInfo.assetId, attachment: attachment_base58}
      }

      _this.$store.dispatch("signerTransfer", {data:transferTx, isNFT: true}).then(res => { 
        if(res.error){
          if(res.message){
             _this.transferResult = res.message
          }else{
           _this.transferResult = res.error
          }
          _this.haveError=true
        }else{
          _this.transferResult = "NFT Transfered"
          _this.$parent.$emit('removeNFT', _this.nftInfo.assetId)
          _this.haveError=false
        }
      })
    },
  }
}
</script>
<style lang="scss">

</style>