<template>
  <div class="col-md-8 col-lg-8 col-xl-12 px-1 faqPage" ref="faqPage" id="content">
    <div class="w-100 wrap_faq pt-4 centerH" ref="faqPageContainer">
      <transition name="fade">
        <div class="container" v-show="show">
          <div class="row mb-2">
            <div class="col-12 mb-4 coc">
              <h3>CODE OF CONDUCT</h3>
              <div class="texte">
                This Code of Conduct outlines the rules, norms, responsibilities and proper practices for all users interacting with the SIGN Art platform and community.<br><br/>
                SIGN Art wants all users to treat others equally and with respect.
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 mb-lg-0 mb-3 coc">
              <div class="wrap">
                <h3>ALL COMMUNITY MEMBERS</h3>
                <div class="texte">
                  <ul>
                    <li>Do not discriminate</li>
                    <li>Do not threaten or harass other users</li>
                    <li>Be respectful</li>
                    <li>Report scams and wrongful content as soon as possible</li>
                    <li>Wash trading, price exploitation and manipulation is not allowed</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-lg-4 mb-lg-0 mb-3 coc">
              <div class="wrap">
                <h3>CREATORS</h3>
                <div class="texte">
                    <ul>
                      <li>Only upload content which you have created or own.</li>
                      <li>Don’t re-upload other people’s work.</li>
                      <li>Ensure your work doesn’t infringe any copyright including any intellectual material or audio.</li>
                      <li>Ensure you don’t upload spam content.</li>
                      <li>Don't re-mint artworks that have been minted on other platforms.</li>
                      <li>All illegal contents will be removed without warning</li>
                    </ul>
                </div>
              </div>
            </div>

            <div class="col-lg-4 mb-lg-0 mb-3 coc">
              <div class="wrap">
                <h3>ADDING CONTENT</h3>
                <div class="texte">
                  <ul>
                    <li>Anyone adding artwork directly from the dApp (Smart Contract) without using the sign-art.app web application agree by default to align to every standards and verifications (size, format etc) applied by sign-art.app</li>
                    <li>Any content not following our standards could be flagged or even deleted, we recommend to only add content from the web application unless you know exactly what you are doing.</li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js';

export default {
  mixins: [globalMixin],
  data: function(){
    return {
      show:false
    }
  },
  methods:{
    onResize: function(){
      if(this.$refs.faqPage.offsetHeight > this.$refs.faqPageContainer.querySelector(".container").offsetHeight){
        this.$refs.faqPageContainer.classList.add("centerH")
      }else{
        this.$refs.faqPageContainer.classList.remove("centerH")
      }
    }
  },
  mounted(){
    
    window.addEventListener('load', this.onResize)
    window.addEventListener('resize', this.onResize)
    this.show = true
    this.$nextTick(() => {
			window.dispatchEvent(new Event('resize'));
		});
  },
  beforeDestroy() {
    window.removeEventListener('load', this.onResize)
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss" scoped>
  .faqPage{
    background-color: #6c497f;
    height: 100%!important;
    display: flex!important;
    align-items: center!important;
    padding-top: 50px;
    padding-bottom: 50px;
    .wrap{
      display: flex;
      flex-direction: column;
    }
    .texte{
      color: #57137b;
      padding: 20px;
      font-size: 14px;
      background-color: white;
      border-radius: 3px;
      flex: 1;
      ul{
        li{
          padding-left: 20px;
          margin-bottom: 5px;
          position: relative;
          &:before{
            display: block;
            content: '';
            width: 5px;
            height: 5px;
            position: absolute;
            left: 0px;
            background-color: #BA53F0;
            top: 10px;
            border-radius: 50%;
          }
        }
      }
    }
    h3{
      font-size: 1.3rem;
      margin-bottom: 10px;
    }
    .red{
      color: red;
    }
  }

  .centerH{
    min-height: 100%;
    display: flex;
    align-items: center;
  }
</style>