<template>
  <div id="app" ref="mainApp" v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
    <div id="refresh" class="hide">
      <i class="fas fa-redo-alt rotating"></i>
    </div>
    <div id="pull_to_reload" class="hide">Pull to reload</div>
    <app-crosschain-popup></app-crosschain-popup>
    <app-buytoken v-show="$store.state.showBuyPopin"></app-buytoken>
    <app-alert v-show="$store.state.isFilteringArtHome"></app-alert>
    <app-cookie-law theme="dark-lime" buttonText="I ACCEPT!" message="This web application uses cookies to ensure you get the best experience."></app-cookie-law>
    <transition name="fade">
      <div id="visuelFit" v-show="$store.state.imgFit"  @click="handleClick" v-dragscroll v-touch:start="disableSwipe" v-touch:end="enableSwipe">
        <div class="progress">
          <div class="progress-txt"></div>
          <div class="wrap_progress_bar w-100">
            <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
        <div class="wrap_btn">
          <div class="closeFit"><i class="fas fa-times disabled "></i></div>
          <div class="zoomFit"><i class="fas disabled" :class="classObject"></i></div>
        </div>
        <!-- <img src="" class="imgHandler img-fluid d-block"/> -->
      </div>
    </transition>
    <app-signing-choice></app-signing-choice>
    <app-connected></app-connected>
    <div id="msg-layer" v-show="$store.state.alert">
      <div class="wrapper">
        <img src="./assets/images/loader.svg" class="d-block"/>
        <div class="message text-center" v-html="$store.state.alertMessage"></div>
        <div class="progress">
          <div class="progress-txt"></div>
          <div class="wrap_progress_bar w-100">
            <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      </div>  
    </div>  
    <app-faq></app-faq>
    <app-popin :userAddress="$store.state.user.userAddress"></app-popin>
    
    <div id="moburger" @click="menuToggle"><i class="fas fa-bars"></i></div>
    <div id="dappSuspended" v-show="!dappIsRunning" v-b-tooltip.hover.bottom title="The dApp is currently suspended, you can use the web application but not interact with the smart contract. Everything should be back to live mode soon.">dApp in <span>READ ONLY</span> mode. <i class="fas fa-question-circle"></i></div>
    <div id="main" class="container-fluid">
      <div class="row h-100">
        <app-sidebar :menuOpen="menuOpen" :class="menuOpen ? 'open' : ''" />
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from './components/sidebar/Sidebar.vue'
import Popin from './components/elements/Popin.vue'
import CookieLaw from 'vue-cookie-law'
import SigningChoice from './components/elements/SigningChoice.vue'
import CrosschainPopup from './components/elements/crosschainPopup.vue'
import ConnectedAlert from './components/elements/Connected.vue'
import BuyToken from './components/elements/BuyToken.vue'
import Faq from '@/components/elements/Faq.vue'
import imageLoader from '@/plugins/imageLoader.js'
import globalMixin from '@/mixins/global.js';
import Alert from '@/components/elements/Alert.vue'

export default {
  name: 'App',
  mixins: [globalMixin],
  data: function() {
   return {
      menuOpen: false,
      dappIsRunning: true,
      zoomedIn: false
    }
  },
  mounted(){
    imageLoader.init()
    this.setIsMobile()
    if(this.$store.state.isMobile){
      this.menuOpen = false
    }

    this.refresher()

    //document.title = 'Welcome to SIGN Art'
  },
  watch: {
    '$route' (to) {
      document.title = to.meta.title || 'Welcome to SIGN Art'
    },
    '$store.state.user.isLogged' (){
      this.checkSignBalance()
      setInterval(this.checkSignBalance, 15000)
      this.checkUsdnBalance()
      setInterval(this.checkUsdnBalance, 15000)
      this.checkUsdtBalance()
      setInterval(this.checkUsdtBalance, 15000)
      this.checkUsdtPPBalance()
      setInterval(this.checkUsdtPPBalance, 15000)
      this.checkWavesBalance()
      setInterval(this.checkWavesBalance, 15000)
    }
  },
  components: {
    "app-sidebar": Sidebar,
    "app-popin": Popin,
    "app-cookie-law": CookieLaw,
    "app-signing-choice": SigningChoice,
    "app-connected": ConnectedAlert,
    'app-faq': Faq,
    'app-alert': Alert,
    'app-buytoken': BuyToken,
    'app-crosschain-popup': CrosschainPopup
  },
  created(){
    this.init()
  },
  computed: {
    classObject: function () {
      return {
        'fa-search-plus': !this.zoomedIn,
        'fa-search-minus': this.zoomedIn,
      }
    }
  },
  methods: {
    init: function(){
      window.addEventListener('resize', this.$store.commit('setWindowWidth'))
      window.addEventListener('resize', this.setIsMobile)

      this.checkLoginChoice()

      this.checkNewArtists()
      setInterval(this.checkNewArtists, 15000)

      this.checkCertificationPrice()
      setInterval(this.checkCertificationPrice, 30000)

      this.checkSponsoredFee()
      // setInterval(this.checkSponsoredFee, 100000)

      this.checkSponsoredFeeUSDN()
      this.checkSponsoredFeeUSDT()
      this.checkSponsoredFeeUSDTPP()
      // setInterval(this.checkSponsoredFeeUSDN, 100000)
      this.checkSponsoredFeeETH()

      this.checkDappMode()
      setInterval(this.checkDappMode, 30000)
    },
    refresher(){
      let startIsTop, endIsTop = false
      let scrollPos = null
      let start = null;
      let walletClosed = true

      window.addEventListener('touchstart', function(e) {   
          start = e.changedTouches[0];
          scrollPos = document.getElementById("main").scrollTop
          startIsTop = document.getElementById("main").scrollTop
          walletClosed = document.getElementById("buyToken").style.display == "none"
      });

      window.addEventListener('touchmove', function(e) {   
        let current = e.changedTouches[0];
        endIsTop = document.getElementById("main").scrollTop
        let menuOpen = document.getElementById("sidebar").classList.contains("open")
        if(endIsTop + startIsTop == 0 && !menuOpen && current.screenY > start.screenY && walletClosed)
        {
          document.getElementById("pull_to_reload").classList.remove("hide")
          document.getElementById("pull_to_reload").style.top = (current.screenY - start.screenY) / 15 + "px"
        }
      });

      window.addEventListener('touchend', function(e) {
        document.getElementById("pull_to_reload").classList.add("hide")
        document.getElementById("pull_to_reload").style.top = "-60px"
        let end = e.changedTouches[0];
        endIsTop = document.getElementById("main").scrollTop
        let menuOpen = document.getElementById("sidebar").classList.contains("open")
        
        if(end.screenY - start.screenY > 200 && endIsTop + startIsTop == 0 && end.screenY > start.screenY && walletClosed)
        {
            if(screen.width <= 769 && menuOpen || document.getElementById("visuelFit").style.display != "none"){return}else{
              if(scrollPos == document.getElementById("main").scrollTop){
                document.getElementById("refresh").classList.remove("hide")
                window.location.reload()
              }
            }

        }

      });
    },
    async checkDappMode(){
      let isDappRunning = await this.$store.dispatch("checkDappMode")
      this.dappIsRunning = isDappRunning
    },
    loadImage(cid){
        var img = new Image(); // document.getElementById("visuelFit").querySelector(".imgHandler");
        img.className = 'imgHandler img-fluid d-block mx-auto'
        img.load(cid);
        document.getElementById("visuelFit").appendChild(img);
        document.getElementById("visuelFit").querySelector(".progress").classList.add("show")

    },
    handleClick(e) {
      if (e.target.matches('.closeFit')) {
        this.zoomedIn = false
        this.closeImgFit()
      }

      if(e.target.matches('.zoomFit') || e.target.matches('.imgHandler') && !this.zoomedIn){
        this.zoomedIn = !this.zoomedIn
        this.zoomImgFit()
      }
    },
    setIsMobile () { 
      if (screen.width <= 769) {
        this.$store.state.isMobile = true
      } else {
        this.$store.state.isMobile = false
      }
    },
    swipeLeft(){
      if(!this.$store.state.enableSwipe || screen.width > 992){
        return
      }else{
        this.menuOpen = false
      }
    },
    swipeRight(){
      if(!this.$store.state.enableSwipe || screen.width > 992){
        return
      }else{
        this.menuOpen = true
      }
    },
    menuToggle() {
        this.menuOpen = !this.menuOpen;
    },
    checkLoginChoice(){
      this.$store.dispatch("checkStoredLogin")
    },
    checkSignBalance(){
      this.$store.dispatch("getSignBalance")
    },
    checkWavesBalance(){
      this.$store.dispatch("getWavesBalance")
    },
    checkUsdnBalance(){
      this.$store.dispatch("getUsdnBalance")
    },
    checkUsdtBalance(){
      this.$store.dispatch("getUsdtBalance")
    },
    checkUsdtPPBalance(){
      this.$store.dispatch("getUsdtPPBalance")
    },
    checkNewArtists(){
      this.$store.dispatch("getArtists", "VERIFIED")
    },
    checkCertificationPrice(){
      this.$store.dispatch("getCertificationPrice")
    },
    checkSponsoredFee(){
      this.$store.dispatch("getSponsoredFee")
    },
    checkSponsoredFeeUSDN(){
      this.$store.dispatch("getSponsoredFeeUSDN")
    },
    checkSponsoredFeeUSDT(){
      this.$store.dispatch("getSponsoredFeeUSDT")
    },
    checkSponsoredFeeUSDTPP(){
      this.$store.dispatch("getSponsoredFeeUSDTPP")
    },
    checkSponsoredFeeETH(){
      this.$store.dispatch("getSponsoredFeeETH")
    },
    closeImgFit(){
      document.getElementById("visuelFit").querySelector(".progress-txt").innerHTML = "0%"
      document.getElementById("visuelFit").querySelector(".progress-bar").style.width = "0%"
      document.getElementById("visuelFit").querySelector(".progress").classList.remove("show")
      document.getElementById("visuelFit").removeChild(document.querySelector(".imgHandler"))
      document.getElementById("visuelFit").classList.remove("full")
      document.getElementById("visuelFit").classList.remove("fitW")
      document.getElementById("visuelFit").classList.remove("fitH")
      this.$store.state.imgFit = false
    },
     zoomImgFit(){ 
      document.getElementById("visuelFit").classList.toggle("full")
       if(window.innerWidth <= window.innerHeight){
          document.getElementById("visuelFit").classList.toggle("fitH")
        }else{
          document.getElementById("visuelFit").classList.toggle("fitW")
        }
    },
  }
}
</script>
<style lang="scss">
  #refresh, #pull_to_reload{
    position: fixed;
    z-index: 150000;
    background-color: #9d23a4;
    padding: 5px 10px;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 30px;
    font-size: 20px;
    transition: all .4s ease;
    opacity:1;
    pointer-events: none;
    &.hide{
      opacity: 0;
    }
  }
  #pull_to_reload{
    font-size: 14px;
    padding: 5px 15px;
    border-radius: 5px;
    top: -60px;
  }
  @-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
  #dappSuspended{
    position: absolute;
    z-index: 100000;
    background-color: rgba(255, 0, 89, 0.864);
    color: white;
    padding: 5px 10px;
    font-size: 16px;
    left: 50%;
    transform: translateX(-50%);
    top: 15px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    span{
      font-weight: 600;
    }
    @media(max-width: 768px){
      width: 70%;
    }
  }
</style>
