<template>
  <div>
      <h2 class="text-center mt-3 mb-0 d-flex mx-auto justify-content-center align-items-center">
        <span>Your OWNED NFTs</span> 
        <a :href="$store.state.config.sign_art_gateway_url" target="_blank" id="gateway_icon" class="ml-2 mr-0 button" v-b-tooltip.hover.bottom title="Cross Chain Gateway" v-if="toggleSwitch=='NOTFORSALE'">
            <i class="fa-sharp fa-solid fa-diamond"></i>
            <i class="fa-brands fa-ethereum"></i>
          </a></h2>
      <div v-if="loading" class="loading">
        <app-loading :label="'data'"></app-loading>
      </div>
      <div id="collectionList" class="form-group" v-else>
        <div class="row">
          <div class="action-msg col-12" v-if="actionRes">
              <div class="wrap d-flex align-items-center">
                {{ actionMsg }}
              </div>
            </div>
            <div class="col-12">
              <div class="switchForSale">
                <span 
                  class="sales mr-1" 
                  :class="{bold: toggleSwitch=='FORSALE'}"
                  >For sale <span class="count">{{amount_for_sale}}</span></span> 

                <i title="Switch" 
                  class="fas fa-toggle-on" 
                  :class="{on: toggleSwitch=='FORSALE'}" 
                  @click="toggleForSale" ref="switcher"></i> 

                <span class="bids ml-2" 
                  :class="{bold: toggleSwitch=='NOTFORSALE'}">Not for sale <span class="count">{{amount_not_for_sale}}</span></span>
              </div>
            </div>
          <div class="col-xl-3 col-xxl-3 col-lg-6 col-md-6 mb-4" v-for="(nft, index) in nftList" :key="nft.assetId">
            <app-owned 
              :nftInfo="nft" 
              :index="index"
              :toggleswitch="toggleSwitch"
              @removeNFT="removeNFTfromChild"></app-owned>
          </div>
        </div>
        <button class="btn btn-primary mb-2 bigger loadNFT" v-if="loadMore" @click="getNextNFTs()">Load more</button>
        <div v-else class="text-center nft-result">{{ nftList.length ? 'All collectibles loaded' : 'No collectible found' }}</div>
      </div>
  </div> 
</template>
<script>
import ownedMixin from '@/mixins/ownedMixin.js';
import tabsCount from '@/mixins/tabsCount.js';
export default {
  mixins: [ownedMixin,tabsCount],
  data: function(){
    return {
      toggleSwitch: "FORSALE",
      actionRes: false,
      actionMsg: "",
      amount_for_sale: 0,
      amount_not_for_sale: 0
    }
  },
  async created(){
    this.amount_for_sale = await this.getOwnedForSale()
    this.amount_not_for_sale = await this.getOwnedNotForSale()
  },
  watch: {
    toggleSwitch: function(){
      this.resetNFT()
      if(this.toggleSwitch == "FORSALE"){
        this.getAllNFT2ndMarket()
      }else{
        this.getAllNFT()
      }
    }
  },
  methods: {
    toggleForSale: function(){
      this.toggleSwitch = this.toggleSwitch == "FORSALE" ? "NOTFORSALE" : "FORSALE"
    }
  },
  mounted(){

    document.querySelector(".wrap").classList.add("show")
  }
}
</script>
<style lang="scss" scopped>
a.button, #swap_token{
    border-radius: 50px;
    transition: all .4s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto !important;
    border: 1px solid #9A0E88;
    background-color: white;
    padding: 6px 20px;
    color: #000000;
    letter-spacing: -0.015em;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    margin-right: 10px;
    &.router-link-active, &.active, &:hover{
      background-color: #9A0E88;
      color: #FFFFFF;
    }
    &:hover{
      color: #ffffff;
    }
  }
  a.button{
    text-align: center;
    color: white;
    border: 1px solid #9A0E88;
    background-color: white;
    padding: 6px 20px;
    color: #000000;
    font-weight: 500;
    font-size: 14px;

  }
  #collectionList{
    padding-top: 10px!important;
  }
  .action-msg {
    .wrap{
      background-color: #64c264;
      color: white;
      border-bottom: solid white 10px;
      padding: 10px;
      text-align: center;
      justify-content: center;
      .error{
        background-color: rgb(220, 56, 56);
      }
    }
  }
  .switchForSale{
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f7f7f7;
    padding: 7px 0;
    border-radius: 10px;
    margin-bottom: 20px;
    @media(max-width: 768px){
      justify-content: center;
    }
    .bold{
      font-weight: bold;
    }
    .fa-toggle-on {
      color: #ba53f0!important;
      transform: rotate(0deg);
      font-size: 23px;
      &.on{
        transform: rotate(180deg); 
      }
    }
    .count{
        font-size: 18px;
        font-weight: 400;
        color: #767676;
      }
  }
</style>