<template>
  <div>
      <app-user-infos :isBanned="isBanned" :artistInfos="artistInfos" :infosLoaded="infosLoaded" :userExist="userExist" :hasProfile="hasProfile" :userNote="userNote" :collectorInfos="collectorInfos"></app-user-infos>
      <div class="mt-lg-4 container-fluid">
        <div>
          <div v-if="loading" class="loading mb-4">
            <app-loading :label="'data'"></app-loading>
          </div>
          <div class="wrap_data row" v-else>
            <div class="col-12 mb-4 text-center text-black" v-if="collections && !collections.length">
              <div v-if="loading">Loading data</div>
              <div v-else>No collections found.</div>
            </div>
            <div class="col-lg-3 col-xl-3 col-md-6 mb-4 d-flex flex-column" v-for="(collection, key) in collections" :key="key">
              <app-collections :collection="collection"></app-collections>
            </div>
          </div>
        </div>
      </div>

      <div class="wrap_pagination d-flex justify-content-center mt-2">
          <button class="btn btn-primary mb-2 bigger" :class="{'mr-3': collections.length}"  v-if="this.$route.params.pageIndex && this.$route.params.pageIndex > 1" @click="prevNext('prev')">PREVIOUS</button>
          <button class="btn btn-primary mb-2 bigger" @click="prevNext('next')" v-if="collections && collections.length == perPage">NEXT</button>
       </div>

  </div> 
</template>

<script>
import globalMixin from '@/mixins/global.js';
import collections from '@/mixins/collections.js';
import orderedArtworks from "@/mixins/orderedArtworks.js"
import Loading from '@/components/elements/Loading.vue'
import userMixin from '@/mixins/userMixin.js';
import UserInfos from '@/components/elements/UserInfos.vue'
import collectionsItem from '@/components/content/collections/item.vue'

export default {
  mixins: [userMixin, globalMixin, orderedArtworks, collections],
  data: function(){
    return {
      collections: null,
      loading: false,
      perPage: 20
    }
  },
  components:{
    'app-loading': Loading,
    'app-user-infos': UserInfos,
    'app-collections': collectionsItem
  },
  created(){
   this.init()
  },
  watch: {
    '$route.params.pageIndex'(){
      this.init()
    }
  },
  methods: {
    init: async function(){
      this.loading = true
      let collectionsList = await this.getCollections(this.$route.params.addr, null, this.perPage)
      this.collections = collectionsList
      this.loading = false
    },
    prevNext: function(direction){
      let index
      if(direction == "prev"){
        index = +Number(this.$route.params.pageIndex)-1
      }else{
        index = +Number(this.$route.params.pageIndex)+1
        
      }
      this.$router.push({ name:  'user-collections', params: { pageIndex: index } }).catch(()=>{});
    }, 
  }
}
</script>
<style lang="scss" scoped>
  .text-black{
    color: #1E1A1A;
  }
</style>