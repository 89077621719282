<template>
  <div class="col-12 px-1 userPage wrap" id="contentTab">

      <transition name="fade">
        <div id="free_notice" v-if="!hideFreeNotice">
          <span v-if="free.txs!=0">You have {{ free.txs }} free transaction(s) left<br/></span>
          <span v-if="free.drops!=0">You have {{ free.drops }} free artwork drop(s) left<br/></span>
          <span v-if="free.freeCertifications!=0">You have {{ free.freeCertifications }} free certification(s) left<br/></span>
          <span v-if="free.commission!=0">Commission free on sales until block: {{ free.commission }} </span>

          
          <i class="fas fa-times position-absolute" @click="hideNotice(true)"></i>
          <div class="mention">Free transactions applies for:<br/>Create/Update Profile<br/>Certify/ Add/ Update/ Sell Artwork<br/>Accept Offer.</div>
        </div>
      </transition>
      <div v-if="hideFreeNotice && !hideFreeNoticeToggle" id="showFreeNotice" @click="hideNotice(false)">!</div>
      
      <app-balance></app-balance>
      <div class="wrap_tabs d-flex">
       <ul class="tabs">
<!--           <li v-if="!isWhiteListed && !isSuspended">
            <router-link :to="'/user/'+$store.state.user.userAddress+'/account/register'" tag="a" class="nav-item" >I'm an artist</router-link>
          </li> -->
          <li v-if="isRegistered && statusNotAllowed">
            <router-link :to="'/user/'+$store.state.user.userAddress+'/account/add-artwork'" tag="a" class="nav-item" ><strong>+</strong> <span class="hide-mobile">Add Artwork</span></router-link>
          </li>
          <li v-if="(isRegistered || isSuspended) && statusNotAllowed">
            <router-link :to="'/user/'+$store.state.user.userAddress+'/account/artworks/list'" tag="a" class="nav-item" :class="{active: $route.name == 'user-artworks-collections' || $route.name == 'user-artworks-collections-detail'}">Created</router-link>
          </li>
          <li>
            <router-link :to="'/user/'+$store.state.user.userAddress+'/account/owned'" tag="a" class="nav-item" >Owned</router-link>
          </li>
          <li>
            <router-link :to="'/user/'+$store.state.user.userAddress+'/account/activities'" tag="a" class="nav-item" >{{ isWhiteListed ? 'Sales':'Offers' }}</router-link>
          </li>
          <li>
            <router-link :to="'/user/'+$store.state.user.userAddress+'/account/infos'" tag="a" class="nav-item">Account</router-link>
          </li>
          <li>
            <router-link :to="'/user/'+$store.state.user.userAddress+'/account/notifications'" tag="a" class="nav-item"><i class="far fa-bell"></i></router-link>
          </li>
        </ul>
      </div>
  </div>
</template>

<script>
import balance from '@/components/elements/Balance.vue'
import globalMixin from '@/mixins/global.js';
export default {
  mixins: [globalMixin],
  data: function(){
    return {
      statusNotAllowed: this.$store.state.user.status != 'ALLOWED',
      hideFreeNotice: true,
      hideFreeNoticeToggle: true,
      intervalUpdate: null,
      free: {
        txs: 0,
        drops: 0,
        commission: 0,
        currentHeight: 0,
        freeCertifications: 0
      }
    }
  },
  components: {
    'app-balance': balance
  },
  methods: {
    hideNotice: function(choice){
      this.$store.dispatch("setCookie",{name:"hideFreeNotice", data: choice})
      this.hideFreeNotice = choice
    },
    getFreeData: async function(){
      this.free.currentHeight = await this.getCurrentHeight()
      let data = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+this.$store.state.config.dapp_addr_whitelist+"?matches="+this.$store.state.user.userAddress+"(.*)")
      data = await data.json()

      for(var i in data){

        if(data[i].key == this.$store.state.user.userAddress){
          if(this.free.currentHeight < data[i].value){
            this.free.commission = data[i].value
          }else{this.free.commission = 0}
        }
        if(data[i].key == this.$store.state.user.userAddress+"_free_txs"){this.free.txs = data[i].value}
        if(data[i].key == this.$store.state.user.userAddress+"_free_drops"){this.free.drops = data[i].value}
      }

      let dataCertification = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+this.$store.state.config.sign_dapp_addr+"?key=addr_WHITELISTED_"+this.$store.state.user.userAddress)
      dataCertification = await dataCertification.json()
      this.free.freeCertifications = dataCertification[0].value
    }
  },
  computed: {
    isWhiteListed: function() {
      let user = this.$store.getters['getUserObj']
      return user.isWhiteListed
    },
    isSuspended: function() {
      let user = this.$store.getters['getUserObj']
      return user.isSuspended
    },
    isRegistered: function() {
      let user = this.$store.getters['getUserObj']
      return user.isRegistered
    }
  },
  watch:{
    '$store.state.user.status': function(status){
      this.statusNotAllowed = status != 'ALLOWED'
    }
  },
  async created(){
    this.hideFreeNotice = this.$store.getters.getCookie("hideFreeNotice")
    await this.getFreeData()
    if(this.free.txs!=0 || this.free.drops != 0 || this.drops.commission !=0 || this.freeCertifications!=0){
      this.hideFreeNotice = false
      this.hideFreeNoticeToggle = false
    }else{
      this.hideFreeNotice = true
      this.hideFreeNoticeToggle = true
    }
  },
  mounted(){
    document.getElementById("contentTab").classList.add('show')
    let _this = this
    this.intervalUpdate = setInterval(function(){

      _this.getFreeData()
    }, 5000)
  },
  beforeDestroy(){
    clearInterval(this.intervalUpdate)
  }
}
</script>

<style lang="scss">

  #contentTab{
    #showFreeNotice{
      position: fixed;
      z-index: 5000;
      width: 25px;
      height: 25px;
      top: 8px;
      right: 35px;
      cursor: pointer;
      text-align: center;
      border-radius: 50%;
      background-color: #585858;
    }
    #free_notice{
      position: absolute;
      background-color: #2fa7a1;
      padding: 10px 30px 10px 20px;
      border-radius: 10px;
      z-index: 5000;
      top: 20px;
      right: 20px;
      max-width: 450px;
      font-size: 15px;
      line-height: 18px;
      .fa-times{
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
      .mention{
        font-size: 12px;
      }
    }
    &.wrap{
      min-height: inherit;
    }
    .tabs{
      color: black;
      justify-content: center;
      margin-top: 10px;
      border: 1px solid #131111;
      border-radius: 50px;
      display: flex;
      width: auto;
      margin-left: auto;
      margin-right: auto;
      padding-right: 30px;
      padding-left: 30px;
      width: auto;
      align-items: center;
    }
    ul{
      display: flex;
      justify-content: center;
      border-bottom: 1px solid #57137b;
      li{
        background-color: transparent;
        @media(max-width:420px){
          margin-right: 5px;
        }
        &:last-child{
          margin-right: 0px;;
        }
        .nav-item{
          cursor: pointer;
          padding: 5px 15px;
          display: block;
          color: #1E1A1A;
          &:hover, &.is-active, &.router-link-active, &.active{
            color: #9A0E88;
          }
        }
      }
    }
  }
  @media(max-width: 768px){
    #contentTab{
      .tabs{
        padding-left: 10px;
        padding-right: 10px;
        li{
          a{
            padding: 5px;
          }
        }
      }
    }
  }
  @media(max-width: 500px){
    .hide-mobile{
      display: none;
    }
  }
</style>