
<template>
  <span class="usdPrice mr-2" :class="{white: color =='white', grey: color =='grey'}" v-show="show"> ({{ usdPrice != '' ? usdPrice : '...' }})</span>
</template>
<script>
import globalMixin from '@/mixins/global.js';

export default {
  mixins: [globalMixin],
  props: ['artwork', 'color', 'secondary', 'offer'],
  data: function(){
    return {
      usdPrice: '',
      signPrice: '',
      assetsPrice: '',
      show: false
    }
  },
  created(){
    this.getPriceInUsd()
  },
  methods: {
    getPriceInUsd: async function(){
      let _this = this  
      let result, artPrice, assetAccepted
      if(this.secondary){
        artPrice = this.secondary.saleInfo.price
        assetAccepted = this.secondary.saleInfo.asset
      }else if(this.offer){  
        switch(this.offer.assetId) {
          case "SIGN":
            assetAccepted = this.$store.state.config.assetID
            artPrice = this.offer.price * Math.pow(10,8)
          break;

          case "XTN":
            assetAccepted = this.$store.state.config.usdnAssetID
            artPrice = this.offer.price * Math.pow(10,6)
          break;

          case "USDT":
            assetAccepted = this.$store.state.config.usdtAssetID
            artPrice = this.offer.price * Math.pow(10,6)
          break;

          case "USDT-PP":
            assetAccepted = this.$store.state.config.usdtPPAssetID
            artPrice = this.offer.price * Math.pow(10,6)
          break;

          case "WAVES":
            assetAccepted = this.$store.state.config.wavesAssetID
            artPrice = this.offer.price * Math.pow(10,8)
          break;

          default:
        }
      }else{
        artPrice = this.artwork.price ? this.artwork.price : this.artwork.art_price
        assetAccepted = this.artwork.assetAccepted != undefined ? this.artwork.assetAccepted : this.artwork.art_assetAccepted
      }
      if(assetAccepted != _this.$store.state.config.usdtAssetID && assetAccepted != _this.$store.state.config.usdtPPAssetID){this.show=true}else{this.show=false}
      switch(assetAccepted){
        case "WAVES":
        case _this.$store.state.config.wavesAssetID: // WAVES
        result = (artPrice / Math.pow(10, 8)) * this.$store.state.priceInUSDN.wavesPriceInUsd
        break;

        case _this.$store.state.config.assetID: // SIGN
        // result = (artPrice / Math.pow(10, 8) * this.$store.state.priceInUSDN.signPriceInUsd/Math.pow(10,8)) * this.$store.state.priceInUSDN.wavesPriceInUsd
        result = artPrice / Math.pow(10, 8) * this.$store.state.priceInUSDN.signPriceInUsd
        break;

        case this.$store.state.config.usdnAssetID: // SIGN
        result = artPrice / Math.pow(10, 6) * this.$store.state.priceInUSDN.xtnPriceInUsd
        break;

        default:
      }
      if(result){
        this.usdPrice = result.toFixed(2)+"$"
        
      }
    },
  },
  watch:{
    'secondary.saleInfo':{
      deep:true,
      handler() {
        this.getPriceInUsd();
      }
    },
    '$store.state.priceInUSDN':{
      deep:true,
      handler() {
        this.getPriceInUsd();
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .usdPrice{
    font-weight: 400!important;
    color: #ca26c0!important;
    font-size: 13px;
    &.white{
      color: white!important;
      opacity: 0.8;
    }
    &.grey{
      color: rgb(79, 79, 79)!important;
      opacity: 0.8;
    }
  }
</style>