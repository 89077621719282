<template>
  <transition name="fade">
    <div id="acceptBidPopin" v-show="showbidData" ref="acceptBidPopin">
      <div class="wrapper">
        <div class="form-group mb-0">
          <div class="row">
            <div class="col-lg-12 mt-3 text-center">
              <h4 class="uppercase">{{ bidData.type }}</h4>
              <p>OFFER of {{ bidData.price }}</p>
            </div>
            <div class="col-12">
              <div :class="{error: bid.haveError}" v-show="bid.errorMessage!=''" class="response container w-100 mx-auto text-center mt-3">
                <div class="wrap">{{bid.errorMessage}}</div>
              </div>
              <button class="btn btn-primary bigger mb-0 mt-3 mx-auto d-block uppercase" @click.prevent="processAction"> {{ bidData.type }} THE OFFER</button>
            </div>
          </div>
        </div>
      </div>

    </div>
    </transition>
</template>
<script>
import globalMixin from '@/mixins/global.js';
import VueScrollTo from "vue-scrollto";
var options = {
  container: "#content",
  easing: "ease-in",
};

export default {
  props: ['bidData', 'showbidData'],
  mixins: [globalMixin],
  data: function(){
    return {
      bid: {
        haveError: false,
        errorMessage: ""
      },
      finalPrice: 0,
      isWhiteListedTxs: {
        isWhitelisted: false,
        amount: 0
      },
    }
  },
  watch: {
    showbidData: function(){
      this.showbidData ? document.getElementById("content").style.overflowY="hidden" : document.getElementById("content").style.overflowY="auto"
    }
  },
  async created(){
    this.isWhiteListedTxs = await this.whitelistedTxs(this.$store.state.user.userAddress)
    this.init()
  },
  mounted(){
    document.getElementById('acceptBidPopin').addEventListener('mousedown', this.detectClickOutOfBidPopin);
  },
  beforeDestroy() {
    if(document.getElementById('acceptBidPopin')){
      document.getElementById('acceptBidPopin').removeEventListener('mousedown', this.detectClickOutOfBidPopin);
    }
  },
  methods: {
    init: function(){
      this.calculatePriceAndTxFee()
    },
    calculatePriceAndTxFee: function(){
        if(this.getTickerByAssetId(this.bidData.assetId) == 'SIGN' || this.getTickerByAssetId(this.bidData.assetId) == 'WAVES' || this.bidData.assetId == ''){
          this.finalPrice = this.bidData.basePrice * 10 ** 8
        }
        if(this.getTickerByAssetId(this.bidData.assetId) == 'XTN' || this.getTickerByAssetId(this.bidData.assetId) == 'USDT' || this.getTickerByAssetId(this.bidData.assetId) == 'USDT-PP'){
          this.finalPrice = this.bidData.basePrice * 10 ** 6
        }
    },
    processAction: function(){
        VueScrollTo.scrollTo('#content', 600, options)
        this.$store.state.alert=true
        this.$store.state.alertMessage='Processing the offer, Please wait...'
        this.calculatePriceAndTxFee()
        let _this = this;
        let market = this.bidData.market
        let functionToCall, dataTx, callArgs, successMessage

        if(market=="primary"){
          callArgs = [
              { type:"string", value: _this.bidData.artId },
              { type:"string", value: _this.bidData.saleOwner },
              { type:"string", value: _this.bidData.bidId },
              { type:"string", value: _this.bidData.bidOwner }
          ]
          
          switch(this.bidData.type){
            case "accept":
            functionToCall = "acceptBid"
            break
            case "cancel":
            functionToCall = "cancelBid"
            break
            default:
            functionToCall = null
          }

          dataTx = {
            dApp: this.$store.state.config.dapp_addr_artworks,
                call: {
                    function: functionToCall,
                    args: callArgs
                }
          }

          if(this.isWhiteListedTxs.isWhiteListed && this.bidData.type=="accept"){
            dataTx.call.args.push({ type:"string", value: this.$store.state.user.publicKey})
            dataTx.senderPublicKey = this.$store.state.config.dapp_pubkey_artworks
            dataTx.fee = 500000
            dataTx.feeAssetId = null
          }else{
            dataTx.call.args.push({ type:"string", value: "" })
          }

          successMessage = functionToCall == "acceptBid" ? 'Offer accepted successfully!' : "Offer cancelled"
        }else{
          callArgs = [
              { type:"string", value: _this.bidData.nftid },
              { type:"string", value: _this.bidData.bidId },
              { type:"string", value: _this.bidData.bidOwner }
          ]
          
          switch(this.bidData.type){
            case "accept":
            functionToCall = "acceptOffer"
            break
            case "cancel":
            functionToCall = "cancelOffer"
            callArgs.push({ type:"string", value: _this.bidData.saleOwner })
            break
            default:
            functionToCall = null
          }

          dataTx = {
            dApp: this.$store.state.config.dapp_addr_secondary,
                call: {
                    function: functionToCall,
                    args: callArgs
                }
          }
          successMessage = functionToCall == "acceptOffer" ? 'Offer accepted successfully!' : "Offer cancelled"
        }

        this.$store.dispatch("signerInvoke", dataTx).then(async res => { 
            this.$store.state.alert=false
            this.$store.state.alertMessage=''

            if(res.error){
              // this is because you may have 5 pending offer but once you accept one, you cannot accept more unless someone set it back for sale.
              // would take too many ressource to check if each offer if for an open sale
              let addToError = res.error.message.includes("NFT not found") ? " - You may have already accepted an offfer for this sale. You can decline the offer or just ignore it" : ""
              this.bid.errorMessage = res.error.message ? res.error.message+addToError : res.error
              this.bid.haveError = true
            }else{
              // display confirmation message of success
              _this.$store.state.alert=false
              _this.$store.state.alertMessage=''
              _this.bid.errorMessage = ''
              _this.bid.haveError = false

              _this.$parent.haveError = false
              _this.$parent.responseMessage = successMessage
              _this.$parent.showbidData = false

              if(_this.bidData.nftid != ''){ // if Secondary market, could use market=="secondary" instead
                if(_this.$route.name == 'user-activities'){ // action coming from admin area listing
                  await _this.$parent.getOffersListSecondary("", _this.$route.params.addr)
                  _this.$parent.filteredOffersList = _this.$parent.offersList
                  _this.$parent.setFilterTo("OPEN")
                }else{ // action coming from marketplace NFT sale page
                  await _this.$parent.getOffersListSecondary(_this.bidData.nftid, "")
                  _this.$parent.loadArtwork()
                }
              }else{ // Primary market
                if(_this.$route.name == 'user-activities'){ // action coming from admin area listing
                 await _this.$parent.getOffersList("", _this.$route.params.addr)
                  _this.$parent.filteredOffersList = _this.$parent.offersList
                  _this.$parent.setFilterTo("OPEN")
                }else{ // action coming from artwork primary sale page
                 await _this.$parent.getOffersList(_this.bidData.artId, _this.bidData.saleOwner)
                 _this.$parent.loadArtwork()
                }
                
              }
              
              setTimeout(function(){
                _this.$parent.responseMessage = ''
              }, 6000)
            }
        }) 
      
    },
    detectClickOutOfBidPopin: function(e){   
      if(this.showbidData){
        if (!document.getElementById('acceptBidPopin').querySelector(".wrapper").contains(e.target)){
          this.$parent.showbidData = false
          this.bid.errorMessage = ''
          this.bid.haveError = false
          document.getElementById("content").style.overflowY="auto"
        }
      }
    }
  }
}
</script>
<style lang="scss">

</style>