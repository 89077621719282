<template>
  <div>
      <h2 class="text-center mt-3 mb-0">Your recent sales / offers</h2>
      <b-card no-body id="card_wrapper">
        <b-tabs card class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 mx-auto">
          <b-tab no-body title="New drops" @click="clickTab('primary')">
            <app-activities-primary />
          </b-tab>

          <b-tab no-body title="Marketplace" @click="clickTab('secondary')">
            <app-activities-secondary />
          </b-tab>
        </b-tabs>
      </b-card>
  </div> 
</template>

<script>
import activitiesPrimary from '@/components/content/userAccount/activities/primary.vue';
import activitiesSecondary from '@/components/content/userAccount/activities/secondary.vue';

export default {
  data: function(){
    return {}
  },
  components: {
    'app-activities-primary': activitiesPrimary,
    'app-activities-secondary': activitiesSecondary
  },
  computed: {
    isWhiteListed: function() {
      let user = this.$store.getters['getUserObj']
      return user.isWhiteListed
    }
  },
  mounted(){
    let activity = this.$router.currentRoute.fullPath.split("#")
    if(activity.includes("SECONDARY")){
      setTimeout(function(){
        document.getElementById("card_wrapper").querySelector("li:last-child a").click()
      }, 100)
    }
  },
  methods: {
    clickTab: function(e){
      if(e=="primary"){
        this.$router.push(this.$router.currentRoute.path+"#PRIMARY").catch(()=>{});
      }else{
        this.$router.push(this.$router.currentRoute.path+"#SECONDARY").catch(()=>{});
      }
    }
  }
}
</script>
<style lang="scss">
#card_wrapper #response{
  position: fixed;
  z-index: 50;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1024px;
  .fa-times{
    position: absolute;
    right: 20px;
    top: 4px;
    z-index: 2;
    color: white;
    cursor: pointer;
  }
}
  #card_wrapper{
    .card-header{
      border-bottom: 1px solid #b5b5b5;
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      .nav-item{
        &:first-child{
          margin-right: 15px;
        }
        a{
          border: solid 1px #b5b5b5;
          border-radius: 5px 5px 0 0;
          color: #898989;
          &.active{
            border-bottom: 1px solid white;
            color: #ca26c0;
          }
        }
      }
    }
    ul#soldList li, ul#bidList li {
      padding: 10px;
      background-color: #fafafa;
      margin-bottom: 5px;
  }
  .sold{
      a{
        font-size:14px;
      }
    }
  .switchSalesBids{
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    .bold{
      font-weight: bold;
    }
    .fa-toggle-on {
      color: #ba53f0;
      transform: rotate(0deg);
      font-size: 23px;
      &.on{
        transform: rotate(180deg);
        
      }
    }
  }
  #bidFilters{
    select{
      width: 100%;
      max-width: 165px;
    }
  }
  @media(max-width:480px){
    .sold{
      text-align:center;
      overflow-x: auto;
      a{
        font-size:12px;
      }
    }
  }
  }
</style>