import HashFile from "@/plugins/hashfile.js";
import FileType from "file-type/browser";
import normalizeUrl from 'normalize-url';
import encodeUrl from 'encodeurl';
import {libs} from '@waves/waves-transactions';
/* import Compressor from 'compressorjs';  */
export default {
  data: function() {
    return {
      emptySrc: require("@/assets/images/no_display.svg"),
      emptyThumb: require("@/assets/images/thumbnail.png"),
      loaderSrc: require("@/assets/images/loader.svg"),
      spinerSrc: require('@/assets/images/spiner.svg'),
      spinerSidebarSrc: require('@/assets/images/spiner-sidebar.svg'),
      mp4Alert: false,
      isIosShortLink: null
    };
  },
  filters: {
    normalizeUrl: function(e){
      if(e){
        try{
          return normalizeUrl(e, {stripWWW: false})
        }catch(err){
          console.log(err)
        }
      }
    },
    trim: function(value, length) {
      let max = length ? length : 150
      let dots = value.length > length ? "..." : ""
      if (!value) return "";
      value = value.substr(0, max);
      
      return value + dots;
    },
    setDate: function(value) {
      if (!value) return "";
      value = new Date(Number(value));
      let months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let year = value.getFullYear();
      let month = months[value.getMonth()];
      let date = value.getDate();
      let time = date + " " + month + " " + year;
      return time;
    },
    setTime: function(value) {
      if (!value) return "";
      value = new Date(Number(value));
      let time = value.getHours() +":"+ (value.getMinutes()<10?'0':'') + value.getMinutes()
      return time;
    },
    setDateTime: function(value){
      if (!value) return "";
      value = new Date(Number(value));
      let months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let year = value.getFullYear();
      let month = months[value.getMonth()];
      let date = value.getDate();
      let time = date + " " + month + " " + year + " at "+value.getHours() +":"+ (value.getMinutes()<10?'0':'') + value.getMinutes();
      return time;
    }
  },
  methods: {
    closeResponseMessage: function(){
      this.haveError = false
      this.responseMessage = ""
    },
    progress: function(data){ // adding to ipfs
      let completedPercentage = parseInt( ( data.loaded / data.total ) * 100 );
      if(document.getElementById("msg-layer").querySelector(".progress-txt")){
        if(!data.addingToIpfs){
          document.getElementById("msg-layer").querySelector(".progress-txt").innerHTML = completedPercentage+"%"
          document.getElementById("msg-layer").querySelector(".progress-bar").style.width = completedPercentage+"%"
        }else{
          document.getElementById("msg-layer").querySelector(".progress-txt").innerHTML = "Adding file to IPFS, please wait..."
          document.getElementById("msg-layer").querySelector(".progress-bar").style.width = "100%"
        }
      }
    },
    addressFromPublicKey: function(publickey){
      let network_id = this.$store.state.config.network == "testnet" ? "T" : "W"
      return libs.crypto.base58Encode(libs.crypto.buildAddress(publickey, network_id));
    },
    isAddress: function(addr){
      let currentNetwork = this.$store.state.config.network == "mainnet" ? 87 : 84
      return libs.crypto.verifyAddress(addr, {chainId: currentNetwork})
    },
    isGif: function(data){
      return this.artworkExension(data.art_display_cid).toLowerCase() == "gif"
    },
    isVideo: function(data, type="any"){
      let export_cid = data.art_export_cid || data.export_cid // from node or api have different prefix
      let display_cid = data.art_display_cid || data.display_cid
      if(export_cid != ''){
        if(type=="export"){  // if want the export only, pass type export
          return this.$store.state.acceptedFiles.exported.extVid.includes(this.artworkExension(export_cid).toLowerCase())
        }else if(type=="display"){
          return this.$store.state.acceptedFiles.display.extVid.includes(this.artworkExension(display_cid).toLowerCase())
        }else{
          return this.$store.state.acceptedFiles.exported.extVid.includes(this.artworkExension(export_cid).toLowerCase()) || this.$store.state.acceptedFiles.display.extVid.includes(this.artworkExension(display_cid).toLowerCase())
        }
        
      }else{
        return this.$store.state.acceptedFiles.exported.extVid.includes(this.artworkExension(display_cid).toLowerCase())
      }
    },
    cidToUse: function(data, type = "any"){
      let export_cid = data.art_export_cid || data.export_cid  // from node or api have different prefix
      let display_cid = data.art_display_cid || data.display_cid
      if(this.isVideo(data)){
        if(export_cid != ''){
            if(type=="export"){ // if want the export only, pass type export
              return export_cid
            }else{
              return this.artworkExension(display_cid).toLowerCase() == "mp4" ? display_cid : export_cid
            }
        }else{
          return display_cid
        }
      }else{
        return display_cid
      }      
    },
    artworkExension: function(e){
      let ext = e.substr(e.lastIndexOf(".")+1, e.length)
      return ext
    },
    getExplorerUrl: function(){
      if(this.$store.state.config.network == "testnet"){
        return 'https://'+this.$store.state.config.network+'.'+this.$store.state.config.wavesExplorer
      }else{
        return 'https://www.'+this.$store.state.config.wavesExplorer
      }
    },
    getFileType: async function(event){
      let fileType = await FileType.fromBlob(event.target.files[0]);
      return fileType
    },
    getFileExtension: function(fileName){
      let regex = new RegExp('[^.]+$');
      let extension = fileName.match(regex);
      return extension[0]
    },
    /* resetScroll: function(){
      this.$store.state.homeScrollState.scrollToId =  0
      this.$store.state.marketplaceScrollState.scrollToId =  0
      this.$store.state.artistScrollState.scrollToId =  0,
      this.$store.state.artistScrollState.artworksLoaded = 0,
      this.$store.state.artistScrollState.allArtworks = null
      if(this.$route.name == "user"){
        document.getElementById("main").scrollTop = 0
      }
    }, */
    resetHomeScroll: function(){
      this.$store.state.homeScrollState.scrollToId =  0
      //this.$store.state.homeScrollState.artworksLoaded = 0
      //this.$store.state.homeScrollState.allArtworks = undefined
      if(this.$route.name == "home"){
        document.getElementById("main").scrollTop = 0
      }
    },
    resetArtistFilter: function(){
      this.$store.state.filtering.artist = JSON.parse(JSON.stringify(this.$store.state.filtering.artist_init))
    },
    resetArtistScroll: function(){
      this.$store.state.artistScrollState.scrollToId =  0,
      this.$store.state.artistScrollState.artworksLoaded = 0,
      //this.$store.state.artistScrollState.allArtworks = null
      this.$store.state.activeMarket = 'home-paginate';
      if(this.$route.name == "user"){
        document.getElementById("main").scrollTop = 0
      }
    },
    navigateToHome: function() {
      this.$router.push("/");
    },
    getIpfsLink: function(string) {
      if(string!="" && string!=undefined){
        /* if(gateway == "infura"){
        let cid_name = string.split("/");
          return "https://" +encodeURIComponent(cid_name[0]) +"." +this.$store.state.config.ipfsGateway +"/" +encodeURIComponent(cid_name[1]); 
        }else{
          return "https://" + "cloudflare-ipfs.com/ipfs/" +string
        } */
        let cid_name = string.split("/");
        return "https://" +this.$store.state.config.ipfsGateway +"/"+encodeURIComponent(cid_name[0]) +"/" + encodeURIComponent(cid_name[1]);
      }else{
        return ""
      }
    },
    resizeImgage: function(image, useWidth, type) {
      let _this = this
      const width = 768;
      const height = 992;
      const fileName = image.name;
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = event => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
              const elem = document.createElement('canvas');
              let scaleFactor;
              if(useWidth){
                scaleFactor = width / img.width;
                elem.width = width;
                elem.height = img.height * scaleFactor;
              }else{
                scaleFactor = height / img.height;
                elem.height = height;
                elem.width = img.width * scaleFactor;
              }

              const ctx = elem.getContext('2d');
              // img.width and img.height will contain the original dimensions
              if(useWidth){
                ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
              }else{
                ctx.drawImage(img, 0, 0, height, img.width * scaleFactor);
              }
              // 'image/jpeg'
              ctx.canvas.toBlob(async (blob) => {
                  const file = new File([blob], fileName, {
                      type: image.type,
                      lastModified: Date.now()
                  });
                  if(file.size > _this.display.maxsize){
                    _this.display.label = "File is too big"
                    _this.display.dimensionError = true
                  }else{
                    _this.calculateHash(type, file)
                  }
              }, image.type, 1);
            },
            reader.onerror = error => console.log(error);
      }
    },
    checkExported: async function(type, event){

      // let allowedFormat = ["image/png", "image/jpg", "image/jpeg", "image/gif", "video/mp4", "video/webm"]
      let metadata = await this.getFileType(event)
      this.exported.label = "Checking file..."
      this.exported.error = false
      if(type == 'exported'){
        if(!this.$store.state.acceptedFiles.exported.accept.includes(metadata.mime)){
          this.exported.label = "Wrong file format"
          this.exported.error = true
          return
        }
        if(metadata.mime == "video/mp4"){
          this.mp4Alert = true
        }else{
          this.mp4Alert = false
        }
      }

      if(event.target.files[0].size > this.exported.maxsize){
        this.exported.label = "File is too big"
        this.exported.error = true
        return
      }
      if(!this.exported.error){this.calculateHash(type, event)}
    },
    checkCoverCollection: async function(event){

      let metadata = await this.getFileType(event)
      this.new_collection.error_label = "Checking file..."
      this.new_collection.error = false

      if(!this.$store.state.acceptedFiles.collection_cover.accept.includes(metadata.mime)){
        this.new_collection.error_label = "Wrong file format"
        this.new_collection.error = true
        event.target.value = ""
        return
      }

      if(event.target.files[0].size > this.new_collection.maxsize){
        this.new_collection.error_label = "File is too big"
        this.new_collection.error = true
        event.target.value = ""
        return
      }

    },
    fileDimensionCrawler:  async function(type, event){
      this.crawler.error = false
      let _this = this
      let readerImage = new FileReader();
      _this.crawler.dimensionError = false
      _this.crawler.label = "Checking file..."
      
      if (event.target.files[0].type.match('image.*')) {
        readerImage.readAsDataURL(event.target.files[0]);
        readerImage.onload = e => {
          let image = new Image();
          image.src = e.target.result;
          image.onload = function() {
            if(event.target.files[0].size > _this.crawler.maxsize){
              _this.crawler.label = "Max size allowed: "+ _this.crawler.maxsize/1000000+"Mb"
              _this.crawler.dimensionError = true
            }else{
              _this.crawler.label = event.target.files[0].name
              _this.calculateHash(type, event.target.files[0])
            }
          };
        }
      }else{
        _this.crawler.label = `Only ${_this.$store.state.acceptedFiles.crawler.extImg.toString()} accepted at the moment`
        _this.crawler.dimensionError = true
      }
    },
    fileDimension:  async function(type, event){
      this.crawler.cid = ''
      this.crawler.fileHash = ''
      this.crawler.label = "Select the crawler file"
      this.crawler.imageToUpload = null
      this.display.isMP4 = false
      this.display.error = false
      let _this = this
      let readerImage = new FileReader();
      _this.display.dimensionError = false
      _this.display.label = "Checking file..."

      if (event.target.files[0].type.match('image.*')) {
        readerImage.readAsDataURL(event.target.files[0]);
        readerImage.onload = e => {
          
          let image = new Image();
          image.src = e.target.result;

          image.onload = function() {
            if(image.width > 768 || image.height > 992){
              let useWidth
              if(image.width>image.height){
                useWidth = true
              }else if(image.with < image.height){
                useWidth= false
              }else{
                useWidth = true
              }
              if(event.target.files[0].type != 'image/gif'){
                _this.resizeImgage(event.target.files[0], useWidth, type)
              }else{
               _this.display.label = "Animated GIF:<br/> 768 x 992px max"
               _this.display.dimensionError = true
              }
            }else{
                if(event.target.files[0].type == 'image/gif'){ // GIF MAXSIZE = 2x display maxsize 
                  if(event.target.files[0].size > _this.display.maxsizeGif){
                    _this.display.label = "Max GIF size: "+ _this.display.maxsizeGif/1000000+"Mb"
                    _this.display.dimensionError = true
                  }else{
                    _this.calculateHash(type, event.target.files[0])
                  }
                }else{
                  if(event.target.files[0].size > _this.display.maxsize){
                    _this.display.label = "Max size allowed: "+ _this.display.maxsize/1000000+"Mb"
                    _this.display.dimensionError = true
                  }else{
                    _this.calculateHash(type, event.target.files[0])
                  }
                }
            }
            return
          };
        }
      }else if(event.target.files[0].type.match('video.*')){
        let metadata = await this.getFileType(event)
        if(!this.$store.state.acceptedFiles.display.accept.includes(metadata.mime)){
          this.display.label = "Wrong file format"
          this.display.error = true
          return
        }
        if(metadata.mime == "video/mp4"){
          this.mp4Alert = true
        }else{
          this.mp4Alert = false
        }
        if(event.target.files[0].size > this.display.maxsize){
          this.display.label = "File is too big"
          this.display.error = true
          return
        }
        this.display.isMP4 = true
        if(!this.display.error){this.calculateHash(type, event)}
      }else{
        if(type=="display"){
          _this.display.label = `Only ${_this.$store.state.acceptedFiles.display.extImg.toString()} accepted at the moment`
          _this.display.dimensionError = true
          return
        }
        if(type=="crawler"){
          _this.crawler.label = `Only ${_this.$store.state.acceptedFiles.crawler.extImg.toString()} accepted at the moment`
          _this.crawler.dimensionError = true
          return
        }
        if(type=="exported"){
          _this.display.label = `Only ${_this.$store.state.acceptedFiles.exported.extImg.toString() +','+_this.$store.state.acceptedFiles.exported.extVid.toString()} accepted at the moment`
          _this.display.dimensionError = true
          return
        }
        _this.calculateHash(type, event)
      }
    },
    replaceByDefaultImg: function(image){
      image.target.src = this.emptySrc
    },
    calculateHash: async function(type, event) {
      let _this = this;
      let target
      let fileName
      let progressBar

      if((type == "display" || type == "crawler") && event.name){
        target = event
        progressBar = type == "display" ? document.querySelector(".display_wrap") : document.querySelector(".crawler_wrap")
        fileName = event.name
      }else{
        target = event.target.files[0]
        progressBar = event.target.parentNode.parentNode
        fileName = event.target.files[0].name
      }
      
      await HashFile.execute(
        target,
        progressBar,
        async function(res) {
            _this[type].fileHash = res.hash;
            _this[type].label = '<i class="fas fa-check-circle"></i>' + fileName;
            _this[type].reference = _this[type].reference == "" ? res.title.substring(0, 45) : _this[type].reference;
            if(type == "display" || type == "crawler"){
              _this[type].imageToUpload = target
            }
            if(type == "exported"){

            let type = await FileType.fromBlob(res.buffer)
            let size = event.target.files[0].size/1024

            if(event.target.files[0].size < _this.exported.maxsize){
              if(type.mime.match("image/gif") || type.mime.match("image/jpg") || type.mime.match("image/jpeg") || type.mime.match("image/png")){
                
                let image = new Image();
                image.onload = async function() {
                    _this.exported.type = `Type: ${type.mime} - Size: ${size.toFixed(2)} Ko - Dimensions: ${image.width} x ${image.height} px`
                }
                image.src = URL.createObjectURL(res.buffer);
              }else{
                
                _this.exported.type = `Type: ${type.mime} - Size: ${size.toFixed(2)} Ko`
              }
            }
          }
        }
      ); 


    },
    getTickerByAssetId: function(assetId){
      let _this = this
      let ticker = ""
     
      switch(assetId) {
        
        case _this.$store.state.config.assetID:
          ticker = "SIGN"
        break;

        case _this.$store.state.config.usdnAssetID:
          ticker = "XTN"
        break;

        case _this.$store.state.config.usdtAssetID:
          ticker = "USDT-WX"
        break;

        case _this.$store.state.config.usdtPPAssetID:
          ticker = "USDT-PP"
        break;

        case "WAVES":
        case _this.$store.state.config.wavesAssetID:
          ticker = "WAVES"
        break;

        default:

      }
      return ticker
    },
    setRoyaltiesBase10With2Decimals: function(royalties){
      return royalties >= 0 ? royalties / Math.pow(10,2) : 10
    },
    getAssetIdByTicker: function(ticker){
      let _this = this
      let assetId = ""
     
      switch(ticker) {
        
        case "SIGN":
          assetId = _this.$store.state.config.assetID
        break;

        case "XTN":
          assetId = _this.$store.state.config.usdnAssetID
        break;

        case "USDT":
          assetId = _this.$store.state.config.usdtAssetID
        break;

        case "USDT-PP":
          assetId = _this.$store.state.config.usdtPPAssetID
        break;

        case "WAVES":
        case "":
          assetId = "WAVES"
        break;

        case "ETH":
          assetId = _this.$store.state.config.ethAssetID
        break;

        default:

      }
      return assetId
    },
    getDecimalByAssetId: function(assetId){
      let _this = this
      let decimal = 8
     
      switch(assetId) {
        
        case _this.$store.state.config.assetID:
          decimal = 8
        break;

        case _this.$store.state.config.usdnAssetID:
        case _this.$store.state.config.usdtAssetID:
        case _this.$store.state.config.usdtPPAssetID:
          decimal = 6
        break;

        case _this.$store.state.config.wavesAssetID:
          decimal = 8
        break;

        default:

      }
      return decimal
    },
    numberWithSpaces: function (x) {
      let resultat = ""
      if(x){
        if(x.toString().includes(".")){
          var parts = x.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
          resultat = parts.join(".");
        }else{
          resultat = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
         
        }
      }
      
      return resultat == "" ? 0 : resultat 
    },
    copyHash: async function(type, e) {
      try {
        await navigator.clipboard.writeText(this[type].fileHash);
        e.target.parentNode.parentNode.querySelector(".copied").innerHTML = "copied!";
        setTimeout(function() {
          e.target.parentNode.parentNode.querySelector(".copied").innerHTML = "";
        }, 2000);
      } catch (err) {
        console.log(err)
        console.log("Oops, unable to copy");
      }
    },
    copyString: async function(str, e) {
      e.target.parentNode.querySelector(".copied").classList.add("show")
      e.target.parentNode.querySelector(".copied").innerHTML = "Please wait...";
      try {
        await navigator.clipboard.writeText(str);
        e.target.parentNode.parentNode.querySelector(".copied").innerHTML = "copied!";
        setTimeout(function() {
          e.target.parentNode.querySelector(".copied").classList.remove("show")
          e.target.parentNode.parentNode.querySelector(".copied").innerHTML = "";
        }, 2000);
      } catch (err) {
        console.log(err)
        e.target.parentNode.querySelector(".copied").classList.remove("show")
        e.target.parentNode.querySelector(".copied").innerHTML = "";
      }
    },
    trimLongString: function () {
      let toTrim = document.querySelectorAll(".trimMobile");
      toTrim.forEach(function(trim) {
        if (trim.dataset.content.length > 25) {
          if (trim.dataset.content != "") {
            let data = trim.dataset.content;
            let shortAddrLeft = data.substring(0,10);
            let shortAddrRight = data.substr(-10);
            trim.textContent = shortAddrLeft + "..." + shortAddrRight;
          }
        } else {
          trim.textContent = trim.dataset.content;
        }
      });
    },
    untrimLongString: function() {
      let toTrim = document.querySelectorAll(".trimMobile");
      toTrim.forEach(function(trim) {
        trim.textContent = trim.dataset.content;
      });
    },
    disableSwipe: function(){
      this.$store.state.enableSwipe = false
    },
    enableSwipe: function(){
      let _this = this
      setTimeout(function(){
        _this.$store.state.enableSwipe = true
      }, 200)
    },
    getPriceWithTicker: function(assetId, price){ // assetId and non decimal price 100000000
      let ticker, newPrice
      if(assetId == this.$store.state.config.assetID || assetId == 'SIGN'){
              ticker = "SIGN"
              newPrice = price / Math.pow(10,8)
            }else if(assetId == this.$store.state.config.usdnAssetID || assetId == 'XTN'){
              ticker = "XTN"
              newPrice = price / Math.pow(10,6)
            }else if(assetId == this.$store.state.config.usdtAssetID || assetId == 'USDT-WX'){
              ticker = "USDT-WX"
              newPrice = price / Math.pow(10,6)
            }else if(assetId == this.$store.state.config.usdtPPAssetID || assetId == 'USDT-PP'){
              ticker = "USDT-PP"
              newPrice = price / Math.pow(10,6)
            }else if(assetId == this.$store.state.config.wavesAssetID || assetId == "WAVES"){
              ticker = "WAVES"
              newPrice = price / Math.pow(10,8)
            }else{
              ticker = "SIGN"
              newPrice = price / Math.pow(10,8)
            }
            
      return [ticker, newPrice, newPrice + " " +ticker]
    },
    getSignMinSellPrice: async function(){
      let _this = this
      let regex = encodeUrl("certification_fee_"+_this.$store.state.config.assetID)
      let getSignMinSellPrice = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.signOracle+"?matches="+regex)
      getSignMinSellPrice = await getSignMinSellPrice.json()
      _this.signMinPrice = getSignMinSellPrice[0].value / Math.pow(10, 8) * 2
      return getSignMinSellPrice[0].value * 2
    },
    copyLink: function(e, url){
      try {
        navigator.clipboard.writeText(url).then(() => {
          console.log(url)
          e.target.parentNode.querySelector(".copied").classList.add("show")
          e.target.parentNode.querySelector(".copied").innerHTML = "copied!";
          setTimeout(function() {
            e.target.parentNode.querySelector(".copied").innerHTML = "";
            e.target.parentNode.querySelector(".copied").classList.remove("show")
          }, 2000);
        })
        .catch(err => {
          console.log('Something went wrong', err);
        });
        
      } catch (err) {
        console.log(err)
        console.log("Oops, unable to copy");
        e.target.parentNode.querySelector(".copied").innerHTML = "";
        e.target.parentNode.querySelector(".copied").classList.remove("show")
      }
      
    },
    getCurrentUrl: function(){
      this.currentURL = window.location.origin + this.$route.path      
    },
    getCurrentArtistUrl: function(){
      this.currentUserURL = window.location.origin + "/user/" + this.$route.params.addr     
    },
    getShortUrl: async function(){
      let temp = await fetch(this.$store.state.config.signArtApiURL+"/tinyurl", {
        method: "POST",
        body: JSON.stringify({url: window.location.origin + this.$route.path}),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      let json = await temp.json()
      return json.url.tinyurl
    },
    iOS: function () {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    },
    whitelistedDrops: async function(addr){
      let _this = this
      let regex = encodeUrl(addr + "_free_drops");
      let free_drops_amount = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.dapp_addr_whitelist+"?key="+regex)
      free_drops_amount = await free_drops_amount.json();
      return { isWhiteListed: free_drops_amount[0] ? parseInt(free_drops_amount[0].value) > 0 : false, amount: free_drops_amount[0] ? parseInt(free_drops_amount[0].value) : 0 };
    },
    whitelistedTxs: async function(addr){
      let _this = this
      let regex = encodeUrl(addr + "_free_txs");
      let free_txs_amount = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.dapp_addr_whitelist+"?key="+regex)
      free_txs_amount = await free_txs_amount.json();
      return { isWhiteListed: free_txs_amount[0] ? parseInt(free_txs_amount[0].value) > 0 : false, amount: free_txs_amount[0] ? parseInt(free_txs_amount[0].value) : 0 };
    },
    whitelistedCertification: async function(addr){
      let _this = this
      let regex = encodeUrl("addr_WHITELISTED_" + addr);
      let free_certifications_amount = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.sign_dapp_addr+"?key="+regex)
      free_certifications_amount = await free_certifications_amount.json();
      return { isWhiteListed: free_certifications_amount[0] ? parseInt(free_certifications_amount[0].value) > 0 : false, amount: free_certifications_amount[0] ? parseInt(free_certifications_amount[0].value) : 0 };
    },
    copyShortLink: async function(e){
      e.target.parentNode.querySelector(".copied").classList.add("show")
      e.target.parentNode.querySelector(".copied").innerHTML = "Please wait...";
      let isIos = this.iOS()
      if(!isIos){
        let shortUrl = await this.getShortUrl()
        this.copyLink(e, shortUrl)
      }else{
        if(this.isIosShortLink){
          this.copyLink(e, this.isIosShortLink)
        }else{
          let shortUrl = await this.getShortUrl()
          this.isIosShortLink = shortUrl
          e.target.parentNode.querySelector(".copied").innerHTML = "Click again to copy!";
        }
      }
    },
    getCurrentHeight: async function(){
      let currentHeight = await fetch(this.$store.state.config.nodeURL+"/blocks/height")
      currentHeight = await currentHeight.json()
      return currentHeight.height
    },
    getAuctionEndHeight: async function(artId, artistAddr){
      let regex = encodeUrl("^art_endsale_"+ artId + "_" + artistAddr)
      let endHeight = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+this.$store.state.config.dapp_addr_artworks+"?matches="+regex)
      endHeight = await endHeight.json()
      if(endHeight[0]){
        return endHeight[0].value
      }else{
        return 0
      }
    },
    shortTxAddr: function(str){
      let getLeft = str.substring(0, 7)
      let getRight = str.substring(str.length-7)
      return getLeft+"..."+getRight
    },
    dateDiff: function (date_now, date_future){

      var delta = Math.abs(date_future - date_now) / 1000;

      var days = Math.floor(delta / 86400);
      delta -= days * 86400;

      var hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;
    
      var minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;
    
      var seconds = delta % 60; 
       
      return {
        days: parseInt(days),
        hours: parseInt(hours),
        minutes: parseInt(minutes),
        seconds: parseInt(seconds)
      };
    }
  },
};