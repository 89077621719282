//import UserView from "./components/content/userView.vue";

// import UserArtList from "./components/content/userArtList.vue";
import UserArtListApi from "./components/content/userArtListApi.vue";
import UserCollectionsList from "./components/content/userCollectionsList.vue";
import UserCollectionArtworks from "./components/content/userCollectionArtworks.vue";
import UserOwnedList from "./components/content/userOwnedList.vue";
import ArtworkDetails from "./components/content/artworkDetails.vue";
import EditionDetails from "./components/content/editionDetails.vue";
import UsersList from "./components/content/usersList.vue";
import UserAccount from "./components/content/userAccount.vue";
import UserAccountTabUserInfos from "./components/content/userAccount/userInfos.vue";
import UserAccountTabUserNotifications from "./components/content/userAccount/userNotifications.vue";
import UserAccountTabUserActivities from "./components/content/userAccount/userActivities.vue";
import UserAccountTabUserRegister from "./components/elements/Register.vue";

import UserAccountTabUserArtworks from "./components/content/userAccount/artworks.vue";
import UserAccountTabUserArtworksList from "./components/content/userAccount/artworks/list.vue";
import UserAccountTabUserArtworksCollections from "./components/content/userAccount/artworks/collections.vue";
import UserAccountTabUserArtworksCollectionsDetail from "./components/content/userAccount/artworks/collectionsDetail.vue";

import UserAccountTabUserArtwork from "./components/content/userAccount/artwork.vue";
import UserAccountTabAddArtworks from "./components/content/userAccount/addArtwork.vue";
import UserAccountTabUserOwned from "./components/content/userAccount/owned.vue";
import PageNotFound from "./components/content/PageNotFound.vue"

import HomeApi from "./components/content/homeContentApi.vue";
import HomePrimaryApi from "./components/content/homePrimaryApi.vue";
import HomeEditionApi from "./components/content/homeEditionApi.vue";
import HomeCollectionsApi from "./components/content/homeCollectionsApi.vue";
//import Home from "./components/content/homeContent.vue";
import Activity from "./components/content/activity.vue";

import EmailHome from "./components/content/emailConfirm/emailHome.vue";
import EmailConfirm from "./components/content/emailConfirm/emailVerified.vue";
import EmailError from "./components/content/emailConfirm/emailError.vue";

import Faq from "./components/content/faq.vue";
import CodeOfConduct from "./components/content/codeOfConduct.vue";
import TermsOfServices from "./components/content/termsOfServices.vue";
import PrivacyPolicy from "./components/content/privacyPolicy.vue";

export const routes = [
    { path: "", component: HomeApi, 
      name: "home",
      redirect: "/page/1",
      meta: {
        title: 'Welcome to SIGN Art'
      },
      children: [
        {
          path: '/page/:pageindex',
          name: "home-paginate",
          component: HomePrimaryApi
        },
        { path: "/marketplace/:pageindex", 
          component: HomeEditionApi, 
          name: "marketplace",
          meta: {
            title: 'SIGN Art Marketplace'
          }
        },
        { path: "/collections/:pageindex", 
          component: HomeCollectionsApi, 
          name: "home-collections",
          meta: {
            title: 'SIGN Art collections'
          }
        },
        { path: "/activity", 
          component: Activity, 
          name: "activity",
          meta: {
            title: 'SIGN Art activity'
          }
        }
      ]
    },
    { path: "/user", component: UsersList, 
      meta: {
        title: 'User page'
      }
    },
    { path: "/user/:addr/", component: HomeApi, children: [
      {
        name: 'user-infos-front',
        path: '',
        component: UserArtListApi
      },
      {
        name: "user-paginate",
        path: '/user/:addr/page/:pageindex',
        component: UserArtListApi
      },
      {
        name: 'user-owned-front',
        path: 'owned',
        component: UserOwnedList
      },
      {
        name: 'user-collections',
        path: 'collections/page/:pageIndex?',
        component: UserCollectionsList
      },
      {
        name: "user-collection-detail",
        path: '/user/:addr/collection/:id/page/:pageIndex?',
        component: UserCollectionArtworks
      },
      { 
        name: "user-artwork-front",
        path: "artwork/:txid", 
        component: ArtworkDetails
      },
      { 
        name: "user-artwork-front-issue",
        path: "artwork/:txid/edition/:issue", 
        component: EditionDetails
      }
    ]
    },

    { path: "/user/:addr/account", component: UserAccount, name: "account", meta: { requiresAuth: true }, children: [
      {
        name: 'user-infos',
        path: 'infos',
        component: UserAccountTabUserInfos
      },
      {
        name: 'user-notifications',
        path: 'notifications',
        component: UserAccountTabUserNotifications
      },
      {
        name: 'user-activities',
        path: 'activities',
        component: UserAccountTabUserActivities
      },
      {
        name: 'user-register',
        path: 'register',
        component: UserAccountTabUserRegister
      },
      {
        name: 'user-artworks',
        path: 'artworks',
        component: UserAccountTabUserArtworks,
        children: [
          {
            name: 'user-artworks-list',
            path: 'list',
            component: UserAccountTabUserArtworksList,
          },
          {
            name: 'user-artwork',
            path: 'list/:txid',
            component: UserAccountTabUserArtwork
          },
          {
            name: 'user-artworks-collections',
            path: 'collections/page/:pageIndex?',
            component: UserAccountTabUserArtworksCollections
          },
          {
            name: 'user-artworks-collections-detail',
            path: 'collections/:id/page/:pageIndex?',
            component: UserAccountTabUserArtworksCollectionsDetail
          },
          
        ]
      },
      {
        name: 'user-add-artwork',
        path: 'add-artwork',
        component: UserAccountTabAddArtworks
      },
      {
        name: 'user-owned',
        path: 'owned',
        component: UserAccountTabUserOwned
      }
    ]
    },
/*     { path: "/user/:addr/artwork/:txid", component: ArtworkDetails, name: "user-artwork-front" },
    { path: "/user/:addr/artwork/:txid/edition/:issue", component: EditionDetails, name: "user-artwork-front-issue" }, */
    { path: "/faq", component: Faq, 
      meta: {
        title: 'FAQ'
      }
    },
    { path: "/code-of-conduct", component: CodeOfConduct, 
      meta: {
        title: 'Code Of Conduct'
      }
    },
    { path: "/terms-of-service", component: TermsOfServices, 
      meta: {
        title: 'Terms Of Service'
      }
    },
    { path: "/privacy", component: PrivacyPolicy, 
      meta: {
        title: 'PrivacyPolicy'
      }
    },
    { path: "/email", component: EmailHome, 
      name: "email-result",
      meta: {
        title: 'Email verification'
      }
    },
    {
      path: "/email/confirmed", component: EmailConfirm,
      name: "email-confirmed"
    },
    {
      path: "/email/error", component: EmailError,
      name: "email-error"
    },
    { path: "*", component: PageNotFound ,
      meta: {
        title: 'Terms Of Service'
      }
    }
    
  ];