<template>
  <transition name="fade">
    <div class="burnPopin" v-show="$parent.burnPopinOpen" @click="closeBurnForm($event)" :class="{'flexstart':haveError}">
      <i class="fa-solid fa-xmark close_popin_nft"></i>
      <div class="col-12 px-0 wrapper">
        <p>Are you sure you want to burn the following NFT?: <strong>{{ nftInfo.name || nft.primary.name }}</strong><br/> This action cannot be reversed.</p>
        <button class="btn btn-primary bigger mb-0 mt-2 mx-auto d-block pointer-events-auto" @click.prevent="burn">I KNOW WHAT I'M DOING!</button>
        <p v-show="burnResult!=''" class="text-center mt-1">{{burnResult }}</p>
      </div>
      <div class="clickZone pointer"></div>
    </div>
  </transition>
</template>
<script>
import globalMixin from '@/mixins/global.js';

export default {
  mixins: [globalMixin],
  props: ["nftInfo", "nft", "transferPopinOpen"],
  data: function(){
    return {
      burnResult: '',
      haveError: false
    }
  },
  created(){},
  methods: {
    closeBurnForm: function(e){
      if(e.target.closest('.clickZone') || e.target.closest('.close_popin_nft')){
        this.$parent.burnPopinOpen = false;
      }
    },
      burn: function(){
      let _this = this
      _this.burnResult = "NFT Burn in progress..."

      const burnTx = {
        dApp: this.$store.state.config.dapp_addr_burn,
        call:  {
            function: "burnNFTProxy",
            args:[]
        },
        payment: [{ assetId: _this.nftInfo.assetId, amount: 1 }]
      }

      _this.$store.dispatch("signerInvoke", burnTx).then(res => { 
        if(res.error){
          _this.haveError=true
          if(res.message){
             _this.burnResult = res.message
          }else{
           _this.burnResult = res.error
          }
        }else{
          _this.burnResult = "NFT burned!"
          _this.haveError=false
          _this.$parent.$emit('removeNFT', _this.nftInfo.assetId)
          
        }
      })
    },
  }
}
</script>