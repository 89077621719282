export default {
  methods: {
    loadArtworksFromApi: async function(artistAddr = null, showBanned = false){
      let index = this.$route.params.pageindex ? this.$route.params.pageindex : 1
      const _this = this
      this.loadnext = false
      this.loadprev = false
      this.loading=true
      this.callingApi = true
      let artworks
      _this.allArtworks = undefined

      let decimal 
      if(artistAddr){
        _this.filters = this.$store.state.filtering.artist
        decimal = this.getDecimalByAssetId(this.$store.state.filtering.artist.currency)
      }else{
        _this.filters = this.$store.state.filtering.home
        decimal = this.getDecimalByAssetId(this.$store.state.filtering.home.currency)
      }
      try{
        let filtered = {
          "page": index,
          "perpage": this.artPerPage,
          "status": _this.filters.status || "all",
          "stringSearch": this.$store.state.searchStr,
          "assetAccepted": _this.filters.currency,
          "address": artistAddr,
          "showBanned": showBanned,
          "fromRange": _this.filters.fromRange * Math.pow(10, decimal),
          "toRange": _this.filters.toRange * Math.pow(10, decimal),
          "sort": _this.filters.sort,
          "crosschainOnly":  _this.filters.crosschainOnly
        }
        if(_this.filters.onsale == "offerOnly"){
          filtered.offerOnly = true
          filtered.onsale = "all"
        }else{
          filtered.onsale = _this.filters.onsale
        }

        let getArtworks = await fetch(
          this.$store.state.config.signArtApiURL+"/artworks/paginate-filter",
          {
            method: 'post',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
              filtered
            )
          }
        )
      getArtworks = await getArtworks.json()
      artworks = getArtworks
      }catch(e){
        console.log(e)
      }

      if(artworks){
          if(_this.$store.state.config.useIMGIX == "true"){
            let imgixData =[]
            artworks.forEach(function(e){
              imgixData.push({id: e.txid, url: _this.getIpfsLink(e.display_cid)})
            })
            let response = await fetch(this.$store.state.config.signArtApiURL+"/imageboss", {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(imgixData)
                });
                const imgix = await response.json();
            this.imgix = imgix.res
          }

          if(artworks.length > this.artPerPage-1){
            _this.fullyLoaded = false
          }else{
            _this.fullyLoaded = true
          }
          
          _this.allArtworks = Object.values(artworks)
          this.updateMsnryLayout()
          _this.loading = false
        }else{
          _this.fullyLoaded = true
          _this.loading = false
        }
      _this.callingApi = false
      return
    }
  }
}