<template>
  <transition name="fade" :duration="3000">
    <div id="crosschainPopup" class="text-center" v-show="isLogged && isArtist && !hidePopup && !isCrosschain">
        <div class="closeNL" @click="closeForm"><i class="fas fa-times"></i></div>
        <h2>Enable <strong>Cross-chain</strong> now!</h2>
        <p class="mb-3 mb-lg-4">
          Hey <strong>{{ artistName }}</strong>, you can now register your Ethereum address to allow collectors to transfer your NFT's to Ethereum through our 
          <a :href="$store.state.config.sign_art_gateway_url" target="_blank">SIGN Art Gateway</a><br/><span class="mention mt-1">(Royalties from other blockchains, if supported, will be paid to this address. <a href="https://signaturechain.medium.com/sign-art-nfts-bridge-to-ethereum-network-2ca2a0436e2d" target="_blank">Read more</a>)</span>
        </p>
        <div class="form-group col-lg-12 mb-3 mb-lg-4">
          <input type="text" id="erc20" v-model="userErc20" class="form-control mb-2" placeholder="ex: 0x79FC24Fe4cE264C7Ca67769514e3b578d71e0287"/>
        </div>
        <div id="response" :class="{error: haveError}" v-show="responseMessage!=''" class="col-xl-12 mx-auto text-center mt-3">
          <div class="wrap">{{responseMessage}}</div>
        </div>
        <a href="#" class="btn" @click="updateErc20">UPDATE PROFILE</a> <span class="btn ml-0 ml-lg-3 greyish" @click="closeForm">NOT NOW</span>
    </div>
  </transition>
</template>
<script>
import globalMixin from '@/mixins/global.js';

export default {
  mixins: [globalMixin],
  props: ['user_social'],
  data: function(){
    return {
      delay: true,
      hidePopup: false,
      isArtist: false,
      isCrosschain: false,
      isLogged: this.$store.state.user.isLogged,
      userErc20: '',
      haveError: false,
      responseMessage: '',
      artistName: ''
    }
  },
  async created(){
    this.init()
  },
  watch: {
    '$store.state.user.isLogged': function(logged){
      this.isLogged = logged
      this.init()
    }
  },
  methods: {
    init: async function(){
      let hidePopup = this.$cookies.get("hideCrosschainPopup")
      if(hidePopup){
        this.hidePopup = true
      }else{
        await this.getCrossChainStatus()
        await this.getArtistStatus()
      }
    },
    getCrossChainStatus: async function(){
      let regex = 'user_ERC20_'+this.$store.state.user.userAddress
      let status = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+this.$store.state.config.dapp_addr_users+"?matches="+regex)
      status = await status.json()
      if(status[0] && status[0].value != ''){
        this.isCrosschain = true
        this.$cookies.set("hideCrosschainPopup", true)
      }
    },
    getArtistStatus: async function(){
      let regex = encodeURIComponent('user_status_'+this.$store.state.user.userAddress+'|user_name_'+this.$store.state.user.userAddress)
      let status = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+this.$store.state.config.dapp_addr_users+"?matches="+regex)
      status = await status.json()
      if(status[0] && status[1] && (status[1].value == 'REGISTERED' || status[1].value == 'VERIFIED')){
        this.isArtist = true
        this.artistName = status[0].value
      }
    },
    closeForm: function(){
      let _this = this
      this.$cookies.set("hideCrosschainPopup", true)
      setTimeout(function(){
        _this.hidePopup = true
      }, 100)
      
    },
    updateErc20: async function(){
      let _this = this;
      this.haveError = false
      this.responseMessage = 'Checking data...'
      if(this.userErc20 == ''){
        this.haveError = true
        this.responseMessage = 'Erc20 address cannot be empty'
        return
      }
      const dataTx = {
        dApp: this.$store.state.config.dapp_addr_users,
        /* feeAssetId: this.$store.state.config.assetID,  */
            call: {
                function: "setERC20User",
                 args:[
                    { type:"string", value: this.userErc20 }
                ]
            }
      }
      this.$store.state.alert=true
      this.$store.state.alertMessage='Sending your data to the Waves Blockchain...'
      this.$store.dispatch("signerInvoke", dataTx).then(res => {
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          if(res.error){
            // siplay error
            console.log(res.error)
            this.responseMessage = res.error.message
            this.haveError = true
          }else{
            // display confirmation message of success
            this.responseMessage = 'Informations updated!'
            this.haveError = false
            setTimeout(function(){
              _this.closeForm()
            }, 100)
          }
      })
    },
  },
  mounted(){
    this.isLogged = this.$store.state.user.isLogged
  }
}
</script>
<style lang="scss">
#crosschainPopup{
  position: absolute;
  z-index: 5000;
  width: 100%;
  height: auto;
  top: 15%;
  left: 50%;
  background-color: white;
  max-width: 700px;
  transform: translateX(-50%);
  padding: 20px;
  color: #1E1A1A;
  box-shadow: 0px 0px 20px 4px rgb(0 0 0 / 10%);
  background-image: linear-gradient(#fef2ff, #f8e4f9);
  border: solid 10px white;
  h2{
    text-transform: uppercase;
    font-weight: 600;
  }
  .mention{
    font-style: italic;
  }
  .btn{
    transition: all .4s ease;
    &.greyish{
      background-color: #919191;
    }
    &:hover{
      background-color: #57137b;
      color: white;
    }
  }
}

</style>