<template>
  <div class="col-md-8 col-lg-8 col-xl-12 px-1 userPage wrap" id="content">
    <div>
      <div v-if="!userExist" id="missingUser">Redirect to owned...</div>
      <div v-else>
          <app-user-infos :isBanned="isBanned" :artistInfos="artistInfos" :infosLoaded="infosLoaded" :userExist="userExist" :hasProfile="hasProfile" :userNote="userNote" :collectorInfos="collectorInfos"></app-user-infos>
          
          <div id="collection_detail" v-if="collection_details.name != ''">

             
            <h3>
              {{collection_details.name}} 
              <span class="items_count_3d">
                <span class="date text-center ml-2 mt-1">({{ collection_details.item_count }} {{ collection_details.item_count > 1 ? 'items' : 'item'}})</span> 
                <a :href="get3dURL()" target="_blank" class="btn3d ml-1" v-if="artistInfos.user_status == 'VERIFIED'" v-b-tooltip.hover.top title="See this collection in 3D"><img src="../../assets/images/3d_icon.svg"/></a>
              </span>
            </h3>
            <p class="mb-0">{{collection_details.description}}</p>
          </div>

          <div v-show="loading" class="loading">
            <app-loading :label="'data'"></app-loading>
          </div>
          <div id="nocontent" class="mb-4" v-if="!loading && Object.keys(allArtworks).length === 0">
            <div v-if="!this.$route.params.pageindex">There are no artworks available yet.</div>
            <div v-else>There are no artworks available</div>
          </div>
          
          <div class="grid" v-show="!loading && Object.keys(allArtworks).length">
            <div class="grid-sizer"></div>
            <div class="grid-item" v-for="(artwork, key) in allArtworks" :key="key" :data-index="key" @mousedown="storeArtworkClickedIndex($event)">

              <app-artwork-item :artwork="artwork" :showArtist="false" :keyIndex="key"></app-artwork-item>
            </div>
          </div>
           <div class="wrap_pagination d-flex justify-content-center mt-2" v-if="!loading">
              <button class="btn btn-primary mb-2 bigger" :class="{'mr-3': allArtworks.length}"  v-if="this.$route.params.pageIndex && this.$route.params.pageIndex > 1" @click="prevNext('prev')">PREVIOUS</button>
              <button class="btn btn-primary mb-2 bigger" @click="prevNext('next')" v-if="allArtworks && allArtworks.length == artPerPage">NEXT</button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Masonry from "masonry-layout";
import UserInfos from '@/components/elements/UserInfos.vue'
import Loading from '@/components/elements/Loading.vue'
import collections from '@/mixins/collections.js';
import imagesLoaded from 'imagesloaded';
import globalMixin from '@/mixins/global.js';
import userMixin from '@/mixins/userMixin.js';
import ArtworkItemApi from '@/components/content/artwork/ArtworkItemApi.vue'

export default {
  mixins: [userMixin, globalMixin, collections],
  data: function(){
    return {
      addr: this.$route.params.addr,
      //detailOpen: false,
      imgix: undefined,
      //tempPaddingTopContent: 0,
      allArtworks: undefined,
      loading: true,
      artPerPage: 20,
      queryType: 2, // 1 = 2 per artists overall, 2 = 2 per artist then 3 per artist etc, 3 = 2 per artists then all remaining by date
      fullyLoaded: false,
      callingApi: false,
      //cursor: '',
      currentPage: 1,
      //filters: this.$store.state.filtering.artist,
      collection_details: {
        name: "",
        description: "",
        cover: "",
        id: null,
        item_count: 0
      }
    }
  },
  components: {
    'app-user-infos': UserInfos,
    'app-loading': Loading,
    'app-artwork-item': ArtworkItemApi,
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    }
  },
  created(){
    this.init()
  },
  methods: {
    init: async function(){
      await this.$store.dispatch("getPriceInUsd")
      document.getElementById('main').scrollTop = 0
      document.getElementById("main").classList.add("show")
      this.gotoScroll()
      this.loading = true
      let collection_details = await this.getCollections(this.$route.params.addr, this.$route.params.id)
      this.collection_details = collection_details[0]
      let artworks = await this.getCollectionItems(this.$route.params.addr, this.$route.params.id, this.artPerPage)
      let imgixData =[]
      let _this = this
      artworks.forEach(function(e){
        imgixData.push({id: e.txid, url: _this.getIpfsLink(e.display_cid)})
      })
      let response = await fetch(this.$store.state.config.signArtApiURL+"/imageboss", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(imgixData)
          });
          const imgix = await response.json();
      this.imgix = imgix.res
      this.allArtworks = artworks
      this.loading = false

      this.$nextTick(() => { // IF PERFORMANCE PROBLEM, see to run this once only
        if(window.msnry != undefined){
          this.$nextTick(() => {
            window.msnry.layout();
          }) 
        }
      })
    },
    get3dURL: function(){
      let collectionPath = this.$route.name == "user-collection-detail" && this.allArtworks.length > 0 ? '/collection/'+this.$route.params.id+"/page/"+this.$route.params.pageIndex : ''
      return this.$store.state.config.network == 'mainnet' ? 'https://room.sign-art.app/'+this.artistInfos.user_addr+collectionPath : 'https://room.sign-art.app/'
    },
    gotoScroll: function(){
      if(document.getElementById(this.$store.state.collectionItemScrollState.scrollToId)){
        document.getElementById(this.$store.state.collectionItemScrollState.scrollToId).scrollIntoView()
        this.$store.state.collectionItemScrollState.scrollToId = 0
      }
    },
    prevNext: function(direction){
      let index
      this.loading = true
      if(direction == "prev"){
        index = +Number(this.$route.params.pageIndex)-1
      }else{
        index = +Number(this.$route.params.pageIndex)+1
        
      }
      this.$router.push({ name:  'user-collection-detail', params: { id: this.collection_details.id, pageIndex: index } }).catch(()=>{});
    },
    storeArtworkClickedIndex: function(e){
      this.$store.state.collectionItemScrollState.scrollToId = e.target.id
    },
    updateMsnryLayout: function(){
      if(window.msnry){
        window.msnry.layout();
        this.gotoScroll()
        var imgLoad = imagesLoaded('.grid');
        imgLoad.on( 'progress', function(  ) {
          window.msnry.layout();
        });
      }
    }
  },
  watch: {
    allArtworks: function(){
      if(document.querySelector(".grid")){
        document.querySelector(".grid").classList.add("show")
      }
      this.$nextTick(() => { // IF PERFORMANCE PROBLEM, see to run this once only
        window.msnry = new Masonry('.grid', {
          percentPosition: true,
          initLayout: false,
          itemSelector: ".grid-item",
          columnWidth: ".grid-sizer",
        });
        
        this.updateMsnryLayout()
        this.$nextTick(() => {
          this.updateMsnryLayout()
        }) 
      })
    },
    $route(to){
      if(to.name == "user-collections-paginate" || to.name == "user-collections"){
        this.init()
      }
    },
    '$route.params.addr'(){
      this.artistInfos={}
      this.getArtistInfos()
    },
    '$route.params.pageIndex'(){
      this.init()
    }
  },
  mounted(){
    document.getElementById('main').scrollTop = 0
    document.getElementById("main").classList.add("show")
    this.gotoScroll()
  }
}
</script>
<style lang="scss" scoped>
.btn3d{
    padding: 0px 0px;
    border-radius: 50%;
    color: black;
    display: flex;
    align-items: center;
    width: 35px;
    height: 35px;
    justify-content: center;
    
    img{
      transition: all 0.4s ease;
      margin-bottom: 2px;
      mix-blend-mode: difference;
      &:hover{
        opacity: 0.7;
      }
    }
}
#collection_detail{
  color: #1E1A1A;
  padding: 25px;
  /* border-top: solid 1px #ccc; */
  margin-bottom: 10px;
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 15px;
  border: solid 1px #ccc;
  .date {
    color: #aaa;
    font-size: 14px;
  }
  h3{
    font-size: 24px;
    margin-bottom: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p{
    text-align: center;
  }
  .items_count_3d{
      display: flex;
      align-items: center;
    }
}
  .wrap_pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    //position: sticky;
    margin-top: 15px;
    padding-bottom: 15px;
    bottom: 20px;
    z-index: 5000;
    /* pointer-events: none; */
    .loadnext, .loadprev{
      pointer-events:all;
      margin: initial;
      margin-right: 30px;
      &.no-mr{ margin-right: 0px;}
    }
  }
  #homeGridWrapper{
    padding-bottom: 20px;
  }
  @media(max-width: 768px){
    #collection_detail{
      h3{
        flex-direction: column;
        
      }
    }
  }
  </style>