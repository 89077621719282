<template>
  <div>
    
    <ul class="social" v-if="validSocial">
      
      <li><span class="copied" @click="copyShortLink"></span> <img src="../../assets/images/copy_icon.svg" :data-link="currentUserURL" @click="copyShortLink" v-b-tooltip.hover.top title="Generate short link" class="cursor-pointer mb-1"/></li>
      <li v-show="JSON.parse(user_social).facebook != ''"><a :href="JSON.parse(user_social).facebook | normalizeUrl" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
      <li v-show="JSON.parse(user_social).twitter"><a :href="JSON.parse(user_social).twitter | normalizeUrl" target="_blank"><i class="fab fa-twitter"></i></a></li>
      <li v-show="JSON.parse(user_social).behance"><a :href="JSON.parse(user_social).behance | normalizeUrl" target="_blank"><i class="fab fa-behance"></i></a></li>
      <li v-show="JSON.parse(user_social).deviantart"><a :href="JSON.parse(user_social).deviantart | normalizeUrl" target="_blank"><i class="fab fa-deviantart"></i></a></li>
      <li v-show="JSON.parse(user_social).instagram"><a :href="JSON.parse(user_social).instagram | normalizeUrl" target="_blank"><i class="fab fa-instagram"></i></a></li>
      <li v-show="JSON.parse(user_social).website"><a :href="JSON.parse(user_social).website | normalizeUrl" target="_blank"><i class="fas fa-globe-americas"></i></a></li>
    </ul>
  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js';
//import TinyURL from 'tinyurl';
export default {
  mixins: [globalMixin],
  props: ['user_social'],
  data: function(){
    return {
      validSocial: true,
      currentUserURL: "",
      showCopied: false
    }
  },

  created(){
    this.init()
    try{
      JSON.parse(this.user_social)
     this.validSocial = true
    }catch(err){
      this.validSocial = false
    }
  },
  methods: {
    init: function(){
      this.getCurrentArtistUrl()
    },
  }
}
</script>
<style lang="scss" scoped>
  .copied{
    opacity:0;
    &.show{
      opacity: 1!important;
      bottom: -5px!important;
      left: -85px!important;
      padding: 0px 15px;
    }
  }
  .social{
    .fa-copy{
      color:#9A0E88;
    }
  }
</style>