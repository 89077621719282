<template>
  <div class="col-12 px-0  wrap" id="content">
    <div class="market_menu d-flex" :class="{'show': showMenuTop}">
      <div class="wrapper">
        <router-link :to="'/page/1'+dropsQueries" @mousedown.native="resetScrollId">
          <img src="../../assets/images/logo-white.svg" class="w-100 img-fluid d-block" id="mainLogo-white" width="90"/>
        </router-link>
        <div class="wrap_market_btn d-flex justify-content-center align-items-center mt-2 mt-md-0">
          <div class="position-relative">
            <button class="noborder" @click="setShowExplore(true)" @mouseover="setShowExplore(true)" @mouseout="setShowExplore(false)">Explore</button>
            <transition name="fade">
              <ul id="explore_menu" v-show="showExplore" @mouseover="setShowExplore(true)" @mouseout="setShowExplore(false)">
                <li @click="currentMenuItem = 'home-paginate'; showExplore=false">
                  <router-link :to="'/page/1'+dropsQueries" tag="button" class="noborder" :class="{'active': currentMenuItem == 'home-paginate'}" @mousedown.native="resetScrollId;">{{ deskopXL ? 'New drops' : 'Drops' }}</router-link>
                </li>
                <li @click="currentMenuItem = 'home-collections'; showExplore=false">
                  <router-link :to="'/collections/1'+collectionsQueries" tag="button" class="noborder" :class="{'active': currentMenuItem == 'home-collections'}" @mousedown.native="resetScrollId;">Collections</router-link>
                </li>
                <li @click="currentMenuItem = 'marketplace'; showExplore=false">
                  <router-link :to="'/marketplace/1'+marketplaceQueries" class="noborder" :class="{'active': currentMenuItem == 'marketplace'}" tag="button" @mousedown.native="resetScrollId;"><span>Marketplace</span></router-link>
                </li>
              </ul>
            </transition>
          </div>
           <router-link :to="'/activity'" class="noborder" :class="{'active': $store.state.activeMarket == 'activity'}" tag="button" @mousedown.native="resetScrollId"><span>Activity</span></router-link>
        </div>
    
        <div class="mt-lg-0 d-block" id="searchField"  >
          <i class="fas fa-times" @click="clearSearch" v-show="$store.state.searchStr!=''"></i>
          <input type="text" v-model="$store.state.searchStr" :placeholder="deskopXL ? 'What are you looking for?' : 'Search here...'" @keyup.enter="filterSearch(true)" :class="{'on':$store.state.searchStr!=''}"/>
          <select id="select_search" @change="setActiveMarketSearch($event)">
            <option value="home-paginate" :selected="$store.state.activeMarket == 'home-paginate'">New drops</option>
            <option value="home-collections" :selected="$store.state.activeMarket == 'home-collections'">Collections</option>
            <option value="marketplace" :selected="$store.state.activeMarket == 'marketplace'">Marketplace</option>
          </select>
          <button ><img src="../../assets/images/search-black.svg" @mousedown="resetScrollId;" @click="$route.params.pageindex = 1; filterSearch(true)"/></button>
          
        </div>
        <div id="wrap_buy_apply" class="mt-lg-0" >
          <div id="swap_token" @click.prevent="$store.state.showBuyPopin = !$store.state.showBuyPopin">
            $SIGN {{ deskopXL ? 'Token' : '' }}
          </div>
          <a href="https://sign-art.app/register" target="_blank" class="button apply" tag="button"><span @click="resetScrollId()">Apply {{ deskopXL ? 'as Artist' : '' }}</span></a> 
          <app-widgetLogin></app-widgetLogin>
          <a :href="$store.state.config.sign_art_gateway_url" target="_blank" id="gateway_icon" class="ml-0 mr-0 mr-sm-2 ml-xl-2 button" v-b-tooltip.hover.bottom title="Cross Chain Gateway">
            <i class="fa-sharp fa-solid fa-diamond"></i>
            <i class="fa-brands fa-ethereum"></i>
          </a>
        </div>
      </div>
    </div>

    <router-view :key="forceReload" id="view_container"></router-view>

  </div>
</template>

<script>
import WidgetLogin from '../../components/elements/WidgetLogin.vue'
import gotoMixin from '@/mixins/goto.js';
export default {
  mixins: [gotoMixin],
  data: function(){
    return {
      forceReload: 0,
      showMenuTop: false,
      deskopXL: true,
      showExplore: false,
      currentMenuItem: "home-paginate"
    }
  },
  components: {
    "app-widgetLogin": WidgetLogin,
  },
  mounted(){
    document.querySelector("body").addEventListener('mousedown', this.detectClickOutOfExplore);
    this.onResize()
    let openLogin = this.$route.query.l
    if(openLogin){
      let _this = this
      if(!this.$store.state.user.isLogged){
        _this.$store.state.signingChoice.showChoice = true
      }
    }
  },
  created() {
    this.$store.state.activeMarket = "home-paginate"
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    document.querySelector("body").removeEventListener('mousedown', this.detectClickOutOfExplore);
  },
  computed: {
    marketplaceQueries() {
        return `?search=${this.$store.state.searchStr}&status=${this.$route.query.status || this.$store.state.filtering.marketplace.statusArtist}`
    },
    dropsQueries() {
        return `?search=${this.$store.state.searchStr}&status=${this.$route.query.status || this.$store.state.filtering.home.status}`
    },
    collectionsQueries() {
        return `?search=${this.$store.state.searchStr}&status=${this.$route.query.status || this.$store.state.filtering.collections.status}`
    },
  },
  watch: { 
    '$route': {
      handler: function(to) {
        if(to.name=="marketplace" || to.name=="home-paginate" || to.name=="home-collections"){
          this.$store.state.activeMarket = to.name
          if(this.previousQuery != JSON.stringify(to.fullPath) && to.query.status){
            this.previousQuery = JSON.stringify(to.fullPath)
            this.createPath()
            if(to.name=="marketplace"){
              this.filterSearch(true)
            }
            if(to.name=="home-paginate"){
              this.filterSearch()
            }
            if(to.name=="home-collections"){
              this.filterSearch(true)
            }
            
          }else{
            this.filterSearch()
          }
        }
        
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onResize() {
      if (window.innerWidth > 1335) {
        this.deskopXL = true
      } else {
        this.deskopXL = false
      }
    },
    chooseSigningMethod(){
       if(this.$store.state.user.isLogged){
        this.goToAccount()
       }else{
         this.$store.state.signingChoice.showChoice = true
       }
    },
    setShowExplore: function(bool){
      let _this = this
      if(this.$store.state.isMobile){
        _this.showExplore = true
      }else{
        setTimeout(function(){
          _this.showExplore = bool
        }, 100)
      }
    },
    resetScrollId: function(){
      this.$store.state.homeScrollState.scrollToId=null
      this.$store.state.marketplaceScrollState.scrollToId=null
      this.$store.state.collectionsScrollState.scrollToId=null
    },
    createPath: function(){
      let query = this.$route.query
      
      if(query.search != "" && query.search !=  undefined &&query.search !=  null){
          this.$store.state.searchStr = this.$sanitize(query.search)
        }else{
          this.$store.state.searchStr = ""
        }
      if(query.status != "" && query.status != undefined && query.status != null){
        if(this.$route.name=='marketplace'){
          this.$store.state.filtering.marketplace.statusArtist = query.status || this.$store.state.filtering.marketplace.statusArtist
        }
        if(this.$route.name=='home-paginate'){
          
          this.$store.state.filtering.home.status = query.status || this.$store.state.filtering.home.status
        }
        if(this.$route.name=='home-collections'){
          this.$store.state.filtering.collections.status = query.status || this.$store.state.filtering.collections.status
        }
      }
    },
    setActiveMarketSearch: function(val){
      this.$store.state.activeMarket = val.target.value
    },
    filterSearch: function(reload=false){
        /* if(this.$route.name=='marketplace' || this.$route.name=='home-paginate' || this.$route.name=='home-collections'){
          if(reload){
            this.forceReload++
          }
        }else{  */
          this.currentMenuItem = this.$store.state.activeMarket;

          if(this.$store.state.activeMarket == "home-paginate"){
            let openLogin = this.$route.query.l
            this.$router.push({ name: "home-paginate", query: { search: this.$store.state.searchStr, status: this.$store.state.filtering.home.status, l: openLogin } }).catch(()=>{});
            
          }
          if(this.$store.state.activeMarket == "marketplace"){
            this.$router.push({ name: "marketplace", query: { search: this.$store.state.searchStr, status: this.$store.state.filtering.marketplace.statusArtist } }).catch(()=>{});
          }
          if(this.$store.state.activeMarket == "home-collections"){
            this.$router.push({ name: "home-collections", query: { search: this.$store.state.searchStr, status: this.$store.state.filtering.collections.status } }).catch(()=>{});
          }
          if(reload){
            this.forceReload++
          }
          
          
       // }
    },

    clearSearch: function(){
      if(this.$store.state.activeMarket == "marketplace"){
        this.$router.push({ path: '/marketplace/1', query: { search: ""} }).catch(()=>{});
      }else if(this.$store.state.activeMarket == "home-collections"){
        this.$router.push({ path: '/collections/1', query: { search: ""} }).catch(()=>{});
      }else{
        this.$router.push({ path: '/page/1', query: { search: ""} }).catch(()=>{});
      }
      this.$store.state.homeScrollState.scrollToId=null
      this.$store.state.marketplaceScrollState.scrollToId=null
      this.$store.state.collectionsScrollState.scrollToId=null
      this.$store.state.searchStr = ""
      this.forceReload++
    },
    detectClickOutOfExplore: function(e){  
      if(this.showExplore){
        if (!document.getElementById('explore_menu').contains(e.target)){
          this.showExplore = !this.showExplore
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>

#select_search{
  position: absolute;
  top: 1px;
  right: 50px;
  height: 37px;
  border: 0;
  border-left: solid 1px #ccc;
  padding-left: 10px;
  padding-right: 5px;
  padding-bottom: 2px;
  background-color:white;
  -webkit-appearance:menulist;
  &:focus-visible{
    outline: none;
  }
}
#explore_menu{
  //opacity: 0;
  //pointer-events: none;
  position: absolute;
  z-index: 2;
  left: -30px;
  background-color: white;
  padding: 15px;
  box-shadow: 0px 7px 16px -6px rgba(0,0,0,0.26);
  border-radius: 15px;
  top: 28px;
}
#explore_menu li button{
  text-align: center;
  width: 100%!important;
}
#explore_menu.open{
  opacity: 1;
  pointer-events:all;
}
#view_container{
  width: 100%;
  max-width: 80%;
  margin: auto;
  margin-top: 30px;
}
#main #mainLogo-white {
    max-width: 90px;
    margin-right: 40px;
    cursor: pointer;
}
.market_menu{
  padding: 30px;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 20%);
  width: 100%;
  .wrapper{
    max-width: 85%;
    margin: auto;
    width: 100%;
    display: flex;
  }
  button, a.button, #swap_token{
    border-radius: 50px;
    transition: all .4s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto !important;
    border: 1px solid #9A0E88;
    background-color: white;
    padding: 6px 20px;
    color: #000000;
    letter-spacing: -0.015em;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    margin-right: 10px;
    &.router-link-active, &.active, &:hover{
      background-color: #9A0E88;
      color: #FFFFFF;
    }
    &:hover{
      color: #ffffff;
    }
  }
  a.button{
    text-align: center;
    color: white;
    border: 1px solid #9A0E88;
    background-color: white;
    padding: 6px 20px;
    color: #000000;
    font-weight: 500;
    font-size: 14px;

  }
  button.noborder{
    border: 0;
    font-size: 17px;
    padding: 6px 0;
    transition: none;
    margin-right: 35px;
    &:last-child{
      margin-right: 0px;
    }
    &.router-link-active, &.active{
      background-color: white;
      color: #000000;
      font-weight: 700;
      color: #7F00FF;
    }
    &:hover{
      color: #000000;
      background-color: white;
      color: #7F00FF;
    }
  }
  #wrap_buy_apply{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
  }
 
}
#searchField{
    position: relative;
    flex: 1;
    margin: auto;
    display: block;
    margin-right: 40px;
    margin-left: 40px;
  input{
    width: 100%;
    padding: 8px 25px 8px 20px;
    border-radius: 50px;
    border: solid 1px #000;
    //min-width: 280px;
    font-size:14px;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #808080!important;
        opacity: 1; /* Firefox */
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.02em;
        font-weight: 500;
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #808080!important;
        opacity: 1;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.02em;
        font-weight: 500;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: #808080!important;
        opacity: 1;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.02em;
        font-weight: 500;
      }

    &.on{
      padding: 8px 25px 8px 35px;
    }
  }
  button{
    top: 0px;
    border: 0;
    position: absolute;
    z-index: 50;
    right: 0;
    background-color: transparent;
    padding: 10px;
    
    &:hover{
      color:#9d3bd1;
    }
  }
  input:focus-visible {
      outline: none;
      
  }
}
.fa-times{
  cursor: pointer;
  color: red;
  position: absolute;
  left: 15px;
  top: 12px;
}
@media(max-width: 1450px){
  .market_menu{
    padding: 20px 15px 20px 15px;
    .wrapper{
      padding: 5px 25px;
      //max-width: 90%;
      max-width: 100%;
      padding-left: 80px;
    }
  }
}
@media(max-width: 1200px){
  #select_search{
    right: 35px;
  }
  #main #mainLogo-white {
    margin-right: 30px;
  }
  #view_container{
    max-width: 90%;
  }
  .market_menu{
    padding: 20px 15px 20px 15px;
    .wrapper{
      padding: 5px 25px;
      //max-width: 90%;
      max-width: 100%;
      padding-left: 80px;
    }

    button, a.button{
      letter-spacing: 0.4px;
      //padding: 10px 15px;
      font-size: 14px;
    }
    button{
      &:first-child{
        &.noborder{
          margin-right: 20px;
        }
      }
      &:last-child{
        &.noborder{
          margin-right: 0px;
        }
      }
    }
    #searchField{
      margin-right: 20px;
      margin-left: 20px;
      button{
        margin-right: 0;
      }
    }

  }
}
@media(min-width: 992px) and (max-width: 1200px){
    .mr-lg-3{
      margin-right: 0.5rem !important;
    }
}
@media(max-width: 992px){
  #main #mainLogo-white {
    margin-right: 0px;
  }
  #view_container{
    max-width: 100%;
    margin-top: 10px;
  }
  .market_menu{
    .wrapper{
      top: 0px;
      padding: 5px 25px;
      position:relative;
      width:inherit;
      max-width:none;
      z-index: 700;
      background-color: rgba(255,255,255,0.95);
      flex-direction: row;
      justify-content: center;
      align-items: center;
      transition: all .4s ease;
      flex-wrap: wrap;
    }
    a.button, button{
      width: auto!important;
    }
    #wrap_buy_apply{
      width: auto;
    }
    a.button.apply{
      width: 45%!important;
    }
    #swap_token{
      width: 45%!important;
      margin-left: 0;
    }
    &.show{
      top: 0;
    }

    .wrap_market_btn{
      flex-direction: row;
      width: auto;
      flex: 0 0 100%;
      margin-bottom: 10px;

    }

    #searchField{
      button{
        width: auto!important;
      }
    }
  }
}
@media(min-width: 601px) and (max-width: 680px){
  .market_menu .wrapper{
        padding: 5px 0px;
  }
  .market_menu #searchField{
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media(max-width: 600px){
  #searchField{
    flex: 0 0 100%;
    margin-bottom: 15px;
    
  }
  .market_menu #searchField{
    margin-left: 0px;
    margin-right: 0px;
  }
   .market_menu .wrapper{
        padding: 5px 0px;
  }
  #select_search{
  right: 35px;
  padding-left: 5px;
  padding-right: 5px;
  &:focus-visible{
    outline: none;
  }
}
}
</style>