<template>
  <div id="widgetUser">   
      <button id="login" @click="chooseSigningMethod()" v-show="!this.$store.state.user.isLogged">
        <img src="../../assets/images/login.svg" /> <span> Log in</span>
      </button>
      <div :class="this.$store.state.user.isLogged ? 'connected' : ''">
        <a href="#" ref="goAccount" class="goAccount" v-show="this.$store.state.user.isLogged" @click.prevent="goToAccount">
          <img :src="$store.state.user.thumbnail != '' ? getIpfsLink($store.state.user.thumbnail) : emptyThumb" class="thumb account"/> <span>Account</span>
        </a>
      </div>

  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js';
import gotoMixin from '@/mixins/goto.js';

export default {
  mixins: [globalMixin, gotoMixin],
  data: function(){
    return {
      isHovering: false,
      thumbURL: this.$store.state.user.thumbnail
    }
  },
  watch:{
    '$store.state.user.userReady': function(){
      this.goToAccount()
    }
  },
  methods: {
     chooseSigningMethod(){
       if(this.$store.state.user.isLogged){
         this.goToAccount()
       }else{
         this.$store.state.signingChoice.showChoice = true
       }
    }
  }
}
</script>

<style lang="scss" scoped>
#widgetUser {
  background-color: #9A0E88;
  color: white;
  border-radius: 25px;
  transition: all .4s ease;

  #login {
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
    opacity: 1;
    transition: all .4s ease;
    outline: none;
    padding: 6px 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    border-radius: 50px;
    span {
      font-weight: 300;
      display: inline-block;
      padding-left: 10px;
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 147%;
      text-align: center;
      letter-spacing: -0.015em;
      color: #FFFFFF;
    }

      &:hover {
        background-color: #1E1A1A;
      }
  }

  &.open {
    margin-right: 0;
  }

  .connected {
    img {
      border-radius: 50%;
      width: 22px;
      margin-right: 10px;
    }

    a {
      padding: 5px 20px 5px 7px;
      color: white;
      display: flex;
      align-items: center;
      transition: all .4s ease;
      justify-content: flex-start;
      font-size: 14px;
      border-radius: 50px;
      &:hover {
        background-color: #1E1A1A;
      }
    }
  }
  @media(max-width:1200px) {
    position: absolute;
    right: 10px;
    top: 5px;
    z-index: 25222;
    #login{
      padding: 12px;
      span{
        display: none;
      }
    }
    .goAccount{
      padding: 5px!important;
      span{
        display: none;
      }
      img{
        margin-right: 0;
        width: 30px;
        height: 30px;
        object-fit: cover;
      }
    }
  }
}

</style>