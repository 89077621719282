
<template>
  <div class="stats text-center mb-2" style="color: #ffffff;"><strong>{{artists}} Artists</strong> <span class="circle"></span> <strong>{{drops}} Drops</strong> <span class="circle"></span> <strong>{{sales}} Sales</strong></div>
</template>
<script>
import globalMixin from '@/mixins/global.js';
import encodeUrl from 'encodeurl';
export default {
  mixins: [globalMixin],
  props: ['numOfArtist'],
  data: function(){
    return {
      artists: 0,
      drops: 0,
      sales: 0,
      listenToStats: undefined
    }
  },
  created(){
    this.getSales()
    this.getTotalArtists()
    this.getTotalArtworks()
  },
  methods: {
    getSales: async function(){
      let _this = this
      let regex = encodeUrl("^total_nft_issued")
      let salesNumber = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.dapp_addr_artworks+"?matches="+regex)
      salesNumber = await salesNumber.json()
      this.sales = salesNumber[0].value

    },
    getTotalArtists: async function(){
      let artistsNumber = await fetch(this.$store.state.config.signArtApiURL+"/users/count/all")
      artistsNumber = await artistsNumber.json()
      this.artists = artistsNumber.count

    },
    getTotalArtworks: async function(){
      let artsNumber = await fetch(this.$store.state.config.signArtApiURL+"/artworks/count/all")
      artsNumber = await artsNumber.json()
      this.drops = artsNumber.count
    }
  },
  mounted(){
    let _this = this
    this.listenToStats = setInterval(function(){
      _this.getSales()
      _this.getTotalArtists()
      _this.getTotalArtworks()
    }, 10000)
  },
  destroyed(){
    clearTimeout(this.listenToStats)
  }
}
</script>
<style lang="scss" scoped>
.stats{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .circle{
    display: inline-block;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 50%;
  }
}
</style>